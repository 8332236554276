// ----------------------------------------------------
// Imports
// ----------------------------------------------------

import React from "react";
import { apiv2 } from "~base/API/v2/apiv2";
import { PostResponse } from "~base/Model/ResponseModels/PostResponse";
import { Session } from "~base/Model/Session";
import { PostAction } from "./Store";
import { PostViewState } from "./Types";

// ----------------------------------------------------
// Functions
// ----------------------------------------------------

/**
 * Get the user post.
 * @param state 
 * @param session 
 * @param context 
 * @param dispatch 
 */
export function getPost(state: PostViewState, session: Session, dispatch: React.Dispatch<PostAction>) {
    apiv2(`api/v2/get/post/${state.postId}`, "GET").then((response) => {
        if (response.error != null) {
            session.setInformation("error", "Views.Post.CouldNotGetPost");
            console.error(response.error);
        } else {
            const result = response.data as PostResponse;
            console.log(result);
            dispatch({ type: "setPost", value: result });
        }
    });
}
