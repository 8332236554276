import { apiv2 } from "~base/API/v2/apiv2";
import { SearchAdsRequest } from "~base/Model/RequestModels/SearchAdsRequest";
import { Session } from "~base/Model/Session";
import { Actions } from "./Store";

export function getAdsFromSearch(searchAdsRequest: SearchAdsRequest, session: Session, dispatch: React.Dispatch<Actions>) {
    dispatch({ type: "setLoading", value: true });
    apiv2("api/v2/post/searchAds", "POST", searchAdsRequest).then((response) => {
        console.log(response);
        if(response.error === null){
            dispatch({ type: "setAds", value: response.data })
        }
        dispatch({ type: "setLoading", value: false });
    });
}