import { SearchBar } from "./SearchBar";
import React from "react";
import { useNavigate } from "react-router-dom";
import { Session } from "~base/Model/Session";
import { AiOutlineMenu } from "react-icons/ai";
import { Row } from "~base/Components/Elements/Cosmetic/Row";
import { ViewState } from "../Types";
import { Actions } from "../Store";
import { Avatar, Button, Link, Typography } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { ABOUT_US, LOGIN_PATH } from "~base/Routing/Paths";
import { HendigoTheme } from "~base/Resources/Styling/HendigoTheme";
import { useTheme } from "react-jss";
import { useIntl } from "~base/Internalization/IntlContext";
import { useFilesAPIService } from "~base/Services/FilesAPIServiceContext";

interface DesktopNavbarProps {
    classes: any;
    session: Session;
    state: ViewState;
    dispatch: React.Dispatch<Actions>;
}

export const DesktopNavbar: React.FC<DesktopNavbarProps> = ({ classes, state, dispatch }) => {

    const intl = useIntl();
    const navigate = useNavigate();
    const filesAPIService = useFilesAPIService();

    const [searchValue, setSearchValue] = React.useState("");

    const theme: HendigoTheme = useTheme();

    const {
        companyProfile,
        userProfile,
    } = state;

    const profilePicture = filesAPIService.getProfileImageSmall(
        companyProfile ?
            companyProfile.small_image_pathname
            : userProfile?.small_image_pathname
    );

    const firstLetterInName = companyProfile ?
        companyProfile.company_name.substring(0, 1)
        : userProfile?.fullname.substring(0, 1);

    const profileLink = companyProfile ?
        `/foretag/${companyProfile.company_id}`
        : `/anvandare/${userProfile?.user_id}`;

    return <div
        className={classes.navbar}
        style={
            window.location.pathname === "/hem" ||
                window.location.pathname === LOGIN_PATH ||
                window.location.pathname === "/registrera" ||
                window.location.pathname === "/jobb" ||
                window.location.pathname === "/fardigstall-privat" ||
                window.location.pathname === "/fardigstall-foretag" ||
                window.location.pathname === ABOUT_US 
                ?
                { position: "absolute", width: "80%", background: (theme.darkMode ? "rgb(0, 0, 0, 0.5)" : "rgb(255, 255, 255, 0.5)"), border: "none" }
                : {}
        }
    >

        <div className={classes.logoSection}>
            <Typography variant="h4">
                <Link
                    component={RouterLink}
                    to={userProfile || companyProfile ? "/" : "/hem"}
                    underline="none"
                    color="inherit"
                >hendigo</Link>
            </Typography>
        </div>

        <div className={classes.searchSection}>
            <div style={{ margin: "0 auto" }}>
                {!window.location.pathname.includes("/jobb") &&
                    window.location.pathname !== "/hem" &&
                    window.location.pathname !== LOGIN_PATH &&
                    window.location.pathname !== "/registrera" &&
                    window.location.pathname !== "/fardigstall-privat" &&
                    window.location.pathname !== "/fardigstall-foretag" &&
                    window.location.pathname !== ABOUT_US  ?
                    <SearchBar
                        value={searchValue}
                        onChange={value => setSearchValue(value)}
                        placeholder={intl.formatText("View.Navbar.SearchBar.Placeholder")}
                        onSearch={() => searchValue.length > 0 ? navigate('/resultat?s=' + searchValue) : null}
                    /> : null}
            </div>
        </div>

        <div className={classes.moreSection}>

            <Row style={{ columnGap: "15px", flexWrap: "nowrap" }}>
                {(
                    window.location.pathname === "/hem"
                    || window.location.pathname === LOGIN_PATH
                    || window.location.pathname === "/registrera"
                    || window.location.pathname.includes("/jobb")
                    ||window.location.pathname === ABOUT_US 
                ) && (
                        <>
                            <Link
                                component={RouterLink}
                                to="/hem"
                                className={classes.link}
                                underline="none"
                                color="inherit"
                            >
                                {intl.formatText("Views.Navbar.Home")}
                            </Link>

                            <Link
                                component={RouterLink}
                                to="/jobb"
                                className={classes.link}
                                underline="none"
                                color="inherit"
                            >
                                {intl.formatText("Views.Navbar.Jobs")}
                            </Link>

                            <Link
                                component={RouterLink}
                                to="/services"
                                className={classes.link}
                                underline="none"
                                color="inherit"
                            >
                                {intl.formatText("Views.Navbar.Services")}
                            </Link>

                            <Link
                                component={RouterLink}
                                to="/pricing"
                                className={classes.link}
                                underline="none"
                                color="inherit"
                            >
                                {intl.formatText("Views.Navbar.Pricing")}
                            </Link>
                            <Link
                                component={RouterLink}
                                to={ABOUT_US}
                                className={classes.link}
                                underline="none"
                                color="inherit"
                                width="56px"
                            >
                                {intl.formatText("Views.Navbar.AboutUs")}
                            </Link>
                        </>
                    )}

                {(
                    state.userProfile === null
                    && state.companyProfile === null
                    && window.location.pathname !== LOGIN_PATH
                    && window.location.pathname !== "/registrera"

                ) ? (
                    <Button
                        onClick={() => navigate(LOGIN_PATH)}
                        sx={{ width: "202px" }}
                        variant="contained"
                        className={classes.loginButton}
                    >
                        {intl.formatText("Views.Navbar.Login")}
                    </Button>
                ) : (
                    (
                        window.location.pathname !== LOGIN_PATH
                        && window.location.pathname !== "/registrera"
                    ) && (
                        <div className={classes.link}>
                            <Avatar
                                src={profilePicture}
                                className={classes.avatar}
                                component={RouterLink}
                                to={profileLink}
                            >
                                {firstLetterInName}
                            </Avatar>
                        </div>
                    )
                )}

                <Button onClick={() => dispatch({ type: "setNavigationDrawerOpen", value: true })}>
                    <AiOutlineMenu style={{ fontSize: "26px" }} />
                </Button>
            </Row>
        </div>

    </div>
}