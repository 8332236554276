import React from "react";
import { BsDot } from "react-icons/bs";
import { createUseStyles, useTheme } from "react-jss"
import { useNavigate } from "react-router-dom";
import { HendigoTheme } from "~base/Resources/Styling/HendigoTheme";
import { HendigoBaseTheme } from "../../../Resources/Styling/HendigoBaseTheme";

const useStyles = createUseStyles({
    wrapper: {

    },
    ad: {
        margin: "0 auto",
        marginTop: "10px",
        width: "95%",
        height: "200px",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        opacity: "0.9",
        position: "relative",
        borderRadius: "10px"
    },
    bottom: {
        position: "absolute",
        width: "100%",
        backgroundColor: HendigoBaseTheme.colors.hendigoDark + "ba",
        bottom: "0px",
        color: "white",
        paddingBottom: "5px",
        borderRadius: "0px 0px 10px 10px"
    },
    title: {
        fontWeight: 600,
        margin: "5px 5px 0px 5px"
    },
    url: {
        marginLeft: "5px"
    },
    dot: {
        '&:hover': {
            cursor: "pointer"
        }
    },
    link: {
        '&:hover': {
            cursor: "pointer"
        }
    }
});

export type Slide = { imageURL: string, title?: string, link?: string, hendigoAd?: boolean, subtitle?: string }

interface SlideShowProps {
    slides: Slide[],
    cssWidth?: string,

    /** Slider frequency in milliseconds (ms) */ 
    timer?: number
}

/**
 * SlideShow component
 * @slides slides
 * @cssWidth cssWidth
 * @timer Slider frequency in milliseconds (10000)
 * @returns SlideShow component
 */
export const SlideShow: React.FC<SlideShowProps> = ({ slides, cssWidth, timer = 10000 }) => {

    const classes = useStyles();
    const theme: HendigoTheme = useTheme();
    const style = {
        width: cssWidth
    }

    const [index, setIndex] = React.useState<number>(0);
    const [slide, setSlide] = React.useState<Slide>(slides[0]);
    const [timeoutId, setTimeoutId] = React.useState<NodeJS.Timeout>();

    let navigate = useNavigate();
    
    React.useEffect(() => {
        clearTimeout(timeoutId);
        const id = setTimeout(() => {
            if (index + 1 < slides.length) {
                setSlide(slides[index + 1]);
                setIndex(index + 1);
            } else {
                setSlide(slides[0]);
                setIndex(0);
            }
        }, timer);
        setTimeoutId(id);
        // eslint-disable-next-line
    }, [index, slides]);

    return <div
        style={style}
        className={classes.wrapper + " " + (slide.link ? classes.link : "")}
    >
        <div
            className={classes.ad}
            style={{ backgroundImage: "url(" + slide.imageURL + ")" }}
            onClick={() => {
                if(slide.link && slide.hendigoAd === true){
                    navigate(slide.link);
                } else if (slide.link){
                    window.open(slide.link, '_blank');
                }
            }}
        >
            <div className={classes.bottom}>
                {slide.title ? <div className={classes.title}>{slide.title.length > 75 ? slide.title.substring(0, 75) + "..." : slide.title}</div> : null}
                {slide.link || slide.subtitle ? <div className={classes.url}>{slide.subtitle ? slide.subtitle : slide.link}</div> : null}
            </div>
        </div>

        <div style={{ textAlign: "center", fontSize: "28px" }}>
            {slides.map((slide, thisIndex) => {
                return <BsDot
                    key={thisIndex + slide.imageURL}
                    onClick={() => {
                        setIndex(thisIndex);
                        setSlide(slide);
                    }}
                    style={{
                        color: thisIndex === index ? theme.textPrimary : theme.textSecondary
                    }}
                    className={classes.dot}
                />
            })}
        </div>

    </div>
}