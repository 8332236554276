import { Button, Typography } from "@mui/material";
import React from "react";
import { Loader } from "~base/Components/Elements/Cosmetic/Loader";
import { MiddleWrapper } from "~base/Components/Elements/Cosmetic/MiddleWrapper";
import { ViewBaseProps } from "~base/Model/ViewBaseProps";
import { initialState, reducer } from "./Store";
import { useNavigate } from "react-router-dom";
import { AdCard } from "~base/Components/Composite/AdCard/AdCard";
import { LOGIN_PATH } from "~base/Routing/Paths";
import { getMyApplications } from "./Fetch";
import { Link } from "react-router-dom";
import { useIntl } from "~base/Internalization/IntlContext";

export const MyApplications: React.FC<ViewBaseProps> = ({ session }) => {

    const intl = useIntl();

    window.view = "MyApplications";
    intl.formatPageTitle("PageTitle.MyApplications");

    const navigate = useNavigate();

    React.useEffect(() => {
        if (session.userProfile == null) {
            navigate(`${LOGIN_PATH}?redirect=/jobb/mina-ansokningar`);
        } else {
            getMyApplications(session, dispatch);
        }
    }, [session]);

    // State and dispatch
    const [state, dispatch] = React.useReducer(reducer, initialState());

    return <MiddleWrapper>
        {state.loading && <div style={{ display: "flex", flexDirection: "column", rowGap: "25px", alignItems: "center" }}>
            <Typography variant={"h5"}>
                {intl.formatText(state.loadingMessage)}
            </Typography>
            <Loader />
        </div>}

        {state.applications.length > 0 ?
            state.applications.map(application => {
                return <AdCard
                    key={application.application.id}
                    ad={application.ad}
                    onClickControlPanel={() => navigate(application.application.id)}
                    controlPanelTextCode="View.MyApplications.GoToApplication"
                />
            }) :
            <>
                <Typography variant={"h5"} align="center">
                    {intl.formatText("Views.MyApplications.NoApplications")}
                </Typography>

                <br/>

                <Button
                    variant="contained"
                    color="primary"
                    to="/jobb"
                    component={Link}
                    style={{width: "200px", margin: "0 auto"}}
                >
                    {intl.formatText("Views.MyApplications.SearchForAds")}
                </Button>
            </>
        }

    </MiddleWrapper>
}