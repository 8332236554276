/**
 * OLD DO NOT USE! USE THEME INSTEAD
 */
export const HendigoBaseTheme ={
    colors: {
        hendigoPrimary: '#52ab98',
        hendigoHover: '#479483',
        hendigoSecondary: '#2b6777',
        hendigoDark: '#212529',
        hendigoLightDark: '#2c3136',
        hendigoPurple: '#6a11cb',
        hendigoBlue: '#2575fc',
        hendigoLightGray: "#f1f5f8",
        hendigoDarkBlue: "#0055fa",
        textColor: "#9EA2A6",
        rgb: {
            hendigoBlue: "rgb(37, 117, 252)",
            hendigoBlueOpacity50: "rgb(37, 117, 252, 0.5)"
        }
    }
}