// ----------------------------------------------------
// Imports
// ----------------------------------------------------

import { CompanyViewState } from "./Types";
import React from "react";
import { CompanyAction } from "./Store";
import { Session } from "~base/Model/Session";
import { apiv2 } from "~base/API/v2/apiv2";
import { CompanyProfileResponse } from "~base/Model/ResponseModels/CompanyProfileResponse";
import { PostResponse } from "~base/Model/ResponseModels/PostResponse";
import { AdResponse } from "~base/Model/ResponseModels/AdResponse";

// ----------------------------------------------------
// Functions
// ----------------------------------------------------

/**
 * Fetch company profile
 * @param state 
 * @param dispatch 
 * @param session 
 */
export function fetchCompanyProfile(stateYourProfile: boolean, stateID: string, dispatch: React.Dispatch<CompanyAction>, session: Session) {
    if (stateYourProfile === false) {
        apiv2(`api/v2/get/companyProfile/${stateID}`, "GET").then((response => {
            if (response.error != null) {
                session.setInformation("error", response.error);
                dispatch({ type: "setAccountNotFound", value: true });
            } else {
                dispatch({ type: "setProfile", value: response.data as CompanyProfileResponse });
                dispatch({ type: "setAccountNotFound", value: false });
            }
        }));
    } else {
        dispatch({ type: "setProfile", value: session.companyProfile });
        dispatch({ type: "setAccountNotFound", value: false });
    }
}

/**
 * Fetch the company posts
 * @param state 
 * @param dispatch 
 * @param session 
 */
export function fetchCompanyPosts(stateID: string, dispatch: React.Dispatch<CompanyAction>, session: Session) {
    apiv2(`api/v2/get/companyPosts/${stateID}`, "GET").then((response => {
        if (response.error != null) {
            session.setInformation("error", response.error);
        } else if (response.data != null) {
            dispatch({ type: "setPosts", value: response.data as PostResponse[] });
        }
    }));
}

/**
 * Post follow company.
 * @param state 
 * @param dispatch 
 * @param session 
 */
export function postFollowCompany(state: CompanyViewState, dispatch: React.Dispatch<CompanyAction>, session: Session) {
    const obj = {
        user_id: session.userId,
        followed_user_id: null,
        company_id: session.companyId,
        followed_company_id: state.id,
    }

    apiv2(`api/v2/post/follow`, "POST", obj).then((response => {
        if (response.error != null) {
            session.setInformation("error", response.error);
        } else {
            dispatch({ type: "setIsFollowing", value: true });
        }
    }));
}

export function getCompanyAds(companyId: string, dispatch: React.Dispatch<CompanyAction>, session: Session) {
    apiv2(`api/v2/get/companyAds/${companyId}`, "GET").then((response => {
        if (response.error != null) {
            session.setInformation("error", response.error);
        } else if (response.data != null) {
            dispatch({ type: "setAds", value: response.data as AdResponse[] });
        }
    }));
}

/**
 * Get follow status
 * @param state 
 * @param dispatch 
 * @param session 
 */
export function getFollowCompanyStatus(stateID: string, dispatch: React.Dispatch<CompanyAction>, session: Session) {
    apiv2(`api/v2/get/followCompanyStatus/${stateID}`, "GET").then((response) => {
        if (response.error != null) {
            session.setInformation("error", response.error);
        } else {
            dispatch({ type: "setIsFollowing", value: response.data as boolean });
        }
    });
}

/**
 * Remove follow company
 * @param state 
 * @param dispatch 
 * @param session 
 */
export function removeFollowCompany(state: CompanyViewState, dispatch: React.Dispatch<CompanyAction>, session: Session) {
    apiv2(`api/v2/delete/followCompany/${state.id}`, "DELETE").then((response => {
        if (response.error != null) {
            session.setInformation("error", response.error);
        } else {
            dispatch({ type: "setIsFollowing", value: false });
        }
    }));
}