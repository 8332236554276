import { createUseStyles } from "react-jss";
import { HendigoTheme } from "~base/Resources/Styling/HendigoTheme";

export const useStyles = createUseStyles((theme: HendigoTheme) => ({
    span: {
        color: theme.dark,
        fontSize: "70px",
    },
    image: {
        height: "400px",
    },
    row: {
        justifyContent: "center",
        gap: "2%",
        padding: "0px 2%",
    },
    row2: {
        justifyContent: "end",
        gap: "2%",
        padding: "0px 2%",
    },
    '@media (max-width: 1343px)': {
        image: {
            display: "none"
        }
    },
}));