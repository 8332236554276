import { FeedSideNavbarStyles } from "./Styling";
import { AiOutlineCalendar, AiOutlineHome, AiOutlineUsergroupAdd } from 'react-icons/ai'
import { BsBadgeAd } from 'react-icons/bs'
import { AiOutlineProfile } from 'react-icons/ai'
import { FiLogOut } from 'react-icons/fi'
import { useNavigate, useParams } from "react-router-dom";
import { CgProfile } from "react-icons/cg";
import { HiOutlineUserGroup } from "react-icons/hi";
import React from "react";
import { TbHammer, TbHeartHandshake, TbHierarchy } from "react-icons/tb";
import { GoTriangleLeft, GoTriangleDown } from "react-icons/go";
import { Divider } from "~base/Components/Elements/Cosmetic/Divider";
import { HendigoTheme } from "~base/Resources/Styling/HendigoTheme";
import { useTheme } from "react-jss";
import { ViewBaseProps } from "~base/Model/ViewBaseProps";
import { apiv2 } from "~base/API/v2/apiv2";
import { Button as MUIButton } from "@mui/material";
import { LOGIN_PATH } from "~base/Routing/Paths";
import { useIntl } from "~base/Internalization/IntlContext";

export const FeedSideNavbar: React.FC<ViewBaseProps> = ({ session }) => {
    
    const intl = useIntl();
    const navigate = useNavigate();
    const theme: HendigoTheme = useTheme();
    const classes = FeedSideNavbarStyles({ theme });

    const params = useParams();
    const paramId = params.id;

    const [userId, setUserId] = React.useState(session.userId);
    const [companyId, setCompanyId] = React.useState(session.companyId);

    const [logoutLoading, setLogoutLoading] = React.useState(false);

    const [showCooperationFolder, setShowCooperationFolder] = React.useState(false);

    const [showAdsFolder, setShowAdsFolder] = React.useState(window.location.pathname.includes("annonser"));

    React.useEffect(() => {
        const obs = () => {
            setUserId(session.userId);
            setCompanyId(session.companyId);
        }
        session.addObserver(obs);
        return () => {
            session.removeObserver(obs);
        }
    }, [paramId, session, session.companyProfile, session.userId, session.companyId]);

    return <div className={classes.wrapper} >
        {/*<Button className={window.location.pathname === "/" ? classes.button + " " + classes.active : classes.button} onClick={() => navigate("/")} >
            <AiOutlineHome className={classes.button_icon}></AiOutlineHome>
            <div style={{ marginTop: "3px" }}>{intl.formatText("Views.SideNavBar.Home")}</div>
</Button>*/}

        <MUIButton
            fullWidth
            variant={window.location.pathname === "/" ? "contained" : "text"}
            style={{ fontSize: "16px" }}
            onClick={() => navigate("/")}
        >
            <AiOutlineHome style={{ fontSize: "25px" }} />
            <div style={{ margin: "5px auto 0px 15px" }}>{intl.formatText("Views.SideNavBar.Home")}</div>
        </MUIButton>

        {companyId ?
            <>
                <MUIButton
                    fullWidth
                    variant={window.location.pathname.includes("/foretag/") && companyId === paramId ? "contained" : "text"}
                    style={{ fontSize: "16px" }}
                    onClick={() => navigate("/foretag/" + companyId)}
                >
                    <AiOutlineProfile style={{ fontSize: "25px" }} />
                    <div style={{ margin: "5px auto 0px 15px" }}>{intl.formatText("Views.SideNavBar.MyCompany")}</div>
                </MUIButton>

                <Divider />

                <MUIButton
                    fullWidth
                    variant={!showAdsFolder && (window.location.pathname.includes("/foretag/annonser")) ? "contained" : "text"}
                    style={{ fontSize: "16px" }}
                    onClick={() => setShowAdsFolder(!showAdsFolder)}
                >
                    <BsBadgeAd style={{ fontSize: "25px" }} />
                    <div style={{ margin: "5px auto 0px 15px" }}>{intl.formatText("Views.SideNavBar.AdsTab")}</div>
                    {showAdsFolder ? <GoTriangleDown style={{ float: "right" }} /> : <GoTriangleLeft style={{ float: "right" }} />}
                </MUIButton>

                {showAdsFolder && <div className={classes.folder}>
                    <div
                        className={window.location.pathname === "/foretag/annonser" ? classes.folderLink + " " + classes.active : classes.folderLink}
                        onClick={() => navigate("/foretag/annonser")}
                    >
                        {intl.formatText("Views.SideNavBar.Ads")}
                    </div>

                    <div
                        className={window.location.pathname === "/foretag/annonser/statistik" ? classes.folderLink + " " + classes.active : classes.folderLink}
                        onClick={() => navigate("/foretag/annonser/statistik")}
                    >
                        Statistik
                    </div>
                </div>}

                <MUIButton
                    fullWidth
                    variant={window.location.pathname === "/foretag/marknadsforing" ? "contained" : "text"}
                    style={{ fontSize: "16px" }}
                    onClick={() => navigate("/foretag/marknadsforing")}
                    disabled
                >
                    <BsBadgeAd style={{ fontSize: "25px" }} />
                    <div style={{ margin: "5px auto 0px 15px" }}>{intl.formatText("Views.SideNavBar.Marketing")}</div>
                </MUIButton>

                <MUIButton
                    fullWidth
                    variant={window.location.pathname === "/projekt" ? "contained" : "text"}
                    style={{ fontSize: "16px" }}
                    onClick={() => navigate("/projekt")}
                    disabled
                >
                    <TbHierarchy style={{ fontSize: "25px" }} />
                    <div style={{ margin: "5px auto 0px 15px" }}>{intl.formatText("Views.SideNavBar.Project")}</div>
                </MUIButton>

                <MUIButton
                    fullWidth
                    variant={!showCooperationFolder && (window.location.pathname === "/underleverantorer" || window.location.pathname === "/overleverantorer") ? "contained" : "text"}
                    style={{ fontSize: "16px" }}
                    onClick={() => setShowCooperationFolder(!showCooperationFolder)}
                    disabled
                >
                    <TbHeartHandshake style={{ fontSize: "25px" }} />
                    <div style={{ margin: "5px auto 0px 15px" }}>{intl.formatText("Views.SideNavBar.Cooperation")}</div>
                    {showCooperationFolder ? <GoTriangleDown style={{ float: "right" }} /> : <GoTriangleLeft style={{ float: "right" }} />}
                </MUIButton>

                {showCooperationFolder && <div className={classes.folder}>
                    <div
                        className={window.location.pathname === "/underleverantorer" ? classes.folderLink + " " + classes.active : classes.folderLink}
                        onClick={() => navigate("/underleverantorer")}
                    >
                        {intl.formatText("Views.SideNavBar.Subcontractor")}
                    </div>
                    <div
                        className={window.location.pathname === "/overleverantorer" ? classes.folderLink + " " + classes.active : classes.folderLink}
                        onClick={() => navigate("/overleverantorer")}
                    >
                        {intl.formatText("Views.SideNavBar.TurnkeyContractor")}
                    </div>
                </div>}

                <MUIButton
                    fullWidth
                    variant={window.location.pathname === "/kompetens_och_sakerhet" ? "contained" : "text"}
                    style={{ fontSize: "16px" }}
                    onClick={() => navigate("/kompetens_och_sakerhet")}
                    disabled
                >
                    <div style={{ margin: "5px auto 0px 15px" }}>{intl.formatText("Views.SideNavBar.CompetenceAndSecurity")}</div>
                </MUIButton>
            </>
            : (userId && <>
                <MUIButton
                    fullWidth
                    variant={window.location.pathname.includes('/anvandare/') && userId === paramId ? "contained" : "text"}
                    style={{ fontSize: "16px" }}
                    onClick={() => navigate("/anvandare/" + userId)}
                >
                    <CgProfile style={{ fontSize: "25px" }} />
                    <div style={{ margin: "5px auto 0px 15px" }}>{intl.formatText("Views.SideNavBar.MyProfile")}</div>
                </MUIButton>

                <Divider />

                <MUIButton
                    fullWidth
                    style={{ fontSize: "16px" }}
                    onClick={() => setShowCooperationFolder(!showCooperationFolder)}
                >
                    <TbHammer style={{ fontSize: "25px" }} />
                    <div style={{ margin: "5px auto 0px 15px" }}>{intl.formatText("Views.SideNavBar.Work")}</div>
                    {showCooperationFolder ? <GoTriangleDown style={{ float: "right" }} /> : <GoTriangleLeft style={{ float: "right" }} />}
                </MUIButton>

                {showCooperationFolder && <div className={classes.folder}>
                    <div
                        className={window.location.pathname === "/jobb" ? classes.folderLink + " " + classes.active : classes.folderLink}
                        onClick={() => navigate("/jobb")}
                    >
                        {intl.formatText("Views.SideNavBar.Ads")}
                    </div>
                    <div
                        className={window.location.pathname === "/jobb/mina-ansokningar" ? classes.folderLink + " " + classes.active : classes.folderLink}
                        onClick={() => navigate("/jobb/mina-ansokningar")}
                    >
                        {intl.formatText("Views.SideNavBar.Applications")}
                    </div>
                </div>}

                <MUIButton
                    fullWidth
                    variant={window.location.pathname === "/natverk" ? "contained" : "text"}
                    style={{ fontSize: "16px" }}
                    onClick={() => navigate("/natverk")}
                >
                    <AiOutlineUsergroupAdd style={{ fontSize: "25px" }} />
                    <div style={{ margin: "5px auto 0px 15px" }}>{intl.formatText("Views.SideNavBar.Network")}</div>
                </MUIButton>

                <MUIButton
                    fullWidth
                    variant={window.location.pathname === "/event" ? "contained" : "text"}
                    style={{ fontSize: "16px" }}
                    onClick={() => navigate("/event")}
                    disabled
                >
                    <AiOutlineCalendar style={{ fontSize: "25px" }} />
                    <div style={{ margin: "5px auto 0px 15px" }}>Event</div>
                </MUIButton>

                <MUIButton
                    fullWidth
                    variant={window.location.pathname === "/grupper" ? "contained" : "text"}
                    style={{ fontSize: "16px" }}
                    onClick={() => navigate("/grupper")}
                >
                    <HiOutlineUserGroup style={{ fontSize: "25px" }} />
                    <div style={{ margin: "5px auto 0px 15px" }}>{intl.formatText("Views.SideNavBar.Groups")}</div>
                </MUIButton>
            </>)}

        <Divider />

        {/*<Button
            className={classes.button}
            onClick={() => {
                setLogoutLoading(true);

                apiv2("api/v2/get/logout/", "GET").then(
                    (response) => {
                        if (response.error != null) {
                            session.setInformation("error", intl.formatText("Views.SideNavBar.CouldNotLogOut"))
                            console.error(response.error);
                        } else {
                            navigate(LOGIN_PATH);
                            window.location.reload();
                            setLogoutLoading(false);
                        }
                    }
                ).catch();
            }}
            loading={logoutLoading}
        >
            <FiLogOut className={classes.button_icon}></FiLogOut><div style={{ marginTop: "2px" }}>{intl.formatText("Views.SideNavBar.LogOut")}</div>
        </Button>*/}

        <MUIButton
            fullWidth
            style={{ fontSize: "16px" }}
            onClick={() => {
                setLogoutLoading(true);

                apiv2("api/v2/get/logout/", "GET").then(
                    (response) => {
                        if (response.error != null) {
                            session.setInformation("error", intl.formatText("Views.SideNavBar.CouldNotLogOut"))
                            console.error(response.error);
                        } else {
                            navigate(LOGIN_PATH);
                            window.location.reload();
                            setLogoutLoading(false);
                        }
                    }
                ).catch();
            }}
            disabled={logoutLoading}
        >
            <FiLogOut style={{ fontSize: "25px" }} />
            <div style={{ margin: "5px auto 0px 15px" }}>{intl.formatText("Views.SideNavBar.LogOut")}</div>
        </MUIButton>

    </div>
}