// ----------------------------------------------------
// Imports
// ----------------------------------------------------

import { NavigateFunction } from "react-router-dom";
import { apiv2 } from "~base/API/v2/apiv2";
import { IntlContext } from "~base/Internalization/IntlContext";
import { Session } from "~base/Model/Session";
import { LOGIN_PATH } from "~base/Routing/Paths";

// ----------------------------------------------------
// Fetch functions
// ----------------------------------------------------

export function logout (session: Session, intl: IntlContext, navigate: NavigateFunction) {
    apiv2("api/v2/get/logout/", "GET").then(
        (response) => {
            if (response.error != null) {
                session.setInformation("error", intl.formatText("Views.SideNavBar.CouldNotLogOut"))
                console.error(response.error);
            } else {
                navigate(LOGIN_PATH);
                window.location.reload();
            }
        }
    ).catch();
}