import { PurpleWrapper } from "~base/Components/Composite/PurpleWrapper/PurpleWrapper";
import { ViewBaseProps } from "~base/Model/ViewBaseProps";
import { HomeStyles } from "./Styling";
import { useNavigate } from "react-router-dom";
import { Button, Container, Grid, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import React from "react";
import { LOGIN_PATH } from "~base/Routing/Paths";
import { useIntl } from "~base/Internalization/IntlContext";

export const Home: React.FC<ViewBaseProps> = ({ session }) => {

    const intl = useIntl();

    window.view = "Home";
    intl.formatPageTitle("PageTitle.Home")

    const classes = HomeStyles();
    const navigate = useNavigate();

    return <PurpleWrapper>
        <Container>
            <Grid container>
                <Grid item xs={12} md={6}>
                    <Typography variant="h1" color="white" fontWeight="600" ><span className={classes.span} >#</span>hendigo</Typography>
                    <Typography variant="h2" color="white" fontWeight="500">
                        {intl.formatText("View.Home.Swedens")}
                        <span className={classes.span} >{intl.formatText("View.Home.First")}</span> 
                        {intl.formatText("View.Home.Network")}
                    </Typography>
                    <Typography variant="h4" color="white" fontWeight="300">
                        {intl.formatText("View.Home.Ingress")}
                    </Typography>

                    <Grid container spacing={1} mt="25px">
                        {session.isAuth === false ? <>
                            <Grid item xs={6} md={4}>
                                <Button
                                    variant={"contained"}
                                    fullWidth
                                    sx={{ height: "45px" }}
                                    component={Link}
                                    to="/registrera"
                                >
                                    {intl.formatText("View.Home.RegisterButton")}
                                </Button>
                            </Grid>

                            <Grid item xs={5} md={3}>
                                <Button
                                    variant={"outlined"}
                                    //color="secondary"
                                    fullWidth
                                    sx={{ height: "45px" }}
                                    component={Link}
                                    to={LOGIN_PATH}
                                >
                                    {intl.formatText("View.Home.LoginButton")}
                                </Button>
                            </Grid>
                        </> : <>
                            <Grid item xs={5} md={3}>
                                <Button
                                    variant={"contained"}
                                    //color="secondary"
                                    fullWidth
                                    sx={{ height: "45px" }}
                                    component={Link}
                                    to="/"
                                >
                                    {intl.formatText("View.Home.FeedButton")}
                                </Button>
                            </Grid>
                        </>}


                    </Grid>
                </Grid>
                <Grid item xs={12} md={6}>
                    <div>
                        <iframe
                            src="https://my.spline.design/hendigo-b767c32121909d218bac66bdd617e04a/"
                            className={classes.splineFigure}
                            title="Spline figure"
                        />
                    </div>
                </Grid>
            </Grid>
        </Container>
    </PurpleWrapper>
}