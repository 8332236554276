// ----------------------------------------------------
// Imports
// ----------------------------------------------------

import { ViewState } from "./Types";

// ----------------------------------------------------
// Actions
// ----------------------------------------------------

type SetCreateGroupOverlayOpen = { type: "setCreateGroupOverlayOpen", value: boolean }
type SetGroups = { type: "setGroups", value: ViewState["groups"] }
type SetSearch = { type: "setSearch", value: string }

export type ViewAction = 
    SetCreateGroupOverlayOpen
    | SetGroups
    | SetSearch;

// ----------------------------------------------------
// Reducer
// ----------------------------------------------------

export const reducer = (state: ViewState, action: ViewAction): ViewState => {
    switch (action.type) {
        case "setCreateGroupOverlayOpen":
            return {
                ...state,
                createGroupOverlayState: {
                    open: action.value
                }
            }

        case "setGroups":
            return {
                ...state,
                groups: action.value
            }

        case "setSearch":
            return {
                ...state,
                search: action.value
            }

        default:
            return {
                ...state
            }
    }
}

// ----------------------------------------------------
// Initial State
// ----------------------------------------------------

export const initialState = (): ViewState => {
    return {
        createGroupOverlayState: {
            open: false,
        },
        groups: [],
        search: "",
    }
}