import { createUseStyles } from "react-jss";
import { HendigoTheme } from "~base/Resources/Styling/HendigoTheme";

export const HomeStyles = createUseStyles((theme: HendigoTheme) => ({
    wrapper: {
        padding: "50px 10%"
    },
    titleCol: {
        width: "50%",
    },
    title1: {
        color: "white",
        fontSize: "80px",
        marginBottom: "0"
    },
    title2: {
        color: "white",
        fontSize: "50px",
        margin: "0"
    },
    text: {
        color: "white",
        width: "75%",
        fontSize: "20px",
    },
    span: {
        color: theme.dark
    },
    splineFigure: {
        border: "none",
        width: "100%",
        height: "600px"
    },
    loginRegisterButtonRow: {
        alignItems: "center", 
        gap: "10px"
    },
    '@media (max-width: 899px)': {
        splineFigure: {
            display: "none"
        },
        titleCol: {
            width: "100%",
            height: "80vh"
        }
    },
}))