import { apiv2 } from "~base/API/v2/apiv2";
import { Product } from "~base/Model/Entities/Product";
import { CreateAdAction } from "./Store";
import { Session } from "~base/Model/Session";
import { CreateAdRequest } from "~base/Model/RequestModels/CreateAdRequest";
import { NavigateFunction } from "react-router-dom";

/**
 * Get products containing type Product.Ad...
 */
export function getAdProducts(dispatch: React.Dispatch<CreateAdAction>, session: Session) {
    apiv2(`api/v2/get/products/${"Product.Ad"}`, "GET").then((response) => {
        console.log(response);

        if (response.error != null) {
            session.setInformation("error", response.error);
        } else {
            const products = response.data as Product[];
            dispatch({ type: "setProducts", value: products });
            dispatch({ type: "setChosenProduct", value: products.length > 0 ? products[0] : null });
        }
    });
}

/**
 * Create Ad.
 * @param session 
 * @param createAdRequest 
 * @param navigate 
 */
export function createAd(session: Session, createAdRequest: CreateAdRequest, navigate: NavigateFunction) {
    console.log(createAdRequest)
    apiv2(`api/v2/post/ad`, "POST", createAdRequest).then((response) => {
        console.log(response);

        if (response.error != null) {
            session.setInformation("error", response.error);
        } else {
            navigate("/foretag/annonser");
        }
    });
}