import { apiv2 } from "~base/API/v2/apiv2";
import { PostComment } from "~base/Model/Entities/PostComment";
import { PostCommentResponse } from "~base/Model/ResponseModels/PostCommentResponse";
import { Session } from "~base/Model/Session";

/**
 * Edit a user post.
 * @param postId 
 * @param content 
 * @param session 
 */
export function editPost(postId: string, content: string, session: Session, onEditContent: (content: string) => void) {
    const obj = {
        postContent: content
    };
    apiv2(`api/v2/put/post/${postId}`, "PUT", obj).then((response) => {
        if (response.error !== null) {
            session.setInformation("error", response.error);
        } else {
            onEditContent(content);
            session.setInformation("information", "Post.PostEdited");
        }
    });
}

/**
 * Like a user post by post id.
 * @param postId 
 * @param session 
 */
export function likePost(postId: string, session: Session) {
    apiv2(`api/v2/post/postLike`, "POST", { postId: postId }).then((result) => {
        if (result.error !== null) {
            session.setInformation("error", result.error);
        }
    });
}

/**
 * Remove a user post like by post id.
 * @param postId 
 * @param session 
 */
export function removeLikePost(postId: string, session: Session) {
    apiv2(`api/v2/delete/postLike/${postId}`, 'DELETE').then((result) => {
        if (result.error !== null) {
            session.setInformation("error", result.error);
        }
    });
}

/**
 * Delete user post by post id.
 */
export function deletePost(postId: string, session: Session, onDelete: () => void) {
    apiv2(`api/v2/delete/post/${postId}`, "DELETE").then((response) => {
        if (response.error !== null) {
            session.setInformation("error", response.error);
        } else {
            onDelete();
            session.setInformation("information", "Post.PostDeleted");
        }
    });
}

/**
 * Get the user post comments.
 * @param state 
 * @param session 
 * @param context 
 * @param dispatch 
 * @param navigate 
 */
export function getPostComments(postId: string, session: Session, setComments: (comments: PostCommentResponse[]) => void) {
    apiv2(`api/v2/get/postComments/${postId}`, "GET").then((response) => {
        if (response.error != null) {
            session.setInformation("error", response.error);
            console.error(response.error);
        } else {
            const comments = response.data as PostCommentResponse[];
            setComments(comments);
        }
    });
}

/**
 * Add comment.
 * @param state 
 * @param session 
 * @param dispatch 
 * @param postId 
 * @param isUserPost 
 * @param navigate 
 */
export function addComment(postId: string, commentInputText: string, setCommentInputText: (text: string) => void,session: Session, setComments: (comments: PostCommentResponse[]) => void, comments: PostCommentResponse[]) {
    apiv2('api/v2/post/postComment', "POST", {
        postId: postId,
        content: commentInputText
    }).then((response) => {
        if (response.error != null) {
            session.setInformation("error", response.error!);
        } else {
            const comment = {
                ...response.data as PostComment,
                name: session.companyProfile ? session.companyProfile.company_name : session.userProfile!.fullname,
                small_image_pathname: session.companyProfile ? session.companyProfile.small_image_pathname : session.userProfile!.small_image_pathname,
                time_created: new Date().toISOString()
            } as PostCommentResponse;
            setComments([comment, ...comments]);
            setCommentInputText('');
        }
    });
}