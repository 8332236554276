import { createUseStyles } from "react-jss"
import { MiddleWrapper } from "~base/Components/Elements/Cosmetic/MiddleWrapper";
import { useIntl } from "~base/Internalization/IntlContext";
import { ViewBaseProps } from "~base/Model/ViewBaseProps";
import { HendigoTheme } from "~base/Resources/Styling/HendigoTheme";

const useStyles = createUseStyles((theme: HendigoTheme) => ({
    wrapper: {
        margin: "0 auto",
        marginTop: "100px",
        color: theme.textPrimary
    },
    title: {
        textAlign: "center",
        fontWeight: "normal"
    },
    image: {
        display: "block",
        margin: "0 auto",
        opacity: 0.5,
        '&:hover': {
            cursor: "pointer"
        }
    },
    text: {
        margin: "0 auto",
        textAlign: "center",
        width: "75%"
    }
}));

interface UnderConstructionProps extends ViewBaseProps {
    message?: string,
    title?: string,
}

export const UnderConstruction: React.FC<UnderConstructionProps> = ({ message, title }) => {

    const intl = useIntl();

    window.view = "UnderConstruction";
    intl.formatPageTitle("PageTitle.UnderConstruction")

    const classes = useStyles();
    return <MiddleWrapper>
        <div className={classes.wrapper}>
            <img alt="under construction" className={classes.image} src="/images/hendigo/underconstruction.gif" onClick={() => window.open("https://lordicon.com/", '_blank')} width={175}></img>
            <h1 className={classes.title}>{intl.formatText("Views.UnderConstruction.Title")}</h1>
            <h2 className={classes.title}>{title}</h2>
            <div className={classes.text}>{message}</div>
        </div>

    </MiddleWrapper>
}