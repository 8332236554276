import React from "react";
import { PurpleWrapperLayout } from "~base/Components/Layouts/PurpleWrapperLayout";
import { ViewBaseProps } from "~base/Model/ViewBaseProps";
import { NotFound } from "~base/Components/Views/NotFound/Component";
import { Navbar } from "~base/Components/Composite/Navbar/Component";
import { Home } from "~base/Components/Views/Home/Component";
import { RouteObject } from "react-router-dom";
import { Login } from "~base/Components/Views/Login/Component";
import * as PATHS from "./Paths";
import { Register } from "~base/Components/Views/Register/Component";
import { Job } from "~base/Components/Views/Job/Component";
import { AdsSearchResult } from "~base/Components/Views/AdsSearchResult/Component";
import { Ad } from "~base/Components/Views/Ad/Component";
import { CompleteRegistrationUser } from "~base/Components/Views/CompleteRegistrationUser/CompleteRegistrationUser";
import { CompleteRegistrationCompany } from "~base/Components/Views/CompleteRegistrationCompany/CompleteRegistrationCompany";
import { AboutUs } from "~base/Components/Views/AboutUs/Component";

type GetBaseRoutes = (viewBaseProps: ViewBaseProps) => RouteObject[];

export const getBaseRoutes: GetBaseRoutes = (viewBaseProps) => [
    /**
     * Plain layout routes.
     */
    { 
        path: PATHS.HOME_PATH, 
        element: <React.Fragment><Navbar {...viewBaseProps}/> <Home {...viewBaseProps} /> </React.Fragment> 
    },
    {
        path: PATHS.WORK,
        element: <React.Fragment><Navbar {...viewBaseProps}/> <Job {...viewBaseProps} /> </React.Fragment>
    },
    {
        path: PATHS.WORK_RESULTS,
        element: <React.Fragment><Navbar {...viewBaseProps}/> <AdsSearchResult {...viewBaseProps} /> </React.Fragment>
    },
    {
        path: `${PATHS.WORK_AD}/:id`,
        element: <React.Fragment><Navbar {...viewBaseProps}/> <Ad {...viewBaseProps} /> </React.Fragment>
    },
    {
        path: PATHS.ABOUT_US,
        element: <React.Fragment><Navbar {...viewBaseProps}/> <AboutUs {...viewBaseProps}/> </React.Fragment>
    },
    /**
     * Purple wrapper layout routes with navbar.
     */
    {
        element: <PurpleWrapperLayout viewBaseProps={viewBaseProps} />,
        children: [
            { path: "/*", element: <NotFound {...viewBaseProps} /> },
            { path: PATHS.LOGIN_PATH, element: <Login {...viewBaseProps} />},
            { path: PATHS.REGISTER_PATH, element: <Register {...viewBaseProps} />},
            { path: PATHS.FINISH_USER_PROFILE, element: <CompleteRegistrationUser {...viewBaseProps} /> },
            { path: PATHS.FINISH_COMPANY_PROFILE, element: <CompleteRegistrationCompany {...viewBaseProps} /> },

        ],
    },
];