import { Session } from "~base/Model/Session";
import { ViewAction } from "./Store";
import { apiv2 } from "~base/API/v2/apiv2";

export function getMyApplications(session: Session, dispatch: React.Dispatch<ViewAction>){
    apiv2("api/v2/get/myApplications/", "GET").then((response) => {
        if(response.error != null){
            session.setInformation("error", response.error);
        } else {
            dispatch({ type: "setApplications", value: response.data });
            dispatch({ type: "setLoading", value: false })
        }
    })
}