export const SmallImagePathNameDefault = "/images/hendigo/default_small.png"

export const LOGIN_PATH = "/logga-in";
export const HOME_PATH = "/hem";
export const REGISTER_PATH = "/registrera";
export const FEED_PATH = "/";
export const SEARCH_RESULT_PATH = "/resultat";
export const COMPANY_PROFILE_PATH = "/foretag";
export const USER_PROFILE_PATH = "/anvandare";
export const COMPANY_MANAGE_ADS = "/foretag/annonser";
export const WORK = "/jobb";
export const MY_APPLICATIONS = "/jobb/mina-ansokningar";
export const WORK_RESULTS = "/jobb/resultat";
export const WORK_AD = "/jobb/annons";
export const CREATE_WORK_AD = "/jobb/annons/skapa";
export const COMPANY_POST = "/foretag/inlagg";
export const USER_POST = "/anvandare/inlagg";
export const PROJECT = "/projekt";
export const SUB_CONTRACTORS = "/underleverantorer";
export const MARKETING = "foretag/marknadsforing";
export const CONTRACTORS = "/overleverantorer";
export const NETWORK = "/natverk";
export const EVENTS = "/evenemang";
export const GROUPS = "/grupper";
export const GROUP = "/grupp";
export const FINISH_USER_PROFILE = "/fardigstall-privat";
export const FINISH_COMPANY_PROFILE = "/fardigstall-foretag";
export const ABOUT_US = "/about-us";
