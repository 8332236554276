import React, { useEffect } from "react";
import { createUseStyles } from "react-jss"
import { HendigoTheme } from "~base/Resources/Styling/HendigoTheme";
import { ContactWithProfile } from "~base/API/ResponseTypes/ContactResponse";
import { Session } from "~base/Model/Session";
import { Link, useNavigate } from "react-router-dom";
import { Button } from "~base/Components/Elements/Input/Button";
import { BeatLoader } from 'react-spinners';
import { apiv2 } from "~base/API/v2/apiv2";
import { ImCross } from "react-icons/im";
import { useIntl } from "~base/Internalization/IntlContext";
import { useFilesAPIService } from "~base/Services/FilesAPIServiceContext";
import { Avatar } from "@mui/material";

const useStyles = createUseStyles((theme: HendigoTheme) => ({
	contentWrapper: {
		position: "relative",
		backgroundColor: theme.backgroundSecondary,
		padding: "10px",
		borderRadius: "10px",
		margin: "0px 0px 20px 0px"
	},
	avatar: {
		height: "100px !important",
		width: "100px !important",
		textDecoration: "none",
		fontSize: "50px !important",
		'&:hover': {
			cursor: "pointer",
			opacity: "0.8"
		},
		margin: "0 auto",
	},
	contactWrapper: {
		display: "grid",
		gridTemplateColumns: "1fr 1fr 1fr",
		rowGap: "10px",
	},
	contactInnerWrapper: {
		position: "relative",
		backgroundColor: theme.backgroundThird,
		borderRadius: "10px",
		padding: "25px",
		marginRight: "10px",
		textAlign: "center",
		border: `1px ${theme.border} solid`,
	},
	contactName: {
		fontSize: "14px",
		fontWeight: "500",
		color: theme.textPrimary,
		'&:hover': {
			cursor: "pointer",
			textDecoration: "underline"
		},
	},
	showAmount: {
		textAlign: "right",
		fontSize: "18px",
		fontWeight: "500",
		color: theme.textPrimary,
		'&:hover': {
			cursor: "pointer",
			textDecoration: "underline"
		},
		paddingTop: "10px",
		paddingBottom: "5px",
	},
	RequestTitle: {
		textAlign: "left",
		fontSize: "22px",
		fontWeight: "350",
		color: theme.textPrimary,
		height: "35px",

		marginLeft: "10px",
	},
	timeSent: {
		textAlign: "left",
		fontSize: "14px",
		fontWeight: "350",
		color: theme.textPrimary,
		marginTop: "-25px",
		marginLeft: "-10px",
	},
	showContactType: {
		display: "flex",
		fontWeight: "350",
		gap: "35px",
		color: theme.textSecondary,
	},
	activeType: {
		fontWeight: "500",
		textDecoration: "underline",
		'&:hover': {
			cursor: "pointer",
		},
	},
	passiveType: {
		'&:hover': {
			cursor: "pointer",
			textDecoration: "underline",
		},
	},
	loading: {
		opacity: "0.5",
		pointerEvents: "none",
	},
	loadingSymbol: {
		display: "block !important",
		position: "relative",
		top: "50%",
		left: "50%",
		marginLeft: "-30px",
		marginTop: "-23px",
	},
	declineButton: {
		position: "absolute",
		top: "15px",
		right: "15px",
		'&:hover': {
			color: "red",
			cursor: "pointer",
		},

	},
	title: {
		margin: "0",
		fontWeight: "500",
		color: theme.textPrimary
	}
}));

interface ContactSentReceivedProps {
	contactsReceived: ContactWithProfile[],
	contactsSent: ContactWithProfile[],
	session: Session,
	setContactReceived: any,
	setContactSent: any,
	setContacts: any,
}

export const ContactSentReceived: React.FC<ContactSentReceivedProps> = (props) => {

	const {
		contactsReceived,
		contactsSent,
		session,
		setContactReceived,
		setContactSent,
		setContacts
	} = props;

	const intl = useIntl();
	const classes = useStyles();
	const navigate = useNavigate();
	const filesAPIService = useFilesAPIService();

	const [expanded, setExpanded] = React.useState<boolean>(false);
	const [showReceived, setShowReceived] = React.useState<boolean>(true);
	const [activeContact, setActiveContact] = React.useState<ContactWithProfile[] | []>([]);
	const [loading, setLoading] = React.useState<Number>(-1);

	const handleShowReceived = (showReceived: boolean) => {
		setShowReceived(showReceived);
		setExpanded(false);
	};

	useEffect(() => {
		setActiveContact(showReceived ? contactsReceived : contactsSent);
	}, [contactsReceived, contactsSent, showReceived]);

	const handleAccept = (contact: ContactWithProfile, index: Number) => {
		setLoading(index);

		apiv2("api/v2/post/contact", "POST", { userId2: contact.user_id1 }).then((response) => {
			if (response.error !== null) {
				session.setInformation("error", response.error);
				setLoading(-1);
			} else {
				session.setInformation("success", "Contact request accepted");
				handleContactRemove(contact);
				handleContactAdd(contact);
				setLoading(-1);
			}
		});
	};

	const handleContactWithdraw = (contact: ContactWithProfile, index: Number) => {
		setLoading(index);

		apiv2(`api/v2/delete/contact/${contact.user_id2}`, "DELETE").then((response) => {
			if (response.error !== null) {
				session.setInformation("error", response.error);
				setLoading(-1);
			} else {
				session.setInformation("success", "Contact request withdrawn");
				handleContactRemove(contact);
				setLoading(-1);
			}
		});
	};

	const handleContactDecline = (contact: ContactWithProfile, index: Number) => {
		setLoading(index);

		apiv2(`api/v2/delete/contact/${contact.user_id1}`, "DELETE").then((response) => {
			if (response.error !== null) {
				session.setInformation("error", response.error);
				setLoading(-1);
			} else {
				session.setInformation("success", "Contact request declined");
				handleContactRemove(contact);
				setLoading(-1);
			}
		});
	};

	const handleContactRemove = (contact: ContactWithProfile) => {
		setContactReceived(contactsReceived.filter((c: ContactWithProfile) => c.id !== contact.id));
		setContactSent(contactsSent.filter((c: ContactWithProfile) => c.id !== contact.id));
	};

	const handleContactAdd = (contact: ContactWithProfile) => {
		setContacts((contacts: ContactWithProfile[]) => [...contacts, contact]);
	};

	return <div className={classes.contentWrapper} >

		<h2 className={classes.title}>{intl.formatText("View.Network.ContactSentReceived.Requests")}</h2>

		<div className={classes.showContactType}>
			<div
				className={showReceived ? classes.activeType : classes.passiveType}
				onClick={() => handleShowReceived(true)}
			>
				<p>{intl.formatText("View.Network.ContactSentReceived.Received")} ({contactsReceived.length})</p>
			</div>

			<div
				className={showReceived ? classes.passiveType : classes.activeType}
				onClick={() => handleShowReceived(false)}
			>
				<p>{intl.formatText("View.Network.ContactSentReceived.Sent")} ({contactsSent.length})</p>
			</div>
		</div>

		<div className={classes.contactWrapper}>
			{activeContact.slice(0, expanded ? activeContact.length : 6).map((contact, index) => {

				const profilePicture = filesAPIService.getProfileImageSmall(contact.small_image_pathname);

				const firstLetterInName = contact?.fullname.substring(0, 1);

				const profileLink = `/anvandare/${contact?.user_id}`;

				return <div key={index} >
					<BeatLoader color="#6a11cb" loading style={{ display: "none" }} className={`${loading === index ? classes.loadingSymbol : ""}`} size={15} />
					<div className={`${classes.contactInnerWrapper} ${loading === index ? classes.loading : ""}`}>
						<div className={classes.declineButton}>
							{showReceived ? <ImCross onClick={() => handleContactDecline(contact, index)} /> : ""}
						</div>
						<div className={classes.timeSent}>
							<p>{intl.formatTimeLong(new Date(contact.time_created))}</p>
						</div>

						<Avatar
							src={profilePicture}
							className={classes.avatar}
							component={Link}
							to={profileLink}
						>
							{firstLetterInName}
						</Avatar>

						<p className={classes.contactName}
							onClick={() => navigate("/anvandare/" + contact.user_id)}
						>
							{contact?.fullname}
						</p>
						<Button
							colorType="blue"
							style={{ width: "70%" }}
							onClick={() => showReceived ? handleAccept(contact, index) : handleContactWithdraw(contact, index)}
						>{showReceived ? intl.formatText("View.Network.ContactSentReceived.Accept") : intl.formatText("View.Network.ContactSentReceived.Cancel")}</Button>
					</div>
				</div>
			})}
		</div>
		{activeContact.length < 6 ? null :
			<div className={classes.showAmount} onClick={() => setExpanded(!expanded)}>
				{expanded ? intl.formatText("View.Network.ContactSentReceived.ShowLess") : intl.formatText("View.Network.ContactSentReceived.ShowMore")}
			</div>
		}
	</div>
}