import { StandardTextFieldProps, TextField } from "@mui/material"
import { muiStyles } from "~base/Resources/Styling/MuiTheme"

interface MUITextFieldProps extends StandardTextFieldProps {
    limit?: number;
}

/**
 * MUITextField has hendigo styling and a character limit.
 * @param props 
 * @returns 
 */
export const MUITextField: React.FC<MUITextFieldProps> = (props) => {
    const classes = muiStyles();

    return <>
        <TextField
            {...props}
            onChange={props.limit ? ((e) => e.target.value.length <= props.limit! && props.onChange?.(e)) : props.onChange}
            className={classes.textField}
        />
        <p style={{width: "36px", marginLeft: "auto"}}>{props.limit && (props.limit - (props.value as string).length)}</p>
    </>
}