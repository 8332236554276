// ----------------------------------------------------
// Imports
// ----------------------------------------------------

import { AdResponse } from "~base/Model/ResponseModels/AdResponse";
import { ManageAdsViewState } from "./Types";

// ----------------------------------------------------
// Actions
// ----------------------------------------------------

type SetAds = { type: "setAds", value: AdResponse[] }

export type ManageAdsAction =
    SetAds;

// ----------------------------------------------------
// Reducer
// ----------------------------------------------------

export const manageAdsReducer = (state: ManageAdsViewState, action: ManageAdsAction): ManageAdsViewState => {
    switch (action.type) {
        case "setAds":
            return {
                ...state,
                ads: action.value
            }
    }
}

// ----------------------------------------------------
// Initial state
// ----------------------------------------------------

export const initialManageAdsState = (): ManageAdsViewState => {
    return {
        ads: null
    }
}