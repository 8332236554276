import React from "react";
import { CreateAdStyles } from "../Styling";
import { Professions } from "~base/Resources/Data/Professions";
import { Autocomplete, Button, FormControl, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import { FormsOfEmployment as formsOfEmploymentArray } from "~base/Resources/Data/FormsOfEmployment";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { BsInfoCircle, BsPerson } from "react-icons/bs";
import { Row } from "~base/Components/Elements/Cosmetic/Row";
import { BiBuilding, BiMessageAltDetail } from "react-icons/bi";
import { IoTrashOutline } from "react-icons/io5";
import { HiOutlineArrowNarrowRight } from "react-icons/hi";
import { CreateAdViewState } from "../Types";
import { CreateAdAction } from "../Store";
import { Session } from "~base/Model/Session";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { AiOutlineStar } from "react-icons/ai";
import { RadioProduct } from "~base/Components/Composite/RadioProduct/Component";
import { muiStyles } from "~base/Resources/Styling/MuiTheme";
import { useIntl } from "~base/Internalization/IntlContext";

interface EnterInformationProps {
    state: CreateAdViewState;
    dispatch: React.Dispatch<CreateAdAction>;
    session: Session;
}

export const EnterInformation: React.FC<EnterInformationProps> = ({ state, dispatch, session }) => {
    
    const intl = useIntl();
    const classes = CreateAdStyles();
    const muiClasses = muiStyles();

    React.useEffect(() => {
        dispatch({ type: "setValid" });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state]);

    return <>
        <div className={classes.sectionWrapper}>
            <Row className={classes.sectionTitleRow}>
                <h1 className={classes.sectionTitle}>{intl.formatText("Views.CreateAd.Step.Information")}</h1>
            </Row>

            <p className={classes.description}>
                {intl.formatText("Views.CreateAd.EnterInformation.Description")}
            </p>
        </div>

        <div className={classes.sectionWrapper}>
            <Row className={classes.sectionTitleRow}>
                <BsInfoCircle className={classes.icon} />

                <h3 className={classes.sectionTitle}>
                    {intl.formatText("Views.CreateAd.EnterInformation.BasicInformation")}
                </h3>
            </Row>

            <p className={classes.description}>
                {intl.formatText("Views.CreateAd.EnterInformation.BasicInformation.Description")}
            </p>

            <Grid2 container spacing={2}>
                <Grid2 xs={12}>
                    <TextField
                        id="outlined-basic"
                        label={intl.formatText("Views.CreateAd.EnterInformation.Title")}
                        variant="outlined"
                        className={muiClasses.textField + " " + classes.input}
                        value={state.ad.title}
                        onChange={value => dispatch({ type: "setTitle", value: value.target.value })}
                        style={{ margin: "1rem 0 0 0" }}
                    />
                </Grid2>

                <Grid2 xs={12} md={6}>
                    <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        options={Professions}
                        renderInput={(params) => <TextField {...params} label={intl.formatText("Views.CreateAd.EnterInformation.JobTitle")} />}
                        className={muiClasses.autocomplete + " " + classes.input}
                        value={state.ad.profession_title}
                        onChange={(e, value) => dispatch({ type: "setPosition", value: value })}
                    />
                </Grid2>

                <Grid2 xs={12} md={6}>
                    <FormControl className={muiClasses.select + " " + classes.input}>
                        <InputLabel id="demo-simple-select-label">
                            {intl.formatText("Views.CreateAd.EnterInformation.FormOfEmployment")}
                        </InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={state.ad.form_of_employment}
                            label={intl.formatText("Views.CreateAd.EnterInformation.FormOfEmployment")}
                            onChange={value => dispatch({ type: "setFormOfEmployment", value: value.target.value })}
                        >
                            {formsOfEmploymentArray.map((formOfEmployment, index) => {
                                return <MenuItem
                                    key={"CreateAdFormsOfEmployment" + index}
                                    value={formOfEmployment}
                                >
                                    {intl.formatText(formOfEmployment)}
                                </MenuItem>
                            })}
                        </Select>
                    </FormControl>
                </Grid2>

                <Grid2 xs={12} md={6}>
                    <FormControl className={muiClasses.select + " " + classes.input}>
                        <InputLabel id="location-select-label">
                            {intl.formatText("Views.CreateAd.EnterInformation.Location")}
                        </InputLabel>
                        <Select
                            labelId="location-select-label"
                            id="location-select"
                            value={state.ad.location_id}
                            label={intl.formatText("Views.CreateAd.EnterInformation.Location")}
                            onChange={e => dispatch({ type: "setLocation", id: e.target.value as number, value: session.data.locations.find(l => l.id === e.target.value)?.location ?? "" })}
                        >
                            {session.data.locations.map((location, index) => {
                                return <MenuItem
                                    key={"CreateAdLocation" + index}
                                    value={location.id}
                                >
                                    {intl.formatText(location.location)}
                                </MenuItem>
                            })}
                        </Select>
                    </FormControl>
                </Grid2>

                <Grid2 xs={12} md={6}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DesktopDatePicker
                            label={intl.formatText("Views.CreateAd.EnterInformation.DueDate")}
                            inputFormat="DD/MM/YYYY"
                            value={state.ad.deadline}
                            onChange={(value) => dispatch({ type: "setDeadline", value: dayjs(value) })}
                            renderInput={(params) => <TextField {...params} />}
                            className={muiClasses.datePicker + " " + classes.input}
                        />
                    </LocalizationProvider>
                </Grid2>
            </Grid2>
        </div>

        <div className={classes.sectionWrapper}>
            <Row className={classes.sectionTitleRow}>
                <BiBuilding className={classes.icon} />

                <h3 className={classes.sectionTitle}>
                    {intl.formatText("Views.Ad.About")} {session.companyProfile?.company_name}
                </h3>
            </Row>

            <p className={classes.description}>
                {intl.formatText("Views.CreateAd.EnterInformation.AboutTheCompany.Description")}
            </p>

            <TextField
                id="outlined-multiline-flexible"
                label={intl.formatText("Views.CreateAd.EnterInformation.AboutTheCompany")}
                multiline
                maxRows={4}
                value={state.ad.company_description}
                onChange={value => dispatch({ type: "setCompanyDescription", value: value.target.value })}
                className={muiClasses.textField + " " + classes.input}
                style={{ margin: "1rem 0 0 0" }}
            />
        </div>

        <div className={classes.sectionWrapper}>
            <Row className={classes.sectionTitleRow}>
                <BsInfoCircle className={classes.icon} />
                <h3 className={classes.sectionTitle}>
                    {intl.formatText("Views.Ad.AboutThePosition")}
                </h3>
            </Row>

            <p className={classes.description}>
                {intl.formatText("Views.CreateAd.EnterInformation.AboutThePosition.Description")}
            </p>

            <TextField
                id="outlined-multiline-flexible"
                label={intl.formatText("Views.CreateAd.EnterInformation.AboutThePosition")}
                multiline
                maxRows={4}
                value={state.ad.position_description}
                onChange={value => dispatch({ type: "setAboutJob", value: value.target.value })}
                className={muiClasses.textField + " " + classes.input}
                style={{ margin: "1rem 0 0 0" }}
            />
        </div>

        <div className={classes.sectionWrapper}>
            <Row className={classes.sectionTitleRow}>
                <BsPerson className={classes.icon} />

                <h3 className={classes.sectionTitle}>
                    {intl.formatText("Views.CreateAd.EnterInformation.AboutTheJobSeeker")}
                </h3>
            </Row>

            <p className={classes.description}>
                {intl.formatText("Views.CreateAd.EnterInformation.AboutTheJobSeeker.Description")}
            </p>

            <TextField
                id="outlined-multiline-flexible"
                label={intl.formatText("Views.CreateAd.EnterInformation.AboutTheJobSeeker")}
                multiline
                maxRows={4}
                value={state.ad.applicant_description}
                onChange={value => dispatch({ type: "setAboutYou", value: value.target.value })}
                className={muiClasses.textField + " " + classes.input}
                style={{ margin: "1rem 0 0 0" }}
            />
        </div>

        <div className={classes.sectionWrapper}>
            <Row className={classes.sectionTitleRow}>
                <BsInfoCircle className={classes.icon} />
                <h3 className={classes.sectionTitle}>
                    {intl.formatText("Views.Ad.Requirement")}
                </h3>
            </Row>

            <p className={classes.description}>
                {intl.formatText("Views.CreateAd.EnterInformation.Requirement.Description")}
            </p>

            <Grid2 container spacing={2} style={{ marginTop: (state.adRequirements?.length! > 0 ? "1rem" : "0") }}>
                {state.adRequirements?.map((requirement, index) => {
                    return (<>
                        <Grid2 xs={11} key={"CreateAdEnterInformationRequirementTextField" + index}>
                            <TextField
                                id="outlined-multiline-flexible"
                                label={intl.formatText("Views.CreateAd.EnterInformation.Requirement") + (index + 1)}
                                multiline
                                maxRows={4}
                                value={requirement.description}
                                onChange={value => dispatch({ type: "setRequirement", index: index, value: value.target.value })}
                                className={muiClasses.textField + " " + classes.input}
                            />
                        </Grid2>
                        <Grid2 xs={1} style={{ display: "flex", alignItems: "center" }} key={"CreateAdEnterInformationRequirementButton" + index}>
                            <Button
                                onClick={() => dispatch({ type: "removeRequirement", index: index })}
                            >
                                <IoTrashOutline style={{ fontSize: "20px" }} />
                            </Button>
                        </Grid2>
                    </>)
                })}
                <Grid2 xs={4}>
                    <Button
                        color="primary"
                        onClick={() => dispatch({ type: "addRequirement" })}
                    >
                        {intl.formatText("Views.CreateAd.EnterInformation.AddRequirement")}
                    </Button>
                </Grid2>
            </Grid2>
        </div>

        <div className={classes.sectionWrapper}>
            <Row className={classes.sectionTitleRow}>
                <BiMessageAltDetail className={classes.icon} />
                <h3 className={classes.sectionTitle}>{intl.formatText("Views.Ad.Contact")}</h3>
            </Row>

            <p className={classes.description}>
                {intl.formatText("Views.CreateAd.EnterInformation.Contact.Description")}
            </p>
        </div>

        <div className={classes.sectionWrapper}>
            <Row className={classes.sectionTitleRow}>
                <AiOutlineStar className={classes.icon} />

                <h3 className={classes.sectionTitle}>
                    {intl.formatText("Views.CreateAd.EnterInformation.Level")}
                </h3>
            </Row>

            <p className={classes.description}>
                {intl.formatText("Views.CreateAd.EnterInformation.Level.Description")}
            </p>

            <Grid2 container spacing={2} style={{ marginTop: "1rem" }}>
                {state.products?.sort((a, b) => a.price - b.price).map((product) => {
                    return <Grid2 key={product.id} xs={6} md={4}>
                        <RadioProduct
                            product={product}
                            value={product.type}
                            checked={state.chosenProduct?.type === product.type}
                            onChange={val => dispatch({ type: "setChosenProduct", value: product })}
                        />
                    </Grid2>
                })}
            </Grid2>
        </div>

        <Row style={{ marginTop: "2rem" }}>
            <Button
                variant="contained"
                style={{ marginLeft: "auto" }}
                onClick={() => dispatch({ type: "setCurrentStep", value: "Views.CreateAd.Step.Preview" })}
                disabled={!state.valid}
            >
                {intl.formatText("Views.CreateAd.NextStep")}
                <HiOutlineArrowNarrowRight style={{ marginLeft: "5px" }} />
            </Button>
        </Row>
    </>
}