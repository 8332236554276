import { createTheme } from "@mui/material";

export const muiTheme = (darkMode: boolean) => createTheme({
    components: {
        MuiTypography: {
            defaultProps: {
                variantMapping: {
                    h1: 'h2',
                    h2: 'h2',
                    h3: 'h2',
                    h4: 'h2',
                    h5: 'h2',
                    h6: 'h2',
                    subtitle1: 'h2',
                    subtitle2: 'h2',
                    body1: 'span',
                    body2: 'span',
                },
            },
        },
        MuiListItemText: {
            styleOverrides: {
                primary: {
                    fontSize: "22px"
                }
            }
        },
        MuiListItemIcon: {
            styleOverrides: {
                root: {
                    fontSize: "30px"
                }
            }
        }
    },
    typography: {
        "fontFamily": `proxima-soft, sans-serif`,
        "fontSize": 14,
        "fontWeightLight": 300,
        "fontWeightRegular": 400,
        "fontWeightMedium": 500
    },
    //------------------------------------------------------------------
    // Colors
    //------------------------------------------------------------------
    palette: {
        mode: (darkMode ? 'dark' : 'light'),
        primary: {
            main: "#2575fc",
        }, 
        secondary: {
            main: "#52ab98",
        },
        
    },
});