import { createUseStyles } from "react-jss";
import { HendigoTheme } from "~base/Resources/Styling/HendigoTheme";

export const CompleteRegistrationStyles = createUseStyles((theme: HendigoTheme) => ({
    wrapper: {
        width: "50%",
        /*background: theme.loginRegisterBackgroundRGBA,
        borderRadius: "5px",
        boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
        backdropFilter: "blur(5px)",
        WebkitBackdropFilter: "blur(5px)",
        border: "1px solid rgba(255, 255, 255, 0.1)",
        padding: "50px",*/
    },
    '@media screen and (max-width: 1100px)': {
        wrapper: {
            width: "100%"
        }
    },
    arrowWrapper: {
        border: "2px solid transparent",
        borderRadius: "10px",
        color: "white",
        fontSize: "50px",
        height: "55px",
        margin: "auto 0",
        '&:hover': {
            border: "2px solid white",
            cursor: "pointer"
        },
        '&:active': {
            backgroundColor: "white",
            color: "black"
        }
    },
    row: {
        WebkitJustifyContent: "space-between",
        justifyContent: "space-between"
    },
    title: {
        fontSize: "35px",
        color: "white",
        width: "auto",
        justifyContent: "center",
        animationName: "$fadeInAnimation",
        animation: "1s fadeInAnimation",
    },
    '@keyframes fadeInAnimation': {
        "0%": {
            opacity: 0.1
        },
        "100%": {
            opacity: 1
        }
    },
    middleWrapper: {
        height: "25vh",
        width: "75%"
    },
    titleIcon: {
        margin: "0px 20px 0px 0px"
    },
    input: {
        height: "60%",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        animationName: "$fadeInAnimation",
        animation: "1s fadeInAnimation",
    },
    doneText: {
        fontSize: "16px",
        margin: "48px 0px"
    }
}))