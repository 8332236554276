import { createUseStyles } from "react-jss"

const useStyles = createUseStyles({
    row: {
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
    }
});

interface RowProps {
    children?: React.ReactNode;
    className?: string,
    style?: React.CSSProperties,
    onClick?: () => void
}

export const Row: React.FC<RowProps> = (props): JSX.Element => {
    const classes = useStyles();

    return <div className={classes.row + " " + props.className} style={props.style} onClick={props.onClick}>
        {props.children}
    </div>
}