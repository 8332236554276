import React from "react";
import { languageCodes } from "./formatText";
import { Session } from "~base/Model/Session";

export interface IntlContext {
    language: languageCodes;
    setLanguage: (lang: languageCodes) => void;
    formatText: (code: string) => string;
    formatTimeShort: (date: Date) => string;
    formatTimeLong: (date: Date) => string; 
    formatDateShort: (date: Date | string) => string;
    formatDateLong: (date: Date | string) => string;
    formatPageTitle: (title: string) => void;
    formatPageTitleString: (title: string) => void;
    session: Session;
    styling: {
        setDarkMode: (darkMode: boolean) => void;
    }
}

export const IntlContextProvider = React.createContext<IntlContext>({} as IntlContext);

export const useIntl = () => React.useContext(IntlContextProvider);