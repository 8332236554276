import { createUseStyles } from "react-jss";
import { HendigoTheme } from "~base/Resources/Styling/HendigoTheme";

export const AdsStyles = createUseStyles((theme : HendigoTheme) => ({
    sectionWrapper: {
        backgroundColor: theme.backgroundSecondary,
        padding: "10px",
        marginBottom: "20px",
        borderRadius: "5px",
    },
    sectionTitle: {
        margin: "10px 0 0 0",
        fontWeight: "500",
        color: theme.textPrimary
    },
    sectionDescription: {
        color: theme.textSecondary,
    },
    row: {
        marginBottom: "20px",
    },
    rowTitle: {
        width: "100%",
        fontSize: "25px"
    },
    ad: {
        '&:hover': {
            opacity: 0.5
        }
    },
    button: {
        marginRight: "5px"
    },
    title: {
        fontSize: "25px",
        color: theme.textPrimary,
        marginBottom: "10px"
    }
}))