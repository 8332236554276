import React from "react";
import { useNavigate } from "react-router-dom";
import { MiddleWrapper } from "~base/Components/Elements/Cosmetic/MiddleWrapper";
import { ViewBaseProps } from "~base/Model/ViewBaseProps";
import { ContactSentReceived } from "./ChildComponents/ContactSentReceived";
import { apiv2 } from "~base/API/v2/apiv2";
import { ContactWithProfile } from "~base/API/ResponseTypes/ContactResponse";
import { Contacts } from "./ChildComponents/Contacts";
import { useIntl } from "~base/Internalization/IntlContext";

export const Network: React.FC<ViewBaseProps> = ({ session }): JSX.Element => {

	const intl = useIntl();

	window.view = "Network";
	intl.formatPageTitle("PageTitle.Network");
	let navigate = useNavigate();

	const [contacts, setContacts] = React.useState<ContactWithProfile[]>([]);
	const [contactsReceived, setContactsReceived] = React.useState<ContactWithProfile[]>([]);
	const [contactsSent, setContactSent] = React.useState<ContactWithProfile[]>([]);

	React.useEffect(() => {
		apiv2(`api/v2/get/contacts/${session.userId}`, "GET").then((response) => {
			if (response.error !== null) {
				session.setInformation("error", response.error);
				console.log(response.error);
			} else {
				console.log(response.data)
				const result = response.data as ContactWithProfile[];
				setContacts( result.filter((contact: ContactWithProfile) => contact.accepted) );
				setContactsReceived( result.filter((contact: ContactWithProfile) => !contact.accepted && contact.user_id1 !== session.userId) );
				setContactSent( result.filter((contact: ContactWithProfile) => !contact.accepted && contact.user_id1 === session.userId) );
			}
		});
	}, [navigate, session]);

	return <MiddleWrapper>
		<ContactSentReceived
			contactsReceived={contactsReceived}
			contactsSent={contactsSent}
			session={session}
			setContactReceived={(contacts: ContactWithProfile[]) => setContactsReceived(contacts)}
			setContactSent={(contacts: ContactWithProfile[]) => setContactSent(contacts)}
			setContacts={(contacts: ContactWithProfile[]) => setContacts(contacts)}
		/>
		<Contacts contacts={contacts} />
	</MiddleWrapper>
}