// ----------------------------------------------------
// Imports
// ----------------------------------------------------

import { AdResponse } from "~base/Model/ResponseModels/AdResponse";
import { FeedViewState } from "./Types";
import { PostResponse } from "~base/Model/ResponseModels/PostResponse";
import { ExtendedPostResponse } from "~base/Components/Composite/Post/Component";

// ----------------------------------------------------
// Actions
// ----------------------------------------------------

type SetPosts = { type: "setPosts", value: PostResponse[] }
type AddPost = { type: "addPost", value: PostResponse }
type SetEditPostOverlay = { type: "setEditPostOverlay", show: boolean, currentEditPostId: string | null, currentEditPostContent: string | null }
type SaveEditPostOverlay = { type: "saveEditPostOverlay", isCompanyPost: boolean, currentEditPostId: string, content: string | null }
type SetFeed = { type: "setFeed", posts: PostResponse[], ads: AdResponse[] }
type SetPostText = { type: "setPostText", value: string }
type SetPostImages = { type: "setPostImages", value: File[] }
type SetPosterAreaExpanded = { type: "setPosterAreaExpanded", value: boolean }

export type FeedAction =
    SetPosts |
    AddPost |
    SetEditPostOverlay |
    SaveEditPostOverlay |
    SetFeed | 
    SetPostText |
    SetPostImages |
    SetPosterAreaExpanded;

// ----------------------------------------------------
// Reducer
// ----------------------------------------------------

export const feedReducer = (state: FeedViewState, action: FeedAction): FeedViewState => {
    switch (action.type) {
        case "setPosts":
            return {
                ...state,
                posts: action.value
            }

        case "addPost":
            return {
                ...state,
                postText: "",
                postImages: [],
                posts: [action.value, ...state.posts ?? []]
            }

        case "setEditPostOverlay":
            return {
                ...state,
                showEditPostOverlay: action.show,
                currentEditPostId: action.currentEditPostId,
                currentEditPostContent: action.currentEditPostContent
            }

        case "saveEditPostOverlay":
            return {
                ...state,
            }

        case "setFeed": {

            const adsTransformed = action.ads.map(ad => {
                return {
                    name: ad.company_name,
                    company_id: ad.company_id,
                    title: ad.title,
                    content: ad.position_description,
                    time_created: ad.time_created,
                    small_image_pathname: ad.small_image_pathname,
                    ad: true,
                    ad_id: ad.id,
                } as ExtendedPostResponse;
            });

            // Define the interval at which to insert ads (e.g., every 3 posts)
            const adInterval = 3;

            // Create a new array to store the shuffled posts with ads
            const shuffledPosts: PostResponse[] = [];

            // Loop through the original posts array and insert ads
            for (let i = 0; i < action.posts.length; i++) {
                shuffledPosts.push(action.posts[i]);

                // Check if it's time to insert an ad
                if ((i + 1) % adInterval === 0 && adsTransformed.length > 0) {
                    // Insert an ad from the ads array
                    shuffledPosts.push(adsTransformed.pop() as PostResponse); // You can modify this to select ads based on certain criteria
                }
            }

            // If there are remaining ads, you can append them to the end of the shuffledPosts array
            shuffledPosts.push(...adsTransformed);
            return {
                ...state,
                posts: shuffledPosts
            };
        }

        case "setPostText":
            return {
                ...state,
                postText: action.value
            }

        case "setPostImages":
            return {
                ...state,
                postImages: action.value
            }
        
        case "setPosterAreaExpanded":
            return {
                ...state,
                posterAreaExpanded: action.value
            }
    }
}

// ----------------------------------------------------
// Initial state
// ----------------------------------------------------

export const initialFeedState = (): FeedViewState => {
    return {
        posts: null, // THIS CONTAINS ADS ASWELL
        showEditPostOverlay: false,
        currentEditPostId: null,
        currentEditPostContent: null,
        postText: "",
        postImages: [],
        posterAreaExpanded: false,
    }
}