import { createUseStyles } from "react-jss";
import { HendigoTheme } from "~base/Resources/Styling/HendigoTheme";

export const CreateAdStyles = createUseStyles((theme: HendigoTheme) => ({
    wrapper: {
        maxWidth: "100%",
        minHeight: "91vh",
        backgroundColor: theme.backgroundPrimary,
        padding: "50px 25%"
    },
    '@keyframes slideIn': {
        from: {
            opacity: 0,
            transform: "translateX(-100px)"
        },
        to: {
            opacity: 1,
            transform: "translateX(0%)"
        }
    },
    sectionWrapper: {
        backgroundColor: theme.backgroundSecondary,
        padding: "10px",
        marginBottom: "10px",
        borderRadius: "5px",
        animation: "$slideIn 0.75s ease-in-out"
    },
    input: {
        width: "100%"
    },
    sectionTitleRow: {
        alignItems: "center",
        gap: "10px",
        marginBottom: "1rem"
    },
    sectionTitle: {
        color: theme.textPrimary,
        margin: "0px",
        fontWeight: "500"
    },
    description: {
        color: theme.textSecondary,
        margin: "0px 0px 5px 0px",
        fontSize: "15px",
        whiteSpace: "pre-wrap"
    },
    icon: {
        color: theme.blue,
        fontSize: "18px"
    },
    steps: {
        width: '100%',
        marginBottom: "2rem",
    },
    stepCompleted: {
        "& .MuiStepLabel-label": {
            color: theme.textPrimary + " !important",
            '&:hover': {
                cursor: 'pointer',
                textDecoration: 'underline !important'
            }
        },
    },
    step: {
        "& .MuiStepLabel-label": {
            color: theme.textPrimary + " !important",
        },
    }
}))