import { Information, Session } from "../../../Model/Session"

export interface InternalInformationBoxState {
    open: boolean,
    inOut: boolean,
    information: Information | null,
    timeoutId: any,
}

type setOpen = { type: "setOpen", value: boolean }
type setInOut = { type: "setInOut", value: boolean }
type setInformation = { type: "setInformation", information: Information | null }
type close = { type: "close" }
type setTimeoutId = { type: "setTimeoutId", timeoutId: NodeJS.Timeout }

export type InformationBoxAction = setOpen | setInOut | setInformation | close | setTimeoutId;

export const informationBoxReducer = (state: InternalInformationBoxState, action: InformationBoxAction): InternalInformationBoxState => {
    switch (action.type) {
        case "setInOut":
            return {
                ...state,
                inOut: action.value
            }

        case "setOpen":
            return {
                ...state,
                open: action.value
            }

        case "setInformation":
            return {
                ...state,
                information: action.information
            }

        case "close":
            return {
                ...state,
                inOut: false,
                open: false
            }

        case "setTimeoutId":
            return {
                ...state,
                timeoutId: action.timeoutId
            }
    }
}

export const initialInformationBoxState = (session: Session): InternalInformationBoxState => {
    return {
        open: false,
        inOut: false,
        information: session.information,
        timeoutId: null
    }
}