import { createUseStyles } from "react-jss";
import { HendigoTheme } from "~base/Resources/Styling/HendigoTheme";
import { Navbar } from "../Composite/Navbar/Component";
import { ViewBaseProps } from "~base/Model/ViewBaseProps";
import { Outlet } from "react-router-dom";
import { FeedSideNavbar } from "../Composite/SideNavbar/Component";
import { Handy } from "../Composite/Handy/Component";

const useStyles = createUseStyles((theme: HendigoTheme) => ({
    wrapper: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        backgroundColor: theme.backgroundPrimary,
        padding: "20px 10%"
    },
    '@media (max-width: 1500px)': {
        wrapper: {
            padding: "20px 1%"
        }
    }
}));

interface FeedLayoutProps {
    viewBaseProps: ViewBaseProps;
    useNavbar?: boolean;
}

export const FeedLayout: React.FC<FeedLayoutProps> = (props) => {
    const {
        viewBaseProps,
        useNavbar = true,
    } = props;

    const classes = useStyles();

    return <>
        {useNavbar && <Navbar {...viewBaseProps} />}
        
        <div className={classes.wrapper}>
            <FeedSideNavbar {...viewBaseProps} />
            <Outlet />
            <Handy {...viewBaseProps} />
        </div>
    </>
}