import { Avatar, FormControl, InputLabel, MenuItem, Select, Typography } from "@mui/material";
import React from "react";
import { createUseStyles } from "react-jss";
import { Link, useNavigate } from "react-router-dom";
import { Loader } from "~base/Components/Elements/Cosmetic/Loader";
import { Overlay } from "~base/Components/Elements/Cosmetic/Overlay";
import { Row } from "~base/Components/Elements/Cosmetic/Row";
import { ApplicationResponse } from "~base/Model/ResponseModels/ApplicationResponse";
import { Session } from "~base/Model/Session";
import { postStatus } from "../Fetch";
import { ManageAdAction } from "../Store";
import { useIntl } from "~base/Internalization/IntlContext";
import { useFilesAPIService } from "~base/Services/FilesAPIServiceContext";

const useStyles = createUseStyles({
    row: {
        gap: "15px",
        marginBottom: "15px"
    },
    avatar: {
        height: "100px !important",
        width: "100px !important",
        fontSize: "50px !important",
        textDecoration: "none",
        "&:hover": {
            cursor: "pointer"
        }
    },
    name: {
        "&:hover": {
            cursor: "pointer",
            textDecoration: "underline"
        }
    }
})

interface ApplicationOverlayProps {
    show: boolean;
    application: ApplicationResponse | null;
    onCancel: () => void;
    session: Session;
    statusLoading: boolean;
    dispatch: React.Dispatch<ManageAdAction>;
    statusError: boolean;
}

export const ApplicationOverlay: React.FC<ApplicationOverlayProps> = ({ show, application, onCancel, session, statusLoading: showStatusLoading, dispatch, statusError }) => {

    const intl = useIntl();
    const classes = useStyles();
    const navigate = useNavigate();
    const filesAPIService = useFilesAPIService();

    const profilePicture = filesAPIService.getProfileImageSmall(application?.small_image_pathname);
    const firstLetterInName = application?.fullname?.substring(0, 1);
    const profileLink = `/anvandare/${application?.user_id}`;

    return (
        <Overlay
            show={show}
            onCancel={onCancel}
            abortButtonText="STÄNG"
            showSaveButton={false}
            title="Ansökan"
        >
            {application != null ? <div>
                <Row className={classes.row}>
                    <Avatar
                        src={profilePicture}
                        className={classes.avatar}
                        component={Link}
                        to={profileLink}
                    >
                        {firstLetterInName}
                    </Avatar>

                    <div>
                        <Typography
                            className={classes.name}
                            variant="h5"
                            onClick={() => navigate(profileLink)}
                        >
                            {application.fullname}
                        </Typography>

                        <Typography>{application.title}</Typography>

                        <br />

                        <Typography>Ansökte <b>{intl.formatDateLong(application.time_created ?? "")}</b></Typography>
                    </div>
                </Row>

                <Typography variant="h6">Brev</Typography>

                <Typography>{application.letter}</Typography>

                <br />

                <br />

                <hr />

                <Typography variant="h6">Ändra status</Typography>

                <br />

                {showStatusLoading && <Row style={{ justifyContent: "center" }}><Loader /></Row>}

                {!showStatusLoading && (
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Status</InputLabel>
                        <Select
                            error={statusError}
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={application.status_id ?? 0}
                            //defaultValue={application.status_id}
                            label="Status"
                            onChange={(val) => application ? postStatus({
                                ad_id: application.ad_id,
                                application_id: application.id,
                                status_id: Number(val.target.value)
                            }, dispatch, session) : null}
                        >
                            {session.data.applicationStatus.map(s =>
                                <MenuItem
                                    key={`status_${s.id}`}
                                    value={Number(s.id)}
                                >
                                    {intl.formatText(s.status)}
                                </MenuItem>
                            )}
                        </Select>
                    </FormControl>
                )}
            </div> : <></>}
        </Overlay>
    )
}