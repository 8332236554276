import { ViewBaseProps } from "~base/Model/ViewBaseProps";
import { AdApplyStyles } from "./Styling";
import { Row } from "~base/Components/Elements/Cosmetic/Row";
import { AdCard } from "~base/Components/Composite/AdCard/AdCard";
import { BsInfoCircle } from "react-icons/bs";
import { Button, Typography } from "@mui/material";
import React from "react";
import { createApplication, getAd } from "./Fetch";
import { useNavigate, useParams } from "react-router-dom";
import { initialState, reducer } from "./Store";
import { Loader } from "~base/Components/Elements/Cosmetic/Loader";
import { CreateApplicationRequest } from "~base/Model/RequestModels/CreateApplicationRequest";
import { MUITextField } from "~base/Components/Elements/MUI/MUITextField";
import { useIntl } from "~base/Internalization/IntlContext";

export const AdApply: React.FC<ViewBaseProps> = ({ session }) => {

    const intl = useIntl();

    window.view = "AdApply";
    intl.formatPageTitle("PageTitle.AdApply");

    const { id } = useParams();

    const classes = AdApplyStyles();

    const navigate = useNavigate();

    // State and dispatch
    const [state, dispatch] = React.useReducer(reducer, initialState());

    React.useEffect(() => {
        getAd(id ?? "", dispatch, session);
    }, []);

    function onApply() {
        createApplication({ ad_id: id, letter: state.coverLetter } as CreateApplicationRequest, dispatch, session, navigate);
    }

    return <div className={classes.wrapper}>
        
        {state.loading && <div style={{ display: "flex", flexDirection: "column", rowGap: "25px", alignItems: "center" }}>
            <Typography variant={"h5"}>
                {intl.formatText(state.loadingMessage)}
            </Typography>
            <Loader />
        </div>}

        {!state.loading && <>
            <div className={classes.sectionWrapper}>
                <Row className={classes.sectionTitleRow}>
                    <h1 className={classes.sectionTitle}>
                        {intl.formatText("Views.AdApply.Title")}
                    </h1>
                </Row>

                <p className={classes.description}>
                    {intl.formatText("Views.AdApply.Description")}
                </p>
            </div>

            <AdCard ad={state.ad} />

            <div className={classes.sectionWrapper}>
                <Row className={classes.sectionTitleRow}>
                    <BsInfoCircle className={classes.icon} />

                    <h3 className={classes.sectionTitle}>
                        {intl.formatText("Views.AdApply.Letter")}
                    </h3>
                </Row>

                <p className={classes.description}>
                    {intl.formatText("Views.AdApply.CoverLetter.Description")}
                </p>

                <br />

                <MUITextField 
                    label={intl.formatText("Views.AdApply.CoverLetter.Label")}
                    multiline
                    rows={4}
                    fullWidth
                    value={state.coverLetter}
                    onChange={(e) => dispatch({ type: "setCoverLetter", value: e.target.value })}
                    limit={2000}
                />
            </div>

            <Button
                variant={"contained"}
                className={classes.applyButton}
                onClick={onApply}
                disabled={state.loading || state.coverLetter.length === 0}
            >
                {intl.formatText("Views.AdApply.Apply")}
            </Button>
        </>}
    </div>
}