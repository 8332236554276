import { ListItem, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import { AiOutlineCalendar, AiOutlineUsergroupAdd } from "react-icons/ai";
import { CgProfile } from "react-icons/cg";
import { FiLogOut } from "react-icons/fi";
import { HiOutlineUserGroup } from "react-icons/hi";
import { TbHammer } from "react-icons/tb";
import { useIntl } from "~base/Internalization/IntlContext";
import { UserProfileResponse } from "~base/Model/ResponseModels/UserProfileResponse";

interface UserMenuProps {
    handleClick: (route: string | null) => void;
    userProfile: UserProfileResponse;
    handleLogout: () => void;
}

export const UserMenu: React.FC<UserMenuProps> = ({ handleClick, userProfile, handleLogout }) => {

    const intl = useIntl();

    return <>
        <ListItem onClick={() => handleClick("/anvandare/" + userProfile.user_id)} disablePadding>
            <ListItemButton>
                <ListItemText primary={intl.formatText("Views.SideNavBar.MyProfile")} />
                <ListItemIcon>
                    <CgProfile style={{ fontSize: "25px" }} />
                </ListItemIcon>
            </ListItemButton>
        </ListItem>

        <ListItem onClick={() => handleClick("/jobb")} disablePadding>
            <ListItemButton>
                <ListItemText primary={intl.formatText("Views.SideNavBar.Work")} />
                <ListItemIcon>
                    <TbHammer style={{ fontSize: "25px" }} />
                </ListItemIcon>
            </ListItemButton>
        </ListItem>

        <ListItem onClick={() => handleClick("/natverk")} disablePadding>
            <ListItemButton>
                <ListItemText primary={intl.formatText("Views.SideNavBar.Network")} />
                <ListItemIcon>
                    <AiOutlineUsergroupAdd style={{ fontSize: "25px" }} />
                </ListItemIcon>
            </ListItemButton>
        </ListItem>

        <ListItem /*onClick={() => handleClick("/event")}*/ disablePadding>
            <ListItemButton disabled>
                <ListItemText primary={'Event'} />
                <ListItemIcon>
                    <AiOutlineCalendar style={{ fontSize: "25px" }} />
                </ListItemIcon>
            </ListItemButton>
        </ListItem>

        <ListItem /*onClick={() => handleClick("/grupper")}*/ disablePadding>
            <ListItemButton disabled>
                <ListItemText primary={intl.formatText("Views.SideNavBar.Groups")} />

                <ListItemIcon>
                    <HiOutlineUserGroup style={{ fontSize: "25px" }} />
                </ListItemIcon>
            </ListItemButton>
        </ListItem>

        <ListItem onClick={() => handleLogout()} disablePadding>
            <ListItemButton>
                <ListItemText primary={intl.formatText("Views.SideNavBar.LogOut")} />
                <ListItemIcon>
                    <FiLogOut style={{ fontSize: "25px" }} />
                </ListItemIcon>
            </ListItemButton>
        </ListItem>
    </>
}