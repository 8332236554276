import { Text } from "../Text/Texts";

/**
 * Internalization and localization of error messages.
 */
export const Errors = [
    /**
     * Common errors.
     */
    {
        code: "Error.Common.TransactionError",
        en: "Transaction error.",
        sv: "Transaktionsfel."
    },
    {
        code: "Error.Common.FailedToCommitTransaction",
        en: "Failed to commit transaction.",
        sv: "Misslyckades med att slutföra transaktionen."
    },
    {
        code: "Error.Common.CouldNotConnect",
        en: "Could not connect to server.",
        sv: "Kunde inte ansluta till servern."
    },
    {
        code: "Error.Common.NotAuthorized",
        en: "Not authorized.",
        sv: "Inte behörig."
    },
    {
        code: "Error.Common.DBError",
        en: "There was a problem with updating the database.",
        sv: "Det uppstod ett problem med uppdatering av databasen."
    },
    {
        code: "Error.Common.InvalidAction",
        en: "Invalid API action.",
        sv: "Ogiltig API action."
    },
    /**
     * View-specific errors.
     */
    {
        code: "Error.Registration.EmailAlreadyExists",
        en: "Email address already exists.",
        sv: "E-postadressen finns redan."
    },
    {
        code: "Error.Registration.FailedAddingUserToDB",
        en: "Failed adding user to database.",
        sv: "Misslyckades med att lägga till användare i databasen."
    },
    {
        code: "Error.Registration.FailedCreatingFolder",
        en: "Failed creating folder.",
        sv: "Misslyckades med att skapa mapp."
    },
    {
        code: "Error.Registration.FailedAddingUserProfileToDB",
        en: "Failed adding user profile to database.",
        sv: "Misslyckades med att lägga till användarprofil i databasen."
    },
    {
        code: "Error.Registration.OrgNumberAlreadyExists",
        en: "Organization number already exists.",
        sv: "Organisationsnummer finns redan."
    },
    {
        code: "Error.Registration.FailedAddingCompanyToDB",
        en: "Failed adding company to database.",
        sv: "Misslyckades med att lägga till företag i databasen."
    },
    {
        code: "Error.Registration.FailedAddingCompanyProfileToDB",
        en: "Failed adding company profile to database.",
        sv: "Misslyckades med att lägga till företagsprofil i databasen."
    },
    {
        code: 'Error.CompanyProfile.CompanyNameInvalid',
        en: 'Company name is invalid.',
        sv: 'Företagsnamnet är ogiltigt.'
    },
    {
        code: 'Error.CompanyProfile.CompanyDescriptionInvalid',
        en: 'Company description is invalid.',
        sv: 'Företagsbeskrivningen är ogiltig.'
    },
    {
        code: 'Error.CompanyProfile.CompanyAddressInvalid',
        en: 'Company address is invalid.',
        sv: 'Företagsadressen är ogiltig.'
    },
    {
        code: 'Error.CompanyProfile.CompanyZipCodeInvalid',
        en: 'Company zip code is invalid.',
        sv: 'Företagspostnumret är ogiltigt.'
    },
    {
        code: 'Error.CompanyProfile.CompanyCityInvalid',
        en: 'Company city is invalid.',
        sv: 'Företagsorten är ogiltig.'
    },
    {
        code: 'Error.CompanyProfile.CouldNotEditCompanyName',
        en: 'Could not edit company name.',
        sv: 'Kunde inte ändra företagsnamnet.'
    },
    {
        code: 'Error.CompanyProfile.CouldNotEditCompanyLocation',
        en: 'Could not edit company location.',
        sv: 'Kunde inte ändra företagsplatsen.'
    },
    {
        code: 'Error.CompanyProfile.CouldNotEditCompanyDescription',
        en: 'Could not edit company description.',
        sv: 'Kunde inte ändra företagsbeskrivningen.'
    },
    {
        code: 'Error.CompanyProfile.CouldNotEditCompanyAddress',
        en: 'Could not edit company address.',
        sv: 'Kunde inte ändra företagsadressen.'
    },
    {
        code: 'Error.CompanyProfile.CouldNotEditCompanyCity',
        en: 'Could not edit company city.',
        sv: 'Kunde inte ändra företagsorten.'
    },
    {
        code: 'Error.CompanyProfile.CouldNotEditCompanyZipCode',
        en: 'Could not edit company zip code.',
        sv: 'Kunde inte ändra företagspostnumret.'
    }
] as Text[];