// ----------------------------------------------------
// Imports
// ----------------------------------------------------

import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { AiOutlineEdit, AiOutlineShareAlt } from "react-icons/ai";
import { Card } from "~base/Components/Elements/DataVisualization/Card";
import { ProfileStyles } from "~base/Components/Shared/Styling/ProfileStyling";
import { MiddleWrapper } from "~base/Components/Elements/Cosmetic/MiddleWrapper";
import { EditCompanyOverlay } from "./ChildComponents/EditCompanyOverlay";
import { EditProfilePictureOverlay } from "~base/Components/Composite/EditProfilePictureOverlay/EditProfilePictureOverlay";
import { CompanyNotFound } from "./ChildComponents/CompanyNotFound";
import { Row } from "~base/Components/Elements/Cosmetic/Row";
import { PlusCard } from "~base/Components/Elements/Cosmetic/PlusCard";
import { SmallImagePathNameDefault } from "~base/Routing/Paths";
import { Listing } from "~base/Components/Elements/DataVisualization/Listing";
import { Divider } from "~base/Components/Elements/Cosmetic/Divider";
import { ViewBaseProps } from "~base/Model/ViewBaseProps";
import { Button as LegacyButton } from "~base/Components/Elements/Input/Button";
import { companyReducer, initialCompanyState } from "./Store";
import {
  fetchCompanyPosts,
  fetchCompanyProfile,
  getCompanyAds,
  getFollowCompanyStatus,
  postFollowCompany,
  removeFollowCompany,
} from "./Fetch";
import { EditCompanyPostOverlay } from "./ChildComponents/EditCompanyPostOverlay";
import { useIntl } from "~base/Internalization/IntlContext";
import { AdCard } from "~base/Components/Composite/AdCard/AdCard";
import { Avatar, Button, Typography } from "@mui/material";
import { useFilesAPIService } from "~base/Services/FilesAPIServiceContext";

// ----------------------------------------------------
// Component
// ----------------------------------------------------

export const Company: React.FC<ViewBaseProps> = ({ session }): JSX.Element => {
  window.view = "Company";

  const intl = useIntl();
  const { id } = useParams();
  const navigate = useNavigate();
  const filesAPIService = useFilesAPIService();

  const [state, dispatch] = React.useReducer(
    companyReducer,
    initialCompanyState(session, id as string)
  );

  const classes = ProfileStyles();

  // Have profile compant name as page title
  React.useEffect(() => {
    if (state.profile?.company_name) {
      intl.formatPageTitleString(state.profile?.company_name);
    } else {
      intl.formatPageTitle("PageTitle.Company");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.profile]);

  // If you switch between from one company page to another we need to reset the state
  // For example if you are on company/not you and then you go to company/you via useNavigate() by pressing "My Company"
  React.useEffect(() => {
    dispatch({
      type: "resetState",
      state: initialCompanyState(session, id as string),
    });
  }, [id, state.yourProfile, session]);

  /**
   * Fetch
   */
  React.useEffect(() => {
    fetchCompanyProfile(state.yourProfile, state.id, dispatch, session);
    fetchCompanyPosts(state.id, dispatch, session);
    getFollowCompanyStatus(state.id, dispatch, session);
    getCompanyAds(state.id, dispatch, session);
  }, [session, state.id, state.yourProfile]);

  const profilePicture = filesAPIService.getProfileImageSmall(state.profile?.small_image_pathname);
  const backgroundPicture = state.profile?.big_image_pathname ?
    filesAPIService.getProfileImageLarge(state.profile?.big_image_pathname) :
    "/images/hendigo/default_big.jpg";
  const firstLetterInName = state.profile?.company_name.substring(0, 1);

  return (
    <MiddleWrapper>
      <EditCompanyOverlay
        profile={state.profile}
        showOverlay={state.showEditProfileOverlay}
        onCancel={() =>
          dispatch({ type: "setShowEditProfileOverlay", value: false })
        }
        onError={(error) => session.setInformation("error", error)}
        session={session}
      />

      <EditProfilePictureOverlay
        show={state.showEditProfilePictureOverlay}
        onCancel={() =>
          dispatch({ type: "setShowEditProfilePictureOverlay", value: false })
        }
        onSave={() => {
          window.location.reload();
          dispatch({ type: "setShowEditProfilePictureOverlay", value: false });
        }}
        session={session}
        smallImagePathname={session.companyProfile?.small_image_pathname!}
        bigImagePathname={session.companyProfile?.big_image_pathname!}
      />

      <EditCompanyPostOverlay
        session={session}
        show={state.showEditPostOverlay}
        onCancel={() =>
          dispatch({ type: "setShowEditPostOverlay", value: false, post: null })
        }
        onSave={() => {
          dispatch({
            type: "setShowEditPostOverlay",
            value: false,
            post: null,
          });
          window.location.reload();
        }}
        post={state.post}
      />

      {state.accountNotFound || state.id === null ? (
        <CompanyNotFound />
      ) : (
        <>
          <div
            style={{
              backgroundImage: "url(" + backgroundPicture + ")",
            }}
            className={
              classes.background_image +
              " " +
              (state.yourProfile === true ? classes.yourBackground : "")
            }
            onClick={() =>
              state.yourProfile === true
                ? dispatch({
                  type: "setShowEditProfilePictureOverlay",
                  value: true,
                })
                : null
            }
          >
            <Avatar
              src={profilePicture}
              className={classes.avatar}
            >
              {firstLetterInName}
            </Avatar>
          </div>

          <div className={classes.wrapper}>
            <div className={classes.aboutWrapper}>
              <div>
                <AiOutlineShareAlt
                  className={classes.edit_button}
                  onClick={() => {
                    session.setInformation(
                      "information",
                      "Views.Company.CopyToClipboard"
                    );
                    navigator.clipboard.writeText(window.location.href);
                  }}
                />

                {state.yourProfile === true ? (
                  <AiOutlineEdit
                    className={classes.edit_button}
                    onClick={() =>
                      dispatch({
                        type: "setShowEditProfileOverlay",
                        value: true,
                      })
                    }
                  />
                ) : null}
              </div>

              <h2 className={classes.name}>{state.profile?.company_name}</h2>

              <div className={classes.location}>
                {intl.formatText(state.profile?.location ?? "")}
              </div>

              <div
                className={classes.socialLink}
                onClick={() =>
                  navigate("/foretag/" + state.profile?.id + "/foljare")
                }
              >
                {state.profile?.follower_count} följare
              </div>

              <Divider style={{ margin: "10px 0px" }} />

              {state.yourProfile === false ? (
                <LegacyButton
                  className={classes.followButton}
                  colorType="blue"
                  onClick={() => {
                    if (state.isFollowing === false) {
                      postFollowCompany(state, dispatch, session);
                    } else {
                      removeFollowCompany(state, dispatch, session);
                    }
                  }}
                >
                  {state.isFollowing
                    ? intl.formatText("View.Company.FollowButton.Unfollow")
                    : intl.formatText("View.Company.FollowButton.Follow")}
                </LegacyButton>
              ) : null}
            </div>

            <div className={classes.contentWrapper}>
              <h2 className={classes.name}>
                {intl.formatText("Views.Company.About")}
              </h2>
              <p className={classes.about_text}>
                {state.profile?.company_description === ""
                  ? intl.formatText("Views.Company.NoDescription")
                  : state.profile?.company_description}
              </p>
            </div>

            <div className={classes.contentWrapper}>
              {state.yourProfile && (
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "space-between",
                    marginBottom: "10px",
                  }}
                >
                  <h2 className={classes.name}>
                    {intl.formatText("Views.Company.Ads")}
                  </h2>
                  <Button
                    onClick={() => navigate("/jobb/annons/skapa")}
                    variant="contained"
                  >
                    {intl.formatText("Views.Company.CreateAd")}
                  </Button>
                </div>
              )}

              {state.ads.map((ad, index) => {
                return <AdCard ad={ad} />;
              })}
            </div>

            <div className={classes.contentWrapper}>
              <h2 className={classes.name}>
                {intl.formatText("Views.Company.post")}
              </h2>

              <Row>
                {state.posts.map((post, index) => {
                  return (
                    <Card
                      key={post.id + index}
                      text={post.content}
                      companyName={post.name}
                      avatarImagePathName={post.small_image_pathname}
                      owner={state.yourProfile}
                      time={intl.formatTimeShort(new Date(post.time_created))}
                      onFurtherClick={() =>
                        navigate("/foretag/inlagg/" + post.id)
                      }
                      onEditClick={() =>
                        dispatch({
                          type: "setShowEditPostOverlay",
                          value: true,
                          post: post,
                        })
                      }
                    />
                  );
                })}
                {state.yourProfile ? (
                  <PlusCard onClick={() => navigate("/")} />
                ) : null}
              </Row>
            </div>

            {/*<div className={classes.contentWrapper}>
              <h2 className={classes.name}>
                {intl.formatText("Views.Company.Employees")}
              </h2>

              <Listing
                imagePath={SmallImagePathNameDefault}
                title="Fredrik Lundström"
                subTitle="Systemutvecklare"
                subSubTitle="2021-2022"
                link="/anvandare"
              />
              <Divider />
              <Listing
                imagePath={SmallImagePathNameDefault}
                title="Kadir Cinsulluoglu"
                subTitle="Säljare"
                subSubTitle="2021-2022"
                link="/anvandare"
              />
            </div>*/}
          </div>
        </>
      )}
    </MiddleWrapper>
  );
};
