// ----------------------------------------------------
// Imports
// ----------------------------------------------------

import React from "react";
import { BiEdit, BiPlus } from "react-icons/bi";
import { Link, useNavigate, useParams } from "react-router-dom";
import { EditUserOverlay } from "./ChildComponents/EditUserOverlay";
import { EditPostOverlay } from "../../Composite/Post/ChildComponents/EditPostOverlay";
import { UserNotFound } from "./ChildComponents/UserNotFound";
import { userReducer, initialUserState } from "./Store";
import { ProfileStyles } from "~base/Components/Shared/Styling/ProfileStyling";
import { MiddleWrapper } from "~base/Components/Elements/Cosmetic/MiddleWrapper";
import { EditProfilePictureOverlay } from "~base/Components/Composite/EditProfilePictureOverlay/EditProfilePictureOverlay";
import { Divider } from "~base/Components/Elements/Cosmetic/Divider";
import { Row } from "~base/Components/Elements/Cosmetic/Row";
import { Card } from "~base/Components/Elements/DataVisualization/Card";
import { PlusCard } from "~base/Components/Elements/Cosmetic/PlusCard";
import { ViewBaseProps } from "~base/Model/ViewBaseProps";
import { AiOutlineShareAlt } from "react-icons/ai";
import { Button } from "~base/Components/Elements/Input/Button";
import { deleteContact, getContactStatus, getExperience, getFollowUserStatus, getUserPosts, getProfile, postContact, postFollowUser, removeFollowUser } from "./Fetch";
import { createUseStyles } from "react-jss";
import { HendigoTheme } from "~base/Resources/Styling/HendigoTheme";
import { CreateUserExperienceOverlay } from "./ChildComponents/CreateUserExperienceOverlay";
import { EditUserExperienceOverlay } from "./ChildComponents/EditUserExperienceOverlay";
import { useIntl } from "~base/Internalization/IntlContext";
import { Avatar } from "@mui/material";
import { useFilesAPIService } from "~base/Services/FilesAPIServiceContext";

// ----------------------------------------------------
// Component
// ----------------------------------------------------

const styling = createUseStyles((theme: HendigoTheme) => ({
    experienceWrapper: {
        padding: "10px 0px 10px 0px",
        color: theme.textPrimary,
    },
    experienceImageWrapper: {
        marginTop: 2,
    },
    experienceImage: {
        height: "59px",
        width: "59px",
        borderRadius: "100%",
        marginRight: "10px"
    },
    experienceTextWrapper: {
        lineHeight: "1.3",
    },
    experienceTitle: {
        display: "flex",
        gap: "5px",
        alignItems: "center",
    },
    experienceTitleHeader: {
        fontWeight: "700",
        margin: "0 !important",
    },
    experienceTitleSubHeader: {
        margin: "0 !important",
    },
    experienceSubTitle: {
        color: theme.textSecondary,
    },
    experienceSubSubTitle: {
        color: theme.textSecondary,
    },
    experienceDescription: {
        marginTop: 5,
    },
}));

export const User: React.FC<ViewBaseProps> = ({ session }) => {

    const intl = useIntl();

    const filesAPIService = useFilesAPIService();

    window.view = "User";

    const classes = ProfileStyles();
    const classesExperience = styling();

    const { id } = useParams();

    const navigate = useNavigate();

    const [state, dispatch] = React.useReducer(userReducer, initialUserState(session, id as string, intl));

    // Have profile fullname as page title
    React.useEffect(() => {
        if (state.profile?.fullname) {
            intl.formatPageTitleString(state.profile?.fullname);
        } else {
            intl.formatPageTitle("PageTitle.User");
        }
    }, [intl, state.profile]);

    // If you switch between from one user page to another we need to reset the state
    // For example if you are on user/notyou and then you go to user/you via useNavigate() by pressing "Min profil"
    React.useEffect(() => {
        dispatch({ type: "resetState", state: initialUserState(session, id as string, intl) })
    }, [id, state.yourProfile, session, intl]);

    /**
     * Fetch
     */
    React.useEffect(() => {
        getProfile(session, dispatch, id as string, session.userId === id);
        getUserPosts(session, dispatch, id as string);
        getContactStatus(session, dispatch, session.userId === id, id as string, intl);
        getFollowUserStatus(id as string, dispatch, session);
        getExperience(session, dispatch, id as string);
    }, [session, id, intl]);

    const profilePicture = filesAPIService.getProfileImageSmall(state.profile?.small_image_pathname);
    const backgroundPicture = state.profile?.big_image_pathname ?
        filesAPIService.getProfileImageLarge(state.profile?.big_image_pathname) :
        "/images/hendigo/default_big.jpg";
    const firstLetterInName = state.profile?.fullname.substring(0, 1);

    return <MiddleWrapper>

        <EditUserOverlay
            state={state}
            onCancel={() => dispatch({ type: "setShowOverlay", value: false })}
            dispatch={dispatch}
            onError={(error) => session.setInformation("error", error)}
            session={session}
        />

        <EditProfilePictureOverlay
            session={session}
            show={state.showEditProfilePictureOverlay}
            onCancel={() => dispatch({ type: "setShowEditProfilePictureOverlay", value: false })}
            onSave={() => {
                dispatch({ type: "setShowEditProfilePictureOverlay", value: false });
                window.location.reload();
            }}
            smallImagePathname={profilePicture}
            bigImagePathname={backgroundPicture}
        />

        <EditPostOverlay
            session={session}
            show={state.showEditPostOverlay}
            onCancel={() => dispatch({ type: "setShowEditPostOverlay", value: false, post: null })}
            onSave={(newContent: string) => {
                dispatch({ type: "setShowEditPostOverlay", value: false, post: null });
                window.location.reload();
            }}
            content={state.post?.content ?? ""}
        />

        <CreateUserExperienceOverlay
            session={session}
            show={state.showCreateUserExperienceOverlay}
            onCancel={() => dispatch({ type: "setShowCreateUserExperienceOverlay", value: false })}
            onSave={() => {
                dispatch({ type: "setShowCreateUserExperienceOverlay", value: false });
                window.location.reload();
            }}
        />

        <EditUserExperienceOverlay
            session={session}
            show={state.showEditUserExperienceOverlay}
            onCancel={() => dispatch({ type: "setShowEditUserExperienceOverlay", value: false })}
            onSave={() => {
                dispatch({ type: "setShowEditUserExperienceOverlay", value: false });
                window.location.reload();
            }}
            userExperience={state.userExperience}
            classesExperience={classesExperience}
        />

        {state.accountNotFound || state.id === null ? <UserNotFound /> : <>
            <div
                style={{
                    backgroundImage: "url(" + backgroundPicture + ")",
                }}
                className={classes.background_image + " " + (state.yourProfile === true ? classes.yourBackground : "")}
                onClick={() => state.yourProfile === true ? dispatch({ type: "setShowEditProfilePictureOverlay", value: true }) : null}
            >
                <Avatar
                    src={profilePicture}
                    className={classes.avatar}
                >
                    {firstLetterInName}
                </Avatar>
            </div>

            <div className={classes.wrapper}>
                <div className={classes.aboutWrapper}>
                    <AiOutlineShareAlt
                        className={classes.edit_button}
                        onClick={() => {
                            session.setInformation("information", intl.formatText("Views.User.CopyToClipboard"));
                            navigator.clipboard.writeText(window.location.href);
                        }}
                    />
                    {state.yourProfile === true ?
                        <BiEdit
                            className={classes.edit_button}
                            onClick={() => dispatch({ type: "setShowOverlay", value: true })} />
                        : null}

                    <h2 className={classes.name}>
                        {state.profile?.fullname}
                    </h2>

                    <div className={classes.location}>{state.profile?.title} • {intl.formatText(state.profile?.location ?? "")}</div>

                    <Row style={{ columnGap: "10px" }}>
                        <div
                            className={classes.socialLink}
                        //onClick={() => navigate("/anvandare/" + state.profile?.id + "/kontakter")}
                        >
                            {state.profile?.contact_count} {intl.formatText("Views.User.Contacts")}
                        </div>

                        {state.profile?.public || state.yourProfile ?
                            <div
                                className={classes.socialLink}
                            //onClick={() => navigate("/anvandare/" + state.profile?.id + "/foljare")}
                            >
                                {state.profile?.follower_count} {intl.formatText("Views.User.Followers")}
                            </div>
                            : null}
                    </Row>

                    <Divider style={{ margin: "10px 0px" }} />

                    {state.contactButton.show ? <Button
                        className={classes.followButton}
                        colorType="blue"
                        disabled={state.contactButton.disabled}
                        onClick={() => {
                            if (state.contactButton.disabled === false && state.contactButton.text === intl.formatText("View.User.ContactButton.MakeContact")) {
                                postContact(session, state);
                                dispatch({ type: "setContactButtonText", value: intl.formatText("View.User.ContactButton.Sent") });
                                dispatch({ type: "setContactButtonDisabled", value: true })
                            }
                            if (state.contactButton.disabled === false && state.contactButton.text === intl.formatText("View.User.ContactButton.IsContacts")) {
                                deleteContact(session, state);
                                dispatch({ type: "setContactButtonText", value: intl.formatText("View.User.ContactButton.MakeContact") });
                            }
                        }}
                    >
                        {state.contactButton.text}
                    </Button> : null}

                    {state.yourProfile === false && state.profile?.public === true ? <Button
                        className={classes.followButton}
                        colorType="blue"
                        onClick={() => {
                            if (state.isFollowing === false) {
                                postFollowUser(state, dispatch, session);
                            } else {
                                removeFollowUser(state, dispatch, session);
                            }
                        }}
                    >
                        {state.isFollowing ? intl.formatText("View.Company.FollowButton.Unfollow") : intl.formatText("View.Company.FollowButton.Follow")}
                    </Button> : null}

                </div>

                <div className={classes.contentWrapper}>
                    <h2 className={classes.name}>{intl.formatText("Views.User.About")}</h2>
                    <p className={classes.about_text}>
                        {state.profile?.user_description === "" ? intl.formatText("Views.User.NoDescription") : state.profile?.user_description}
                    </p>
                </div>


                <div className={classes.contentWrapper}>
                    {state.yourProfile && (
                        <>
                            <BiPlus className={classes.edit_button} onClick={() => dispatch({ type: "setShowCreateUserExperienceOverlay", value: true })} />
                            <BiEdit className={classes.edit_button} onClick={() => dispatch({ type: "setShowEditUserExperienceOverlay", value: true })} />
                        </>
                    )}

                    <h2 className={classes.name}>{intl.formatText("Views.User.Experience")}</h2>

                    {state.userExperience.map((experience, index) => (
                        <div key={experience.id}>
                            <Row className={classesExperience.experienceWrapper}>
                                <div className={classesExperience.experienceImageWrapper}>
                                    <img className={classesExperience.experienceImage} src={experience.company_image ? experience.company_image : "/images/hendigo/default_small.png"} />
                                </div>
                                <div className={classesExperience.experienceTextWrapper}>
                                    <div className={classesExperience.experienceTitle}>
                                        <p className={classesExperience.experienceTitleHeader}>{experience.title}</p>
                                        <p className={classesExperience.experienceTitleSubHeader}>- {intl.formatText(experience.position_type)}</p>
                                    </div>
                                    <div className={classesExperience.experienceSubTitle}>{experience.company_name}</div>
                                    <div className={classesExperience.experienceSubSubTitle}>{intl.formatDateShort(experience.time_start)} - {experience.time_end ? intl.formatDateShort(experience.time_end) : "current"}</div>
                                    <div className={classesExperience.experienceDescription}>{experience.description}</div>
                                </div>
                            </Row>
                            {index !== state.userExperience.length - 1 ? <Divider /> : null}
                        </div>
                    ))}
                </div>

                <div className={classes.contentWrapper}>
                    <h2 className={classes.name}>{intl.formatText("Views.User.Posts")}</h2>
                    <Row>
                        {state.posts.map((post, index) => {
                            return <Card
                                key={post.id + index}
                                text={post.content}
                                companyName={post.name}
                                avatarImagePathName={post.small_image_pathname}
                                owner={state.yourProfile}
                                time={intl.formatTimeShort(new Date(post.time_created))}
                                onFurtherClick={() => navigate("/anvandare/inlagg/" + post.id)}
                                onEditClick={() => dispatch({ type: "setShowEditPostOverlay", value: true, post: post })}
                            />
                        })}
                        {state.yourProfile ? <PlusCard onClick={() => navigate("/")} /> : null}
                    </Row>
                </div>

                {/*<div className={classes.contentWrapper}>
                    <h2 className={classes.name}>{intl.formatText("Views.User.Certificates")}</h2>
                    <Listing imagePath="/images/hendigo/id06.jpg" title="ID06 Säkerhetskurs" subTitle="ID06" subSubTitle="2021" />
                    <Divider />
                    <Listing imagePath="/images/hendigo/leico.png" title="Liftutbildning" subTitle="Leico" subSubTitle="2020" />

                </div>*/}
            </div>
        </>
        }
    </MiddleWrapper>
}