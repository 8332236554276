import { createUseStyles } from "react-jss";
import { HendigoTheme } from "~base/Resources/Styling/HendigoTheme";

export const useStyles = createUseStyles((theme: HendigoTheme) => ({
    sectionWrapper: {
        backgroundColor: theme.backgroundSecondary,
        padding: "10px",
        marginBottom: "20px",
        borderRadius: "5px",
    },
    sectionTitle: {
        margin: "0",
        fontWeight: "500",
        color: theme.textPrimary
    },
    sectionDescription: {
        color: theme.textSecondary,
    },
    background: {
        backgroundColor: theme.green,
        borderRadius: "10px 10px 0px 0px",
        padding: "10px",
    },
    avatar: {
        height: "150px !important",
        width: "150px !important",
        fontSize: "50px !important",
    },
    aboutWrapper: {
        backgroundColor: theme.backgroundSecondary,
        padding: "10px",
        //border: "1px solid " + theme.border,
        //borderTop: "none",
        borderRadius: "0px 0px 10px 10px",
        margin: "0px 0px 20px 0px"
    },
    edit_button: {
        float: "right",
        marginTop: "5px",
        marginRight: "5px",
        fontSize: "25px",
        transition: "font-size 0.3s",
        color: theme.textSecondary,
        //border: "1px solid " + theme.border,
        borderRadius: "5px",
        '&:hover': {
            fontSize: "28px",
            cursor: "pointer",
        },
        padding: "2px"
    },
    name: {
        color: theme.textPrimary,
        margin: "0px",
        fontWeight: "500"
    },
    location: {
        color: theme.textSecondary,
        marginBottom: "10px",
        fontWeight: "500"
    },
    socialLink: {
        color: theme.blue,
        fontSize: "14px",
        marginTop: "4px",
        '&:hover': {
            cursor: "pointer",
            textDecoration: "underline"
        }
    },
    button: {
        marginRight: "8px",
    },
    row: {
        padding: "10px",
        backgroundColor: theme.backgroundSecondary,
        borderRadius: "10px",
        color: theme.textPrimary,
        marginBottom: "10px",
        gap: "10px",
    },
    errorText: {
        textAlign: "center",
        fontWeight: "500",
        color: theme.textSecondary
    },
    loaderWrapper: {
        margin: "20px auto"
    },
    poster: {
        borderRadius: "10px",
        padding: "10px",
        backgroundColor: theme.backgroundSecondary,
        color: theme.textPrimary,
        //border: "1px solid " + theme.border
    },
    posterTextareaWrapper: {
        margin: "10px 0px",
        width: "100%",
    },
}));