import React from "react";
import { ViewBaseProps } from "~base/Model/ViewBaseProps";
import { initialManageAdState, manageAdReducer } from "./Store";
import { getAd, getApplications } from "./Fetch";
import { useParams } from "react-router-dom";
import { MiddleWrapper } from "~base/Components/Elements/Cosmetic/MiddleWrapper";
import { createUseStyles } from "react-jss";
import { HendigoTheme } from "~base/Resources/Styling/HendigoTheme";
import { AdCard } from "~base/Components/Composite/AdCard/AdCard";
import { Row } from "~base/Components/Elements/Cosmetic/Row";
import { Button } from "@mui/material";
import { AiOutlineSetting } from "react-icons/ai";
import { BsPerson } from "react-icons/bs";
import { Listing } from "~base/Components/Elements/DataVisualization/Listing";
import { ApplicationOverlay } from "./ChildComponents/ApplicationOverlay";
import { useIntl } from "~base/Internalization/IntlContext";
import { useFilesAPIService } from "~base/Services/FilesAPIServiceContext";

const useStyles = createUseStyles((theme: HendigoTheme) => ({
    sectionWrapper: {
        backgroundColor: theme.backgroundSecondary,
        padding: "10px",
        marginBottom: "20px",
        borderRadius: "5px",
    },
    sectionTitle: {
        margin: "10px 0 0 0",
        fontWeight: "500",
        color: theme.textPrimary
    },
    sectionDescription: {
        color: theme.textSecondary,
    },
    sectionTitleRow: {
        alignItems: "center",
        gap: "10px",
        marginBottom: "1rem"
    },
    sectionSmallTitle: {
        color: theme.textPrimary,
        margin: "0px",
        fontWeight: "500"
    },
    icon: {
        color: theme.blue,
        fontSize: "18px"
    }
}));

interface ManageAdProps extends ViewBaseProps {

}

export const ManageAd: React.FC<ManageAdProps> = ({ session }) => {

    const intl = useIntl();
    const filesAPIService = useFilesAPIService();

    window.view = "ManageAd";
    intl.formatPageTitle("PageTitle.ManageAd");

    const classes = useStyles();

    const [state, dispatch] = React.useReducer(manageAdReducer, initialManageAdState());

    const { id } = useParams();

    React.useEffect(() => {
        if (id) {
            getAd(id, dispatch, session);
            getApplications(id, dispatch, session);
        }
    }, [id]);

    return <MiddleWrapper>
        <div className={classes.sectionWrapper}>
            <h2 className={classes.sectionTitle}>{intl.formatText("Views.ManageAd.Title")}</h2>
            <p className={classes.sectionDescription}>{intl.formatText("Views.ManageAd.Description")}</p>
        </div>

        <AdCard ad={state.ad} />

        <div className={classes.sectionWrapper}>
            <Row className={classes.sectionTitleRow}>
                <AiOutlineSetting className={classes.icon} />
                <h3 className={classes.sectionSmallTitle}>{intl.formatText("Views.ManageAd.Settings")}</h3>
            </Row>

            <Row style={{ gap: "10px" }}>
                <Button
                    variant="outlined"
                    disabled
                >
                    Redigera
                </Button>
                <Button
                    variant="outlined"
                    color="error"
                    disabled
                >
                    Avsluta annons
                </Button>
            </Row>
        </div>

        <div className={classes.sectionWrapper}>
            <Row className={classes.sectionTitleRow}>
                <BsPerson className={classes.icon} />
                <h3 className={classes.sectionSmallTitle}>{intl.formatText("Views.ManageAd.Applicants")}</h3>
            </Row>

            {state.applications.map(a =>
                <Listing
                    key={`application-${a.id}`}
                    imagePath={filesAPIService.getProfileImageSmall(a.small_image_pathname)}
                    title={a.fullname}
                    subTitle={a.title}
                    subSubTitle={a.letter?.substring(0, 50) + "..."}
                    onClick={() => dispatch({ type: "setOverlayState", value: { show: true, application: a } })}
                />)}
        </div>

        <ApplicationOverlay 
            {...state.overlayState}
            onCancel={() => {
                dispatch({ type: "setOverlayState", value: { ...state.overlayState, show: false }});
                dispatch({ type: "setStatusLoading", value: false });
                dispatch({ type: "setStatusError", value: false });
            }}
            session={session}
            statusLoading={state.statusLoading}
            statusError={state.statusError}
            dispatch={dispatch}
        />
    </MiddleWrapper>
}