// ----------------------------------------------------
// Imports
// ----------------------------------------------------

import { PostResponse } from "~base/Model/ResponseModels/PostResponse";
import { PostViewState } from "./Types";



// ----------------------------------------------------
// Actions
// ----------------------------------------------------

type SetPost = { type: "setPost", value: PostResponse | null }

export type PostAction =
    SetPost;

// ----------------------------------------------------
// Reducer
// ----------------------------------------------------

export const postReducer = (state: PostViewState, action: PostAction): PostViewState => {
    switch (action.type) {
        case "setPost":
            return {
                ...state,
                post: action.value
            }
    }
}

// ----------------------------------------------------
// Initial state
// ----------------------------------------------------

export const initialPostState = (postId: string): PostViewState => {
    return {
        postId: postId,
        post: null
    }
}