import { CreateAdAction } from "../Store";
import { CreateAdViewState } from "../Types";
import { Button, TextField } from "@mui/material";
import { HiOutlineArrowNarrowLeft, HiOutlineArrowNarrowRight } from "react-icons/hi";
import { Row } from "~base/Components/Elements/Cosmetic/Row";
import { CreateAdStyles } from "../Styling";
import { BsInfoCircle } from "react-icons/bs";
import Grid2 from "@mui/material/Unstable_Grid2";
import { CheckoutProduct } from "~base/Components/Composite/CheckoutProduct/Component";
import { Product } from "~base/Model/Entities/Product";
import { Divider } from "~base/Components/Elements/Cosmetic/Divider";
import { muiStyles } from "~base/Resources/Styling/MuiTheme";
import { useIntl } from "~base/Internalization/IntlContext";

interface PaymentProps {
    state: CreateAdViewState;
    dispatch: React.Dispatch<CreateAdAction>;
}

export const Payment: React.FC<PaymentProps> = ({ state, dispatch }) => {

    const intl = useIntl();
    const classes = CreateAdStyles();
    const muiClasses = muiStyles();

    return <>
        <div className={classes.sectionWrapper}>
            <Row className={classes.sectionTitleRow}>
                <h1 className={classes.sectionTitle}>
                    {intl.formatText("Views.CreateAd.Payment.Title")}
                </h1>
            </Row>

            <p className={classes.description}>
                {intl.formatText("Views.CreateAd.Payment.Description")}
            </p>
        </div>

        <div className={classes.sectionWrapper}>
            <Row className={classes.sectionTitleRow}>
                <BsInfoCircle className={classes.icon} />

                <h3 className={classes.sectionTitle}>
                    {intl.formatText("Views.CreateAd.Payment.Products")}
                </h3>
            </Row>

            <CheckoutProduct
                product={state.chosenProduct ?? {} as Product}
            />

            <p className={classes.description} style={{ marginTop: "1rem" }}>
                {intl.formatText("Views.CreateAd.Payment.Total")}: {state.invoice.amount} {state.invoice.currency}
            </p>

            <Divider />
            <p className={classes.description} style={{ marginTop: "1rem" }}>
                {intl.formatText("Views.CreateAd.Payment.ShipmentDate")}: {intl.formatDateLong(state.invoice.delivery_date)}
            </p>

            <p className={classes.description} style={{ marginTop: "1rem" }}>
                {intl.formatText("Views.CreateAd.Payment.Terms")}: {intl.formatText(state.invoice.payment_terms)}
            </p>

            <p className={classes.description} style={{ marginTop: "1rem" }}>
                {intl.formatText("Views.CreateAd.Payment.PaymentDueDate")}: {intl.formatDateLong(state.invoice.payment_due_date)}
            </p>
        </div>

        <div className={classes.sectionWrapper}>
            <Row className={classes.sectionTitleRow}>
                <BsInfoCircle className={classes.icon} />

                <h3 className={classes.sectionTitle}>
                    {intl.formatText("Views.CreateAd.Payment.InvoiceInformation")}
                </h3>
            </Row>

            <p className={classes.description}>
                {intl.formatText("Views.CreateAd.Payment.InvoiceInformation.Description")}
            </p>

            <Grid2 container spacing={2} style={{ margin: "1rem 0 0 0" }}>
                <Grid2 xs={6} >
                    <TextField
                        id="outlined-basic"
                        label={intl.formatText("Views.CreateAd.Payment.InvoiceInformation.CompanyName")}
                        variant="outlined"
                        className={muiClasses.textField + " " + classes.input}
                        value={state.invoice.buyer_name}
                        onChange={value => dispatch({ type: "setTitle", value: value.target.value })}
                    />
                </Grid2>

                <Grid2 xs={6} >
                    <TextField
                        id="outlined-basic"
                        label={intl.formatText("Views.CreateAd.Payment.InvoiceInformation.Address")}
                        variant="outlined"
                        className={muiClasses.textField + " " + classes.input}
                        value={state.invoice.buyer_address}
                        onChange={value => dispatch({ type: "setTitle", value: value.target.value })}
                    />
                </Grid2>

                <Grid2 xs={6} >
                    <TextField
                        id="outlined-basic"
                        label={intl.formatText("Views.CreateAd.Payment.InvoiceInformation.City")}
                        variant="outlined"
                        className={muiClasses.textField + " " + classes.input}
                        value={state.invoice.buyer_city}
                        onChange={value => dispatch({ type: "setTitle", value: value.target.value })}
                    />
                </Grid2>

                <Grid2 xs={6} >
                    <TextField
                        id="outlined-basic"
                        label={intl.formatText("Views.CreateAd.Payment.InvoiceInformation.ZipCode")}
                        variant="outlined"
                        className={muiClasses.textField + " " + classes.input}
                        value={state.invoice.buyer_zip_code}
                        onChange={value => dispatch({ type: "setTitle", value: value.target.value })}
                    />
                </Grid2>
            </Grid2>
        </div>

        <Row style={{ marginTop: "2rem" }}>
            <Button
                variant="contained"
                onClick={() => dispatch({ type: "setCurrentStep", value: "Views.CreateAd.Step.Preview" })}
            >
                <HiOutlineArrowNarrowLeft style={{ marginRight: "5px" }} />
                {intl.formatText("Views.CreateAd.PreviousStep")}
            </Button>
            <Button
                variant="contained"
                style={{ marginLeft: "auto" }}
                onClick={() => dispatch({ type: "setCurrentStep", value: "Views.CreateAd.Step.Create" })}
            >
                {intl.formatText("Views.CreateAd.NextStep")}
                <HiOutlineArrowNarrowRight style={{ marginLeft: "5px" }} />
            </Button>
        </Row>
    </>
}