import { HendigoBaseTheme } from '../../../Resources/Styling/HendigoBaseTheme';
import './Switch.css';

interface SwitchProps {
    checked?: boolean,
    onChange?: (checked: boolean) => void,
    id: string
}

export const Switch: React.FunctionComponent<SwitchProps> = (props): JSX.Element => {
    return <>
        <input
            className="react-switch-checkbox"
            id={props.id}
            type="checkbox"
            checked={props.checked}
            onChange={() => props.onChange && props.onChange(!props.checked)}
        />
        <label
            className="react-switch-label"
            htmlFor={props.id}
            style={props.checked ? { background: HendigoBaseTheme.colors.hendigoBlue } : {}}
        >
            <span className={`react-switch-button`} />
        </label>
    </>
}