import React from "react"
import { createUseStyles } from "react-jss"
import { Session } from "~base/Model/Session";
import { Overlay } from "../../Elements/Cosmetic/Overlay";
import { Divider } from "../../Elements/Cosmetic/Divider";
import { UploadFileButton } from "../../Elements/Input/UploadFileButton";
import { useIntl } from "~base/Internalization/IntlContext";
import { HendigoTheme } from "~base/Resources/Styling/HendigoTheme";
import fileAPIv2 from "~base/API/v2/fileAPIv2";

const useStyles = createUseStyles((theme: HendigoTheme) => ({
    title: {
        fontWeight: "400",
        color: theme.textPrimary
    }
}));

interface EditProfilePictureOverlayProps {
    show: boolean,
    onCancel: () => void,
    onSave: () => void,
    session: Session,
    smallImagePathname: string,
    bigImagePathname: string,
}

export const EditProfilePictureOverlay: React.FC<EditProfilePictureOverlayProps> = (props) => {
    const { 
        show = false, 
        onCancel, 
        onSave, 
        session, 
        smallImagePathname, 
        bigImagePathname, 
    } = props;

    const intl = useIntl();
    const classes = useStyles();

    const [image, setImage] = React.useState<File>();
    const [largeImage, setLargeImage] = React.useState<File>();

    return <Overlay
        title={intl.formatText("Shared.EditProfilePictureOverlay.ChangePicture")}
        show={show}
        onCancel={onCancel}
        onSave={() => {
            if (image != null) {
                fileAPIv2(image, "upload/image/profile/small").then((response) => {
                    if (response.data === true) {
                        onSave();
                    } else {
                        session.setInformation("error", response.error!);
                    }
                })
            }
            
            if (largeImage != null){
                fileAPIv2(largeImage, "upload/image/profile/large").then((response) => {
                    if (response.data === true) {
                        onSave();
                    } else {
                        session.setInformation("error", response.error!);
                    }
                })
            }
            
            if (image == null && largeImage == null) {
                session.setInformation("error", intl.formatText("Shared.EditProfilePictureOverlay.ChooseFile"));
            }
        }}
    >
        <div>            
            <Divider style={{ margin: "10px 0px" }} />
            <h3 className={classes.title}>{intl.formatText("Shared.EditProfilePictureOverlay.ProfilePicture")}</h3>
            <UploadFileButton
                id="fileToUpload"
                onChange={(e) => setImage(e.target.files![0])}
                fileTypes="image/*"
                name="fileToUpload"
                originalURL={smallImagePathname}
            />

            <Divider style={{ margin: "10px 0px" }} />
            <h3 className={classes.title}>{intl.formatText("Shared.EditProfilePictureOverlay.Background")}</h3>
            <UploadFileButton
                id="fileToUpload"
                onChange={(e) => setLargeImage(e.target.files![0])}
                fileTypes="image/*"
                name="fileToUpload"
                originalURL={bigImagePathname}
                previewCSSWidth="100%"
            />
        </div>
    </Overlay>
}