import { createUseStyles } from "react-jss";
import { HendigoTheme } from "~base/Resources/Styling/HendigoTheme";

export const useStyles = createUseStyles((theme: HendigoTheme) => ({
    sectionWrapper: {
        backgroundColor: theme.backgroundSecondary,
        padding: "10px",
        marginBottom: "20px",
        borderRadius: "5px",
    },
    sectionTitle: {
        margin: "10px 0 0 0",
        fontWeight: "500",
        color: theme.textPrimary
    },
    sectionDescription: {
        color: theme.textSecondary,
    },
    row: {
        marginBottom: "20px",
    },
}));