import React from "react";
import { Session } from "../../../Model/Session";
import { informationBoxReducer, initialInformationBoxState } from "./Store";
import { useInformationBoxStyles } from "./Styling";
import { Alert } from "@mui/material";
import { useIntl } from "~base/Internalization/IntlContext";

interface InformationBoxProps {
    session: Session,
}

export const InformationBox: React.FC<InformationBoxProps> = ({ session }) => {

    const intl = useIntl();
    const classes = useInformationBoxStyles();

    const [state, dispatch] = React.useReducer(informationBoxReducer, initialInformationBoxState(session));

    // TODO : Bygg ett kösystem med alla informationer som kommer in?

    React.useEffect(() => {
        const obs = () => {
            const time = session.information?.type === "error" ? 10000 : 3000;
            if (state.open === false && state.inOut === false) {
                clearTimeout(state.timeoutId);
                dispatch({ type: "setInformation", information: session.information });
                dispatch({ type: "setOpen", value: true });
                dispatch({ type: "setInOut", value: true });
                const id = setTimeout(() => {
                    dispatch({ type: "setInOut", value: false });
                    setTimeout(() => {
                        dispatch({ type: "setOpen", value: false });
                    }, 900);
                }, time);
                dispatch({type: "setTimeoutId", timeoutId: id});
            }
        }
        session.addInformationObserver(obs);
        return () => {
            session.removeInformationObserver(obs);
        }
    }, [session, state, dispatch]);

    const style = {
        /*background: state.information?.type === "error" ? "rgba(255, 41, 41)" : "rgba(37, 117, 252)",
        borderRadius: "10px",
        boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
        backdropFilter: "blur(5px)",
        WebkitBackdropFilter: "blur(5px)",
        border: "1px solid " + state.information?.type === "error" ? "rgba(255, 41, 41, 0.4)" : "rgba(37, 117, 252, 0.3)",*/
    }

    const severity = state.information?.type === "error" ? "error" : "info";

    return state.open ? <div style={style} className={state.inOut ? classes.wrapper : classes.out}>
        <Alert severity={severity}>
            {/*<BsInfoCircle className={classes.icon}/>
            {state.information?.type === "error" ? context.formatText("Elements.DataDisplacement.InformationBox.ErrorMessage") : context.formatText("Elements.DataDisplacement.InformationBox.Information")}
            <BsX
                className={classes.exitButton}
                onClick={() => {
                    dispatch({ type: "close" });
                }}
            />*/}
            {intl.formatText(state.information?.message ?? "")}
        </Alert>
    </div> : null;
}