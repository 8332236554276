import { createUseStyles } from "react-jss"
import { useNavigate } from "react-router-dom";
import { Row } from "../Cosmetic/Row";
import { HendigoTheme } from "~base/Resources/Styling/HendigoTheme";
import { Avatar } from "@mui/material";

const useStyles = createUseStyles((theme: HendigoTheme) => ({
    wrapper: {
        padding: "10px 0px 10px 0px",
        color: theme.textPrimary,
        '&:hover': {
            cursor: "pointer"
        }
    },
    image: {
        height: "59px !important",
        width: "59px !important",
        marginRight: "10px"
    },
    aboutText: {
        color: theme.textSecondary,
        fontSize: "14px",
        fontWeight: "500"
    },
    aboutTitle: {
        fontWeight: "500"
    }
}));

export interface ListingProps {
    imagePath?: string,
    title?: string,
    subTitle?: string,
    subSubTitle?: string,
    link?: string | null,
    onClick?: () => void
}

export const Listing: React.FC<ListingProps> = ({ imagePath, title, subTitle, subSubTitle, link, onClick }) => {
    const classes = useStyles();
    let navigate = useNavigate();

    function onRowClick() {
        if (link) {
            navigate(link);
        }
        if (onClick) {
            onClick();
        }
    }

    const firstLetterInName = title?.substring(0, 1);

    return <Row className={classes.wrapper} onClick={onRowClick}>
        <Avatar
            src={imagePath}
            className={classes.image}
        >
            {firstLetterInName}
        </Avatar>

        <div>
            <div className={classes.aboutTitle}>
                {title}
            </div>
            <div className={classes.aboutText}>
                {subTitle}
            </div>
            <div className={classes.aboutText}>
                {subSubTitle}
            </div>
        </div>
    </Row>
}