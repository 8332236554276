import React from "react";
import { useNavigate } from 'react-router-dom';
import { LoginStyles } from "./Styling";
import { isEmail, isOrgNr, isPassword } from "~base/Internalization/Validation";
import loginCompany from "~base/API/OldFetch/login/loginCompany";
import loginUser from "~base/API/OldFetch/login/loginUser";
import { ViewBaseProps } from "~base/Model/ViewBaseProps";
import { Button, Link, TextField } from "@mui/material";
import { muiStyles } from "~base/Resources/Styling/MuiTheme";
import Grid2 from "@mui/material/Unstable_Grid2";
import { Link as RouterLink } from "react-router-dom";
import { FEED_PATH, LOGIN_PATH } from "~base/Routing/Paths";
import { useIntl } from "~base/Internalization/IntlContext";

export const Login: React.FC<ViewBaseProps> = ({ session }) => {

    const intl = useIntl();

    window.view = "Login";
    intl.formatPageTitle("PageTitle.Login");

    const redirect = new URLSearchParams(window.location.search).get("redirect");

    const [input, setInput] = React.useState("");
    const [inputValid, setInputvalid] = React.useState(true);
    const [password, setPassword] = React.useState("");
    const [validPassword, setValidPassword] = React.useState(true);
    const [passwordError, setPasswordError] = React.useState("");

    //const [company, setCompany] = React.useState(false);
    const [error, setError] = React.useState("");

    const [valid, setValid] = React.useState(false);

    let navigate = useNavigate();
    const classes = LoginStyles();
    const muiClasses = muiStyles();

    React.useEffect(() => {
        setValid(
            (
                inputValid &&
                validPassword &&
                input.length !== 0 &&
                password.length !== 0
            )
        );

        setInputvalid(isOrgNr(input) || isEmail(input));

        if (!inputValid)
            setError(intl.formatText("Views.Login.SetValidInput"));
        else
            setError("");
    }, [input, inputValid, validPassword, password]);

    return <div className={classes.loginCard}>
        <h2 className={classes.title} >{intl.formatText("Views.Login.Login")}</h2>

        <Grid2 container spacing={2}>
            <Grid2 xs={12}>
                <TextField
                    onChange={(event) => setInput(event.target.value)}
                    error={!inputValid}
                    helperText={error}
                    value={input}
                    label={intl.formatText("Views.Login.SetEmailOrOrganizationNumber")}
                    placeholder={"ex. xx@hendigo.se 5555555555"}
                    fullWidth
                    className={muiClasses.textField}
                    variant="filled"
                />
            </Grid2>

            <Grid2 xs={12}>
                <TextField
                    onChange={(event) => {
                        setPassword(event.target.value);
                        const valid = isPassword(event.target.value);
                        if (!valid) {
                            setPasswordError(intl.formatText("Views.Login.PasswordMinLength"));
                        } else {
                            setPasswordError("");
                        }
                        setValidPassword(valid);
                    }}
                    value={password}
                    label={intl.formatText("Views.Login.SetPassword")}
                    type="password"
                    error={!validPassword}
                    helperText={passwordError}
                    fullWidth
                    className={muiClasses.textField}
                    variant="filled"
                />
            </Grid2>
        </Grid2>

        <Grid2 xs={12}>
            <Link
                style={{ display: "block", textAlign: "center", marginTop: "15px" }}
                component={RouterLink}
                to={LOGIN_PATH}
            >
                {intl.formatText("Views.Login.ForgetPassword")}
            </Link>
        </Grid2>

        <Button
            disabled={!valid}
            style={{ margin: "0 auto", marginTop: "15px", width: "75%" }}
            onClick={() => {
                setValid(false);
                if (isOrgNr(input)) {
                    loginCompany(input, password)
                        .then(response => {
                            if (response === true) {
                                session.authenticate();

                                if(redirect){
                                    navigate(redirect);
                                } else {
                                    navigate(FEED_PATH);
                                }
                            } else {
                                setPassword("");
                                setValidPassword(false);
                                setValid(false);
                                session.setInformation("error", response)
                                setValid(true);
                            }
                        }
                        );
                } else if(isEmail(input)) {
                    loginUser(input, password)
                        .then(response => {
                            if (response === true) {
                                session.authenticate();

                                if(redirect){
                                    navigate(redirect);
                                } else {
                                    navigate(FEED_PATH);
                                }
                            } else {
                                setPassword("");
                                setValidPassword(false);
                                setValid(false);
                                session.setInformation("error", response);
                                setValid(true);
                            }
                        }
                        );
                }
            }}
            variant="contained"
            className={muiClasses.button}
        >
            {intl.formatText("Views.Login.Login")}
        </Button>

        <p className={classes.or}>{intl.formatText("Views.Login.Or")}</p>

        <Button
            style={{ width: "200px", margin: "0 auto" }}
            onClick={() => { 
                if(redirect){
                    navigate("/registrera?redirect=" + redirect);
                } else {
                    navigate("/registrera"); 
                }
            }}
            variant="contained"
            className={muiClasses.button}
        >
            {intl.formatText("Views.Login.Register")}
        </Button>
    </div>
}