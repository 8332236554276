import { apiv2 } from "~base/API/v2/apiv2";
import { GetAdResponse } from "~base/Model/ResponseModels/GetAdResponse";
import { Session } from "~base/Model/Session";
import { AdApplyAction } from "./Store";
import { CreateApplicationRequest } from "~base/Model/RequestModels/CreateApplicationRequest";
import { NavigateFunction } from "react-router-dom";

export function getAd(adId: string, dispatch: React.Dispatch<AdApplyAction>, session: Session){
    dispatch({type: "setLoading", value: true});
    dispatch({type: "setLoadingMessage", value: "Views.AdApply.LoadingAd"});
    
    apiv2(`api/v2/get/ad/${adId}`, "GET").then(response => {
        if(response.error !== null){
            session.setInformation("error", response.error);
        } else {
            const getAdResponse = response.data as GetAdResponse;
            console.log(getAdResponse);
            dispatch({type: "setAd", value: getAdResponse.ad});
        }
        dispatch({type: "setLoading", value: false});
    });
}

export function createApplication(createApplicationRequest: CreateApplicationRequest, dispatch: React.Dispatch<AdApplyAction>, session: Session, navigate: NavigateFunction){
    dispatch({type: "setLoading", value: true});
    dispatch({type: "setLoadingMessage", value: "Views.AdApply.LoadingCreateApplication"});

    apiv2(`api/v2/post/application`, "POST", createApplicationRequest).then(response => {
        if(response.error !== null){
            session.setInformation("error", response.error);
        } else {
            navigate("/");
            session.setInformation("information", "Views.AdApply.ApplicationCreated");
        }
        dispatch({type: "setLoading", value: false});
    });
}