import { Session } from "~base/Model/Session";
import { Actions } from "./Store";
import { apiv2 } from "~base/API/v2/apiv2";

export function getTop3Ads(session: Session, dispatch: React.Dispatch<Actions>){
    apiv2("api/v2/post/top3ads", "POST", { title: "anläggare", location_id: 22 }).then(response => {
        if(response.error != null){
            session.setInformation("error", response.error);
        } else {
            dispatch({ type: "setTop3Ads", value: response.data });
        }
    });
}