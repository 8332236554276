// ----------------------------------------------------
// Imports
// ----------------------------------------------------

import React from "react";
import { createUseStyles } from "react-jss";
import { HendigoTheme } from "~base/Resources/Styling/HendigoTheme";

// ----------------------------------------------------
// Styling
// ----------------------------------------------------

const useStyles = createUseStyles((theme: HendigoTheme) => ({
    textarea: {
        width: "100%",
        height: "19px",
        outline: "none",
        border: "2px solid transparent",
        borderRadius: "8px",
        padding: "10px",
        resize: "none",
        fontFamily: "Arial",
        fontSize: "16px",
        transition: "height 0.3s ease-in-out",
        backgroundColor: theme.inputBackgroundColor,
        color: theme.inputTextColor,
        '&:focus': {
            //border: "2px solid dimgray",
            height: "75px",
        },
    },
    p:{
        fontSize: "15px",
        fontFamily: "system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Ubuntu, 'Helvetica Neue', sans-serif",
        fontWeight: "bold",
        margin: "0",
        color: theme.textPrimary,
        marginBottom: "4px"
    },
    textLimit: {
        fontWeight: "bold",
        margin: "0px -25px 0px auto",
        color: theme.textPrimary,
        float: "right"
    },
    inputInvalid: {
        border: "2px solid #ff726f !important",
    }
}));

// ----------------------------------------------------
// Props
// ----------------------------------------------------

interface TextAreaProps {
    value?: string,
    onChange?: (event: any) => void,
    placeholder?: string,
    titleStyle?: any
    titleClassName?: string,
    title?: string,
    className?: string,
    showLimit?: boolean,
    limit?: number,
    cssWidth?: string,
    valid?: boolean,
    cssHeight?: string,
}

// ----------------------------------------------------
// Component
// ----------------------------------------------------

export const TextArea: React.FC<TextAreaProps> = ({ valid, cssWidth, cssHeight, value, onChange, placeholder, titleClassName, title, titleStyle, className, showLimit = false, limit = 2000 }) => {
    const classes = useStyles();

    return <>
        <p style={titleStyle} className={classes.p + " " + titleClassName}>{title}</p>
        <textarea
            style={{ width: cssWidth, height: cssHeight }}
            className={valid ? classes.textarea + " " + className : classes.textarea + " " + className + " " + classes.inputInvalid}
            placeholder={placeholder}
            value={value}
            onChange={onChange}
        />
        {showLimit ? <div className={classes.textLimit}>{value === undefined ? limit : limit - value?.length}</div> : null}
        </>
}