import { Errors } from "~base/Resources/Error/Errors";
import { Texts } from "../Resources/Text/Texts"

export type languageCodes = "sv" | "en";

export const formatText = (code: string, language: "sv" | "en"): string => {

    if(code === "") return "";

    let finalText = code;

    [...Texts, ...Errors].forEach(text => {
        if (text.code === code) {
            switch (language) {
                case "sv": 
                    finalText = text.sv;
                    break;
                case "en":
                    finalText = text.en;
                    break;
                default:
                    finalText = text.sv;
            }
        }
    });

    if (finalText === code) {
        console.warn(`Text with code "${code}" not found`);
        finalText = "{No Text Code} " + code;
    }
    
    return finalText;
}