import { Session } from "~base/Model/Session";
import { ViewAction } from "./Store";
import { apiv2 } from "~base/API/v2/apiv2";

export function getGroup(groupId: string, dispatch: React.Dispatch<ViewAction>, session: Session){
    apiv2(`api/v2/get/userGroup/${groupId}`, "GET").then(response => {
        if(response.error !== null){
            session.setInformation("error", response.error);
        } else {
            dispatch({type: "setGroup", value: response.data});
        }
    });
}

export function getGroupPosts(groupId: string, dispatch: React.Dispatch<ViewAction>, session: Session){
    apiv2(`api/v2/get/groupPosts/${groupId}`, "GET").then(response => {
        if(response.error !== null){
            session.setInformation("error", response.error);
        } else {
            dispatch({type: "setPosts", value: response.data});
        }
    });
}