import { BsBadgeAd } from "react-icons/bs";
import { HandyAdsStyles } from "../Styling"
import { Slide, SlideShow } from "~base/Components/Elements/DataVisualization/SlideShow";
import { Row } from "~base/Components/Elements/Cosmetic/Row";
import { useIntl } from "~base/Internalization/IntlContext";

interface HandyAdsProps {
}

export const HandyAds: React.FC<HandyAdsProps> = ({ }) => {

    const intl = useIntl();
    const classes = HandyAdsStyles();

    const slides = [
        { 
            imageURL: "/images/hendigo/byggmax.jpg", 
            title: intl.formatText("Views.Handy.HandyAds.WeHaveEverythingWithinBuilding"), 
            link: "https://byggmax.se" 
        },
        { 
            imageURL: "/images/hendigo/ad.jpg", 
            title: intl.formatText("Views.Handy.HandyAds.AdItTech"), 
            hendigoAd: true, 
            link: "/jobb/annons?id=1", 
            subtitle: intl.formatText("Views.Handy.HandyAds.AdItTech")  
        },
    ] as Slide[]

    return <div className={classes.wrapper}>
        <Row>
            <div className={classes.title}>{intl.formatText("Views.Handy.HandyAds.Ads")}</div>
            <BsBadgeAd className={classes.adIcon} />
        </Row>
        {slides.length > 0 ? <SlideShow slides={slides} /> : <div className={classes.noAdsText}>{intl.formatText("Views.Handy.NoAds")}</div>}

    </div>
}