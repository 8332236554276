import { BsFacebook, BsLinkedin, BsYoutube } from "react-icons/bs";
import { FooterStyles } from "./Styling"
import { ViewBaseProps } from "~base/Model/ViewBaseProps";
import { Link, Typography } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { useIntl } from "~base/Internalization/IntlContext";
import { ABOUT_US } from "~base/Routing/Paths";

export const Footer: React.FC<ViewBaseProps> = ({ }) => {
    
    const intl = useIntl();
    const classes = FooterStyles();

    return <>
        <div className={classes.wrapper}>
            <div className={classes.footerContent}>
                <Typography variant="h6">{intl.formatText("Views.Footer.AboutUs")}</Typography>
                <p>{intl.formatText("Views.Footer.AboutUsText")}</p>
            </div>
            <div>
                <Typography variant="h6">{intl.formatText("Views.Footer.Links")}</Typography>
                <ul>
                    <li className={classes.li}>
                        <Link
                            className={classes.link}
                            component={RouterLink}
                            to="/"
                        >
                            {intl.formatText("Views.Footer.Home")}
                        </Link>
                    </li>
                    <li className={classes.li}>
                        <Link
                            className={classes.link}
                            component={RouterLink}
                            to="/services"
                        >
                            {intl.formatText("Views.Footer.Services")}
                        </Link>
                    </li>
                    <li className={classes.li}>
                        <Link
                            className={classes.link}
                            component={RouterLink}
                            to="/jobb"
                        >
                            {intl.formatText("Views.Footer.Ads")}
                        </Link>
                    </li>
                    <li className={classes.li}>
                        <Link
                            className={classes.link}
                            component={RouterLink}
                            to="/pricing"
                        >
                            {intl.formatText("Views.Footer.Prices")}
                        </Link>
                    </li>
                    <li className={classes.li}>
                        <Link
                            className={classes.link}
                            component={RouterLink}
                            to={ABOUT_US}
                        >
                            {intl.formatText("Views.Footer.AboutUs")}
                        </Link>
                    </li>
                    <li className={classes.li}>
                        <Link
                            className={classes.link}
                            component={RouterLink}
                            to="/privacy-policy"
                        >
                            {intl.formatText("Views.Footer.PrivacyPolicy")}
                        </Link>
                    </li>
                </ul>
            </div>
            <div>
                <Typography variant="h6" mb="20px">{intl.formatText("Views.Footer.SocialMedia")}</Typography>
                <div className={classes.socialIcon}><BsLinkedin /></div>
                <div className={classes.socialIcon}><BsFacebook /></div>
                <div className={classes.socialIcon}><BsYoutube /></div>
            </div>
        </div>
        <div className={classes.copyrightRow}>
            Copyright &copy; HendiGO AB {new Date().getFullYear()}
        </div>
    </>
}