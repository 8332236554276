import { createUseStyles } from "react-jss";
import { HendigoTheme } from "~base/Resources/Styling/HendigoTheme";

export const LoginStyles = createUseStyles((theme: HendigoTheme) => ({
    loginCard: {
        float: "left",
        width: "400px",
        height: "450px",
        padding: "0px 20px 20px 20px",
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        background: theme.loginRegisterBackgroundRGBA,
        borderRadius: "5px",
        color: theme.textPrimary
    },
    title: {
        fontWeight: "500",
        textAlign: "center",
    },
    or: {
        textAlign: "center",
        fontSize: "15px",
    },
    button: {
        width: "128px",
        margin: "0 auto",
        textAlign: "start",
    },
    button_icon: {
        fontSize: "20px",
    }
}));