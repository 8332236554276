import { createUseStyles } from "react-jss";
import { HendigoTheme } from "~base/Resources/Styling/HendigoTheme";

export const SearchResultStyling = createUseStyles((theme: HendigoTheme) => ({
    row: {
        padding: "10px",
        backgroundColor: theme.backgroundSecondary,
        borderRadius: "10px",
        color: theme.textPrimary,
        marginBottom: "10px",
        gap: "10px",
    },
    button: {
        marginRight: "8px",
    },
    loadingRow: {
        height: "50%",
        alignContent: "center"
    },
    title: {
        fontSize: "20px"
    }
}));