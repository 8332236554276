// ----------------------------------------------------
// Imports
// ----------------------------------------------------

import { CompanyProfileResponse } from "~base/Model/ResponseModels/CompanyProfileResponse";
import { CompanyViewState } from "./Types";
import { Session } from "~base/Model/Session";
import { PostResponse } from "~base/Model/ResponseModels/PostResponse";
import { AdResponse } from "~base/Model/ResponseModels/AdResponse";



// ----------------------------------------------------
// Actions
// ----------------------------------------------------

type SetId = { type: "setId", value: string }
type SetYourProfile = { type: "setYourProfile", value: boolean }
type SetProfile = { type: "setProfile", value: CompanyProfileResponse | null }
type SetAccountNotFound = { type: "setAccountNotFound", value: boolean }
type SetPosts = { type: "setPosts", value: PostResponse[] }
type SetAds = { type: "setAds", value: AdResponse[] }
type SetShowEditProfileOverlay = { type: "setShowEditProfileOverlay", value: boolean }
type SetShowEditProfilePictureOverlay = { type: "setShowEditProfilePictureOverlay", value: boolean }
type ResetState = { type: "resetState", state: CompanyViewState }
type SetIsFollowing = { type: "setIsFollowing", value: boolean}
type setShowEditPostOverlay = { post: PostResponse | null; type: "setShowEditPostOverlay", value: boolean }

export type CompanyAction = 
    SetId |
    SetYourProfile |
    SetProfile |
    SetAccountNotFound |
    SetPosts |
    SetAds |
    SetShowEditProfileOverlay |
    SetShowEditProfilePictureOverlay |
    ResetState |
    SetIsFollowing |
    setShowEditPostOverlay;

// ----------------------------------------------------
// Reducer
// ----------------------------------------------------

export const companyReducer = (state: CompanyViewState, action: CompanyAction): CompanyViewState => {
    switch (action.type) {
        case "setId":
            return {
                ...state,
                id: action.value
            }

        case "setYourProfile":
            return {
                ...state,
                yourProfile: action.value
            }

        case "setProfile":
            return {
                ...state,
                profile: action.value
            }

        case "setAccountNotFound":
            return {
                ...state,
                accountNotFound: action.value
            }

        case "setPosts":
            return {
                ...state,
                posts: action.value
            }
        
        case "setAds":
            return {
                ...state,
                ads: action.value
            }

        case "setShowEditProfileOverlay":
            return {
                ...state,
                showEditProfileOverlay: action.value
            }

        case "setShowEditProfilePictureOverlay":
            return {
                ...state,
                showEditProfilePictureOverlay: action.value
            }

        case "resetState":
            return {
                ...action.state
            }

        case "setIsFollowing":
            return {
                ...state,
                isFollowing: action.value
            }
        
        case "setShowEditPostOverlay":
            return {
                ...state,
                showEditPostOverlay: action.value,
                post: action.post
            }

        default:
            return {
                ...state
            };
    }
}

// ----------------------------------------------------
// Initial State
// ----------------------------------------------------

export const initialCompanyState = (session: Session, paramId: string): CompanyViewState => {
    return {
        id: paramId,
        yourProfile: session.companyId === paramId,
        profile: null,
        accountNotFound: false,
        posts: [],
        post: null,
        ads: [],
        showEditProfileOverlay: false,
        showEditProfilePictureOverlay: false,
        isFollowing: false,
        showEditPostOverlay: false,
    }
}