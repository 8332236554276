/**
 * List all types of responses from the backend here.
 */
export interface Response {
    error: string | null,
    data: any
}
// ----------------------------------------------------------------------
// API
// ----------------------------------------------------------------------

type FileapiURL =
    "upload/image/profile/small" |
    "upload/image/profile/large" |
    "upload/images/post";

export default async function fileAPIv2(file: File | File[], url: FileapiURL): Promise<Response> {
    
    let authResponse = "";
    
    const formData = new FormData();

    if(Array.isArray(file)){
        file.forEach((file) => {
            formData.append(`file[]`, file);
        });
    } else {
        formData.append('file', file);
    }

    // window.location.origin.replace("3000", "") byter endast bort 3000 om vi är i lokal
    // utvecklingsmiljö.
    await fetch(
        window.location.origin.replace("3000", "") + `/server/files/${url}`,
        {
            method: 'POST',
            credentials: 'include',
            body: formData,
        }
    )
        .then(response =>
            response.body?.getReader().read().then(res => {
                authResponse = new TextDecoder().decode(res.value).replaceAll('<br />', '').replaceAll('<b>', '').replaceAll('</b>', '');
            }))
        .catch(error => {
            authResponse = String(error);
        });

    try {
        return JSON.parse(authResponse);
    } catch (e) {
        // Om vi får detta beror det på ett SYNTAX ERROR i PHP.
        // För debugging: Gå till localhost/server/controller/
        return {
            error: String(authResponse)
        } as Response
    }
}