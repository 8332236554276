import { createUseStyles } from "react-jss";
import { HendigoTheme } from "~base/Resources/Styling/HendigoTheme";
import { Navbar } from "../Composite/Navbar/Component";
import { ViewBaseProps } from "~base/Model/ViewBaseProps";
import { Outlet } from "react-router-dom";

const useStyles = createUseStyles((theme: HendigoTheme) => ({
    wrapper: {
        backgroundImage: "linear-gradient(to right, #6a11cb 0%, #2575fc 100%)",
        display: "flex",
        flexDirection: "row",
        minHeight: "100vh",
        justifyContent: "center",
        alignItems: "center",
      }
}));

interface PurpleWrapperLayoutProps {
    viewBaseProps: ViewBaseProps;
    useNavbar?: boolean;
}

export const PurpleWrapperLayout: React.FC<PurpleWrapperLayoutProps> = (props) => {
    const {
        viewBaseProps,
        useNavbar = true,
    } = props;

    const classes = useStyles();

    return <>
        {useNavbar && <Navbar {...viewBaseProps} />}
        
        <div className={classes.wrapper}>
            <Outlet />
        </div>
    </>
}