import { createUseStyles } from "react-jss";
import { HendigoTheme } from "~base/Resources/Styling/HendigoTheme";

export const useStyles = createUseStyles((theme: HendigoTheme) => ({
    sectionWrapper: {
        backgroundColor: theme.backgroundSecondary,
        padding: "10px",
        marginBottom: "10px",
        borderRadius: "5px",
        animation: "$slideIn 0.75s ease-in-out"
    },
    sectionTitleRow: {
        alignItems: "center",
        gap: "10px",
        marginBottom: "1rem"
    },
    sectionTitle: {
        color: theme.textPrimary,
        margin: "0px",
        fontWeight: "500"
    },
    description: {
        color: theme.textSecondary,
        margin: "0px 0px 5px 0px",
        fontSize: "15px",
        whiteSpace: "pre-wrap"
    },
    icon: {
        color: theme.blue,
        fontSize: "18px"
    },
    applyButton: {
        display: "block !important", 
        margin: "25px auto 0px auto !important",
        minWidth: "150px !important"
    },
    '@media screen and (max-width: 1500px)':{
        wrapper: {
            padding: "20px 5% 0px 5%"
        }
    },
}));