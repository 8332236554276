import { apiv2 } from "~base/API/v2/apiv2";
import { AdAction } from "./Store";
import { Session } from "~base/Model/Session";
import { GetAdResponse } from "~base/Model/ResponseModels/GetAdResponse";

export function getAd(adId: string, dispatch: React.Dispatch<AdAction>, session: Session){
    apiv2(`api/v2/get/ad/${adId}`, "GET").then(response => {
        if(response.error !== null){
            session.setInformation("error", response.error);
        } else {
            const getAdResponse = response.data as GetAdResponse;
            console.log(getAdResponse);
            dispatch({type: "setAd", value: getAdResponse.ad});
            dispatch({type: "setAdRequirements", value: getAdResponse.ad_requirements});
        }
    });
}

export function getApplicationStatus(adId: string, dispatch: React.Dispatch<AdAction>, session: Session){
    apiv2(`api/v2/get/applicationStatus/${adId}`, "GET").then(response => {
        if(response.error !== null){
            session.setInformation("error", response.error);
        } else {
            console.log(response.data)
            dispatch({type: "setApplication", value: response.data});
        }
    });
}

export function getTop3Ads(session: Session, dispatch: React.Dispatch<AdAction>){
    apiv2("api/v2/post/top3ads", "POST", { title: "anläggare", location_id: 22 }).then(response => {
        if(response.error != null){
            session.setInformation("error", response.error);
        } else {
            dispatch({ type: "setTop3Ads", value: response.data });
        }
    });
}