import { createUseStyles } from "react-jss";

const useStyles = createUseStyles({
    wrapper: {
        backgroundImage: "linear-gradient(to right, #6a11cb 0%, #2575fc 100%)",
        display: "flex",
        flexDirection: "row",
        minHeight: "100vh",
        justifyContent: "center",
        alignItems: "center",
      }
});

interface PurpleWrapperProps {
    children?: React.ReactNode;
}

export const PurpleWrapper: React.FC<PurpleWrapperProps> = ({ children }): JSX.Element => {
    const classes = useStyles();

    return <div className={classes.wrapper}>
      {children}
    </div>
}