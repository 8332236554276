import { apiv2 } from "~base/API/v2/apiv2";
import { AdResponse } from "~base/Model/ResponseModels/AdResponse";
import { Session } from "~base/Model/Session";
import { ManageAdsAction } from "./Store";

export function getCompanyAds(session: Session, dispatch: React.Dispatch<ManageAdsAction>) {
    apiv2(`api/v2/get/companyAds/${session.companyProfile?.company_id}`, "GET").then(response => {
        if(response.error != null) {
            session.setInformation("error", response.error);
        } else {
            dispatch({ type: "setAds", value: response.data as AdResponse[] });
        }
    });
}