import { createUseStyles } from "react-jss";
import { HendigoBaseTheme } from "~base/Resources/Styling/HendigoBaseTheme";
import { HendigoTheme } from "~base/Resources/Styling/HendigoTheme";

export const AdStyles = createUseStyles((theme: HendigoTheme) => ({
    '@keyframes slideIn': {
        from: {
            opacity: 0,
            transform: "translateX(-100px)"
        },
        to: {
            opacity: 1,
            transform: "translateX(0%)"
        }
    },
    sectionWrapper: {
        backgroundColor: theme.backgroundSecondary,
        padding: "10px",
        marginBottom: "10px",
        borderRadius: "5px",
        animation: "$slideIn 0.5s ease-in-out",
    },
    authorAvatar: {
        width: "150px !important",
        height: "150px !important",
        borderRadius: "5px !important",
        fontSize: "50px !important",
    },
    title: {
        margin: "0px 0px 10px 0px",
        overflowWrap: "break-word"
    },
    titleWrapper: {
        margin: "0px 0px 0px 10px",
        color: theme.textPrimary
    },
    bulletIcon: {
        margin: "2px 5px 0px 0px"
    },
    bulletText: {
        fontWeight: "bold"
    },
    contactIcon: {
        fontSize: "18px",
        margin: "1px 5px 5px 0px"
    },
    contactLink: {
        textDecoration: "underline",
        color: HendigoBaseTheme.colors.hendigoBlue,
        '&:hover': {
            cursor: "pointer"
        }
    },
    button: {
        display: "block",
        width: "100%",
        marginTop: "10px"
    },
    loadingImageWrapper: {
        width: "150px",
        height: "150px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center"
    },
    sectionTitleRow: {
        alignItems: "center",
        gap: "10px",
        marginBottom: "1rem"
    },
    sectionTitle: {
        color: theme.textPrimary,
        margin: "0px",
        fontWeight: "500"
    },
    description: {
        color: theme.textSecondary,
        margin: "0px 0px 5px 0px",
        fontSize: "15px",
        whiteSpace: "pre-wrap"
    },
    icon: {
        color: theme.blue,
        fontSize: "18px"
    }
}));