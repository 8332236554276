// ----------------------------------------------------
// Imports
// ----------------------------------------------------

import React from "react";
import { createUseStyles } from "react-jss"
import { UserAction } from "../Store";
import { UserViewState } from "../Types";
import { HendigoBaseTheme } from "~base/Resources/Styling/HendigoBaseTheme";
import { Counties } from "~base/Resources/Data/Counties";
import { Professions } from "~base/Resources/Data/Professions";
import { Overlay } from "~base/Components/Elements/Cosmetic/Overlay";
import { InputAlternative } from "~base/Components/Elements/Input/InputAlternative";
import { Input as LegacyInput } from "~base/Components/Elements/Input/Input";
import { TextArea } from "~base/Components/Elements/Input/TextArea";
import { Row } from "~base/Components/Elements/Cosmetic/Row";
import { Switch } from "~base/Components/Elements/Input/Switch";
import { Button } from "~base/Components/Elements/Input/Button";
import { apiv2 } from "~base/API/v2/apiv2";
import { HendigoTheme } from "~base/Resources/Styling/HendigoTheme";
import { Autocomplete, FormControl, Grid, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import { Session } from "~base/Model/Session";
import { muiStyles } from "~base/Resources/Styling/MuiTheme";
import { useIntl } from "~base/Internalization/IntlContext";

// ----------------------------------------------------
// Styling
// ----------------------------------------------------

const useStyles = createUseStyles((theme: HendigoTheme) => {
    return {
        container: {
            display: "flex",
            flexDirection: "column",
            rowGap: "20px",
        },
        updatePasswordButton: {
            marginTop: "20px"
        },
        openForNewJobsText: {
            marginLeft: "8px",
            color: theme.textPrimary

        },
        openForNewJobsRow: {
            marginTop: "20px"
        }
    }
});

// ----------------------------------------------------
// Props
// ----------------------------------------------------

interface EditUserOverlayProps {
    state: UserViewState,
    onCancel: () => void,
    dispatch: React.Dispatch<UserAction>,
    onError: (error: string) => void,
    session: Session
}

// ----------------------------------------------------
// Component
// ----------------------------------------------------

export const EditUserOverlay: React.FC<EditUserOverlayProps> = ({ state, onCancel, dispatch, onError, session }) => {

    const intl = useIntl();

    const classes = useStyles();
    const muiClasses = muiStyles();

    const limit = 500;

    const [locationId, setLocationId] = React.useState<number>(state.profile?.location_id ?? 0);
    const [name, setName] = React.useState<string>(state.profile ? state.profile?.fullname : "");
    const [description, setDescription] = React.useState<string>(state.profile ? state.profile?.user_description : "");
    const [occupation, setOccupation] = React.useState<string>(state.profile ? state.profile?.title : "");
    const [openForNewJobs, setOpenForNewJobs] = React.useState<boolean>(false);
    const [publicProfile, setPublicProfile] = React.useState<boolean>(state.profile?.public ?? false);

    const [ortValid, setOrtValid] = React.useState<boolean>(true);
    const [namnValid, setNamnValid] = React.useState<boolean>(true);
    const [descriptionValid, setDescriptionValid] = React.useState<boolean>(true);
    const [occupationValid, setOccupationValid] = React.useState<boolean>(true);

    React.useEffect(() => {
        setLocationId(state.profile?.location_id ?? 0);
        setName(state.profile ? state.profile?.fullname : "");
        setDescription(state.profile ? state.profile?.user_description : "");
        setOccupation(state.profile ? state.profile?.title : "");
        setOpenForNewJobs(false);
        setPublicProfile(state.profile?.public ?? false);
    }, [state]);

    React.useEffect(() => {
        setOrtValid(
            true
        );
        setNamnValid(
            name.length > 1
        );
        setOccupationValid(
            occupation.length > 1
        );
        setDescriptionValid(
            description.length > 3
        );
    }, [locationId, name, occupation, description]);

    return <Overlay
        show={state.showOverlay}
        title={intl.formatText("Views.User.EditProfile")}
        onCancel={onCancel}
        cssWidth="600px"
        onSave={() => {
            if (occupationValid && ortValid && namnValid && descriptionValid) {
                if (
                    name !== state.profile?.fullname ||
                    locationId !== state.profile?.location_id ||
                    description !== state.profile?.user_description ||
                    occupation !== state.profile?.title ||
                    publicProfile !== state.profile?.public
                ) {
                    apiv2(`api/v2/put/userProfile/${state.profile?.user_id}`, "PUT", {
                        fullname: name,
                        location_id: locationId,
                        user_description: description,
                        title: occupation,
                        public: publicProfile
                    }).then(response => {
                        if (response.error != null) {
                            onError(response.error);
                        } else {
                            window.location.reload();
                        }
                    });
                } else {
                    onCancel();
                }
            }
        }}
    >
        <div className={classes.container}>
            <FormControl className={muiClasses.select} fullWidth>
                <InputLabel id="location-select-label">
                    {intl.formatText("Views.CompleteRegistrationCompany.EnterCounty")}
                </InputLabel>
                <Select
                    labelId="location-select-label"
                    id="location-select"
                    value={locationId}
                    label={intl.formatText("Views.CompleteRegistrationCompany.EnterCounty")}
                    onChange={value => setLocationId(value.target.value as number)}
                >
                    {session.data.locations.map((l, index) => {
                        return <MenuItem
                            key={"CompleteRigstrationCompanyLocation" + index}
                            value={l.id}
                        >
                            {intl.formatText(l.location)}
                        </MenuItem>
                    })}
                </Select>
            </FormControl>

            <TextField
                label={intl.formatText("Views.User.Name")}
                variant="outlined"
                value={name}
                onChange={e => setName(e.target.value)}
                error={!namnValid}
                fullWidth={true}
                className={muiClasses.textField}
            />

            <Autocomplete
                disablePortal
                options={Professions}
                fullWidth={true}
                renderInput={(params) => <TextField {...params} label={intl.formatText("Views.User.Occupation")} />}
                value={occupation}
                onChange={(e, value) => setOccupation(value ?? "")}
                onInputChange={(e) => setOccupation((e?.target as any)?.value ?? "")}
                freeSolo={true}
                className={muiClasses.autocomplete}
            />

            <TextField
                label={intl.formatText("Views.User.Description")}
                variant="outlined"
                value={description}
                onChange={e => {
                    if (e.target.value.length <= limit)
                        setDescription(e.target.value);
                }}
                error={!descriptionValid}
                fullWidth={true}
                className={muiClasses.textField}
                multiline={true}
                rows={5}
            />

            {/* <TextArea
            value={description}
            onChange={value => {
                if (value.target.value.length <= limit)
                    setDescription(value.target.value);
            }}
            title={intl.formatText("Views.User.Description")}
            titleClassName={classes.title}
            cssHeight="100px"
            placeholder={description === "" ? intl.formatText("Views.User.NoDescription") : ""}
            showLimit
            limit={limit}
            valid={descriptionValid}
        /> */}

            {/* <Row className={classes.openForNewJobsRow}>
            <Switch
                id={"editUserOverlayOpenForWorkSwitch"}
                checked={openForNewJobs}
                onChange={value => setOpenForNewJobs(value)}
            />
            <div className={classes.openForNewJobsText}>{intl.formatText("Views.User.OpenToWork")}</div>
        </Row> */}

            <Row className={classes.openForNewJobsRow}>
                <Switch
                    id={"editUserOverlayPublicProfileSwitch"}
                    checked={publicProfile}
                    onChange={value => setPublicProfile(value)}
                />
                <div className={classes.openForNewJobsText}>{intl.formatText("Views.User.PublicProfile")}</div>
            </Row>

            {/* <Button
            className={classes.updatePasswordButton}
        >
            {intl.formatText("Views.User.UpdatePassword")}
        </Button> */}
        </div>
    </Overlay>
}