// ----------------------------------------------------
// Imports
// ----------------------------------------------------

import { AdResponse } from "~base/Model/ResponseModels/AdResponse";
import { ViewState } from "./Types";

// ----------------------------------------------------
// Actions
// ----------------------------------------------------

type SetAdRequirements = { type: "setAdRequirements", value: string }
type SetAd = { type: "setAd", value: AdResponse }
type SetLoading = { type: "setLoading", value: boolean }
type SetLoadingMessage = { type: "setLoadingMessage", value: string }
type SetCoverLetter = { type: "setCoverLetter", value: string }

export type AdApplyAction = SetAdRequirements | SetAd | SetLoading | SetLoadingMessage | SetCoverLetter;

// ----------------------------------------------------
// Reducer
// ----------------------------------------------------

export const reducer = (state: ViewState, action: AdApplyAction): ViewState => {
    switch (action.type) {
        case "setAd":
            return {
                ...state,
                ad: action.value
            }
        
        case "setLoading":
            return {
                ...state,
                loading: action.value
            }

        case "setLoadingMessage":
            return {
                ...state,
                loadingMessage: action.value
            }

        case "setCoverLetter":
            return {
                ...state,
                coverLetter: action.value
            }

        default:
            return {
                ...state
            }
    }
}

// ----------------------------------------------------
// Initial State
// ----------------------------------------------------

export const initialState = (): ViewState => {
    return {
        ad: null,
        loading: false,
        loadingMessage: "",
        coverLetter: ""
    }
}