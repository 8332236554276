// ----------------------------------------------------
// Imports
// ----------------------------------------------------

import { AdResponse } from "~base/Model/ResponseModels/AdResponse";
import { ViewState } from "./Types";
import { AdRequirement } from "~base/Model/Entities/AdRequirement";
import { ApplicationResponse } from "~base/Model/ResponseModels/ApplicationResponse";

// ----------------------------------------------------
// Actions
// ----------------------------------------------------

type SetAd = { type: "setAd", value: AdResponse }
type SetAdRequirements = { type: "setAdRequirements", value: AdRequirement[] }
type SetApplications = { type: "setApplications", value: ApplicationResponse[] }
type SetOverlayState = { type: "setOverlayState", value: { show: boolean, application: ApplicationResponse } }
type SetStatusLoading = { type: "setStatusLoading", value: boolean }
type SetStatusError = { type: "setStatusError", value: boolean }
type UpdateApplicationStatus = {type: "updateApplicationStatus", value: number, id: string}

export type ManageAdAction =
    SetAd |
    SetAdRequirements |
    SetApplications |
    SetOverlayState |
    SetStatusLoading |
    SetStatusError |
    UpdateApplicationStatus;

// ----------------------------------------------------
// Reducer
// ----------------------------------------------------

export const manageAdReducer = (state: ViewState, action: ManageAdAction): ViewState => {
    switch (action.type) {
        case "setAd":
            return {
                ...state,
                ad: action.value
            }

        case "setAdRequirements":
            return {
                ...state,
                adRequirements: action.value
            }

        case "setApplications":
            return {
                ...state,
                applications: action.value
            }

        case "setOverlayState":
            return {
                ...state,
                overlayState: action.value
            }

        case "setStatusLoading":
            return {
                ...state,
                statusLoading: action.value
            }

        case "setStatusError":
            return {
                ...state,
                statusError: action.value
            }
        
        case "updateApplicationStatus":
            return {
                ...state,
                applications: state.applications.map((application) => {
                    if (application.id === action.id) {
                        application.status_id = action.value;
                    }
                    return application;
                }
            )}
    }
}

// ----------------------------------------------------
// Initial state
// ----------------------------------------------------

export const initialManageAdState = (): ViewState => {
    return {
        ad: null,
        adRequirements: [],
        applications: [],
        overlayState: {
            show: false,
            application: {} as ApplicationResponse
        },
        statusLoading: false,
        statusError: false
    }
}