import React from "react";
import { HandyAds } from "./ChildComponents/HandyAds";
import { LatestNotification } from "./ChildComponents/LatestNotification";
import { HandyStyles } from "./Styling";
import { Divider } from "~base/Components/Elements/Cosmetic/Divider";
import { ViewBaseProps } from "~base/Model/ViewBaseProps";

export const Handy: React.FC<ViewBaseProps> = ({ session }): JSX.Element => {
    const classes = HandyStyles();

    const [loggedIn, setLoggedIn] = React.useState<boolean>(session.userId !== null || session.companyId !== null);

    React.useEffect(() => {
        const obs = () => {
            setLoggedIn(session.userId !== null || session.companyId !== null);
        }

        session.addObserver(obs);

        return () => {
            session.removeObserver(obs);
        }
    }, [session, session.userId, session.companyProfile])

    return <div className={classes.wrapper}>
        {loggedIn === true ? <><LatestNotification session={session} />
            <Divider style={{ margin: "20px 0px 20px 0px" }} /></> : null}
        <HandyAds />
        <Divider style={{ margin: "20px 0px 20px 0px" }} />
    </div>
}