// ----------------------------------------------------
// Imports
// ----------------------------------------------------

import React from "react";
import { createUseStyles } from "react-jss"
import { HendigoBaseTheme } from "~base/Resources/Styling/HendigoBaseTheme";
import { Overlay } from "~base/Components/Elements/Cosmetic/Overlay";
import { Row } from "~base/Components/Elements/Cosmetic/Row";
import { Session } from "~base/Model/Session";
import { HendigoTheme } from "~base/Resources/Styling/HendigoTheme";
import { TextField } from "@mui/material";
import { useIntl } from "~base/Internalization/IntlContext";

// ----------------------------------------------------
// Styling
// ----------------------------------------------------

const useStyles = createUseStyles((theme: HendigoTheme) => {
    return {
        title: {
            color: HendigoBaseTheme.colors.hendigoLightGray + " !important"
        },
        updatePasswordButton: {
            marginTop: "20px"
        },
        alternativeList: {
            backgroundColor: HendigoBaseTheme.colors.hendigoDark,
            opacity: 0.96
        },
        openForNewJobsText: {
            marginLeft: "8px"
        },
        openForNewJobsRow: {
            marginTop: "20px"
        },
        poster_textarea: {
            width: "100%",
            minHeight: "18px",
            height: "250px",
            overflow: "auto",
            outline: "1px solid " + theme.border,
            border: "none",
            borderRadius: "20px",
            backgroundColor: theme.inputBackgroundColor,
            padding: "12px",
            resize: "none",
            fontFamily: "Arial",
            fontSize: "14px",
            margin: "10px 0px 10px 0px",
            color: theme.inputTextColor,
            '&:focus': {
                outline: "1px solid " + theme.inputTextColor,
                resize: "vertical",
                borderRadius: "20px 20px 0px 20px"
            },
        },
    }
});

// ----------------------------------------------------
// Props
// ----------------------------------------------------

interface EditOverlayProps {
    session: Session,
    onCancel: () => void,
    onSave: (newContent: string) => void,
    content: string,
    show: boolean,
}

// ----------------------------------------------------
// Component
// ----------------------------------------------------

export const EditPostOverlay: React.FC<EditOverlayProps> = ({ session, show, onCancel, onSave, content }) => {
    
    const intl = useIntl();
    const classes = useStyles();
    const [text, setText] = React.useState(content);

    React.useEffect(() => {
        setText(content);
    }, [content]);

    return <Overlay
        show={show}
        title={intl.formatText("Views.User.EditPostTitle")}
        onCancel={onCancel}
        onSave={() => {
            if (text.length > 0) {
                onSave(text);
            } else {
                session.setInformation("error", intl.formatText("Views.User.EditPostError"));
            }
        }}
    >
        <Row>
            <TextField
                //className={classes.poster_textarea}
                placeholder={intl.formatText("Views.Feed.Share")}
                value={text}
                onChange={(e) => {
                    if (e.target.value.length <= 2000)
                        setText(e.target.value);
                }}
                rows={10}
                multiline
                fullWidth
            />
        </Row>
    </Overlay>
}