// ----------------------------------------------------
// Imports
// ----------------------------------------------------

import { apiv2 } from "~base/API/v2/apiv2";
import { AdResponse } from "~base/Model/ResponseModels/AdResponse";
import { CompanyProfileResponse } from "~base/Model/ResponseModels/CompanyProfileResponse";
import { UserGroupResponse } from "~base/Model/ResponseModels/GroupResponse";
import { PostResponse } from "~base/Model/ResponseModels/PostResponse";
import { UserProfileResponse } from "~base/Model/ResponseModels/UserProfileResponse";
import { Session } from "~base/Model/Session";


// ----------------------------------------------------
// Functions
// ----------------------------------------------------

/**
 * Get the user post.
 * @param state 
 * @param session 
 * @param context 
 * @param dispatch 
 */
export function getPersons( session: Session, searchQuery: string, setPersons: (_:UserProfileResponse[]) => void) {
    apiv2(`api/v2/get/searchUsers/${searchQuery}`, "GET").then((response) => {
        if (response.error != null) {
            session.setInformation("error", "Views.Post.CouldNotGetgetPersons");
            console.error(response.error);
        } else {
            const result = response.data as UserProfileResponse[];
            setPersons(result);
        }
    });
}
export function getCompanies( session: Session, searchQuery: string, setCompanies: (_:CompanyProfileResponse[]) => void) {
    apiv2(`api/v2/get/searchCompanies/${searchQuery}`, "GET").then((response) => {
        if (response.error != null) {
            session.setInformation("error", "Views.Post.CouldNotGetgetCompanies");
            console.error(response.error);
        } else {
            const result = response.data as CompanyProfileResponse[];
            setCompanies(result);
        }
    });
}
export function getAds( session: Session, searchQuery: string, setAds: (_:AdResponse[]) => void) {
    apiv2(`/api/v2/get/searchAds/${searchQuery}`, "GET").then((response) => {
        if (response.error != null) {
            session.setInformation("error", "Views.Post.CouldNotGetgetAds");
            console.error(response.error);
        } else {
            const result = response.data as AdResponse[];
            setAds(result);
        }
    });
}
export function getPosts( session: Session, searchQuery: string, setPosts: (_:PostResponse[]) => void) {
    apiv2(`api/v2/get/searchPosts/${searchQuery}`, "GET").then((response) => {
        if (response.error != null) {
            session.setInformation("error", "Views.Post.CouldNotGetgetPosts");
            console.error(response.error);
        } else {
            const result = response.data as PostResponse[];
            setPosts(result);
        }
    });
}

export function getGroups( session: Session, searchQuery: string, setGroups: (_:UserGroupResponse[]) => void) {
    apiv2(`/api/v2/get/searchGroups/${searchQuery}`, "GET").then((response) => {
        if (response.error != null) {
            session.setInformation("error", "Views.Post.CouldNotGetgetGroups");
            console.error(response.error);
        } else {
            const result = response.data as UserGroupResponse[];
            setGroups(result);
        }
    });
}
