// ----------------------------------------------------
// Imports
// ----------------------------------------------------

import { Session } from "~base/Model/Session";
import { CreateAdSteps, CreateAdViewState } from "./Types";
import { FormsOfEmployment as formsOfEmploymentArray } from "~base/Resources/Data/FormsOfEmployment";
import dayjs, { Dayjs } from "dayjs";
import { LoremIpsumFull } from "~base/Resources/Text/LoremIpsum";
import { Product } from "~base/Model/Entities/Product";
import { AdResponse } from "~base/Model/ResponseModels/AdResponse";
import { AdRequirement } from "~base/Model/Entities/AdRequirement";

// ----------------------------------------------------
// Actions
// ----------------------------------------------------

type SetFormOfEmployment = { type: "setFormOfEmployment", value: string }
type SetTitle = { type: "setTitle", value: string }
type SetPosition = { type: "setPosition", value: string | null }
type SetCompanyDescription = { type: "setCompanyDescription", value: string }
type SetAboutJob = { type: "setAboutJob", value: string }
type SetAboutYou = { type: "setAboutYou", value: string }
type AddRequirement = { type: "addRequirement" }
type SetRequirement = { type: "setRequirement", index: number, value: string }
type RemoveRequirement = { type: "removeRequirement", index: number }
type SetCurrentStep = { type: "setCurrentStep", value: CreateAdSteps }
type SetLocation = { type: "setLocation", id: number, value: string }
type SetValid = { type: "setValid" }
type SetDeadline = { type: "setDeadline", value: Dayjs | null }
type SetChosenProduct = { type: "setChosenProduct", value: Product | null }
type SetProducts = { type: "setProducts", value: Product[] }
type SetInvoiceName = { type: "setInvoiceName", value: string }
type SetInvoiceAddress = { type: "setInvoiceAddress", value: string }
type SetInvoiceCity = { type: "setInvoiceCity", value: string }
type SetInvoiceZipCode = { type: "setInvoiceZipCode", value: string }

export type CreateAdAction =
    SetFormOfEmployment |
    SetTitle |
    SetPosition |
    SetCompanyDescription |
    SetAboutJob |
    SetAboutYou |
    AddRequirement |
    SetRequirement |
    RemoveRequirement |
    SetCurrentStep |
    SetLocation |
    SetValid |
    SetDeadline |
    SetChosenProduct |
    SetProducts |
    SetInvoiceName |
    SetInvoiceAddress |
    SetInvoiceCity |
    SetInvoiceZipCode;

// ----------------------------------------------------
// Reducer
// ----------------------------------------------------

export const createAdReducer = (state: CreateAdViewState, action: CreateAdAction): CreateAdViewState => {
    switch (action.type) {
        case "setFormOfEmployment":
            return {
                ...state,
                ad: {
                    ...state.ad,
                    form_of_employment: action.value
                }
            }

        case "setTitle":
            return {
                ...state,
                ad: {
                    ...state.ad,
                    title: action.value
                }
            }

        case "setPosition":
            return {
                ...state,
                ad: {
                    ...state.ad,
                    profession_title: action.value!
                }
            }

        case "setCompanyDescription":
            return {
                ...state,
                ad: {
                    ...state.ad,
                    company_description: action.value
                }
            }

        case "setAboutJob":
            return {
                ...state,
                ad: {
                    ...state.ad,
                    position_description: action.value
                }
            }

        case "setAboutYou":
            return {
                ...state,
                ad: {
                    ...state.ad,
                    applicant_description: action.value
                }
            }

        case "addRequirement":
            const newRequirement = {
                id: "",
                ad_id: "",
                description: ""
            } as AdRequirement
            return {
                ...state,
                adRequirements: [...state.adRequirements!, newRequirement]
            }

        case "setRequirement":
            return {
                ...state,
                adRequirements: state.adRequirements?.map((req, index) => {
                    if (index === action.index) {
                        return {
                            ...req,
                            description: action.value
                        };
                    }
                    return req;
                })

            }

        case "removeRequirement":
            return {
                ...state,
                adRequirements: state.adRequirements?.filter((_, index) => index !== action.index)
            }

        case "setCurrentStep":
            window.scrollTo({ top: 0, behavior: 'smooth' });
            return {
                ...state,
                currentStep: action.value
            }

        case "setLocation":
            return {
                ...state,
                ad: {
                    ...state.ad,
                    location_id: action.id,
                    location: action.value
                }
            }

        case "setValid":
            const requirementsValid = (state.adRequirements?.length > 0 && state.adRequirements?.every(req => req.description?.length! > 0)) ?? false;

            const valid = state.ad.title?.length! > 0 &&
                state.ad.form_of_employment?.length! > 0 &&
                state.ad.profession_title !== null &&
                state.ad.company_description?.length! > 0 &&
                state.ad.position_description?.length! > 0 &&
                state.ad.applicant_description?.length! > 0 &&
                state.ad.location !== null &&
                (dayjs(state.ad.deadline).isAfter(dayjs()) ?? false) &&
                requirementsValid;

            return {
                ...state,
                valid: valid
            }

        case "setDeadline":
            return {
                ...state,
                ad: {
                    ...state.ad,
                    deadline: String(action.value)
                }
            }

        case "setChosenProduct":
            return {
                ...state,
                ad: {
                    ...state.ad,
                    product_type: action.value?.type ?? ""
                },
                invoice: {
                    ...state.invoice,
                    amount: action.value?.price ?? 0
                },
                chosenProduct: action.value
            }

        case "setProducts": {
            return {
                ...state,
                products: action.value
            }
        }

        default:
            return {
                ...state
            };
    }
}

// ----------------------------------------------------
// Initial State
// ----------------------------------------------------

export const initialCreateAdState = (session: Session): CreateAdViewState => {
    const preliminaryPaymentDueDate = new Date();
    preliminaryPaymentDueDate.setDate(preliminaryPaymentDueDate.getDate() + 30);
    return {
        ad: {
            id: "",
            title: "",
            form_of_employment: formsOfEmploymentArray[0],
            profession_title: null!,
            company_description: session.companyProfile?.company_description ?? "",
            position_description: window.location.origin === 'http://localhost:3000' ? LoremIpsumFull : "",
            applicant_description: window.location.origin === 'http://localhost:3000' ? LoremIpsumFull : "",
            deadline: String(dayjs(new Date())),
            product_type: "",
            location_id: session.companyProfile?.location_id,
            company_name: session.companyProfile?.company_name ?? "",
            company_id: session.companyProfile?.id ?? "",
            small_image_pathname: session.companyProfile?.small_image_pathname ?? "",
            time_created: String(new Date()),
        } as AdResponse,
        currentStep: "Views.CreateAd.Step.Information",
        valid: false,
        adRequirements: [],
        invoice: {
            id: "",
            date: "",
            buyer_name: session.companyProfile?.company_name ?? "",
            buyer_address: session.companyProfile?.address ?? "",
            buyer_city: session.companyProfile?.city ?? "",
            buyer_zip_code: session.companyProfile?.zip_code ?? "",
            delivery_date: new Date().toISOString(),
            payment_terms: "Invoice.PaymentTerms30Days",
            payment_due_date: preliminaryPaymentDueDate.toISOString(),
            payment_interest: "10%",
            company_id: session.companyProfile?.id ?? "",
            user_id: null,
            moms: 0.25,
            amount: 0,
            currency: "SEK",
            status: "Invoice.Status.Unpaid",
            seller_name: "HendiGO AB",
            seller_address: "Frejgatan 4A",
            seller_city: "Stockholm",
            seller_zip_code: "114 20",
            seller_telephone: "070-741 50 41",
            seller_email: "support@hendigo.se",
            vat_number: "SE556 000 000 000",
            bank_transfer: "1234-1234 1234 1234",
        },
        products: null,
        chosenProduct: null
    }
}