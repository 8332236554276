// ----------------------------------------------------
// Imports
// ----------------------------------------------------

import { ViewState } from "./Types";

// ----------------------------------------------------
// Actions
// ----------------------------------------------------

type SetGroup = { type: "setGroup", value: ViewState["group"] }
type SetCurrentTab = { type: "setCurrentTab", value: ViewState["currentTab"] }
type SetPosts = { type: "setPosts", value: ViewState["posts"] }
type SetPostText = { type: "setPostText", value: string }
type SetPostImages = { type: "setPostImages", value: File[] }
type SetPosterAreaExpanded = { type: "setPosterAreaExpanded", value: boolean }

export type ViewAction =
    SetGroup
    | SetCurrentTab
    | SetPosts
    | SetPostText
    | SetPostImages
    | SetPosterAreaExpanded;

// ----------------------------------------------------
// Reducer
// ----------------------------------------------------

export const reducer = (state: ViewState, action: ViewAction): ViewState => {
    switch (action.type) {
        case "setGroup":
            return {
                ...state,
                group: action.value,
            }

        case "setCurrentTab":
            return {
                ...state,
                currentTab: action.value,
            }

        case "setPosts":
            return {
                ...state,
                posts: action.value,
            }

        case "setPostText":
            return {
                ...state,
                postText: action.value
            }

        case "setPostImages":
            return {
                ...state,
                postImages: action.value
            }

        case "setPosterAreaExpanded":
            return {
                ...state,
                posterAreaExpanded: action.value
            }
    }
}

// ----------------------------------------------------
// Initial State
// ----------------------------------------------------

export const initialState = (): ViewState => {
    return {
        group: null,
        currentTab: "posts",
        posts: null,
        postText: "",
        postImages: [],
        posterAreaExpanded: false
    }
}