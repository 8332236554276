/**
 * Annons-sidan ska visa alla ads.
 * Man ska kunna skapa nya ads.
 * Redigera ads.
 * Statistik för ads.
 * Se gamla ads.
 * Dela annons (t.ex med URL).
 */

import React from "react";
import { useNavigate } from "react-router-dom";
import { EditAdOverlay } from "./ChildComponents/EditAdOverlay";
import { AdsStyles } from "./Styling";
import { MiddleWrapper } from "~base/Components/Elements/Cosmetic/MiddleWrapper";
import { ViewBaseProps } from "~base/Model/ViewBaseProps";
import { Button, TextField } from "@mui/material";
import { AdCard } from "~base/Components/Composite/AdCard/AdCard";
import { getCompanyAds } from "./Fetch";
import { Row } from "~base/Components/Elements/Cosmetic/Row";
import { muiStyles } from "~base/Resources/Styling/MuiTheme";
import { initialManageAdsState, manageAdsReducer } from "./Store";
import { useIntl } from "~base/Internalization/IntlContext";

export const ManageAds: React.FC<ViewBaseProps> = ({ session }) => {
  const intl = useIntl();

  window.view = "ManageAds";
  intl.formatPageTitle("PageTitle.ManageAds");

  const classes = AdsStyles();
  const muiClasses = muiStyles();

  let navigate = useNavigate();

  const [showEditAdOverlay, setShowEditAdOverlay] =
    React.useState<boolean>(false);

  const [state, dispatch] = React.useReducer(
    manageAdsReducer,
    initialManageAdsState()
  );

  React.useEffect(() => {
    getCompanyAds(session, dispatch);
  }, []);

  return (
    <MiddleWrapper>
      <EditAdOverlay
        onCancel={() => setShowEditAdOverlay(false)}
        onError={() => {}}
        show={showEditAdOverlay}
      />

      <div className={classes.sectionWrapper}>
        <h2 className={classes.sectionTitle}>
          {intl.formatText("Views.ManageAds.Title")}
        </h2>
        <p className={classes.sectionDescription}>
          {intl.formatText("Views.ManageAds.Description")}
        </p>

        <Row>
          <Button
            variant="contained"
            onClick={() => navigate("/jobb/annons/skapa")}
            className={muiClasses.button}
          >
            {intl.formatText("Views.ManageAds.CreateAd.Button")}
          </Button>

          <TextField
            className={muiClasses.textField}
            size="small"
            id="outlined-search"
            label={intl.formatText("Views.ManageAds.Search.Label")}
            type="search"
            style={{ marginLeft: "auto" }}
          />
        </Row>
      </div>

      {state.ads ? (
        state.ads
          .sort((a, b) => (a.time_created! < b.time_created! ? 1 : -1))
          .map((ad, index) => {
            return (
              <AdCard
                ad={ad}
                onClickControlPanel={() =>
                  navigate(`/foretag/annonser/${ad.id}`)
                }
              />
            );
          })
      ) : (
        <>
          <AdCard loading />
          <AdCard loading />
        </>
      )}
    </MiddleWrapper>
  );
};
