import React from "react";
import { initialState, mockAd, reducer } from "./Store";
import { Divider } from "~base/Components/Elements/Cosmetic/Divider";
import { AdCard } from "~base/Components/Composite/AdCard/AdCard";
import { ViewBaseProps } from "~base/Model/ViewBaseProps";
import { Button } from "@mui/material";
import { CompositeAd } from "~base/Components/Composite/Ad/Component";
import { createUseStyles } from "react-jss";
import { HendigoTheme } from "~base/Resources/Styling/HendigoTheme";
import { Link, useNavigate, useParams } from "react-router-dom";
import { getAd, getApplicationStatus, getTop3Ads } from "./Fetch";
import { Row } from "~base/Components/Elements/Cosmetic/Row";
import { BsInfoCircle } from "react-icons/bs";
import { LOGIN_PATH } from "~base/Routing/Paths";
import { useIntl } from "~base/Internalization/IntlContext";

export const useStyles = createUseStyles((theme: HendigoTheme) => ({
    wrapper: {
        maxWidth: "100%",
        minHeight: "91vh",
        backgroundColor: theme.backgroundPrimary,
        padding: "50px 25%"
    },
    sectionWrapper: {
        backgroundColor: theme.backgroundSecondary,
        padding: "10px",
        marginBottom: "10px",
        borderRadius: "5px",
        animation: "$slideIn 0.75s ease-in-out"
    },
    sectionTitleRow: {
        alignItems: "center",
        gap: "10px",
        marginBottom: "1rem"
    },
    sectionTitle: {
        color: theme.textPrimary,
        margin: "0px",
        fontWeight: "500"
    },
    icon: {
        color: theme.blue,
        fontSize: "18px"
    },
    '@media screen and (max-width: 1500px)':{
        wrapper: {
            padding: "20px 5% 0px 5%"
        }
    },
}));

export const Ad: React.FC<ViewBaseProps> = ({ session }) => {
    window.view = "Ad";

    const intl = useIntl();
    const { id } = useParams();
    const classes = useStyles();
    const navigate = useNavigate();

    intl.formatPageTitle("PageTitle.Ad");

    // State and dispatch
    const [state, dispatch] = React.useReducer(reducer, initialState());

    // Fetch ad
    React.useEffect(() => {
        if (id != null) {
            getAd(id, dispatch, session);
            getApplicationStatus(id, dispatch, session);
            getTop3Ads(session, dispatch);
        } else {
            session.setInformation("error", "No Ad id provided");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    const onApply = () => {
        if (session.userProfile === null) {
            navigate(`${LOGIN_PATH}?redirect=${window.location.pathname}`);
        } else {
            navigate('./apply');
        }
    }

    return <div className={classes.wrapper}>

        {state.application && <div className={classes.sectionWrapper}>
            <Row className={classes.sectionTitleRow}>
                <BsInfoCircle className={classes.icon} />

                <h3 className={classes.sectionTitle}>
                    {intl.formatText("Views.Ad.AlreadyApplied")}
                </h3>
            </Row>

            <Button
                variant="contained"
                component={Link}
                to={`/jobb/mina-ansokningar/${state.application.id}`}
            >
                {intl.formatText("Views.Ad.ViewApplication")}
            </Button>
        </div>}

        <div style={{ opacity: state.alreadyApplied ? 0.5 : 1 }}>
            <CompositeAd
                ad={state.ad ?? {}}
                adRequirements={state.adRequirements}
                loading={state.ad === null}
                applyButtonDisabled={session.companyProfile !== null || state.application != null}
                onApply={onApply}
            />
        </div>

        <Divider style={{ margin: "25px 0px", borderColor: "lightgray" }} />

        {state.top3Ads.length > 0 ?
            state.top3Ads.filter(ad => ad.id !== state.ad?.id).map((ad, index) => <AdCard key={"Adtop3ads" + index} ad={ad} />)
            :
            <>
                <AdCard loading />
                <AdCard loading />
                <AdCard loading />
            </>
        }

        <Button
            variant="contained"
            style={{ display: "block", margin: "20px auto 0" }}
        >{intl.formatText("Views.Ad.ShowMoreAds")}</Button>
    </div>
}