import { CSSProperties } from "react";
import { createUseStyles } from "react-jss";
import { BeatLoader } from "react-spinners";
import { HendigoBaseTheme } from "../../../Resources/Styling/HendigoBaseTheme";

const UseStyles = createUseStyles({
    button: {
        backgroundColor: HendigoBaseTheme.colors.hendigoDark,
        border: "2px solid transparent",
        padding: "10px",
        color: "white",
        fontWeight: "600",
        fontSize: "14px",
        '&:hover': {
            cursor: "pointer",
            backgroundColor: "black",
            //color: HendigoBaseTheme.colors.hendigoDark,
            border: "2px solid black",
        }
    },
    disabled: {
        opacity: "50%",
        '&:hover': {
            cursor: "default",
            backgroundColor: HendigoBaseTheme.colors.hendigoDark,
        }
    },
    buttonBlue: {
        backgroundColor: HendigoBaseTheme.colors.hendigoBlue,
        border: "2px solid transparent",
        padding: "10px",
        color: "white",
        fontSize: "14px",
        fontWeight: "600",
        '&:hover': {
            cursor: "pointer",
            backgroundColor: HendigoBaseTheme.colors.hendigoDarkBlue,
            border: "2px solid " + HendigoBaseTheme.colors.hendigoDarkBlue,
        }
    },
    buttonRed: {
        backgroundColor: "red",
        border: "2px solid transparent",
        padding: "10px",
        color: "white",
        fontSize: "14px",
        fontWeight: "600",
        '&:hover': {
            cursor: "pointer",
            backgroundColor: "red",
            border: "2px solid red",
        }
    },
});

const override: CSSProperties = {
    display: "block",
    margin: "0 auto",
}

type ButtonProps = {
    style?: React.CSSProperties;
    onClick?: () => void;
    disabled?: boolean;
    loading?: boolean;
    className?: string;
    children?: React.ReactNode | React.ReactNode[];
    error?: string;
    colorType?: "blue" | "dark" | "red";
    borderType?: "round" | "sharp";
}

const defaultProps: ButtonProps = {
    style: {},
    onClick: () => { },
    disabled: false,
    loading: false,
    className: "",
    colorType: "dark",
    borderType: "round"
}

export const Button: React.FunctionComponent<ButtonProps> = (props) => {
    const classes = UseStyles();
    // Use cases to set the color of the button now that we have added red as an option
    const buttonColor: string = props.colorType === "red" ? classes.buttonRed : props.colorType === "blue" ? classes.buttonBlue : classes.button;

    const style = { ...props.style, borderRadius: (props.borderType === "round" ? "15px" : "5px") } as React.CSSProperties;
    return <><button
        disabled={props.disabled}
        onClick={props.onClick}
        style={style}
        className={!props.disabled ? buttonColor + " " + props.className : buttonColor + " " + classes.disabled + " " + props.className}
    >
        {props.loading ? <BeatLoader color="#ffffff" cssOverride={override} loading={true} size={10} /> : props.children}
    </button>
        {props.error ? <div style={{ color: "#ffcccb", fontSize: "12px", textAlign: "center" }}>{props.error}</div> : null}
    </>
}

Button.defaultProps = defaultProps;