import { createUseStyles } from "react-jss";
import { HendigoTheme } from "~base/Resources/Styling/HendigoTheme";

export const FeedNavbarStyles = createUseStyles((theme: HendigoTheme) => {
    return {
        navbar: {
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            zIndex: "2",
            backgroundColor: theme.backgroundSecondary,
            color: theme.textColor,
            padding: "10px 10%",
        },
        logoSection: {
            width: "20%"
        },
        searchSection: {
            width: "60%",
        },
        drawerBox: {
            width: "25vw"
        },
        moreSection: {
            display: "flex",
            flexDirection: "row",
            justifyContent: "end",
            width: "20%"
        },
        link: {
            margin: "auto 0 !important"
        },
        avatar: {
            height: "35px !important",
            width: "35px !important",
            textDecoration: "none",
            '&:hover': {
                cursor: "pointer"
            }
        },
        loginButton: {
            display: "block"
        },
        '@media screen and (max-width: 1100px)': {
            searchSection: {
                display: "none"
            },
            moreSection: {
                marginLeft: "auto"
            },
            drawerBox: {
                width: "100vw"
            },
            link: {
                display: "none"
            }
        },
        '@media screen and (max-width: 520px)': {
            loginButton: {
                display: "none !important"
            }
        }
    }
});