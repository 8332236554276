export const isEmail = (email: string): boolean => {
    var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

    if (email.match(validRegex)) {
        return true;
    } else {
        return false;
    }
}

export const isPassword = (password: string): boolean => {
    if (password.length >= 8) {
        return true;
    } else {
        return false;
    }
}

export const isNumber = (value: string): boolean => {
    if (value.match(/^[0-9]+$/)) {
        return true;
    } else {
        return false;
    }
}

export const isOrgNr = (value: string): boolean => {
    if (isNumber(value) && value.length === 10) {
        return true;
    } else {
        return false;
    }
}
