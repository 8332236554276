import React from "react";

export interface FilesAPIServiceContext {
    getProfileImageSmall: (imageFileName: string | undefined) => string;
    getProfileImageLarge: (imageFileName: string | undefined) =>  string;
    getPostImage: (imageFileName: string | undefined) => string;
}

export const FilesAPIServiceContextProvider = React.createContext<FilesAPIServiceContext>({} as FilesAPIServiceContext);

export const useFilesAPIService = () => React.useContext(FilesAPIServiceContextProvider);

const FILES_ROOT_PATH = `${window.location.hostname === "localhost" ? "http://" : "https://"}${window.location.hostname}/server/files`;

/**
 * Service for getting the small profile image pathname
 * @param imageFileName for the image with file extension.
 * @returns pathname.
 */
export const getProfileImageSmall = (imageFileName: string | undefined): string => {

    if(imageFileName == "null") {
        return "";
    }

    return `${FILES_ROOT_PATH}/images/profile/small/${imageFileName}`;
}

export const getProfileImageLarge = (imageFileName: string | undefined): string => {

    if(imageFileName == "null") {
        return "";
    }

    return `${FILES_ROOT_PATH}/images/profile/large/${imageFileName}`;
}

export const getPostImage = (imageFileName: string | undefined): string => {

    if(imageFileName == "null") {
        return "";
    }

    return `${FILES_ROOT_PATH}/images/post/${imageFileName}`;
}
