import { useTheme } from "react-jss"
import { BeatLoader } from "react-spinners"
import { HendigoTheme } from "~base/Resources/Styling/HendigoTheme"

interface LoaderProps {

}

export const Loader: React.FC<LoaderProps> = () => {
    const theme: HendigoTheme = useTheme();

    return <BeatLoader 
        color={theme.blue}
        loading={true}
    />
}