import React from "react";
import { AiFillLike, AiOutlineComment, AiOutlineLike, AiOutlineShareAlt } from "react-icons/ai";
import { BsBadgeAd, BsThreeDots } from "react-icons/bs";
import { Row } from "../../Elements/Cosmetic/Row";
import { usePostStyles } from "~base/Components/Composite/Post/Styling";
import { Session } from "~base/Model/Session";
import { EditPostOverlay } from "~base/Components/Composite/Post/ChildComponents/EditPostOverlay";
import { PostResponse } from "~base/Model/ResponseModels/PostResponse";
import { addComment, deletePost, editPost, getPostComments, likePost, removeLikePost } from "./Fetch";
import { Link, useNavigate } from "react-router-dom";
import { Input } from "~base/Components/Elements/Input/Input";
import { Comment } from "./ChildComponents/Comment";
import { PostCommentResponse } from "~base/Model/ResponseModels/PostCommentResponse";
import { Avatar, Menu, MenuItem, Paper } from "@mui/material";
import { Button as MUIButton } from "@mui/material";
import { useIntl } from "~base/Internalization/IntlContext";
import { useFilesAPIService } from "~base/Services/FilesAPIServiceContext";
import Carousel from "react-material-ui-carousel";
import { HendigoTheme } from "~base/Resources/Styling/HendigoTheme";
import { useTheme } from "react-jss";
import { GROUP } from "~base/Routing/Paths";

export interface ExtendedPostResponse extends PostResponse {
    ad_id?: string;
}

export interface PostProps {
    session: Session,
    post: ExtendedPostResponse;
    showCommentButton?: boolean;
    onDelete: () => void;
    onEditContent: (newContent: string) => void;
    showGroupWhenGroupPost?: boolean;
}

/**
 * Shared between Feed and SearchResult
 * @param props 
 * @returns 
 */
export const Post: React.FC<PostProps> = ({ session, post, showCommentButton = true, onDelete, onEditContent, showGroupWhenGroupPost = true }) => {

    const intl = useIntl();
    const classes = usePostStyles();
    const theme: HendigoTheme = useTheme();
    const filesAPIService = useFilesAPIService();

    const [showMore, setShowMore] = React.useState(false);
    const [showComments, setShowComments] = React.useState(!showCommentButton);
    const [content, setContent] = React.useState("");

    const [numberOfLikes, setNumberOfLikes] = React.useState<number>(post.number_of_likes);
    const [liked, setLiked] = React.useState<boolean>(post.liked);

    const [showEditPostOverlay, setShowEditPostOverlay] = React.useState<boolean>(false);

    const [commentInputText, setCommentInputText] = React.useState<string>("");

    const [comments, setComments] = React.useState<PostCommentResponse[]>([]);

    // Menu things
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const navigate = useNavigate();

    /**
     * Check wheter to display show more button or not.
     */
    React.useEffect(() => {
        if (post.content?.match(/([\n])\w+/g)!?.length > 2 && showCommentButton) {
            setShowMore(true);
            setContent(post.content.substring(0, post.content.indexOf("\n", post.content.indexOf("\n"))) + "...");
        } else if (post.content?.length > 225 && showCommentButton) {
            setShowMore(true);
            setContent(post.content.substring(0, 225) + "...");
        } else {
            setShowMore(false);
            setContent(post.content);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [post.content]);

    React.useEffect(() => {
        if (showComments && comments.length === 0) {
            getPostComments(post.id, session, setComments);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showComments]);

    const profilePicture = filesAPIService.getProfileImageSmall(post.small_image_pathname);

    const firstLetterInName = post?.name.substring(0, 1);

    const profileLink = post?.company_id ?
        `/foretag/${post.company_id}`
        : `/anvandare/${post?.user_id}`;

    return (
        <div
            className={classes.wrapper}
            style={{outline: post.ad ? `1px solid ${theme.blue}` : "none"}}
        >
            <EditPostOverlay
                session={session}
                show={showEditPostOverlay}
                onCancel={() => setShowEditPostOverlay(false)}
                onSave={(newContent: string) => {
                    editPost(post.id, newContent, session, onEditContent);
                    setShowEditPostOverlay(false);
                }}
                content={post.content}
            />

            <Row className={classes.authorRow}>
                <Avatar
                    src={profilePicture}
                    className={classes.avatar}
                    component={Link}
                    to={profileLink}
                >
                    {firstLetterInName}
                </Avatar>

                <div>
                    <div style={{display: "flex", gap: "8px"}}>
                        <h3 
                            onClick={() => navigate(profileLink)} 
                            className={classes.companyName}
                        >
                            {post.name}
                        </h3>

                        {(showGroupWhenGroupPost && post.group_name) && (
                            <h3 
                                className={classes.companyName}
                                onClick={() => navigate(`${GROUP}/${post.user_group_id}`)}
                            >
                                <b>({post.group_name})</b>
                            </h3>
                        )}
                    </div>
                    <div className={classes.time}>{intl.formatTimeLong(new Date(post?.time_created))}</div>
                </div>

                {post.ad ? <BsBadgeAd className={classes.adIcon} /> : (
                    (post.company_id && post.company_id === session.companyId) || (post.user_id && post.user_id === session.userId) ? (
                        <div className={classes.managePost}>
                            <MUIButton
                                id="basic-button"
                                aria-controls={Boolean(anchorEl) ? 'basic-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={Boolean(anchorEl) ? 'true' : undefined}
                                onClick={handleClick}
                                style={{ fontSize: "20px", minWidth: "0px" }}
                            >
                                <BsThreeDots />
                            </MUIButton>

                            <Menu
                                id="basic-menu"
                                anchorEl={anchorEl}
                                open={Boolean(anchorEl)}
                                onClose={handleClose}
                                MenuListProps={{
                                    'aria-labelledby': 'basic-button',
                                }}
                            >
                                <MenuItem
                                    onClick={() => {
                                        handleClose();
                                        setShowEditPostOverlay(true);
                                    }}
                                >
                                    {intl.formatText("Shared.Post.Edit")}
                                </MenuItem>

                                <MenuItem
                                    onClick={() => {
                                        handleClose();
                                        deletePost(post.id, session, onDelete);
                                    }}
                                >
                                    {intl.formatText("Shared.Post.Delete")}
                                </MenuItem>
                            </Menu>
                        </div>
                    ) : null
                )}
            </Row>

            <div className={classes.content}>
                <h3 className={classes.title}>{post.title}</h3>

                {post.images?.length > 0 && (
                    <Carousel autoPlay={false} animation="slide">
                        {post.images.map(image => {
                            return (
                                <Paper key={image.id}>
                                    <div className={classes.postImageWrapper}>
                                        <img
                                            className={classes.postImage}
                                            src={filesAPIService.getPostImage(image.filename)}
                                        />
                                    </div>
                                </Paper>
                            );
                        })}
                    </Carousel>
                )}

                <div className={classes.text}>
                    {content}
                </div>

                {showMore && (
                    <div
                        className={classes.showMore}
                        onClick={() => {
                            setShowMore(false);
                            setContent(post.content);
                        }}
                    >
                        {intl.formatText("Shared.Post.ShowMore")}
                    </div>
                )}
            </div>

            {post.ad && <>
                <br />
                <MUIButton
                    variant="outlined"
                    to={`/jobb/annons/${post.ad_id}`}
                    component={Link}
                >Gå till annons</MUIButton>
            </>}



            {post.ad != true && <Row className={classes.socialSection}>
                {
                    liked === true ?
                        <AiFillLike
                            onClick={() => {
                                if (liked === true) {
                                    setNumberOfLikes(numberOfLikes - 1);
                                    setLiked(false);
                                    removeLikePost(post.id, session);
                                }
                            }}
                            className={classes.likedIcon}
                        />
                        :
                        <AiOutlineLike
                            onClick={() => {
                                if (liked === false) {
                                    setNumberOfLikes(numberOfLikes + 1);
                                    setLiked(true);
                                    likePost(post.id, session);
                                }
                            }}
                            className={classes.socialIcon}
                        />
                }

                <div className={classes.numberOf}>{numberOfLikes}</div>

                {showCommentButton && <>
                    <AiOutlineComment
                        onClick={() => {
                            setShowComments(!showComments);
                        }}
                        className={classes.socialIcon}
                    />

                    <div className={classes.numberOf}>{post.number_of_comments}</div>
                </>}

                <AiOutlineShareAlt
                    onClick={() => { }}
                    className={classes.socialIcon}
                />
            </Row>}

            {showComments && <><h4 className={classes.commentTitle}>
                {intl.formatText("Views.Post.Comments")}
            </h4>

                <Row>
                    <Avatar
                        src={profilePicture}
                        className={classes.avatar}
                        component={Link}
                        to={profileLink}
                    >
                        {firstLetterInName}
                    </Avatar>

                    <div style={{ width: "50%" }}>
                        <Input
                            colorType="light"
                            placeholder={intl.formatText("Views.Post.Comment.Placeholder")}
                            className={classes.commentInput}
                            value={commentInputText}
                            onChange={(e) => setCommentInputText(e.target.value)}
                        //cssWidth={"96%"}
                        />
                    </div>

                    <MUIButton
                        //colorType="blue"
                        //className={classes.postCommentButton}
                        onClick={() => addComment(post.id, commentInputText, setCommentInputText, session, setComments, comments)}
                        variant="contained"
                        sx={{ height: "40px", margin: "auto 0 auto 20px" }}
                    >{intl.formatText("Views.Post.Upload")}</MUIButton>
                </Row>

                <div className={classes.commentSection}>
                    {comments.sort((a, b) => a.time_created < b.time_created ? 1 : -1).map((comment, index) => {
                        return <Comment
                            key={"PostComment" + index}
                            comment={comment}
                        />
                    })}
                </div></>}
        </div>
    );
}
