import { createUseStyles } from "react-jss";
import { HendigoBaseTheme } from "../../../Resources/Styling/HendigoBaseTheme";
import { HendigoTheme } from "~base/Resources/Styling/HendigoTheme";

export const FeedSideNavbarStyles = createUseStyles((theme: HendigoTheme) => {
    return {
        wrapper: {
            display: "flex",
            flexDirection: "column",
            rowGap: "10px",
            maxHeight: "500px",
            width: "20%",
            position: "sticky !important",
            top: "20px !important",
            borderRadius: "10px",
            transition: "height ease-in-out 0.2s",
        },
        button: {
            marginBottom: "10px",
            padding: "10px",
            fontSize: "18px",
            textAlign: "start",
            backgroundColor: "transparent",
            color: theme.inputTextColor,
            fontWeight: "500",
            '&:hover': {
                color: theme.inputTextColor,
                border: "2px solid " + HendigoBaseTheme.colors.hendigoPurple,
                backgroundColor: "transparent",
            },
            '&:active': {
                backgroundColor: HendigoBaseTheme.colors.hendigoPurple
            }
        },
        button_icon: {
            float: "left",
            fontSize: "23px",
            marginRight: "20px",
        },
        active: {
            backgroundColor: HendigoBaseTheme.colors.hendigoPurple,
            color: "white"
        },
        '@media screen and (max-width: 1100px)': {
            wrapper: {
                display: 'none',
            }
        },
        '@keyframes fadeInAnimation': {
            "0%": {
                color: "transparent",
                opacity: 0.1,
                height: "0px"
            },
            "35%": {
                color: "transparent"
            },
            "100%": {
                color: theme.inputTextColor,
                opacity: 1,
                height: "78px"
            }
        },
        folder: {
            color: theme.inputTextColor,
            borderLeft: "2px solid " + theme.inputTextColor,
            margin: "0px 0px 10px 32px",
            animationName: "$fadeInAnimation",
            animation: "0.5s fadeInAnimation",
        },
        folderLink: {
            margin: "0px 0px 4px 19px",
            border: "2px solid transparent",
            borderRadius: "10px",
            padding: "5px",
            '&:hover': {
                cursor: "pointer",
                borderColor: HendigoBaseTheme.colors.hendigoPurple
            }
        }
    }
});