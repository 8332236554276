import { ViewBaseProps } from "~base/Model/ViewBaseProps";
import { useStyles } from "./Styling";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { initialState, reducer } from "./Store";
import { useIntl } from "~base/Internalization/IntlContext";
import { MiddleWrapper } from "~base/Components/Elements/Cosmetic/MiddleWrapper";
import { Avatar, Button, TextField } from "@mui/material";
import { AiOutlineShareAlt } from "react-icons/ai";
import { BiEdit } from "react-icons/bi";
import { Row } from "~base/Components/Elements/Cosmetic/Row";
import { Divider } from "~base/Components/Elements/Cosmetic/Divider";
import { getGroup, getGroupPosts } from "./Fetch";
import { Post } from "~base/Components/Composite/Post/Component";
import { Loader } from "~base/Components/Elements/Cosmetic/Loader";
import { AddImagesRow } from "~base/Components/Shared/AddImagesRow/Component";
import { muiStyles } from "~base/Resources/Styling/MuiTheme";

interface ViewProps extends ViewBaseProps {

}

export const Group: React.FC<ViewProps> = ({ session }) => {
    window.view = "Template";

    const intl = useIntl();

    //intl.formatPageTitle("PageTitle.Group");
    intl.formatPageTitleString("Supermålare")

    // Params
    const { id } = useParams();

    // Styling Classes
    const classes = useStyles();
    const muiClasses = muiStyles();

    // Navigation
    const navigate = useNavigate();

    // State and dispatch
    const [state, dispatch] = React.useReducer(reducer, initialState());

    const {
        group,
        currentTab,
        posts,
    } = state;

    React.useEffect(() => {
        if (id) {
            getGroup(id, dispatch, session);
        }
    }, [id, dispatch, session]);

    React.useEffect(() => {
        if (id && group?.isMember) {
            // Get posts, discussions and members
            if (currentTab === "posts" && posts == null) {
                // Get posts
                // getPosts(id, dispatch, session);
                getGroupPosts(id, dispatch, session);
            }
        }
    }, [id, group?.isMember, currentTab, posts]);

    const poster = (
        <div className={classes.poster}>
            <div className={classes.posterTextareaWrapper}>
                <TextField
                    multiline={true}
                    rows={state.posterAreaExpanded ? 3 : 1}
                    placeholder={intl.formatText("Views.Feed.Share")}
                    fullWidth={true}
                    className={muiClasses.textField}
                    value={state.postText}
                    onChange={(e) => {
                        if (e.target.value.length <= 2000)
                            dispatch({ type: "setPostText", value: e.target.value });
                    }}
                    onFocus={() => dispatch({ type: "setPosterAreaExpanded", value: true })}
                />
            </div>

            {state.posterAreaExpanded && (
                <AddImagesRow
                    files={state.postImages}
                    setFiles={(files) =>
                        dispatch({ type: "setPostImages", value: files })
                    }
                />
            )}

            {state.posterAreaExpanded && (
                <Row>
                    <Button
                        variant="contained"
                        onClick={() => {
                            // const content = state.postText;
                            // if (state.postText.length >= 10 && state.postText.length <= 2000) {
                            //     addPost(content, session, dispatch, state.postImages);
                            // }
                        }}
                        disabled={state.postText.length < 10 || state.postText.length > 2000}
                    >
                        {intl.formatText("Views.Feed.AddSomething")}
                    </Button>
                </Row>
            )}
        </div>
    );

    return (
        <MiddleWrapper>
            {group && (
                <>
                    <div
                        className={classes.background}
                    >
                        <Avatar
                            className={classes.avatar}
                        >
                            {group.groupData.name[0].toUpperCase()}
                        </Avatar>
                    </div>

                    <div className={classes.aboutWrapper}>
                        {/* <AiOutlineShareAlt
                            className={classes.edit_button}
                        />

                        <BiEdit
                            className={classes.edit_button}
                        /> */}

                        <h2 className={classes.name}>
                            {group.groupData.name}
                        </h2>

                        {/* <div className={classes.location}>
                            {group.userGroup.name}
                        </div> */}

                        {/* <Row style={{ columnGap: "10px" }}>
                            <div
                                className={classes.socialLink}
                            >
                                {/*"3 medlemmar"*/ /*"Medlemmar"}
                            </div>
                        </Row> */}

                        <Divider style={{ margin: "10px 0px" }} />

                        {!group.isMember && (
                            <Button
                                variant={"contained"}
                            >
                                Gå med
                            </Button>
                        )}
                    </div>

                    <div className={classes.sectionWrapper}>
                        <h3 className={classes.sectionTitle}>
                            Om gruppen
                        </h3>

                        <p className={classes.sectionDescription}>
                            {group.groupData.description}
                        </p>
                    </div>

                    <Row className={classes.row}>
                        <Button
                            variant={currentTab === "posts" ? "contained" : "outlined"}
                            className={classes.button}
                            onClick={() => dispatch({ type: "setCurrentTab", value: "posts" })}
                        >
                            Inlägg
                        </Button>

                        <Button
                            variant={currentTab === "discussions" ? "contained" : "outlined"}
                            className={classes.button}
                            onClick={() => dispatch({ type: "setCurrentTab", value: "discussions" })}
                            disabled={true}
                        >
                            Diskussioner
                        </Button>

                        <Button
                            variant={currentTab === "members" ? "contained" : "outlined"}
                            className={classes.button}
                            onClick={() => dispatch({ type: "setCurrentTab", value: "members" })}
                            disabled={true}
                        >
                            Medlemmar
                        </Button>
                    </Row>

                    {currentTab === "posts" && (
                        function () {
                            if (posts == null) {
                                return (
                                    <div className={classes.loaderWrapper}><Loader /></div>
                                );
                            } else if (group.isMember && posts?.length > 0) {
                                return (
                                    <>
                                        {poster}
                                        {posts.map(post => (
                                            <Post
                                                key={post.id}
                                                post={post}
                                                session={session}
                                                onDelete={() => null}
                                                onEditContent={() => null}
                                                showGroupWhenGroupPost={false}
                                            />
                                        ))}
                                    </>
                                );
                            } else if (group.isMember && posts?.length === 0) {
                                return (
                                    <>
                                        {poster}
                                        <h3 className={classes.errorText}>
                                            Inga inlägg än
                                        </h3>
                                    </>
                                )
                            } else if (!group.isMember) {
                                return (
                                    <h3 className={classes.errorText}>
                                        Gå med i gruppen för att se inlägg
                                    </h3>
                                )
                            }
                        }()
                    )}
                </>
            )
            }
        </MiddleWrapper >
    );
}