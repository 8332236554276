import { createUseStyles } from "react-jss";
import { HendigoBaseTheme } from "~base/Resources/Styling/HendigoBaseTheme";
import { HendigoTheme } from "~base/Resources/Styling/HendigoTheme";

export const JobStyles = createUseStyles((theme: HendigoTheme) => {
    return {
        wrapper2: {
            backgroundColor: theme.backgroundPrimary,
            maxWidth: "100%",
            padding: "20px 25% 20px 25%",
            color: HendigoBaseTheme.colors.hendigoDark,
        },
        title2: {
            textAlign: "center",
            color: theme.textPrimary,
        },
        showMoreButton: {
            display: "block !important",
            margin: "25px auto !important",
            width: "200px"
        },
        description2: {
            color: theme.textPrimary
        },
        span: {
            color: theme.dark
        }
    }
});