// ----------------------------------------------------
// Imports
// ----------------------------------------------------

import { UserProfileResponse } from "~base/Model/ResponseModels/UserProfileResponse";
import { UserViewState } from "./Types";
import { Session } from "~base/Model/Session";
import { IntlContext } from "~base/Internalization/IntlContext";
import { UserExperienceResponse } from "~base/Model/ResponseModels/UserExperienceResponse";
import { PostResponse } from "~base/Model/ResponseModels/PostResponse";

// ----------------------------------------------------
// Actions
// ----------------------------------------------------

type SetShowOverlay = { type: "setShowOverlay", value: boolean }
type SetAccountNotFound = { type: "setAccountNotFound", value: boolean }
type SetProfile = { type: "setProfile", profile: UserProfileResponse | null }
type SaveOverlay = { type: "saveOverlay", newProfile: UserProfileResponse | null }
type SetYourProfile = { type: "setYourProfile", value: boolean }
type SetId = { type: "setId", value: string }
type ResetState = { type: "resetState", state: UserViewState }
type SetContactButtonText = { type: "setContactButtonText", value: string }
type SetContactButtonDisabled = { type: "setContactButtonDisabled", value: boolean }
type SetContactButtonShow = { type: "setContactButtonShow", value: boolean }
type SetPosts = { type: "setPosts", value: PostResponse[] }
type SetShowEditProfilePictureOverlay = { type: "setShowEditProfilePictureOverlay", value: boolean }
type SetIsContacts = { type: "setIsContacts", value: boolean }
type SetIsFollowing = { type: "setIsFollowing", value: boolean }
type SetShowEditPostOverlay = { post: PostResponse | null; type: "setShowEditPostOverlay", value: boolean }
type SetExperience = { type: "setExperience", value: UserExperienceResponse[] }
type SetShowCreateUserExperienceOverlay = { type: "setShowCreateUserExperienceOverlay", value: boolean }
type SetShowEditUserExperienceOverlay = { type: "setShowEditUserExperienceOverlay", value: boolean }

export type UserAction =
    SetShowOverlay |
    SetAccountNotFound |
    SetProfile |
    SaveOverlay |
    SetYourProfile |
    SetId |
    ResetState |
    SetContactButtonText |
    SetContactButtonDisabled |
    SetContactButtonShow |
    SetPosts |
    SetShowEditProfilePictureOverlay |
    SetIsContacts |
    SetIsFollowing |
    SetShowEditPostOverlay |
    SetExperience |
    SetShowCreateUserExperienceOverlay |
    SetShowEditUserExperienceOverlay;

// ----------------------------------------------------
// Reducer
// ----------------------------------------------------

export const userReducer = (state: UserViewState, action: UserAction): UserViewState => {
    switch (action.type) {
        case "setShowOverlay":
            return {
                ...state,
                showOverlay: action.value
            }

        case "setAccountNotFound":
            return {
                ...state,
                accountNotFound: action.value
            }

        case "setProfile":
            return {
                ...state,
                profile: action.profile
            }

        case "saveOverlay":
            return {
                ...state,
                showOverlay: false
            }

        case "setYourProfile":
            return {
                ...state,
                yourProfile: action.value
            }

        case "setId":
            return {
                ...state,
                id: action.value
            }

        case "resetState":
            return {
                ...action.state
            }

        case "setContactButtonText":
            return {
                ...state,
                contactButton: {
                    ...state.contactButton,
                    text: action.value
                }
            }

        case "setContactButtonDisabled":
            return {
                ...state,
                contactButton: {
                    ...state.contactButton,
                    disabled: action.value
                }
            }

        case "setContactButtonShow":
            return {
                ...state,
                contactButton: {
                    ...state.contactButton,
                    show: action.value
                }
            }

        case "setPosts":
            return {
                ...state,
                posts: action.value
            }

        case "setShowEditProfilePictureOverlay":
            return {
                ...state,
                showEditProfilePictureOverlay: action.value
            }

        case "setIsContacts":
            return {
                ...state,
                isContacts: action.value
            }
        
        case "setIsFollowing":
            return {
                ...state,
                isFollowing: action.value
            }
        
        case "setShowEditPostOverlay":
            return {
                ...state,
                showEditPostOverlay: action.value,
                post: action.post
            }
        case "setExperience":
            return {
                ...state,
                userExperience: action.value
            }
        case "setShowCreateUserExperienceOverlay":
            return {
                ...state,
                showCreateUserExperienceOverlay: action.value
            }
        case "setShowEditUserExperienceOverlay":
            return {
                ...state,
                showEditUserExperienceOverlay: action.value
            }
    }
}

// ----------------------------------------------------
// Initial State
// ----------------------------------------------------

export const initialUserState = (session: Session, paramId: string, context: IntlContext): UserViewState => {
    return {
        id: paramId,
        showOverlay: false,
        accountNotFound: false,
        profile: session.userId === paramId ? session.userProfile : null,
        yourProfile: session.userId === paramId,
        contactButton: {
            text: context.formatText("Views.User.CreateContact"),
            disabled: false,
            show: false
        },
        posts: [],
        post: null,
        showEditProfilePictureOverlay: false,
        isContacts: false,
        isFollowing: false,
        showEditPostOverlay: false,
        showCreateUserExperienceOverlay: false,
        showEditUserExperienceOverlay: false,
        userExperience: [],
    }
}