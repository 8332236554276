import { ListItem, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import { AiOutlineProfile } from "react-icons/ai";
import { BsBadgeAd } from "react-icons/bs";
import { FiLogOut } from "react-icons/fi";
import { useIntl } from "~base/Internalization/IntlContext";
import { CompanyProfileResponse } from "~base/Model/ResponseModels/CompanyProfileResponse";

interface CompanyMenuProps {
    handleClick: (route: string | null) => void;
    companyProfile: CompanyProfileResponse;
    handleLogout: () => void;
}

export const CompanyMenu: React.FC<CompanyMenuProps> = ({ handleClick, companyProfile, handleLogout }) => {

    const intl = useIntl();

    return <>
        <ListItem onClick={() => handleClick("/foretag/" + companyProfile.company_id)} disablePadding>
            <ListItemButton>
                <ListItemText primary={intl.formatText("Views.SideNavBar.MyCompany")} />
                <ListItemIcon>
                    <AiOutlineProfile style={{ fontSize: "25px" }} />
                </ListItemIcon>
            </ListItemButton>
        </ListItem>

        <ListItem onClick={() => handleClick("/foretag/annonser")} disablePadding>
            <ListItemButton>
                <ListItemText primary={intl.formatText("Views.SideNavBar.Ads")} />
                <ListItemIcon>
                    <BsBadgeAd style={{ fontSize: "25px" }} />
                </ListItemIcon>
            </ListItemButton>
        </ListItem>

        <ListItem /*onClick={() => handleClick("/foretag/marknadsforing")}*/ disablePadding>
            <ListItemButton disabled>
                <ListItemText primary={intl.formatText("Views.SideNavBar.Marketing")} />
                <ListItemIcon>
                    <BsBadgeAd style={{ fontSize: "25px" }} />
                </ListItemIcon>
            </ListItemButton>
        </ListItem>

        {/*TODO - Add this when project is implemented
        <ListItem onClick={() => handleClick("/projekt")} disablePadding>
            <ListItemButton>
                <ListItemIcon>
                    <TbHierarchy style={{ fontSize: "25px" }} />
                </ListItemIcon>
                <ListItemText primary={intl.formatText("Views.SideNavBar.Project")} />
            </ListItemButton>
</ListItem>*/}

        <ListItem onClick={() => handleLogout()} disablePadding>
            <ListItemButton>
                <ListItemText primary={intl.formatText("Views.SideNavBar.LogOut")} />
                <ListItemIcon>
                    <FiLogOut style={{ fontSize: "25px" }} />
                </ListItemIcon>
            </ListItemButton>
        </ListItem>
    </>
}