import React from "react";
import { CSSProperties } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { BeatLoader } from "react-spinners";
import { SearchResultStyling } from "./Styling";
import { Row } from "~base/Components/Elements/Cosmetic/Row";
import { MiddleWrapper } from "~base/Components/Elements/Cosmetic/MiddleWrapper";
import { Listing } from "~base/Components/Elements/DataVisualization/Listing";
import { Divider } from "~base/Components/Elements/Cosmetic/Divider";
import { ViewBaseProps } from "~base/Model/ViewBaseProps";
import { HendigoTheme } from "~base/Resources/Styling/HendigoTheme";
import { useTheme } from "react-jss";
import { useIntl } from "~base/Internalization/IntlContext";
import { SearchResultTabs } from "./Types";
import { UserProfileResponse } from "~base/Model/ResponseModels/UserProfileResponse";
import { AdResponse } from "~base/Model/ResponseModels/AdResponse";
import { CompanyProfileResponse } from "~base/Model/ResponseModels/CompanyProfileResponse";
import { getAds, getCompanies, getPersons, getPosts, getGroups } from "./Fetch";
import { PostResponse } from "~base/Model/ResponseModels/PostResponse";
import { Button } from "@mui/material";
import { useFilesAPIService } from "~base/Services/FilesAPIServiceContext";
import {
  COMPANY_PROFILE_PATH,
  GROUPS,
  USER_PROFILE_PATH,
} from "~base/Routing/Paths";
import { AdCard } from "~base/Components/Composite/AdCard/AdCard";
import { UserGroupResponse } from "~base/Model/ResponseModels/GroupResponse";

export const SearchResult: React.FC<ViewBaseProps> = ({ session }) => {
  const intl = useIntl();
  const filesAPIService = useFilesAPIService();
  const navigate = useNavigate();

  window.view = "SearchResult";
  intl.formatPageTitle("PageTitle.SearchResult");

  const { search } = useLocation();
  const searchValue = new URLSearchParams(search).get("s");
  const [loading, setLoading] = React.useState<boolean>(false);
  const [persons, setPersons] = React.useState<UserProfileResponse[]>([]);
  const [companies, setCompanies] = React.useState<CompanyProfileResponse[]>(
    []
  );
  const [ads, setAds] = React.useState<AdResponse[]>([]);
  const [posts, setPosts] = React.useState<PostResponse[]>([]);
  const [groups, setGroups] = React.useState<UserGroupResponse[]>([]);

  const [currentView, setCurrentView] = React.useState<SearchResultTabs>("ads");

  const classes = SearchResultStyling();
  const theme: HendigoTheme = useTheme();

  const override: CSSProperties = {
    display: "block",
    margin: "0 auto",
  };

  const isLargestArray = (arr: any[]) => {
    return (
      arr.length >= persons.length &&
      arr.length >= companies.length &&
      arr.length >= ads.length &&
      arr.length >= posts.length &&
      arr.length >= groups.length
    );
  };

  React.useEffect(() => {
    const f = async () => {
      setLoading(true);
      getAds(session, searchValue ?? "", setAds);
      getCompanies(session, searchValue ?? "", setCompanies);
      getPersons(session, searchValue ?? "", setPersons);
      getPosts(session, searchValue ?? "", setPosts);
      getGroups(session, searchValue ?? "", setGroups);
    };

    f().then(() => setLoading(false));
  }, [searchValue]);

  React.useEffect(() => {
    if (isLargestArray(ads)) setCurrentView("ads");
    else if (isLargestArray(persons)) setCurrentView("people");
    else if (isLargestArray(companies)) setCurrentView("company");
    else if (isLargestArray(posts)) setCurrentView("post");
    else if (isLargestArray(groups)) setCurrentView("groups");
  }, [ads, persons, companies, posts, groups]);

  const loadingRow = (
    <Row className={classes.loadingRow}>
      <BeatLoader
        color={theme.inputTextColor}
        cssOverride={override}
        loading={true}
        size={20}
      />
    </Row>
  );

  return (
    <MiddleWrapper>
      {loading ? (
        loadingRow
      ) : (
        <>
          <Row className={classes.row}>
            <div>
              {intl.formatText("Views.SearchResult.ShowResultFor")} "
              <strong>{searchValue}</strong>"
            </div>
          </Row>

          <Row className={classes.row}>
            <Button
              variant={currentView === "people" ? "contained" : "outlined"}
              className={classes.button}
              onClick={() => setCurrentView("people")}
            >
              {intl.formatText("Views.SearchResult.People")} ({persons.length})
            </Button>

            <Button
              variant={currentView === "company" ? "contained" : "outlined"}
              className={classes.button}
              onClick={() => setCurrentView("company")}
            >
              {intl.formatText("Views.SearchResult.Company")} (
              {companies.length})
            </Button>

            <Button
              variant={currentView === "ads" ? "contained" : "outlined"}
              className={classes.button}
              onClick={() => setCurrentView("ads")}
            >
              {intl.formatText("Views.SearchResult.Ads")} ({ads.length})
            </Button>

            <Button
              variant={currentView === "post" ? "contained" : "outlined"}
              className={classes.button}
              onClick={() => setCurrentView("post")}
            >
              {intl.formatText("Views.SearchResult.Posts")} ({posts.length})
            </Button>

            <Button
              variant={currentView === "groups" ? "contained" : "outlined"}
              className={classes.button}
              onClick={() => setCurrentView("groups")}
            >
              {intl.formatText("Views.SearchResult.Groups")} ({groups.length})
            </Button>
          </Row>

          {currentView === "people" && (
            <div className={classes.row}>
              <h1 className={classes.title}>
                {intl.formatText("Views.SearchResult.People")} ({persons.length}
                )
              </h1>

              {persons.map((person, index) => {
                const imagePath = filesAPIService.getProfileImageSmall(
                  person.small_image_pathname
                );

                return (
                  <React.Fragment key={"Searchedperson" + index}>
                    <Listing
                      title={person.fullname}
                      subTitle={person.title}
                      subSubTitle={intl.formatText(person.location)}
                      imagePath={imagePath}
                      onClick={() =>
                        navigate(USER_PROFILE_PATH + "/" + person.user_id)
                      }
                    />
                    {index !== persons.length - 1 && <Divider />}
                  </React.Fragment>
                );
              })}
            </div>
          )}

          {currentView === "company" && (
            <div className={classes.row}>
              <h1 className={classes.title}>
                {intl.formatText("Views.SearchResult.Company")} (
                {companies.length})
              </h1>

              {companies.map((company, index) => {
                const imagePath = filesAPIService.getProfileImageSmall(
                  company.small_image_pathname
                );

                return (
                  <React.Fragment key={"SearchedCompany" + index}>
                    <Listing
                      title={company.company_name}
                      subTitle={
                        company.company_description.substring(0, 100) + "..."
                      }
                      subSubTitle={intl.formatText(company.location)}
                      imagePath={imagePath}
                      onClick={() =>
                        navigate(
                          COMPANY_PROFILE_PATH + "/" + company.company_id
                        )
                      }
                    />
                    {index !== companies.length - 1 && <Divider />}
                  </React.Fragment>
                );
              })}
            </div>
          )}

          {currentView === "ads" && (
            <div className={classes.row}>
              <h1 className={classes.title}>
                {intl.formatText("Views.SearchResult.Ads")} ({ads.length})
              </h1>

              {ads.map((item, index) => {
                return <AdCard key={"SearchedAd" + index} ad={item} />;
              })}
            </div>
          )}

          {currentView === "post" && (
            <div className={classes.row}>
              <h1 className={classes.title}>
                {intl.formatText("Views.SearchResult.Posts")} ({posts.length})
              </h1>

              {posts.map((post, index) => {
                const imagePath = filesAPIService.getProfileImageSmall(
                  post.small_image_pathname
                );

                return (
                  <React.Fragment key={"SearchedPost" + index}>
                    <Listing
                      title={post.name}
                      subTitle={post.content}
                      subSubTitle={intl.formatTimeLong(
                        new Date(post.time_created)
                      )}
                      imagePath={imagePath}
                    />
                    {index !== posts.length - 1 && <Divider />}
                  </React.Fragment>
                );
              })}
            </div>
          )}

          {currentView === "groups" && (
            <div className={classes.row}>
              <h1 className={classes.title}>
                {intl.formatText("Groups")} ({groups.length})
              </h1>

              {groups.map((groups, index) => {
                const imagePath = filesAPIService.getProfileImageSmall(
                  groups.small_image_pathname
                );

                return (
                  <React.Fragment key={"searchedGroup" + index}>
                    <Listing
                      title={groups.name}
                      subTitle={groups.description}
                      subSubTitle={intl.formatTimeLong(
                        new Date(groups.time_created)
                      )}
                      imagePath={imagePath}
                      onClick={() =>
                        navigate(
                            GROUPS + "/" + groups.id
                        )
                      }
                    />
                    {index !== posts.length - 1 && <Divider />}
                  </React.Fragment>
                );
              })}
            </div>
          )}
        </>
      )}
    </MiddleWrapper>
  );
};
