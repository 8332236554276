import React from "react";
import { BsSearch } from "react-icons/bs";
import { createUseStyles, useTheme } from "react-jss";
import { Row } from "~base/Components/Elements/Cosmetic/Row";
import { HendigoBaseTheme } from "~base/Resources/Styling/HendigoBaseTheme";
import { HendigoTheme } from "~base/Resources/Styling/HendigoTheme";

const useStyles = createUseStyles((theme: HendigoTheme) => {
    return {
        search_button: {
            color: "white",
            backgroundColor: HendigoBaseTheme.colors.hendigoBlue,
            padding: "10px",
            width: "20px",
            borderRadius: "0px 20px 20px 0px",
            margin: "auto 0",
            '&:hover': {
                cursor: "pointer",
                backgroundColor: HendigoBaseTheme.colors.hendigoDarkBlue,
            },
        },
        searchBar: {
            border: "none",
            borderRadius: '20px 0px 0px 20px',
            backgroundColor: theme.inputBackgroundColor,
            color: theme.inputTextColor,
            padding: '8px',
            paddingLeft: "12px",
            height: '20px',
            width: 'calc(100% - 75px)',
            margin: "auto 0",
            '&:focus, &:focus + $search_button': {
                boxShadow: "none",
                outline: "1px solid " + theme.inputTextColor
            }
        }
    }
});

interface SearchBarProps {
    placeholder?: string,
    value?: string,
    onChange?: (value: string) => void,
    onSearch?: () => void,
    maxWidth?: string
}

export const SearchBar: React.FC<SearchBarProps> = ({ placeholder, value, onChange = () => { }, onSearch = () => { }, maxWidth = "50%" }) => {
    
    const theme: HendigoTheme = useTheme();
    const classes = useStyles({ theme });

    return <Row style={{ width: "100%", justifyContent: "center" }}>
        <input
            value={value}
            className={classes.searchBar}
            style={{maxWidth: maxWidth}}
            onChange={e => {
                onChange(e.target.value)
            }}
            type="text"
            placeholder={placeholder}
            onKeyDown={e => e.key === 'Enter' ? onSearch() : null}
        />
        <BsSearch
            onClick={onSearch}
            className={classes.search_button}
        />
    </Row>
}