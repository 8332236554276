import React from "react";
import { FeedStyles } from "./Styling"
import { BeatLoader } from "react-spinners";
import { Post } from "~base/Components/Composite/Post/Component";
import { Link, useNavigate } from "react-router-dom";
import { CompanyProfileResponse } from "~base/Model/ResponseModels/CompanyProfileResponse";
import { UserProfileResponse } from "~base/Model/ResponseModels/UserProfileResponse";
import { MiddleWrapper } from "~base/Components/Elements/Cosmetic/MiddleWrapper";
import { Row } from "~base/Components/Elements/Cosmetic/Row";
import { HendigoBaseTheme } from "~base/Resources/Styling/HendigoBaseTheme";
import { ViewBaseProps } from "~base/Model/ViewBaseProps";
import { feedReducer, initialFeedState } from "./Store";
import { addPost, getFeed } from "./Fetch";
import { Avatar, Button as MUIButton, TextField } from "@mui/material";
import { useIntl } from "~base/Internalization/IntlContext";
import { useFilesAPIService } from "~base/Services/FilesAPIServiceContext";
import { muiStyles } from "~base/Resources/Styling/MuiTheme";
import { AddImagesRow } from "~base/Components/Shared/AddImagesRow/Component";

export const Feed: React.FC<ViewBaseProps> = ({ session }) => {

    const intl = useIntl();
    const filesAPIService = useFilesAPIService();

    window.view = "Feed";
    intl.formatPageTitle("PageTitle.Feed");

    const classes = FeedStyles();
    const muiClasses = muiStyles();
    const navigate = useNavigate();

    const [state, dispatch] = React.useReducer(feedReducer, initialFeedState());

    React.useEffect(() => {
        if (session.isAuth === false) {
            navigate("/hem");
        }
    }, [session, session.isAuth, navigate])

    React.useEffect(() => {
        getFeed(dispatch);
    }, [])

    const [companyProfile, setCompanyProfile] = React.useState<CompanyProfileResponse | null>(session.companyProfile);
    const [userProfile, setUserProfile] = React.useState<UserProfileResponse | null>(session.userProfile);

    /**
     * Set up profile for poster area.
     */
    React.useEffect(
        () => {
            const obs = () => {
                if (session.companyId)
                    setCompanyProfile(session.companyProfile);
                if (session.userId)
                    setUserProfile(session.userProfile);
            }

            session.addObserver(obs);

            return () => {
                session.removeObserver(obs);
            }
        },
        [
            session,
            session.companyProfile,
            session.companyId,
            session.userProfile,
            session.userId
        ]
    );

    const profilePicture = filesAPIService.getProfileImageSmall(
        companyProfile ?
            companyProfile.small_image_pathname
            : userProfile?.small_image_pathname
    );

    const firstLetterInName = companyProfile ?
        companyProfile.company_name.substring(0, 1)
        : userProfile?.fullname.substring(0, 1);

    const profileLink = companyProfile ?
        `/foretag/${companyProfile.company_id}`
        : `/anvandare/${userProfile?.user_id}`;

    const sortedPosts = state.posts?.sort((a, b) => new Date(b?.time_created).getTime() - new Date(a?.time_created).getTime()) ?? [];

    return (
        <MiddleWrapper>
            <div className={classes.wrapper}>
                <div className={classes.poster}>
                    <Row>
                        <Avatar
                            src={profilePicture}
                            className={classes.poster_avatar}
                            component={Link}
                            to={profileLink}
                        >
                            {firstLetterInName}
                        </Avatar>

                        <div>
                            <div
                                className={classes.poster_author}
                                onClick={() => navigate(profileLink)}
                            >
                                {companyProfile ? companyProfile.company_name : userProfile?.fullname}
                            </div>

                            <div>{intl.formatText((companyProfile?.location ?? userProfile?.location) ?? "")}</div>
                        </div>
                    </Row>

                    <div className={classes.posterTextareaWrapper}>
                        <TextField
                            multiline={true}
                            rows={state.posterAreaExpanded ? 3 : 1}
                            placeholder={intl.formatText("Views.Feed.Share")}
                            fullWidth={true}
                            className={muiClasses.textField}
                            value={state.postText}
                            onChange={(e) => {
                                if (e.target.value.length <= 2000)
                                    dispatch({ type: "setPostText", value: e.target.value });
                            }}
                            onFocus={() => dispatch({ type: "setPosterAreaExpanded", value: true })}
                        />
                    </div>

                    {state.posterAreaExpanded && (
                        <AddImagesRow
                            files={state.postImages}
                            setFiles={(files) => dispatch({ type: "setPostImages", value: files })}
                        />
                    )}

                    {state.posterAreaExpanded && (
                        <Row>
                            <MUIButton
                                variant="contained"
                                onClick={() => {
                                    const content = state.postText;
                                    if (state.postText.length >= 10 && state.postText.length <= 2000) {
                                        addPost(content, session, dispatch, state.postImages);
                                    }
                                }}
                                disabled={state.postText.length < 10 || state.postText.length > 2000}
                            >
                                {intl.formatText("Views.Feed.AddSomething")}
                            </MUIButton>
                        </Row>
                    )}
                </div>

                {state.posts === null ?
                    <Row>
                        <BeatLoader color={HendigoBaseTheme.colors.hendigoLightGray} style={{ display: "block", margin: "0 auto" }} loading={true} size={20} />
                    </Row>
                    : sortedPosts.map((post) => {
                        return (
                            <Post
                                key={post.id}
                                session={session}
                                post={post}
                                onDelete={() => dispatch({ type: "setPosts", value: state.posts?.filter(p => p.id !== post.id) ?? [] })}
                                onEditContent={newContent => dispatch({ type: "setPosts", value: state.posts?.map(p => p.id === post.id ? { ...p, content: newContent } : p) ?? [] })}
                            />
                        );
                    })
                }
            </div>
        </MiddleWrapper>
    );
}