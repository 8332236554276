import { ApplicationStatus } from "./Entities/ApplicationStatus";
import { Location } from "./Entities/Location";
import { Auth } from "./ResponseModels/Auth";
import { CompanyProfileResponse } from "./ResponseModels/CompanyProfileResponse";
import { UserProfileResponse } from "./ResponseModels/UserProfileResponse";
import { apiv2 } from "~base/API/v2/apiv2";

export interface Information {
  type: "error" | "information",
  message: string
}

export class Session {
  isAuth: boolean;
  companyId: string | null;
  companyProfile: CompanyProfileResponse | null;
  userProfile: UserProfileResponse | null;
  userId: string | null;
  observers: Array<any>;
  information: Information | null;
  informationObservers: Array<any>;
  data: {
    locations: Location[],
    applicationStatus: ApplicationStatus[]
  };

  /**
   * This variable decides if the app should show a loading screen or not.
   */
  isLoading: boolean;

  constructor() {
    this.isAuth = false;
    this.companyId = null;
    this.userId = null;
    this.observers = [];
    this.companyProfile = null;
    this.userProfile = null;
    this.isLoading = true;
    this.information = null;
    this.informationObservers = [];
    this.authenticate();
    this.data = {
      locations: [],
      applicationStatus: [],
    }
  }

  reset() {
    this.isAuth = false;
    this.companyId = null;
    this.userId = null;
    this.observers = [];
    this.companyProfile = null;
    this.userProfile = null;
    this.isLoading = true;
    this.information = null;
    this.informationObservers = [];
    this.notifyObservers();
  }

  /**
   * Session authentication
   * Fetch user/company profile
   * Gather necessary data for the session.
   */
  authenticate() {
    this.setIsLoading(true);

    apiv2("api/v2/get/authorization/", "GET").then((response) => {
      console.log(response);
      const data = response.data as Auth;

      this.data.locations = data.locations;
      this.data.applicationStatus = data.application_status;

      if(response.error !== null) {
        this.setInformation("error", response.error);
        return;
      }
      
      switch (data.is_auth) {

        case true:
          this.setAuth(true);

          // If it is a user
          if (data.user_id) {

            this.setUserId(String(data.user_id));

            apiv2(`api/v2/get/userProfile/${data.user_id}`, "GET").then(response => {
              if(response.error !== null) {
                this.setInformation("error", response.error);
                this.reset();
              }else {
                this.setUserProfile(response.data as UserProfileResponse);
              }
              this.setIsLoading(false);
            });
          }

          // If it is a company
          if (data.company_id) {

            this.setCompanyId(String(data.company_id));

            apiv2(`api/v2/get/companyProfile/${data.company_id}`, "GET").then(response => {
              if(response.error !== null) {
                this.setInformation("error", response.error);
                this.reset();
              }else {
                this.setCompanyProfile(response.data as CompanyProfileResponse);
              }
              this.setIsLoading(false);
            });
          }
          break;

        case false:
          this.setAuth(false);
          this.setIsLoading(false);
          break;

        default:
          this.setAuth(false);
          this.setIsLoading(false);
          this.setInformation("error", "Error.Common.CouldNotConnect")
          break;

      }
    });
  }

  addObserver(callback: any) {
    this.observers = [...this.observers, callback];
  }

  removeObserver(callback: any) {
    this.observers = this.observers.filter(
      (observer) => observer !== callback
    );
  }

  notifyObservers() {
    this.observers.forEach((observer) => {
      try {
        observer();
      } catch (e) {
        console.error(e);
      }
    });
  }

  addInformationObserver(callback: any) {
    this.informationObservers = [...this.informationObservers, callback];
  }

  removeInformationObserver(callback: any) {
    this.informationObservers = this.informationObservers.filter(
      (observer) => observer !== callback
    );
  }

  notifyInformationObservers(){
    this.informationObservers.forEach((observer) => {
      try {
        observer();
      } catch (e) {
        console.error(e);
      }
    });
  }

  setAuth(isAuth: boolean) {
    this.isAuth = isAuth;
    this.notifyObservers();
  }

  setInformation(type: string, message: string) {
    this.information = {
      type: type,
      message: message
    } as Information
    this.notifyInformationObservers();
  }

  setUserId(userId: string | null) {
    this.userId = userId;
    this.notifyObservers();
  }

  setCompanyId(companyId: string | null) {
    this.companyId = companyId;
    this.notifyObservers();
  }

  setCompanyProfile(companyProfile: CompanyProfileResponse) {
    this.companyProfile = companyProfile;
    this.notifyObservers();
  }

  setUserProfile(userProfile: UserProfileResponse) {
    this.userProfile = userProfile;
    this.notifyObservers();
  }

  setIsLoading(isLoading: boolean) {
    this.isLoading = isLoading;
    this.notifyObservers();
  }
}