import { URL } from '../URL'

export default async function loginCompany(orgNr: string, password: string): Promise<any> {
    let loginResponse = "";
    await fetch(URL + '/server/api/v1/controller/login/loginCompany.php', {
        method: 'POST',
        // dataType: 'json',
        credentials: 'include',
        body: JSON.stringify({
            orgNr: orgNr,
            password: password,
        })
    })
    .then(response => 
        response.body?.getReader().read().then(res => {
        loginResponse = new TextDecoder().decode(res.value).replaceAll('<br />','').replaceAll('<b>','').replaceAll('</b>', '');
    }))
    .catch(error => {
        console.error(error);
        loginResponse = String(error);
    });

    try {
        return JSON.parse(loginResponse);
    } catch (e) {
        return loginResponse;
    }
}