import React from "react";
import { AiOutlinePlusCircle } from "react-icons/ai";
import { createUseStyles } from "react-jss";
import { HendigoTheme } from "~base/Resources/Styling/HendigoTheme";

const useStyles = createUseStyles((theme: HendigoTheme) => ({
    wrapper: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignContent: "center",
        width: "310px",
        minHeight: "153px",
        margin: "20px 20px 0px 0px",
        border: "1px solid " + theme.border,
        borderRadius: "15px",
        '&:hover': {
            cursor: "pointer",
            backgroundColor: "rgb(128,128,128, 0.75)"
        },
    },
    icon:{
        color: theme.textSecondary,
        fontSize: "75px",
        opacity: "0.5",
        margin: "0 auto"
    }
}));

interface PlusCardProps {
    onClick?: () => void
}

export const PlusCard: React.FC<PlusCardProps> = ({onClick}): JSX.Element => {
    const classes = useStyles();

    return <div className={classes.wrapper} onClick={onClick}>
            <AiOutlinePlusCircle className={classes.icon}/>
    </div>      
}