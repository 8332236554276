import React from "react";
import { BiEdit } from "react-icons/bi";
import { BsArrowRightCircle, BsBadgeAd, BsBarChartLine } from "react-icons/bs";
import { createUseStyles } from "react-jss";
import { HendigoBaseTheme } from "../../../Resources/Styling/HendigoBaseTheme";
import { Row } from "../Cosmetic/Row";
import { HendigoTheme } from "~base/Resources/Styling/HendigoTheme";

const useStyles = createUseStyles((theme: HendigoTheme) => ({
    wrapper: {
        width: "310px",
        height: "175px",
        transition: "all ease-in-out 0.5s",
        '&:hover': {
            cursor: "pointer",
        },
        margin: "20px 20px 0px 0px",
        border: "1px solid " + theme.border,
        borderRadius: "15px",
        position: "relative",
        color: theme.textPrimary,
    },
    image: {
        width: "400px",
        height: "300px",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
    },
    ad_icon: {
        float: "right",
        fontSize: "25px",
        color: theme.blue,
        padding: "0px 2px 0px 2px",
        borderRadius: "5px",
        alignSelf: "center",
        margin: "0px 10px 0px auto"
    },
    time: {
        fontWeight: "500",
        fontSize: "12px",
        color: theme.blue,
    },
    title: {
        margin: "10px 0px 5px 0px",
        color: theme.textPrimary,
    },
    text: {
        color: theme.textSecondary,
        fontSize: "14px"
    },
    avatar: {
        height: "40px",
        width: "40px",
        alignSelf: "center",
        margin: "10px 10px 0px 10px",
        borderRadius: "100%",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center"
    },
    content: {
        margin: "0px 10px 0px 10px",
    },
    owner: {
        '&:hover': {
            cursor: "default"
        }
    },
    edit: {
        position: "absolute",
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignContent: "center",
        background: "rgb(128,128,128, 0.75)",
        borderRadius: "14px"
    },
    editRow: {
        margin: "auto 0",
        columnGap: "5px"
    },
    editIcon: {
        fontSize: "35px",
        padding: "10px",
        borderRadius: "5px",
        backgroundColor: "black",
        color: "white",
        opacity: "0.75",
        '&:hover': {
            opacity: "1",
            cursor: "pointer"
        },
        zIndex: "3"
    },
    name: {
        marginTop: "10px",
        fontWeight: "500",
    },
    ended: {
        fontWeight: "500",
        fontSize: "12px",
        color: HendigoBaseTheme.colors.hendigoPrimary,
    }
}));

interface CardProps {
    size?: "small" | "medium" | "large",
    title?: string,
    text?: string,
    ad?: boolean,
    companyName?: string,
    avatarImagePathName?: string,
    imagePathName?: string,
    className?: string,
    owner?: boolean,
    onEditClick?: () => void,
    onFurtherClick?: () => void,
    showStatistics?: boolean,
    ended?: boolean,
    time?: string,
}

export const Card: React.FC<CardProps> = (props) => {
    const classes = useStyles();

    let wrapperClass = classes.wrapper;

    if (props.owner === true) {
        wrapperClass += " " + classes.owner;
    }

    const [hover, setHover] = React.useState<boolean>(false);

    return <div
        className={wrapperClass}
        onPointerEnter={() => setHover(true)}
        onPointerLeave={() => setHover(false)}
        onClick={() => {
            if (props.owner === false && props.onFurtherClick){
                props.onFurtherClick();
            }
            setHover(!hover);
        }}
    >
        {props.owner === true && hover === true ?
            <div className={classes.edit}>
                <Row className={classes.editRow}>
                    <BiEdit onClick={props.onEditClick} className={classes.editIcon} />
                   {props.showStatistics === true ? <BsBarChartLine className={classes.editIcon}/> : null}
                    <BsArrowRightCircle onClick={props.onFurtherClick} className={classes.editIcon} />
                </Row>
            </div>
            : null}
        <Row>
            <div className={classes.avatar} style={{backgroundImage: "url(" + props.avatarImagePathName + ")"}} />
            <div>
                <div className={classes.name}>{props.companyName}</div>
                {props.ended === true ? <div className={classes.ended}>Avslutad</div> : <div className={classes.time}>{props.time}</div>}
            </div>
            {props.ad ? <BsBadgeAd className={classes.ad_icon} /> : <></>}
        </Row>
        {props.ad ? <></> :
            props.imagePathName ?
                <div
                    className={classes.image}
                    style={{
                        backgroundImage: "url('" + props.imagePathName + "')"
                    }}
                /> : <></>
        }
        <div className={classes.content}>
            <h3 className={classes.title}>{props.title}</h3>
            <p className={classes.text}>
                {(props.ad === true ? props.text?.substring(0, 100) : props.text?.substring(0, 200)) + "..."}
            </p>
        </div>
    </div>
}