import React from "react";
import { ViewBaseProps } from "~base/Model/ViewBaseProps";
import { CreateAdStyles } from "./Styling";
import { createAdReducer, initialCreateAdState } from "./Store";
import { EnterInformation } from "./ChildComponents/EnterInformation";
import { PreviewAd } from "./ChildComponents/PreviewAd";
import { CreateAd as CreateAdView } from "./ChildComponents/CreateAd";
import { Box, Step, StepLabel, Stepper } from "@mui/material";
import { CreateAdSteps } from "./Types";
import { Payment } from "./ChildComponents/Payment";
import { getAdProducts } from "./Fetch";
import { useIntl } from "~base/Internalization/IntlContext";

export const CreateAd: React.FC<ViewBaseProps> = ({ session }) => {
    document.title = "Skapa annons | HendiGO";

    const intl = useIntl();

    const classes = CreateAdStyles();
    const [state, dispatch] = React.useReducer(createAdReducer, initialCreateAdState(session));

    const [steps, setSteps] = React.useState<CreateAdSteps[]>(
        [
            "Views.CreateAd.Step.Information",
            "Views.CreateAd.Step.Preview",
            "Views.CreateAd.Step.Create"
        ]
    );

    React.useEffect(() => {
        getAdProducts(dispatch, session);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    React.useEffect(() => {
        if(state.invoice.amount > 0) {
            setSteps([
                "Views.CreateAd.Step.Information",
                "Views.CreateAd.Step.Preview",
                "Views.CreateAd.Step.Payment",
                "Views.CreateAd.Step.Create",
            ]);
        } else {
            setSteps([
                "Views.CreateAd.Step.Information",
                "Views.CreateAd.Step.Preview",
                "Views.CreateAd.Step.Create",
            ]);
        }
    }, [state.invoice.amount]);

    window.dispState = state;

    return (
        <div className={classes.wrapper}>

            <Box className={classes.steps} >
                <Stepper activeStep={steps.indexOf(state.currentStep)} alternativeLabel>
                    {steps.map((label, index) => (
                        <Step
                            key={label}
                            className={index < steps.indexOf(state.currentStep) ? classes.stepCompleted : classes.step}
                            onClick={() => {
                                if (index < steps.indexOf(state.currentStep)) {
                                    dispatch({ type: "setCurrentStep", value: label as CreateAdSteps })
                                }
                            }}
                        >
                            <StepLabel>{intl.formatText(label)}</StepLabel>
                        </Step>
                    ))}
                </Stepper>
            </Box>

            {state.currentStep === "Views.CreateAd.Step.Information" ? <EnterInformation session={session} state={state} dispatch={dispatch} /> : null}

            {state.currentStep === "Views.CreateAd.Step.Preview" ? <PreviewAd session={session} state={state} dispatch={dispatch} /> : null}

            {state.currentStep === "Views.CreateAd.Step.Payment" ? <Payment state={state} dispatch={dispatch} /> : null}

            {state.currentStep === "Views.CreateAd.Step.Create" ? <CreateAdView state={state} dispatch={dispatch} session={session} /> : null}

        </div>
    )
}