// ----------------------------------------------------
// Imports
// ----------------------------------------------------

import React from "react";
import { Overlay } from "~base/Components/Elements/Cosmetic/Overlay";
import { useIntl } from "~base/Internalization/IntlContext";

// ----------------------------------------------------
// Styling
// ----------------------------------------------------

// ----------------------------------------------------
// Props
// ----------------------------------------------------

interface EditAdOverlayProps {
    //state: CompanyViewState,
    onCancel: () => void,
    ///dispatch: React.Dispatch<>
    onError: (error: string) => void
    show: boolean,
}

// ----------------------------------------------------
// Component
// ----------------------------------------------------

export const EditAdOverlay: React.FC<EditAdOverlayProps> = ({ onCancel, onError, show }) => {
    //const classes = useStyles();

    const intl = useIntl();

    return <Overlay
        show={show}
        title={intl.formatText("Views.ManageAds.EditAdOverlay.EditAd")}
        onCancel={onCancel}
        onSave={() => {
           
        }}
    >

    </Overlay>
}