import { formatText } from "./formatText";

export function formatTimeShort(date: Date, language: "sv" | "en") {
  var seconds = Math.floor((Number(new Date()) - Number(date)) / 1000);

  var interval = seconds / 31536000;

  if (interval > 1) {
    return Math.floor(interval) + formatText("FormatTime.Year", language);
  }
  interval = seconds / 2592000;
  if (interval > 1) {
    return Math.floor(interval) + formatText("FormatTime.Month", language);
  }
  interval = seconds / 86400;
  if (interval > 1) {
    return Math.floor(interval) + formatText("FormatTime.Day", language);
  }
  interval = seconds / 3600;
  if (interval > 1) {
    return Math.floor(interval) + formatText("FormatTime.Hour", language);
  }
  interval = seconds / 60;
  if (interval > 1) {
    return Math.floor(interval) + formatText("FormatTime.Minute", language);
  }
  return Math.floor(seconds) + formatText("FormatTime.Second", language);
}

export function formatTimeLong(time: Date, language: "sv" | "en") {
  const date = new Date(time);
  const now = new Date();
  const diff = now.getTime() - date.getTime();

  if (diff < 1000 * 60 * 60) {
    return Math.floor(diff / (1000 * 60)) + formatText("FormatTime.MinuteAgo", language);
  } else if (diff < 1000 * 60 * 60 * 24) {
    return Math.floor(diff / (1000 * 60 * 60)) + formatText("FormatTime.HourAgo", language);
  } else if (diff < 1000 * 60 * 60 * 24 * 7) {
    return Math.floor(diff / (1000 * 60 * 60 * 24)) + formatText("FormatTime.DayAgo", language);
  } else if (diff < 1000 * 60 * 60 * 24 * 7 * 4) {
    return Math.floor(diff / (1000 * 60 * 60 * 24 * 7)) + formatText("FormatTime.WeekAgo", language);
  } else {
    return Math.floor(diff / (1000 * 60 * 60 * 24 * 7 * 4)) + formatText("FormatTime.MonthAgo", language);
  }
}

export function formatDateShort(date: Date | string, language: "sv" | "en") {
  if (typeof date === "string") date = new Date(date);

  const monthString: string = getMonthString(date.getMonth(), false, language);

  return `${monthString} ${date.getFullYear()}`;
}

export function formatDateLong(date: Date | string, language: "sv" | "en") {
  if (typeof date === "string") date = new Date(date);

  const monthString: string = getMonthString(date.getMonth(), true, language);

  return `${date.getDate()} ${monthString} ${date.getFullYear()}`;
}

export function getMonthString(month: number, long: boolean, language: "sv" | "en") {
  const extension: string = long ? ".Long" : ".Short";
  switch (month) {
    case 0:
      return formatText(`January${extension}`, language);
    case 1:
      return formatText(`February${extension}`, language);
    case 2:
      return formatText(`March${extension}`, language);
    case 3:
      return formatText(`April${extension}`, language);
    case 4:
      return formatText(`May${extension}`, language);
    case 5:
      return formatText(`June${extension}`, language);
    case 6:
      return formatText(`July${extension}`, language);
    case 7:
      return formatText(`August${extension}`, language);
    case 8:
      return formatText(`September${extension}`, language);
    case 9:
      return formatText(`October${extension}`, language);
    case 10:
      return formatText(`November${extension}`, language);
    case 11:
      return formatText(`December${extension}`, language);
    default:
      return "";
  }
}