import { createUseStyles } from "react-jss"

const useStyles = createUseStyles({
    wrapper: {
        display: "flex",
        flexDirection: "column",
        width: "58%",
        minHeight: "88vh",
        padding: "1%",
        paddingTop: "0"
    },
    '@media screen and (max-width: 1100px)': {
        wrapper: {
          width: '100%',
          margin: '0',
        }
    }
});

interface MiddleWrapperProps {
    children?: React.ReactNode,
    style?: React.CSSProperties
}

export const MiddleWrapper: React.FC<MiddleWrapperProps> = (props): JSX.Element => {
    const classes = useStyles();

    return <div className={classes.wrapper} style={props.style}>
        {props.children}
    </div>
}