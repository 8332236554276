import { TextField } from "@mui/material";
import { RegisterAction } from "../Store";
import { RegisterViewState } from "../Types";
import { isEmail, isOrgNr, isPassword } from "~base/Internalization/Validation";
import Grid2 from "@mui/material/Unstable_Grid2";
import { muiStyles } from "~base/Resources/Styling/MuiTheme";
import { useIntl } from "~base/Internalization/IntlContext";

interface RegisterCompanyProps {
    state: RegisterViewState,
    dispatch: React.Dispatch<RegisterAction>
}

export const RegisterCompany: React.FC<RegisterCompanyProps> = ({ state, dispatch }) => {

    const intl = useIntl();
    const muiClasses = muiStyles();

    return (
        <Grid2 container spacing={2}>
            <Grid2 xs={12}>
                <TextField
                    onChange={(e) => {
                        if (e.target.value.length >= 0 && e.target.value.length <= 10) {
                            dispatch({ type: "setOrgNumber", value: e.target.value });
                        }
                    }}
                    error={!isOrgNr(state.orgNumber) && state.orgNumber.length > 0}
                    helperText={!isOrgNr(state.orgNumber) && state.orgNumber.length > 0 ? intl.formatText("Views.Register.InvalidOrgNumber") : ""}
                    value={state.orgNumber}
                    label={intl.formatText("Views.Register.OrganizationNumber")}
                    placeholder={"ex. 5555555555"}
                    fullWidth
                    className={muiClasses.textField}
                    variant="filled"
                />
            </Grid2>

            <Grid2 xs={12}>
                <TextField
                    onChange={(e) => dispatch({ type: "setCompanyName", value: e.target.value })}
                    error={state.companyName.length < 3 && state.companyName.length > 0}
                    helperText={state.companyName.length < 3 && state.companyName.length > 0 ? intl.formatText("Views.Register.InvalidCompanyName") : ""}
                    value={state.companyName}
                    label={intl.formatText("Views.Register.CompanyName")}
                    placeholder={"ex. HendiGO AB"}
                    fullWidth
                    className={muiClasses.textField}
                    variant="filled"
                />
            </Grid2>

            <Grid2 xs={12}>
                <TextField
                    onChange={(e) => dispatch({ type: "setEmail", value: e.target.value })}
                    value={state.email}
                    error={!isEmail(state.email) && state.email.length > 0}
                    label={intl.formatText("Views.Register.Email")}
                    placeholder="ex. fredrik@hendigo.se"
                    fullWidth
                    type="email"
                    className={muiClasses.textField}
                    variant="filled"
                />
            </Grid2>

            <Grid2 xs={6}>
                <TextField
                    onChange={(e) => dispatch({ type: "setPassword", value: e.target.value })}
                    value={state.password}
                    label={intl.formatText("Views.Register.Password")}
                    type="password"
                    error={!isPassword(state.password) && state.password.length > 0}
                    helperText={!isPassword(state.password) && state.password.length > 0 ? intl.formatText("Views.Register.PasswordMinLength") : ""}
                    fullWidth
                    className={muiClasses.textField}
                    variant="filled"
                />
            </Grid2>

            <Grid2 xs={6}>
                <TextField
                    onChange={(e) => dispatch({ type: "setPasswordConfirm", value: e.target.value })}
                    value={state.passwordConfirm}
                    error={state.password !== state.passwordConfirm && state.passwordConfirm.length > 0}
                    helperText={state.password !== state.passwordConfirm && state.passwordConfirm.length > 0 ? intl.formatText("Views.Register.PasswordDoesNotMatch") : ""}
                    label={intl.formatText("Views.Register.ConfirmPassword")}
                    type="password"
                    fullWidth
                    className={muiClasses.textField}
                    variant="filled"
                />
            </Grid2>
        </Grid2>
    );
}