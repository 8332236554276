// ----------------------------------------------------
// Imports
// ----------------------------------------------------

import React from "react";
import { createUseStyles } from "react-jss"
import { Session } from "~base/Model/Session";
import { HendigoBaseTheme } from "~base/Resources/Styling/HendigoBaseTheme";
import { Overlay } from "~base/Components/Elements/Cosmetic/Overlay";
import { apiv2 } from "~base/API/v2/apiv2";
import { TextField, Typography } from "@mui/material";
import { muiStyles } from "~base/Resources/Styling/MuiTheme";
import { useIntl } from "~base/Internalization/IntlContext";

// ----------------------------------------------------
// Styling
// ----------------------------------------------------

const useStyles = createUseStyles({
    container: {
        display: "flex",
        flexDirection: "column",
        rowGap: "20px",
    },
});

// ----------------------------------------------------
// Props
// ----------------------------------------------------

interface CreateGroupOverlayProps {
    onCancel: () => void,
    session: Session,
    showOverlay: boolean,
}

// ----------------------------------------------------
// Component
// ----------------------------------------------------

export const CreateGroupOverlay: React.FC<CreateGroupOverlayProps> = ({ onCancel, session, showOverlay }) => {

    const intl = useIntl();
    const classes = useStyles();

    const MIN_NAME_LENGTH = 3;
    const MAX_NAME_LENGTH = 500;
    const MIN_DESCRIPTION_LENGTH = 3;
    const MAX_DESCRIPTION_LENGTH = 2000;

    const muiClasses = muiStyles();

    const [name, setName] = React.useState<string>("");
    const [description, setDescription] = React.useState<string>("");

    const [nameValid, setNameValid] = React.useState<boolean>(false);
    const [descriptionValid, setDescriptionValid] = React.useState<boolean>(false);

    React.useEffect(() => {
        setNameValid(name.length > MIN_NAME_LENGTH && name.length < MAX_NAME_LENGTH);
        setDescriptionValid(description.length > MIN_DESCRIPTION_LENGTH && description.length < MAX_DESCRIPTION_LENGTH);
    }, [name, description]);

    React.useEffect(() => {
        setName("");
        setDescription("");
    }, [showOverlay]);

    return (
        <Overlay
            show={showOverlay}
            title={intl.formatText("Views.Groups.CreateGroupOverlay.Title")}
            onCancel={onCancel}
            cssWidth="600px"
            onSave={() => {
                if (nameValid && descriptionValid) {
                    apiv2("api/v2/post/userGroup", "POST", {
                        name: name,
                        description: description,
                    }).then(response => {
                        if (response.error != null) {
                            session.setInformation("error", response.error);
                        } else {
                            onCancel();
                            window.location.reload();
                        }
                    });
                } else {
                    session.setInformation("error", intl.formatText("Views.Company.EditCompanyOverlays.CheckCorrection"));
                }
            }}
        >
            <div className={classes.container}>
                <Typography variant="body1">
                    {intl.formatText("Views.Groups.CreateGroupOverlay.DescriptionText")}
                </Typography>
                <TextField
                    label={intl.formatText("Views.Groups.CreateGroupOverlay.Name")}
                    variant="outlined"
                    value={name}
                    onChange={value => setName(value.target.value)}
                    error={!nameValid && name.length > 0}
                    fullWidth={true}
                    className={muiClasses.textField}
                />

                <TextField
                    label={intl.formatText("Views.Groups.CreateGroupOverlay.Description")}
                    variant="outlined"
                    value={description}
                    onChange={value => {
                        if (value.target.value.length <= MAX_DESCRIPTION_LENGTH)
                            setDescription(value.target.value)
                    }}
                    error={!descriptionValid && description.length > 0}
                    fullWidth={true}
                    className={muiClasses.textField}
                    multiline={true}
                    rows={5}
                />
            </div>
        </Overlay>
    );
}