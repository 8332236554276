import React from "react"
import { createUseStyles } from "react-jss"
import { HendigoTheme } from "~base/Resources/Styling/HendigoTheme"
import { HendigoBaseTheme } from "../../../Resources/Styling/HendigoBaseTheme"
import { Input } from "./Input"

const useStyles = createUseStyles((theme: HendigoTheme) => {
    return {
        alternativeList: {
            overflowY: "scroll",
            maxHeight: "200px",
            backgroundColor: "#D9DAF9",
            opacity: "0.8",
            position: "absolute",
            zIndex: "2",
            borderRadius: "5px",
            padding: "5px",
        },
        alternative: {
            padding: "2px",
            borderRadius: "5px",
            '&:hover': {
                backgroundColor: HendigoBaseTheme.colors.hendigoBlue,
                color: "white",
                cursor: "pointer"
            }
        },
        title: {
            fontWeight: "bold",
            marginBottom: "4px",
            color: theme.textPrimary
        }
    }
})

interface InputAlternativeProps {
    value: string,
    setValue: (value: string) => void
    alternativeList?: string[],
    cssWidth?: string,
    placeholder?: string,
    valid?: boolean,
    //onAlternativeClick?: (alternative: string) => void,
    error?: string,
    title?: string,
    alternativeListClassName?: string,
    alternativeClassName?: string,
    titleClassName?: string,
    marginTop?: string
}

export const InputAlternative: React.FC<InputAlternativeProps> = ({ marginTop = "10px", titleClassName, title, value, setValue, alternativeList = [], cssWidth , placeholder = "", valid = true, error = "", alternativeListClassName, alternativeClassName }) => {

    const classes = useStyles();

    const alternativeListWidth = !cssWidth?.includes("%") ? String(Number(cssWidth?.replace("px", "")) + 15) + "px" : String(Number(cssWidth?.replace("%", "")) - 9) + "%";

    return <div style={{ width: cssWidth, marginTop: marginTop }}>
        <div className={classes.title}>{title}</div>
        <Input
            value={value}
            onChange={(e) => setValue(e.target.value)}
            cssWidth={cssWidth}
            placeholder={placeholder}
            type={"text"}
            valid={valid}
            error={error}
            titleClassName={titleClassName}
        />
        {value?.length > 0 && !alternativeList.includes(value) && alternativeList.filter((alternative) => alternative.toLowerCase().includes(value.toLowerCase())).length > 0 ?
            <div
                className={classes.alternativeList + " " + alternativeListClassName}
                style={{ width: alternativeListWidth }}
            >
                {alternativeList.filter((alternative) => alternative.toLowerCase().includes(value.toLowerCase())).map((alternative, index) => {
                    return <div 
                        key={"alternative" + alternative + index} 
                        className={classes.alternative + " " + alternativeClassName} 
                        onClick={() => setValue(alternative)}>
                        {alternative}
                    </div>
                })}
            </div> : null}
    </div>
}