import { createUseStyles } from "react-jss";
import { HendigoBaseTheme } from "~base/Resources/Styling/HendigoBaseTheme";
import { HendigoTheme } from "~base/Resources/Styling/HendigoTheme";

export const FeedStyles = createUseStyles((theme: HendigoTheme) => {
    return {
        wrapper: {
        },
        poster: {
            borderRadius: "10px",
            padding: "10px",
            backgroundColor: theme.backgroundSecondary,
            color: theme.textPrimary,
            //border: "1px solid " + theme.border
        },
        poster_avatar: {
            height: "50px !important",
            width: "50px !important",
            marginRight: "10px",
            textDecoration: "none",
            '&:hover': {
                cursor: "pointer"
            }
        },
        poster_author: {
            fontWeight: "500",
            '&:hover': {
                cursor: "pointer",
                textDecoration: "underline"
            }
        },
        posterTextareaWrapper: {
            margin: "10px 0px",
            width: "100%",
        },
        poster_icon: {
            fontSize: "35px",
            '&:hover': {
                cursor: "pointer",
            },
            margin: "0px 10px 0px 10px"
        },
        textLimit: {
            fontWeight: "bold",
            margin: "0px 10px 0px auto",
            color: "lightgray"
        },
    }
});