import { 
    Box, 
    Drawer, 
    FormControl, 
    FormControlLabel, 
    InputLabel, 
    List, 
    ListItem, 
    ListItemButton, 
    ListItemIcon, 
    ListItemText, 
    MenuItem, 
    Select, 
    Switch 
} from "@mui/material"
import { ViewState } from "../Types"
import { Actions } from "../Store";
import { AiOutlineHome, } from "react-icons/ai";
import { languageCodes } from "~base/Internalization/formatText";
import { Divider } from "~base/Components/Elements/Cosmetic/Divider";
import { useNavigate } from "react-router-dom";
import { UserMenu } from "./UserMenu";
import { BsArrowRight, BsX } from "react-icons/bs";
import { CompanyMenu } from "./CompanyMenu";
import { logout } from "../Fetch";
import { Session } from "~base/Model/Session";
import { SearchBar } from "./SearchBar";
import React from "react";
import { useTheme } from "react-jss";
import { HendigoTheme } from "~base/Resources/Styling/HendigoTheme";
import { useIntl } from "~base/Internalization/IntlContext";

interface NavigationDrawerProps {
    state: ViewState;
    dispatch: React.Dispatch<Actions>;
    classes: any;
    session: Session;
}

export const NavigationDrawer: React.FC<NavigationDrawerProps> = ({ state, dispatch, classes, session }) => {
    const navigate = useNavigate();
    const theme: HendigoTheme = useTheme();
    const intl = useIntl();

    const [searchValue, setSearchValue] = React.useState("");

    function handleClick(route: string | null) {
        dispatch({ type: "setNavigationDrawerOpen", value: false });
        if (route) {
            navigate(route);
        }
    }

    function handleLogout() {
        dispatch({ type: "setNavigationDrawerOpen", value: false });
        logout(session, intl, navigate);
    }

    function handleSearch() {
        dispatch({ type: "setNavigationDrawerOpen", value: false });
        if(searchValue.length > 0) navigate('/resultat?s=' + searchValue);
    }

    return <Drawer
        anchor={"right"}
        open={state.navigationDrawerOpen}
        onClose={() => dispatch({ type: "setNavigationDrawerOpen", value: false })}
    >
        <Box
            role="presentation"
            className={classes.drawerBox}
        >
            <List>
                <ListItem onClick={() => handleClick(null)} disablePadding>
                    <ListItemButton>
                        <ListItemIcon>
                            <BsX />
                        </ListItemIcon>
                    </ListItemButton>
                </ListItem>

                {(state.userProfile || state.companyProfile) && <ListItem>
                    <SearchBar
                        value={searchValue}
                        maxWidth="100%"
                        onChange={value => setSearchValue(value)}
                        placeholder={intl.formatText("View.Navbar.SearchBar.Placeholder")}
                        onSearch={() => handleSearch()}
                    />
                </ListItem>}

                <ListItem key={"Home"} onClick={() => handleClick("/")} disablePadding>
                    <ListItemButton >
                        <ListItemText primary={intl.formatText("Views.SideNavBar.Home")} /><ListItemIcon>
                            <AiOutlineHome />
                        </ListItemIcon>
                    </ListItemButton>
                </ListItem>

                {state.userProfile &&
                    <UserMenu handleClick={handleClick} userProfile={state.userProfile} handleLogout={handleLogout} />
                }

                {state.companyProfile &&
                    <CompanyMenu handleClick={handleClick} companyProfile={state.companyProfile} handleLogout={handleLogout} />
                }

                <Divider style={{ margin: "10px 0px" }} />

                <ListItem onClick={() => handleClick("/jobb")} disablePadding>
                    <ListItemButton>
                        <ListItemText primary={intl.formatText("Views.SideNavBar.Work")} />
                        <ListItemIcon>
                            <BsArrowRight />
                        </ListItemIcon>
                    </ListItemButton>
                </ListItem>

                <ListItem onClick={() => handleClick("/services")} disablePadding>
                    <ListItemButton>
                        <ListItemText primary={intl.formatText("Views.Navbar.NavigationDrawer.Services")} />
                        <ListItemIcon>
                            <BsArrowRight />
                        </ListItemIcon>
                    </ListItemButton>
                </ListItem>

                <ListItem onClick={() => handleClick("/pricing")} disablePadding>
                    <ListItemButton>
                        <ListItemText primary={intl.formatText("Views.Navbar.NavigationDrawer.Pricing")} />
                        <ListItemIcon>
                            <BsArrowRight />
                        </ListItemIcon>
                    </ListItemButton>
                </ListItem>
            </List>

            <List>
                <Divider style={{ marginBottom: "10px" }} />

                <ListItem key={"Home"} onClick={() => null} style={{ marginBottom: "10px" }}>
                    {intl.formatText("Views.Navbar.NavigationDrawer.Settings")}
                </ListItem>

                <ListItem key={"Language"}>
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Language</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={intl.language}
                            label="Language"
                            onChange={(val) => intl.setLanguage(val.target.value as languageCodes)}
                        >
                            <MenuItem value={"sv"}>Svenska</MenuItem>
                            <MenuItem value={"en"}>English</MenuItem>
                        </Select>
                    </FormControl>
                </ListItem>

                <ListItem key={"DarkMode"}>
                    <FormControlLabel
                        control={
                            <Switch
                                value={theme.darkMode}
                                onChange={(e) => intl.styling.setDarkMode(e.target.checked)}
                                defaultChecked={theme.darkMode}
                            />
                        }
                        label={intl.formatText("Views.Navbar.NavigationDrawer.DarkMode")}
                    />
                </ListItem>
            </List>
        </Box>
    </Drawer>
}