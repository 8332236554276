import React from "react";
import { FcAddImage } from "react-icons/fc";
import { MdDeleteOutline } from "react-icons/md";
import { createUseStyles } from "react-jss";
import { HendigoTheme } from "~base/Resources/Styling/HendigoTheme";

const useStyles = createUseStyles((theme: HendigoTheme) => ({
    wrapper: {
        borderRadius: "5px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        '&:hover': {
            cursor: "pointer",
            background: theme.backgroundPrimary
        },
        '&:hover $deleteIcon': {
            display: "block",
        }
    },
    wrapperImageURL: {
        background: "#222",
        '&:hover': {
            cursor: "pointer",
            background: "#222",
            opacity: "0.3"
        }
    },
    icon: {
        fontSize: "30px",
        color: theme.textPrimary,
    },
    deleteIcon: {
        display: "none",
        position: "absolute",
        fontSize: "40px",
        color: "#222",
    },
    image: {
    },
    imagesRow: {
        display: "flex",
        margin: "10px 0px",
        gap: "5px",
    }
}));

interface AddImagesRowProps {
    files: File[];
    setFiles: (files: File[]) => void;
    limit?: number;
}

export const AddImagesRow: React.FC<AddImagesRowProps> = (props) => {

    const {
        files,
        setFiles,
        limit = 3,
    } = props;

    const classes = useStyles();

    const input = React.useRef<HTMLInputElement>(null);

    return (
        <div className={classes.imagesRow}>

            {files.map((file, index) => {
                return (
                    <div
                        key={`file-${index}`}
                        className={classes.wrapperImageURL + " " + classes.wrapper}
                        style={{
                            height: "100px",
                            width: "100px",
                        }}
                        onClick={() => {
                            //etImageURLs(imageURLs.filter((_, i) => i !== index));
                            setFiles(files.filter((_, i) => i !== index));
                        }}
                    >
                        <>
                            <MdDeleteOutline className={classes.deleteIcon} />
                            <img
                                src={URL.createObjectURL(file)}
                                className={classes.image}
                                style={{
                                    maxWidth: "100px",
                                    maxHeight: "100px",
                                }}
                            />
                        </>
                    </div>
                );
            })}

            {files.length < limit && <div
                className={classes.wrapper}
                style={{
                    height: files.length === 0 ? "50px" : "100px",
                    width: files.length === 0 ? "50px" : "100px",
                }}
                onClick={() => {
                    input.current?.click();
                }}
            >
                <FcAddImage className={classes.icon} />
            </div>}

            <input
                onChange={e => {
                    //setImageURLs([...imageURLs, URL.createObjectURL(e.target.files![0])]);
                    setFiles([...files, e.target.files![0]]);
                }}
                ref={input}
                type="file"
                accept={"image/*"}
                style={{ display: "none" }}
            />
        </div>
    );
}