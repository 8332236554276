import React from "react";
import { createUseStyles } from "react-jss"
import { HendigoBaseTheme } from "../../../Resources/Styling/HendigoBaseTheme";

const useStyles = createUseStyles({
    outer: {
        width: "100%",
        backgroundColor: HendigoBaseTheme.colors.hendigoDark,
        opacity: 0.75,
        borderRadius: "5px"
    },
    inner: {
        height: "100%",
        backgroundColor: HendigoBaseTheme.colors.hendigoBlue,
        transition: "width ease-in-out 0.5s",
    }
});

interface ProgressBarProps {
    cssHeight?: string,
    progress: number,
}

export const ProgressBar: React.FC<ProgressBarProps> = ({cssHeight = "15px", progress}) => {
    const classes = useStyles();

    return <div className={classes.outer} style={{height: cssHeight}}>
        <div className={classes.inner} style={{width: String(progress) + "%", borderRadius: (progress >= 100 ? "5px" : "5px 0px 0px 5px")}}></div>
    </div>
}