import React from "react";
import { apiv2 } from "~base/API/v2/apiv2"
import { PostResponse } from "~base/Model/ResponseModels/PostResponse";
import { Session } from "~base/Model/Session";
import { FeedAction } from "./Store";
import { Post } from "~base/Model/Entities/Post";
import { FeedResponse } from "~base/Model/ResponseModels/FeedResponse";
import fileAPIv2 from "~base/API/v2/fileAPIv2";
import { PostImage } from "~base/Model/Entities/PostImage";

/**
 * Get feed posts.
 * @param dispatch 
 */
export function getFeed(dispatch: React.Dispatch<FeedAction>) {
    apiv2("api/v2/get/feed/", "GET").then((response) => {
        console.log("feed", response.data as FeedResponse)
        if (response.error != null) {
            console.log(response.error);
        } else {
            dispatch({ type: "setFeed", posts: response.data.posts, ads: response.data.ads });
        }
    });
}

export function addPost(content: string, session: Session, dispatch: React.Dispatch<FeedAction>, files: File[]) {
    apiv2("api/v2/post/post", "POST", { content: content }).then((response) => {
        if (response.error != null) {
            session.setInformation("error", response.error);
        } else {
            const post = response.data as Post;

            const newPost = {
                ...post,
                name: session.userProfile?.fullname ?? session.companyProfile?.company_name,
                small_image_pathname: session.userProfile?.small_image_pathname ?? session.companyProfile?.small_image_pathname,
                liked: false,
                time_created: new Date().toLocaleString(),
                number_of_likes: 0,
                number_of_comments: 0,
                ad: false,
            } as PostResponse;

            if(files.length > 0){
                fileAPIv2(files, "upload/images/post").then((response) => {
                    if(response.error != null){
                        session.setInformation("error", response.error);
                    } else {
                        const images = response.data as PostImage[];

                        newPost.images = images;
            
                        dispatch({ type: "addPost", value: newPost });
                    }
                });
            } else {
                dispatch({ type: "addPost", value: newPost });
            }

        }
    });
}
