import React from 'react';
import { useRoutes, useLocation, useNavigate, RouteObject } from 'react-router-dom';
import { ViewBaseProps } from '../Model/ViewBaseProps';
import { getBaseRoutes } from './BaseRoutes';
import { getSecureBaseRoutes, getSecureCompanyRoutes, getSecureUserRoutes } from './SecureRoutes';
import * as PATHS from './Paths';

interface HendigoRoutingProps {
    viewBaseProps: ViewBaseProps,
    setShow401: (show: boolean) => void;
}

export const HendigoRouting: React.FC<HendigoRoutingProps> = ({ viewBaseProps, setShow401 }) => {
    const location = useLocation();
    const navigate = useNavigate();

    //const [routes, setRoutes] = React.useState<RouteObject[]>(getBaseRoutes(viewBaseProps));

    const secureUserRoutes = getSecureUserRoutes(viewBaseProps);
    const secureCompanyRoutes = getSecureCompanyRoutes(viewBaseProps);
    const secureBaseRoutes = getSecureBaseRoutes(viewBaseProps);

    const isAuth = viewBaseProps.session.isAuth;
    const userProfile = viewBaseProps.session.userProfile;
    const companyProfile = viewBaseProps.session.companyProfile;

    let routes: RouteObject[] = getBaseRoutes(viewBaseProps);
    
    /**
     * Special cases
    */
    React.useEffect(() => {
        if (isAuth === false && location.pathname === PATHS.FEED_PATH) {
            navigate(PATHS.HOME_PATH);
        } else if (isAuth && location.pathname === PATHS.LOGIN_PATH) { // If logged in and on login page, redirect to feed
            navigate(PATHS.FEED_PATH);
        } else if (isAuth && location.pathname === PATHS.REGISTER_PATH) { // If logged in and on register page, redirect to feed
            navigate(PATHS.FEED_PATH);
        }
    }, [isAuth, location.pathname]);
    
    /**
     * Set up routes
     */
    if (userProfile != null) {
        //setRoutes(r => [...r, ...secureUserRoutes, ...secureBaseRoutes]);
        routes = [...routes, ...secureUserRoutes, ...secureBaseRoutes];
    }

    if (companyProfile != null) {
        //setRoutes(r => [...r, ...secureCompanyRoutes, ...secureBaseRoutes]);
        routes = [...routes, ...secureCompanyRoutes, ...secureBaseRoutes];
    }

    // 1. If not logged in as a user and trying to access a secure user route
    // 2. If logged in as a company and trying to access a secure user route, show 401
    // 3. Else redirect to login
    if (
        userProfile == null &&
        (
            secureUserRoutes.some(route => basePathNameIsEqualToLocationPathName(route.path ?? "", location.pathname)) ||
            secureUserRoutes.some(route => route.children?.some(child => basePathNameIsEqualToLocationPathName(child.path ?? "", location.pathname)))
        )
    ) {
        if (companyProfile) {
            setShow401(true);
        } else {
            navigate(PATHS.LOGIN_PATH, { state: { redirect: location.pathname } });
            setShow401(false);
        }
    }

    // 1. If not logged in as a company and trying to access a secure company route
    // 2. If logged in as a user and trying to access a secure company route, show 401
    // 3. Else redirect to login
    if (
        companyProfile == null &&
        (
            secureCompanyRoutes.some(route => basePathNameIsEqualToLocationPathName(route.path ?? "", location.pathname)) ||
            secureCompanyRoutes.some(route => route.children?.some(child => basePathNameIsEqualToLocationPathName(child.path ?? "", location.pathname)))
        )
    ) {
        if (userProfile) {
            setShow401(true);
        } else {
            navigate(PATHS.LOGIN_PATH, { state: { redirect: location.pathname } });
            setShow401(false);
        }
    }

    if (
        !isAuth &&
        (
            secureBaseRoutes.some(route => basePathNameIsEqualToLocationPathName(route.path ?? "", location.pathname)) ||
            secureBaseRoutes.some(route => route.children?.some(child => basePathNameIsEqualToLocationPathName(child.path ?? "", location.pathname)))
        )
    ) {
        navigate(PATHS.LOGIN_PATH, { state: { redirect: location.pathname } });
        setShow401(false);
    }

    return useRoutes(routes);
}

function basePathNameIsEqualToLocationPathName(basePathName: string, locationPathName: string) {
    if (basePathName.includes(":id")) {
        const startIndex = basePathName.indexOf("/:id");
        const lastIndex = locationPathName.lastIndexOf("/");

        if (startIndex < lastIndex) {
            return basePathName.replace("/:id", "") === locationPathName.substring(0, startIndex) + locationPathName.substring(lastIndex);
        } else {
            return basePathName.replace("/:id", "") === locationPathName.substring(0, startIndex);
        }
    } else {
        return basePathName === locationPathName;
    }
}