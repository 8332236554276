// ----------------------------------------------------
// Imports
// ----------------------------------------------------

import { AdResponse } from "~base/Model/ResponseModels/AdResponse";
import { Session } from "../../../Model/Session";
import { Counties } from "../../../Resources/Data/Counties";
import { ViewState } from "./Types";

// ----------------------------------------------------
// Actions
// ----------------------------------------------------

type setSearchValue = { type: "setSearchValue", value: string }
type setLocationId = { type: "setLocationId", value: number }
type setLocationError = { type: "setLocationError", value: string }
type setLoading = { type: "setLoading", value: boolean }
type setAds = { type: "setAds", value: AdResponse[] }
type setPage = { type: "setPage", value: number }

export type Actions = setSearchValue | setLocationId | setLocationError | setLoading | setAds | setPage;

// ----------------------------------------------------
// Reducer
// ----------------------------------------------------

export const reducer = (state: ViewState, action: Actions): ViewState => {
    switch(action.type){
        case "setSearchValue": {
            return {
                ...state,
                searchValue: action.value
            }
        }

        case "setLocationId": {
            return {
                ...state,
                locationId: action.value,
                locationValid: true,
                locationError: ""
            }
        }

        case "setLocationError": {
            return {
                ...state,
                locationError: action.value
            }
        }

        case "setLoading": {
            return {
                ...state,
                loading: action.value
            }
        }

        case "setAds": {
            return {
                ...state,
                ads: action.value
            }
        }

        case "setPage": {
            return {
                ...state,
                page: action.value
            }
        }

        default:
            return {
                ...state
            }
    }
}

// ----------------------------------------------------
// Initial State
// ----------------------------------------------------

export const initialState = (session: Session, searchValue: string, locationId: number, page: number): ViewState => {
    return {
        searchValue: searchValue !== "null" ? searchValue : "",
        locationValid: true,
        locationError: "",
        loading: false,
        ads: [],
        locationId: locationId,
        page: page,
        pageSize: 2
    }
}