// ----------------------------------------------------
// Imports
// ----------------------------------------------------

import { Session } from "~base/Model/Session";
import { UserAction } from "./Store";
import { UserViewState } from "./Types";
import { apiv2 } from "~base/API/v2/apiv2";
import { IntlContext } from "~base/Internalization/IntlContext";
import { UserProfileResponse } from "~base/Model/ResponseModels/UserProfileResponse";
import { UserExperienceResponse } from "~base/Model/ResponseModels/UserExperienceResponse";
import { PostResponse } from "~base/Model/ResponseModels/PostResponse";

// ----------------------------------------------------
// Functions
// ----------------------------------------------------

/**
 * Fetch user profile
 * @param session 
 * @param dispatch 
 * @param state 
 */
export function getProfile(session: Session, dispatch: React.Dispatch<UserAction>, stateID: string, stateYourProfile: boolean) {
    if (stateYourProfile === false) {
        apiv2(`api/v2/get/userProfile/${stateID}`, "GET").then((response => {
            if (response.error != null) {
                session.setInformation("error", response.error);
                dispatch({ type: "setAccountNotFound", value: true });
            } else {
                dispatch({ type: "setProfile", profile: response.data as UserProfileResponse });
                dispatch({ type: "setAccountNotFound", value: false });
            }
        }));
    } else {
        dispatch({ type: "setProfile", profile: session.userProfile });
        dispatch({ type: "setAccountNotFound", value: false });
    }
}

/**
 * Fetch user experience
 * @param session 
 * @param dispatch 
 * @param state 
 */
export function getExperience(session: Session, dispatch: React.Dispatch<UserAction>, stateID: string) {
    apiv2(`api/v2/get/userExperience/${stateID}`, "GET").then((response => {
        if (response.error !== null) {
            session.setInformation("error", response.error);
        } else {
            dispatch({ type: "setExperience", value: response.data as UserExperienceResponse[]});
        }
    }));
}

/**
 * Fetch posts
 * @param session 
 * @param dispatch 
 * @param state 
 */
export function getUserPosts(session: Session, dispatch: React.Dispatch<UserAction>, stateID: string) {
    apiv2(`api/v2/get/userPosts/${stateID}`, "GET").then((response => {
        if (response.error != null) {
            session.setInformation("error", response.error);
        } else if (response.data != null) {
            dispatch({ type: "setPosts", value: response.data as PostResponse[] });
        }
    }));
}

/**
 * Get contact status.
 * Everytime we get a userprofile we need to know if we are 
 * already contacts, has sent a request or has no connection.
 * @param session 
 * @param dispatch 
 * @param state 
 */
export function getContactStatus(session: Session, dispatch: React.Dispatch<UserAction>, stateYourProfile: boolean, stateID: string, context: IntlContext) {
    if (stateYourProfile === false && session.companyProfile === null) {
        apiv2(`api/v2/get/contactStatus/${stateID}`, "GET").then((response => {
            if (response.error != null) {
                session.setInformation("error", response.error);
            } else {
                if (response.data === true) {
                    dispatch({type: "setContactButtonShow", value: true});
                    dispatch({type: "setIsContacts", value: true});
                    dispatch({type: "setContactButtonText", value: context.formatText("View.User.ContactButton.IsContacts")});
                }

                if (response.data === false) {
                    dispatch({type: "setContactButtonShow", value: true});
                    dispatch({type: "setContactButtonText", value: context.formatText("View.User.ContactButton.Sent")});
                    dispatch({type: "setContactButtonDisabled", value: true});
                }

                if (response.data === null) {
                    dispatch({type: "setContactButtonShow", value: true});
                    dispatch({type: "setContactButtonText", value: context.formatText("View.User.ContactButton.MakeContact")});
                    dispatch({type: "setContactButtonDisabled", value: false});
                }
            }
        }));
    }
}

/**
 * Post contact request.
 * This action is called when pressing the "Make contact" button.
 * @param session 
 * @param state 
 */
export function postContact(session: Session, state: UserViewState) {
    apiv2("api/v2/post/contact", "POST", { userId2: state.id }).then((response) => {
        if (response.error != null) {
            session.setInformation("error", response.error);
        }
    });
}

/**
 * Delete contact.
 * @param session 
 * @param state 
 */
export function deleteContact(session: Session, state: UserViewState) {
    apiv2(`api/v2/delete/contact/${state.id}`, "DELETE").then((response) => {
        if (response.error != null) {
            session.setInformation("error", response.error);
        }
    });
}

/**
 * Post follow user.
 * @param state 
 * @param dispatch 
 * @param session 
 */
export function postFollowUser(state: UserViewState, dispatch: React.Dispatch<UserAction>, session: Session) {
    const obj = {
        user_id: session.userId,
        followed_user_id: state.id,
        company_id: session.companyId,
        followed_company_id: null,
    }

    apiv2(`api/v2/post/follow`, "POST", obj).then((response => {
        if (response.error != null) {
            session.setInformation("error", response.error);
        } else {
            dispatch({ type: "setIsFollowing", value: true });
        }
    }));
}

/**
 * Get follow status
 * @param state 
 * @param dispatch 
 * @param session 
 */
export function getFollowUserStatus(stateID: string, dispatch: React.Dispatch<UserAction>, session: Session) {
    apiv2(`api/v2/get/followUserStatus/${stateID}`, "GET").then((response) => {
        if (response.error != null) {
            session.setInformation("error", response.error);
        } else {
            dispatch({ type: "setIsFollowing", value: response.data as boolean });
        }
    });
}

/**
 * Remove follow company
 * @param state 
 * @param dispatch 
 * @param session 
 */
export function removeFollowUser(state: UserViewState, dispatch: React.Dispatch<UserAction>, session: Session) {
    apiv2(`api/v2/delete/followUser/${state.id}`, "DELETE").then((response => {
        if (response.error != null) {
            session.setInformation("error", response.error);
        } else {
            dispatch({ type: "setIsFollowing", value: false });
        }
    }));
}