import { RouteObject } from "react-router-dom";
import { FeedLayout } from "~base/Components/Layouts/FeedLayout";
import { ViewBaseProps } from "~base/Model/ViewBaseProps";
import * as PATHS from "./Paths";
import { Feed } from "~base/Components/Views/Feed/Component";
import { SearchResult } from "~base/Components/Views/SearchResult/Component";
import { Company } from "~base/Components/Views/Company/Component";
import { User } from "~base/Components/Views/User/Component";
import { ManageAds } from "~base/Components/Views/ManageAds/Component";
import { ManageAd } from "~base/Components/Views/ManageAd/Component";
import { MyApplications } from "~base/Components/Views/MyApplications/Component";
import { MyApplication } from "~base/Components/Views/MyApplication/Component";
import { Navbar } from "~base/Components/Composite/Navbar/Component";
import { AdApply } from "~base/Components/Views/AdApply/Component";
import { CreateAd } from "~base/Components/Views/CreateAd/Component";
import { Post } from "~base/Components/Views/Post/Component";
import { UnderConstruction } from "~base/Components/Views/UnderConstruction/Component";
import { Network } from "~base/Components/Views/Network/Compontent";
import { Groups } from "~base/Components/Views/Groups/Component";
import { Group } from "~base/Components/Views/Group/Component";

type GetSecureRouteF = (viewBaseProps: ViewBaseProps) => RouteObject[];

export const getSecureBaseRoutes: GetSecureRouteF = (viewBaseProps) => [
    /**
     * Feed layout routes.
     */
    {
        element: <FeedLayout viewBaseProps={viewBaseProps} />,
        children: [
            { path: PATHS.FEED_PATH, element: <Feed {...viewBaseProps} /> },
            { path: PATHS.SEARCH_RESULT_PATH, element: <SearchResult {...viewBaseProps} /> },
            { path: `${PATHS.COMPANY_PROFILE_PATH}/:id`, element: <Company {...viewBaseProps} /> },
            { path: `${PATHS.USER_PROFILE_PATH}/:id`, element: <User {...viewBaseProps} /> },
            { path: `${PATHS.COMPANY_POST}/:id`, element: <Post {...viewBaseProps} /> },
            { path: `${PATHS.USER_POST}/:id`, element: <Post {...viewBaseProps} /> },
            { path: `${PATHS.GROUP}/:id`, element: <Group {...viewBaseProps} /> },
            {
                path: PATHS.PROJECT,
                element: <UnderConstruction
                    {...viewBaseProps}
                    title="Projekt"
                    message='Projekt-sidan är en viktig sida för att företaget ska kunna se över vilka projekt som det ingår i, samt se vilka andra företag, d.v.s under-och överleverantörer, som ingår i samma projekt.'
                />
            },
            {
                path: PATHS.SUB_CONTRACTORS,
                element: <UnderConstruction
                    {...viewBaseProps}
                    title="Underleverantörer"
                    message="Underleverantörer är en sida där du som företag kan enkelt se underleverantörer som skulle passa för ett eller flera av dina projekt."
                />
            },
            {
                path: PATHS.MARKETING,
                element: <UnderConstruction
                    {...viewBaseProps}
                    title="Marknadsföring"
                    message=""
                />
            },
            {
                path: PATHS.CONTRACTORS,
                element: <UnderConstruction
                    {...viewBaseProps}
                    title="Överleverentörer"
                    message="Sidan 'Överleverentörer' är till för ditt företag som vill ingå i andra projekt."
                />
            },
            {
                path: PATHS.EVENTS,
                element: <UnderConstruction
                    {...viewBaseProps}
                    title="Event"
                    message="Sidan 'Event' är till för personer som vill anordna eller delta i ett evenemang."
                />
            },
        ]
    },
] as RouteObject[];

export const getSecureCompanyRoutes: GetSecureRouteF = (viewBaseProps) => [
    /**
     * Plain layout routes.
     */
    {
        path: PATHS.CREATE_WORK_AD,
        element: <><Navbar {...viewBaseProps} /> <CreateAd {...viewBaseProps} /> </>
    },
    /**
     * Feed layout routes.
     */
    {
        element: <FeedLayout viewBaseProps={viewBaseProps} />,
        children: [
            { path: PATHS.COMPANY_MANAGE_ADS, element: <ManageAds {...viewBaseProps} /> },
            { path: `${PATHS.COMPANY_MANAGE_ADS}/:id`, element: <ManageAd {...viewBaseProps} /> },
        ]
    },
] as RouteObject[];

export const getSecureUserRoutes: GetSecureRouteF = (viewBaseProps) => [
    /**
     * Plain layout routes.
     */
    {
        path: "jobb/annons/:id/apply",
        element: <><Navbar {...viewBaseProps} /> <AdApply {...viewBaseProps} /></>
    },
    /**
     * Feed layout routes.
     */
    {
        element: <FeedLayout viewBaseProps={viewBaseProps} />,
        children: [
            { path: PATHS.MY_APPLICATIONS, element: <MyApplications {...viewBaseProps} /> },
            { path: `${PATHS.MY_APPLICATIONS}/:id`, element: <MyApplication {...viewBaseProps} /> },
            { path: PATHS.NETWORK, element: <Network {...viewBaseProps} /> },
            { path: PATHS.GROUPS, element: <Groups {...viewBaseProps} /> },
        ]
    },
] as RouteObject[];