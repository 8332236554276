import React from "react";
import { createUseStyles } from "react-jss"
import { Overlay } from "~base/Components/Elements/Cosmetic/Overlay";
import { Session } from "~base/Model/Session";
import { HendigoTheme } from "~base/Resources/Styling/HendigoTheme";
import { apiv2 } from "~base/API/v2/apiv2";
import { UserExperienceResponse } from "~base/Model/ResponseModels/UserExperienceResponse";
import { ExperienceField } from "./ExperienceFields";
import { useIntl } from "~base/Internalization/IntlContext";

export const useStyles = createUseStyles((theme: HendigoTheme) => ({
    container: {
        display: "flex",
        flexDirection: "column",
        height: "100%",
        paddingTop: 0,
        color: theme.inputTextColor,
    },
    inputContainer: {
        display: "flex",
        flexDirection: "column",
        height: "100%",
        padding: "1rem",
    },
    input: {
        width: "calc(100% - 2rem)",
        height: "2rem",
        padding: "0.5rem",
        border: "1px solid #ccc",
        borderRadius: "0.25rem",
        "&:focus": {
            outline: "none",
            border: "1px solid #000"
        },
        backgroundColor: theme.inputBackgroundColor,
        color: theme.inputTextColor,
    },
    inputTextBox: {
        width: "calc(100% - 1rem)",
        height: "4rem",
        border: "1px solid #ccc",
        borderRadius: "0.25rem",
        "&:focus": {
            outline: "none",
            border: "1px solid #000"
        },
        resize: "vertical",
        backgroundColor: theme.inputBackgroundColor,
        color: theme.inputTextColor,
    },
    inputDropDown: {
        width: "calc(100% - 1rem)",
        height: "3.5rem",
        padding: "0.5rem",
        border: "1px solid #ccc",
        borderRadius: "0.25rem",
        "&:focus": {
            outline: "none",
            border: "1px solid #000"
        },
        backgroundColor: theme.inputBackgroundColor,
        color: theme.inputTextColor,
    },
    inputFile: {
        "&:focus": {
            outline: "none",
            border: "1px solid #000"
        },
    },
    inputAutoComplete: {
        width: "calc(100% - 1rem)",
    },
    label: {
        fontSize: "1rem",
        marginBottom: "0.5rem"
    },
    inputDate: {
        display: "flex",
        width: "100%",
    },
    buttonContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "100%",
        paddingTop: "1rem",
    },
    editButton: {
        marginBottom: -5,
        paddingRight: 10,
    }
}));

interface EditUserOverlayProps {
    session: Session,
    onCancel: () => void,
    onSave: () => void,
    show: boolean,
}

export const CreateUserExperienceOverlay: React.FC<EditUserOverlayProps> = ({ session, show, onCancel, onSave }) => {
    
    const intl = useIntl();

    const [userExperience, setUserExperience] = React.useState({user_id: session.userId, position_type: "fulltime"} as UserExperienceResponse);

    return <Overlay
        show={show}
        title={intl.formatText("Views.User.CreateExperience.Title")}
        onCancel={onCancel}
        onSave={() => {
            if (userExperience && userExperience.title && userExperience.company_name && userExperience.position_type && userExperience.time_start) {
                // Add a month to the start and end date
                const time_start = new Date(userExperience.time_start);
                time_start.setMonth(time_start.getMonth() + 1);
                userExperience.time_start = time_start.toISOString().slice(0, 19).replace('T', ' ');
                if (userExperience.time_end) {
                    const time_end = new Date(userExperience.time_end);
                    time_end.setMonth(time_end.getMonth() + 1);
                    userExperience.time_end = time_end.toISOString().slice(0, 19).replace('T', ' ');
                }

                apiv2(`api/v2/post/userExperience`, "POST", userExperience).then((response) => {
                    if (response.error !== null) {
                        session.setInformation("error", response.error);
                    } else {
                        onSave();
                    }
                });
            } else {
                console.log(userExperience)
                session.setInformation("error", "Please fill in all the fields");
            }
        }}
        cssWidth="60%"
        cssMaxWidth="800px"
        cssHeight="80%"
    >
        <ExperienceField
            session={session}
            experience={userExperience}
            setExperience={setUserExperience}
        />
    </Overlay>
}