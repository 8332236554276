import React from "react";
import { BsArrowLeftShort, BsArrowRightShort, BsPencil } from "react-icons/bs";
import { MdOutlinePlace } from "react-icons/md";
import { TbConfetti } from "react-icons/tb";
import { useNavigate } from "react-router-dom";
import { TextArea } from "~base/Components/Elements/Input/TextArea";
import { Row } from "~base/Components/Elements/Cosmetic/Row";
import { ProgressBar } from "~base/Components/Elements/DataVisualization/ProgressBar";
import { Counties } from "~base/Resources/Data/Counties";
import { CompleteRegistrationStyles } from "~base/Components/Shared/Styling/CompleteRegistrationStyling";
import { ViewBaseProps } from "~base/Model/ViewBaseProps";
import { Input } from "~base/Components/Elements/Input/Input";
import { apiv2 } from "~base/API/v2/apiv2";
import { getCompanyTypes } from "./Fetch";
import { CompanyType } from "~base/Model/Entities/CompanyType";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { muiStyles } from "~base/Resources/Styling/MuiTheme";
import { useIntl } from "~base/Internalization/IntlContext";

interface Slide {
    id: string,
    titleIcon: React.ReactNode,
    title: string,
    inputValue: string,
    alternatives?: string[]
}

export const CompleteRegistrationCompany: React.FC<ViewBaseProps> = ({ session }) => {
    
    window.view = "CompleteRegistrationCompany";

    const intl = useIntl();

    intl.formatPageTitle("PageTitle.CompleteRegistrationCompany");

    // Styles
    const classes = CompleteRegistrationStyles();
    const muiClasses = muiStyles();
    
    let navigate = useNavigate();

    // Values
    //const [county, setCounty] = React.useState("");
    const [locationId, setLocationId] = React.useState<number>();
    const [description, setDescription] = React.useState("");
    const [address, setAddress] = React.useState("");
    const [city, setCity] = React.useState("");
    const [zipCode, setZipCode] = React.useState("");
    const [typeId, setTypeId] = React.useState<number>();

    // Slide progress values
    const [index, setIndex] = React.useState(0);
    const [progress, setProgress] = React.useState<number>(0);

    const [currentValid, setCurrentValid] = React.useState<boolean>(false);
    const [done, setDone] = React.useState(false);

    // Data
    const countyArrayCopy = Counties;
    const [companyTypes, setCompanyTypes] = React.useState<CompanyType[]>([]);

    /**
     * Fetch data.
     */
    React.useEffect(() => {
        getCompanyTypes(setCompanyTypes);
    }, []);

    /**
     * Error messages.
     * Validation messages.
     */
    const printErrorMessages = () => {
        switch (slides[index].id) {
            case "company_description":
                session.setInformation("error", intl.formatText("Views.CompleteRegistrationCompany.EnterAtLeastThreeCharacters"));
                break;
            case "location_id":
                session.setInformation("error", intl.formatText("Views.CompleteRegistrationCompany.ChooseAAlternative"));
                break;
            default:
                session.setInformation("error", intl.formatText("Views.CompleteRegistrationCompany.TheFieldAreInvalid"));
        }
    }

    /**
     * Slides.
     */
    const [slides, setSlides] = React.useState<Slide[]>([
        {
            id: "location_id",
            titleIcon: <MdOutlinePlace className={classes.titleIcon} />,
            title: intl.formatText("Views.CompleteRegistrationCompany.PlaceOfBusiness"),
            inputValue: locationId,
            //alternatives: countyArrayCopy
        },
        {
            id: "company_description",
            titleIcon: <BsPencil className={classes.titleIcon} />,
            title: intl.formatText("Views.CompleteRegistrationCompany.CompanyDescription"),
            inputValue: description,
        },
        {
            id: "address",
            titleIcon: <MdOutlinePlace className={classes.titleIcon} />,
            title: intl.formatText("Views.CompleteRegistrationCompany.Address"),
            inputValue: "",
        },
        {
            id: "city",
            titleIcon: <MdOutlinePlace className={classes.titleIcon} />,
            title: intl.formatText("Views.CompleteRegistrationCompany.City"),
            inputValue: "",
        },
        {
            id: "zip_code",
            titleIcon: <MdOutlinePlace className={classes.titleIcon} />,
            title: intl.formatText("Views.CompleteRegistrationCompany.ZipCode"),
            inputValue: "",
        },
        {
            id: "type_id",
            titleIcon: <MdOutlinePlace className={classes.titleIcon} />,
            title: intl.formatText("Views.CompleteRegistrationCompany.Type"),
            inputValue: "",
        }
    ] as Slide[]);

    /**
     * Setup active slides.
     */
    React.useEffect(() => {
        //nullIds is an array containing all of the ids (which are the object keys) for the slides that have null values.
        const nullIds = Object.entries(session.companyProfile!).filter((entry) => entry[1] == null || entry[1] === "").map((entry) => entry[0]);
        setSlides(slides.filter(slide => nullIds.includes(slide.id)));
        // eslint-disable-next-line
    }, []);

    /**
     * Slide validation.
     */
    React.useEffect(() => {
        if (index !== slides.length) {
            setProgress(index * (100 / slides.length));

            // Put special validation
            switch (slides[index].id) {
                case "company_description":
                    setCurrentValid(description.length > 2);
                    break;
                case "location_id":
                    setCurrentValid(true);
                    break;
                case "address":
                    setCurrentValid(address.length > 2 && address.length < 50);
                    break;
                case "city":
                    setCurrentValid(city.length > 2 && city.length < 50);
                    break;
                case "zip_code":
                    setCurrentValid(zipCode.length > 2 && zipCode.length < 50);
                    break;
                case "type_id":
                    setCurrentValid(typeId != null);
                    break;
                default:
                    setCurrentValid(true);
                    break;
            }
        }
        else {
            setProgress(100);
            setDone(true);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [index, slides, description, locationId, address, city, zipCode, typeId]);

    /**
     * Modify company profile when done.
     * These are the parameters that have been changed.
     */
    const modifyCompanyProfile = () => {
        if (session.companyProfile !== null) {
            session.setCompanyProfile({
                ...session.companyProfile,
                location_id: locationId == null ? session.companyProfile.location_id : locationId,
                location: locationId == null ? session.companyProfile.location : session.data.locations.find(l => l.id === locationId)?.location ?? "",
                company_description: description === "" ? session.companyProfile.company_description : description,
                address: address === "" ? session.companyProfile.address : address,
                city: city === "" ? session.companyProfile.city : city,
                zip_code: zipCode === "" ? session.companyProfile.zip_code : zipCode,
                type_id: typeId == null ? session.companyProfile.type_id : typeId,
                type: typeId == null ? session.companyProfile.type : (companyTypes.find(type => type.id === typeId)?.type ?? "")
            });
        }
    }

    /**
     * Component
     */
    return <div className={classes.wrapper} key={index + "CompleteRegistrationCompany"}>
        {!done ? <Row className={classes.row}>

            {index > 0 ? <div // LEFT ARROW
                className={classes.arrowWrapper}
                onClick={() => {
                    if (index - 1 >= 0) {
                        setIndex(index - 1);
                        setCurrentValid(true);
                    }
                }}
            >
                <BsArrowLeftShort />
            </div> : <div style={{ width: "54px" }}></div>}

            <div className={classes.middleWrapper}>
                <Row className={classes.title}>
                    <div>{slides[index]?.titleIcon}{slides[index]?.title}</div>
                </Row>

                <div className={classes.input}>
                    {
                        slides[index]?.id === "location_id" ?
                            <FormControl className={muiClasses.select} fullWidth>
                                <InputLabel id="location-select-label">
                                    {intl.formatText("Views.CompleteRegistrationCompany.EnterCounty")}
                                </InputLabel>
                                <Select
                                    labelId="location-select-label"
                                    id="location-select"
                                    value={locationId}
                                    label={intl.formatText("Views.CompleteRegistrationCompany.EnterCounty")}
                                    onChange={value => setLocationId(value.target.value as number)}
                                >
                                    {session.data.locations.map((l, index) => {
                                        return <MenuItem
                                            key={"CompleteRigstrationCompanyLocation" + index}
                                            value={l.id}
                                        >
                                            {intl.formatText(l.location)}
                                        </MenuItem>
                                    })}
                                </Select>
                            </FormControl>
                            : null
                    }

                    {
                        slides[index]?.id === "company_description" ?
                            <TextArea
                                key={"companyDescriptionInput"}
                                cssWidth="300px"
                                placeholder={intl.formatText("Views.CompleteRegistrationCompany.WriteHere")}
                                value={description}
                                onChange={e => setDescription(e.target.value)}
                                valid={currentValid}
                            />
                            : null
                    }

                    {
                        slides[index]?.id === "address" ?
                            <Input
                                placeholder="ex. Kungsgatan 1"
                                value={address}
                                onChange={e => setAddress(e.target.value)}
                                valid={currentValid}
                            />
                            : null
                    }

                    {
                        slides[index]?.id === "city" ?
                            <Input
                                placeholder="ex. Eskilstuna"
                                value={city}
                                onChange={e => setCity(e.target.value)}
                                valid={currentValid}
                            />
                            : null
                    }

                    {
                        slides[index]?.id === "zip_code" ?
                            <Input
                                placeholder="ex. 13462"
                                value={zipCode}
                                onChange={e => setZipCode(e.target.value)}
                                valid={currentValid}
                            />
                            : null
                    }

                    {
                        slides[index]?.id === "type_id" ?
                            <FormControl className={muiClasses.select} fullWidth>
                                <InputLabel id="demo-simple-select-label">
                                    {intl.formatText("Views.CompleteRegistrationCompany.CompanyType")}
                                </InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={typeId}
                                    label={intl.formatText("Views.CompleteRegistrationCompany.CompanyType")}
                                    onChange={value => setTypeId(value.target.value as number)}
                                >
                                    {companyTypes.map((ct, index) => {
                                        return <MenuItem
                                            key={"CompleteRigstrationCompanyCompanyType" + index}
                                            value={ct.id}
                                        >
                                            {intl.formatText(ct.type)}
                                        </MenuItem>
                                    })}
                                </Select>
                            </FormControl>
                            : null
                    }
                </div>
                <ProgressBar progress={progress} />
            </div>

            <div //RIGHT ARROW
                className={classes.arrowWrapper}
                onClick={() => {
                    if (currentValid) {
                        setIndex(index + 1);
                    } else {
                        printErrorMessages();
                    }
                }}
            >
                <BsArrowRightShort />
            </div>
        </Row> : <Row className={classes.row}>

            <div //LEFT ARROW
                className={classes.arrowWrapper}
                onClick={() => {
                    if (index - 1 >= 0) {
                        setIndex(index - 1);
                        setCurrentValid(true);
                        setDone(false);
                    }
                }}
            >
                <BsArrowLeftShort />
            </div>

            <div className={classes.middleWrapper} style={{ width: "75%" }}>
                <Row className={classes.title} style={{ width: "100%", textAlign: "center" }}>
                    <TbConfetti className={classes.titleIcon} />
                    <div>{intl.formatText("Views.CompleteRegistrationCompany.EverythingIsCorrect")}</div>
                    <div className={classes.doneText}>{intl.formatText("Views.CompleteRegistrationCompany.Text")}</div>
                    <ProgressBar progress={progress} />
                </Row>
            </div>

            <div //RIGHT ARROW
                className={classes.arrowWrapper}
                onClick={() => {
                    apiv2("api/v2/put/companyProfile/", "PUT", {
                        location_id: locationId,
                        company_description: description.length > 0 ? description : null,
                        address: address.length > 0 ? address : null,
                        city: city.length > 0 ? city : null,
                        zip_code: zipCode.length > 0 ? zipCode : null,
                        type_id: typeId
                    }).then(response => {
                        console.log(response);
                        if (response.error != null) {
                            session.setInformation("error", response.error);
                        } else {
                            modifyCompanyProfile();
                            navigate("/");
                        }
                    });
                }}
            >
                <BsArrowRightShort />
            </div>

        </Row>}
    </div>
}