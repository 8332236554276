import { createUseStyles } from "react-jss";
import { HendigoTheme } from "~base/Resources/Styling/HendigoTheme";

export const FooterStyles = createUseStyles((theme: HendigoTheme) => ({
    wrapper: {
        maxWidth: "100%",
        backgroundColor: theme.backgroundSecondary,
        color: theme.textPrimary,
        display: "flex",
        flexDirection: "row",
        minHeight: "25vh",
        flexWrap: "wrap",
        padding: "25px 27% 25px 27%",
        justifyContent: "space-between",
        '& li': {
            color: theme.textSecondary
        },
        '& p': {
            color: theme.textSecondary
        }
    },
    copyrightRow: {
        width: "100%",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        color: theme.textSecondary,
        backgroundColor: theme.backgroundSecondary,
        paddingBottom: "10px"
    },
    footerContent: {
        width: "30%",
        minWidth: "300px",
    },
    socialIcon: {
        fontSize: "30px",
        marginBottom: "5px",
        color: theme.textSecondary,
        '&:hover': {
            cursor: "pointer",
            color: theme.textPrimary
        },
    },
    '@media screen and (max-width: 1100px)': {
        wrapper: {
            padding: "25px 11% 25px 10%"
        }
    },
    li: {
        listStyle: "none",
        marginLeft: "-38px"
    },
    link: {
        fontWeight: "400",
        color: theme.textSecondary + " !important",
        textDecoration: "none !important",
        '&:hover': {
            cursor: "pointer",
            color: theme.textPrimary
        }
    }
}));