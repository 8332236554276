import { createUseStyles } from "react-jss";
import { Row } from "~base/Components/Elements/Cosmetic/Row";
import { usePostStyles } from "~base/Components/Composite/Post/Styling";
import { HendigoTheme } from "~base/Resources/Styling/HendigoTheme";
import { PostCommentResponse } from "~base/Model/ResponseModels/PostCommentResponse";
import { Link, useNavigate } from "react-router-dom";
import { useIntl } from "~base/Internalization/IntlContext";
import { Avatar } from "@mui/material";
import { useFilesAPIService } from "~base/Services/FilesAPIServiceContext";

const useStyles = createUseStyles((theme: HendigoTheme) => ({
    contentWrapper: {
        minHeight: "30px",
        width: "calc(100% - 68px)",
        padding: "8px",
        paddingBottom: "1rem",
        borderRadius: "0px 8px 8px 8px",
        backgroundColor: theme.backgroundPrimary,
        color: theme.textSecondary,
        fontSize: "14px"
    },
    authorName: {
        fontSize: "14px",
        fontWeight: "500",
        color: theme.textPrimary,
        '&:hover': {
            cursor: "pointer",
            textDecoration: "underline"
        }
    },
    time: {
        marginLeft: "auto",
    }
}));

export interface CommentProps {
    comment: PostCommentResponse
}

export const Comment: React.FC<CommentProps> = ({ comment }) => {

    const intl = useIntl();
    const sharedClasses = usePostStyles();
    const classes = useStyles();
    const navigate = useNavigate();
    const filesAPIService = useFilesAPIService();

    //const navigateToUserOrCompany = () => comment.company_id ? navigate(`/foretag/${comment.company_id}`) : navigate(`/anvandare/${comment.user_id}`);

    const profilePicture = filesAPIService.getProfileImageSmall(comment.small_image_pathname);

    const firstLetterInName = comment?.name.substring(0, 1);

    const profileLink = comment?.company_id ?
        `/foretag/${comment.company_id}`
        : `/anvandare/${comment?.user_id}`;

    return <Row>
        <Avatar
            src={profilePicture}
            className={sharedClasses.avatar}
            component={Link}
            to={profileLink}
        >
            {firstLetterInName}
        </Avatar>

        <div className={classes.contentWrapper}>
            <Row style={{ marginBottom: "1rem" }}>
                <div
                    className={classes.authorName}
                    onClick={() => navigate(profileLink)}
                >
                    {comment.name}
                </div>
                <div className={classes.time}>{intl.formatTimeLong(new Date(comment.time_created))}</div>
            </Row>
            {comment.content}
        </div>
    </Row>
}