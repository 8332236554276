import { Session } from "~base/Model/Session";
import { ViewAction } from "./Store";
import { apiv2 } from "~base/API/v2/apiv2";
import { Application } from "~base/Model/Entities/Application";
import { AdResponse } from "~base/Model/ResponseModels/AdResponse";

export function getApplication(applicationId: string, dispatch: React.Dispatch<ViewAction>, session: Session){
    dispatch({type: "setLoading", value: true});
    dispatch({type: "setLoadingMessage", value: "Views.AdApply.LoadingAd"});
    
    apiv2(`api/v2/get/application/${applicationId}`, "GET").then(response => {
        if(response.error !== null){
            session.setInformation("error", response.error);
        } else {
            const application = response.data.application as Application;
            const ad = response.data.ad as AdResponse;
            dispatch({type: "setApplication", value: application});
            dispatch({type: "setAd", value: ad});
        }
        dispatch({type: "setLoading", value: false});
    });
}