import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { initialState, reducer } from "./Store";
import { AdsSearchResultStyles } from "./Styling";
import { SearchBar } from "~base/Components/Elements/Input/SearchBar";
import { AdCard } from "~base/Components/Composite/AdCard/AdCard";
import { ViewBaseProps } from "~base/Model/ViewBaseProps";
import { getAdsFromSearch } from "./Fetch";
import { FormControl, InputLabel, MenuItem, Pagination, Select } from "@mui/material";
import { useIntl } from "~base/Internalization/IntlContext";

export const AdsSearchResult: React.FC<ViewBaseProps> = ({ session }) => {

    const intl = useIntl();

    window.view = "AdsSearchResult";
    intl.formatPageTitle("PageTitle.AdsSearchResult");

    const { search } = useLocation();
    const searchValue = new URLSearchParams(search).get("searchValue");
    const locationId = Number(new URLSearchParams(search).get("locationId"));
    const page = Number(new URLSearchParams(search).get("page"));
    const navigate = useNavigate();
    const classes = AdsSearchResultStyles();

    const [state, dispatch] = React.useReducer(reducer, initialState(session, searchValue ?? "", locationId ?? 22, page ?? 1));

    React.useEffect(() => {
        getAdsFromSearch(
            {
                searchValue: searchValue ?? "",
                locationId: locationId ?? 22,
                page: page ?? 1,
                pageSize: state.pageSize
            },
            session,
            dispatch
        );
    }, [session, dispatch, searchValue, locationId, page, state.pageSize]);

    return <div className={classes.wrapper}>
        <div className={classes.searchArea}>
            <h3>
                {intl.formatText("Views.AdSearchResult.Title")} {searchValue} {intl.formatText("Views.AdSearchResult.TitlePartTwo")} {intl.formatText(session.data.locations?.find(l => l.id === locationId)?.location ?? "")}
            </h3>

            <SearchBar
                colorType="light"
                placeholder={intl.formatText("Views.AdSearchResult.SearchAd")}
                value={state.searchValue}
                onChange={value => dispatch({ type: "setSearchValue", value: value })}
                onSearch={() => navigate("/jobb/resultat?searchValue=" + state.searchValue + "&locationId=" + state.locationId + "&page=1")}
            />

            <br />

            <FormControl fullWidth>
                <InputLabel id="location-select-label">
                    {intl.formatText("Views.CompleteRegistrationCompany.EnterCounty")}
                </InputLabel>
                <Select
                    labelId="location-select-label"
                    id="location-select"
                    value={state.locationId}
                    label={intl.formatText("Views.CompleteRegistrationCompany.EnterCounty")}
                    onChange={value => {
                        dispatch({ type: "setLocationId", value: value.target.value as number });
                        navigate("/jobb/resultat?searchValue=" + state.searchValue + "&locationId=" + value.target.value + "&page=1");
                    }}
                >
                    {session.data.locations?.map((l, index) => {
                        return <MenuItem
                            key={"SearchAdsLocation" + index}
                            value={l.id}
                        >
                            {intl.formatText(l.location)}
                        </MenuItem>
                    })}
                </Select>
            </FormControl>

            <br />

            <br />
        </div>

        {state.ads?.length > 0  && <h5>Hittade {state.ads[0]?.count ?? 0} resultat</h5>}

        {state.loading && <>
            <AdCard loading />
            <AdCard loading />
            <AdCard loading />
        </>}

        {state.ads?.length > 0 && state.ads.map(ad => {
            return <AdCard key={ad.id} ad={ad} />
        })}

        {state.ads?.length === 0 && <div className={classes.noAdsFoundText}>Inga annonser hittade. Testa att söka efter något annat.</div>}

        <Pagination
            page={state.page}
            count={(state.ads[0]?.count ?? 0) / state.pageSize}
            onChange={(e, value) => {
                dispatch({ type: "setPage", value: value });
                navigate("/jobb/resultat?searchValue=" + state.searchValue + "&locationId=" + state.locationId + "&page=" + value);
            }}
        />
    </div>
}