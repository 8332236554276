import { createUseStyles } from "react-jss";
import { HendigoTheme } from "~base/Resources/Styling/HendigoTheme";

export const AdsSearchResultStyles = createUseStyles((theme: HendigoTheme) => ({
    wrapper: {
        minHeight: "91vh",
        maxWidth: "100%",
        backgroundColor: theme.backgroundPrimary,
        padding: "20px 25% 0px 25%",
        color: theme.textPrimary,
    },
    inputTitle: {
        marginTop: "10px",
    },
    '@media screen and (max-width: 1500px)':{
        wrapper: {
            padding: "20px 5% 0px 5%"
        }
    },
    alternativeList: {
        opacity: 1
    },
    showMoreButton: {
        display: "block", 
        margin: "25px auto 0px auto",
    },
    searchArea: {
        backgroundColor: theme.backgroundSecondary,
        padding: "5px 20px",
        borderRadius: "10px",
        marginBottom: "20px"
    },
    noAdsFoundText: {
        textAlign: "center",
    }
}));