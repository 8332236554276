import { HendigoBaseTheme } from "./HendigoBaseTheme";

//------------------------------------------------------------------
// Dimensions type
// for use @media queries
//------------------------------------------------------------------
interface Dimensions {
    small: "576px",
    medium: "768px",
    large: "992px",
    xlarge: "1200px",
}

//------------------------------------------------------------------
// HendigoTheme type
//------------------------------------------------------------------
export interface HendigoTheme {
    darkMode: boolean,
    //------------------------------------------------------------------
    // Dimension
    //------------------------------------------------------------------

    dimensions: Dimensions,

    //------------------------------------------------------------------
    // Colors
    //------------------------------------------------------------------
    blue: "#2575fc",
    green: "#52ab98",
    purple: "#6a11cb",
    dark: "#212529",
    hendigoPurple: '#6a11cb',
    backgroundPrimary: string,
    backgroundSecondary: string,
    loginRegisterBackgroundRGBA: string,
    backgroundThird: string,
    textColor: string,
    textPrimary: string,
    textSecondary: string,
    border: string

    //------------------------------------------------------------------
    // Input
    //------------------------------------------------------------------
    inputBackgroundColor: string,
    inputTextColor: string,

    postAuthorName: string,
    postLikedButton: string

    //------------------------------------------------------------------
    // View themes
    //------------------------------------------------------------------

}

//------------------------------------------------------------------
// Initializer
//------------------------------------------------------------------
export const initializeHendigoTheme = (darkMode = false) => {
    return {
        darkMode: darkMode,
        //------------------------------------------------------------------
        // Dimensions type
        // for use @media queries
        //------------------------------------------------------------------

        dimensions: {
            small: "576px",
            medium: "768px",
            large: "992px",
            xlarge: "1200px",
        },

        //------------------------------------------------------------------
        // Colors
        //------------------------------------------------------------------
        blue: "#2575fc",
        green: "#52ab98",
        purple: "#6a11cb",
        dark: "#212529",
        backgroundPrimary: darkMode ? "#212529" : "#f1f5f8",
        backgroundSecondary: darkMode ? "#2c3136" : "white",
        backgroundThird: darkMode ? "#353a40" : "#f1f5f8",
        textColor: darkMode ? "#9EA2A6" : "black",
        textPrimary: darkMode ? "lightgray" : "black",
        textSecondary: darkMode ? "gray" : "dimgray",
        border: darkMode ? "dimgray" : "lightgray",
        loginRegisterBackgroundRGBA: darkMode ? "rgb(0, 0, 0, 0.5)" : "rgb(255, 255, 255, 0.5)",

        //------------------------------------------------------------------
        // Element components
        //------------------------------------------------------------------

        //------------------------------------------------------------------
        // Input
        //------------------------------------------------------------------
        inputBackgroundColor: darkMode ? "#212529" : "#f1f5f8",
        inputTextColor: darkMode ? "white" : "black",

        postAuthorName: darkMode ? "lightgray" : "black",
        postLikedButton: darkMode ? "lightgray" : HendigoBaseTheme.colors.hendigoBlue

        //------------------------------------------------------------------
        // View themes
        //------------------------------------------------------------------

    } as HendigoTheme
}