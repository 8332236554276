import React from "react";
import { useNavigate } from "react-router-dom";
import { initialState, reducer } from "./Store";
import { JobStyles } from "./Styling";
import { useTheme } from "react-jss";
import { HendigoTheme } from "~base/Resources/Styling/HendigoTheme";
import { SearchBar } from "~base/Components/Elements/Input/SearchBar";
import { AdCard } from "~base/Components/Composite/AdCard/AdCard";
import { LoremIpsumFull } from "~base/Resources/Text/LoremIpsum";
import { ViewBaseProps } from "~base/Model/ViewBaseProps";
import { PurpleWrapper } from "~base/Components/Composite/PurpleWrapper/PurpleWrapper";
import { Button, Container, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { getTop3Ads } from "./Fetch";
import { useIntl } from "~base/Internalization/IntlContext";

export const Job: React.FC<ViewBaseProps> = ({ session }) => {

    const intl = useIntl();

    window.view = "Ads";
    intl.formatPageTitle("PageTitle.Job");

    // State and dispatch
    const [state, dispatch] = React.useReducer(reducer, initialState(session));

    const navigate = useNavigate();

    const theme: HendigoTheme = useTheme();
    const classes = JobStyles({ theme });

    React.useEffect(() => {
        getTop3Ads(session, dispatch);
    }, []);

    const top3Ads = state.top3Ads.length > 0 ? state.top3Ads.map((ad, index) => <AdCard key={"top3ads" + index} ad={ad} />) :
        <>
            <AdCard loading />
            <AdCard loading />
            <AdCard loading />
        </>;

    const navigationQuery = '/jobb/resultat?searchValue=' + state.searchValue +
        '&locationId=' + (session.userProfile?.location_id ?? 22) + // 22 is the id for all of Sweden
        '&page=1';

    return <React.Fragment>
        <PurpleWrapper>
            <Container style={{ textAlign: "center" }}>
                <Typography variant="h2" color="white">{intl.formatText("Views.Job.FindYourDreamJobFastAndSimple")}</Typography>

                <br />

                <Typography variant="h5" color="white">{LoremIpsumFull}</Typography>

                <br />

                <SearchBar
                    placeholder={intl.formatText("View.Ads.SearchBar.Placeholder")}
                    colorType="light"
                    value={state.searchValue}
                    onChange={value => dispatch({ type: "setSearchValue", value: value })}
                    onSearch={() => state.searchValue.length > 0 ? navigate(navigationQuery) : null}
                />

                <br />

                <Typography variant="h5" color="white">{intl.formatText("Views.Job.RightNowThere")} <span style={{ textDecoration: "underline" }}>1357</span> {intl.formatText("Views.Job.JobAds")}</Typography>

                {session.userProfile !== null && <div style={{ display: "flex", justifyContent: "center", gap: "20px", marginTop: "20px" }}>
                    <Button
                        variant="contained"
                        component={Link}
                        to="/jobb/mina-ansokningar"
                    >Visa ansökningar</Button>

                    <Button
                        variant="contained"
                        component={Link}
                        to={`/jobb/resultat?searchValue=${session.userProfile?.title}&locationId=${session.userProfile?.location_id ?? 22}&page=1`}
                    >Sök på ditt yrke</Button>
                </div>}
            </Container>
        </PurpleWrapper>

        <div className={classes.wrapper2}>
            {session.userProfile !== null ?
                <>
                    <h1 className={classes.title2}>{intl.formatText("Views.Job.TopAdsWithinYourProfessionAndArea")}</h1>
                    <h5 className={classes.description2}>{intl.formatText("Views.Job.ViewingWithin")} {session.userProfile?.title} i {intl.formatText(session.userProfile.location ?? "")}</h5>
                    {top3Ads}
                    {state.top3Ads.length > 0 && <Button
                        variant="contained"
                        className={classes.showMoreButton}
                        component={Link}
                        to={`/jobb/resultat?searchValue=${session.userProfile?.title}&locationId=${session.userProfile?.location_id ?? 22}&page=1`}
                    >{intl.formatText("Views.Job.ShowMoreAds")}</Button>}
                </> :
                <>
                    <h1 className={classes.title2}>{intl.formatText("Views.Job.TopAds")}</h1>
                    <h5 className={classes.description2}>{intl.formatText("Views.Job.ShowingWithinTopAds")}</h5>
                    {top3Ads}
                </>
            }
        </div>
    </React.Fragment>
}