import React from 'react';
import { Session } from './Model/Session';
import { BeatLoader } from 'react-spinners';
import { HendigoRouting } from './Routing/Routing';
import { useLocation, useNavigate } from 'react-router-dom';
import { Footer } from './Components/Composite/Footer/Component';
import { ThemeProvider } from 'react-jss';
import { HendigoTheme, initializeHendigoTheme } from './Resources/Styling/HendigoTheme';
import { PurpleWrapper } from './Components/Composite/PurpleWrapper/PurpleWrapper';
import { InformationBox } from './Components/Composite/InformationBox/InformationBox';
import { ViewBaseProps } from './Model/ViewBaseProps';
import { formatText, languageCodes } from './Internalization/formatText';
import { IntlContext, IntlContextProvider } from './Internalization/IntlContext';
import { formatDateLong, formatDateShort, formatTimeLong, formatTimeShort } from './Internalization/formatTime';
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import { muiTheme } from './Resources/Styling/mui';
import { FilesAPIServiceContext, FilesAPIServiceContextProvider, getPostImage, getProfileImageLarge, getProfileImageSmall } from './Services/FilesAPIServiceContext';

/**
 * Declare global variables
 */
declare global {
  interface Window {
    dispSes: Session;
    view: string;
    darkMode: boolean;
    dispState: any;
  }
}

/**
 * Main HendiGO app component.
 * @returns {JSX.Element}
 */
export const App = (): JSX.Element => {
  
  const [session] = React.useState<Session>(new Session());
  const [loading, setLoading] = React.useState<boolean>(session.isLoading);

  if (localStorage['darkMode'] == null) {
    localStorage['darkMode'] = 'false';
  }

  const [language, setLanguage] = React.useState<languageCodes>(localStorage['language'] || navigator.language.split("-")[0] || "sv");
  const [theme, setTheme] = React.useState<HendigoTheme>(initializeHendigoTheme(localStorage['darkMode'] === 'true'));

  window.dispSes = session;

  const navigate = useNavigate();
  const location = useLocation();
  const pathname = location.pathname;

  /**
   * Listen to changes for session.isLoading
   */
  React.useEffect(() => {
    const obs = () => {
      setLoading(session.isLoading);
    }
    session.addObserver(obs);
    return () => {
      session.removeObserver(obs);
    }
  }, [session, session.isLoading]);

  /**
   * Check if user or company has a fully registered profile.
   * Redirect to fardigstall if they do not have a complete profile.
   * This check occurs each time the website is loaded.
   */
  React.useEffect(() => {
    const obs = () => {
      // Check if user or company has complete registration / any null values.
      if (session.companyProfile !== null && session.userProfile == null && pathname !== "/fardistall-foretag") {
        const isNullUndefined = Object.entries(session.companyProfile).every((entry) => {
          const [parameterName, value] = entry;

          return (
            (value !== null && value !== undefined && value !== "")
            || parameterName === "small_image_pathname"
            || parameterName === "big_image_pathname"
          );
        });

        if (isNullUndefined === false) {
          navigate("/fardigstall-foretag");
        }

      } else if (session.userProfile !== null && session.companyProfile == null && pathname !== "/fardistall-privat") {
        const isNullUndefined = Object.entries(session.userProfile).every(entry => {
          const [parameterName, value] = entry;

          return (
            (value !== null && value !== undefined && value !== "")
            || parameterName === "current_company_id"
            || parameterName === "small_image_pathname"
            || parameterName === "big_image_pathname"
          );
        });

        if (isNullUndefined === false) {
          navigate("/fardigstall-privat")
        }
      }
    }
    session.addObserver(obs);
    return () => {
      session.removeObserver(obs);
    }
  }, [session, pathname, session.userProfile, session.companyProfile, navigate]);

  /**
   * Scroll to top for every new page.
   */
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  /**
   * Intl context
   */
  const intlContext = {
    formatText: (code: string) => formatText(code, language),
    language: language,
    setLanguage: (lang: languageCodes) => {
      localStorage['language'] = lang;
      setLanguage(lang);
    },
    formatTimeShort: (date: Date) => formatTimeShort(date, language),
    formatTimeLong: (date: Date) => formatTimeLong(date, language),
    formatDateShort: (date: Date) => formatDateShort(date, language),
    formatDateLong: (date: Date) => formatDateLong(date, language),
    formatPageTitle: (code: string) => document.title = formatText(code, language) + " | HendiGO",
    formatPageTitleString: (title: string) => document.title = title + " | HendiGO",
    session: session,
    styling: {
      setDarkMode: (val: boolean) => {
        localStorage['darkMode'] = val;
        setTheme(initializeHendigoTheme(val));
      }
    },
  } as IntlContext;

  /**
   * FilesAPIService context
   */
  const filesAPIServiceContext = {
      getProfileImageSmall: getProfileImageSmall,
      getProfileImageLarge: getProfileImageLarge,
      getPostImage: getPostImage,
  } as FilesAPIServiceContext;

  const [show401, setShow401] = React.useState<boolean>(false);

  const viewBaseProps = {
    session: session,
  } as ViewBaseProps;

  return (
    <MuiThemeProvider theme={muiTheme(theme.darkMode)}>
      <ThemeProvider theme={theme}>
        <IntlContextProvider.Provider value={intlContext}>
          <FilesAPIServiceContextProvider.Provider value={filesAPIServiceContext}>
            {loading ?
              <PurpleWrapper>
                <BeatLoader color="#ffffff" style={{ display: "block", margin: "0 auto" }} loading size={10} />
              </PurpleWrapper>
              :
              (
                !show401 ?
                  <>
                    <InformationBox session={session} />
                    <HendigoRouting viewBaseProps={viewBaseProps} setShow401={setShow401} />
                    <Footer {...viewBaseProps} />
                  </>
                  :
                  <>401</>
              )
            }
          </FilesAPIServiceContextProvider.Provider>
        </IntlContextProvider.Provider>
      </ThemeProvider>
    </MuiThemeProvider>
  );
}

export default App;