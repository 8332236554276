import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { MiddleWrapper } from "~base/Components/Elements/Cosmetic/MiddleWrapper";
import { ViewBaseProps } from "~base/Model/ViewBaseProps";
import { initialPostState, postReducer } from "./Store";
import { getPost } from "./Fetch";
import { Post as CompositePost } from "~base/Components/Composite/Post/Component";
import { useIntl } from "~base/Internalization/IntlContext";

export const Post: React.FC<ViewBaseProps> = ({ session }) => {

    const intl = useIntl();

    window.view = "Post";
    intl.formatPageTitle("PageTitle.Post");

    const params = useParams();
    const postId = params.id;
    const navigate = useNavigate();

    const [state, dispatch] = React.useReducer(postReducer, initialPostState(postId as string));

    // Fetch post and comments
    React.useEffect(() => {
        getPost(state, session, dispatch);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return <MiddleWrapper>
        {state.post && <CompositePost
            session={session}
            post={state.post}
            showCommentButton={false}
            onDelete={() => {
                navigate("/");
            }}
            onEditContent={(newContent) => {
                dispatch({ type: "setPost", value: state.post && { ...state.post, content: newContent } })
            }}
        />}
    </MiddleWrapper>
}