import { useIntl } from "~base/Internalization/IntlContext";
import { ViewBaseProps } from "~base/Model/ViewBaseProps";

export const NotFound: React.FC<ViewBaseProps> = ({ }) => {

    const intl = useIntl();

    window.view = "NotFound";
    intl.formatPageTitle("PageTitle.NotFound")
    
    return <h1 style={{color: "white"}}>404 Not found</h1>
}