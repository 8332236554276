// ----------------------------------------------------
// Imports
// ----------------------------------------------------

import { AdResponse } from "~base/Model/ResponseModels/AdResponse";
import { ViewState } from "./Types";
import { AdRequirement } from "~base/Model/Entities/AdRequirement";
import { Application } from "~base/Model/Entities/Application";

// ----------------------------------------------------
// Actions
// ----------------------------------------------------

type SetAd = { type: "setAd", value: AdResponse }
type SetAdRequirements = { type: "setAdRequirements", value: AdRequirement[] }
type SetAlreadyApplied = { type: "setAlreadyApplied", value: boolean }
type SetTop3Ads = { type: "setTop3Ads", value: AdResponse[] }
type SetApplication = { type: "setApplication", value: Application }

export type AdAction = SetAd | SetAdRequirements | SetAlreadyApplied | SetTop3Ads | SetApplication;

// ----------------------------------------------------
// Reducer
// ----------------------------------------------------

export const reducer = (state: ViewState, action: AdAction): ViewState => {
    switch (action.type) {
        case "setAd": {
            return {
                ...state,
                ad: action.value
            }
        }

        case "setAdRequirements": {
            return {
                ...state,
                adRequirements: action.value
            }
        }

        case "setAlreadyApplied": {
            return {
                ...state,
                alreadyApplied: action.value
            }
        }

        case "setTop3Ads": {
            return {
                ...state,
                top3Ads: action.value
            }
        }

        case "setApplication": {
            return {
                ...state,
                application: action.value
            }
        }

        default:
            return {
                ...state
            }
    }
}

// ----------------------------------------------------
// Initial State
// ----------------------------------------------------

export const initialState = (): ViewState => {
    return {
        ad: null,
        adRequirements: [
        ],
        alreadyApplied: false,
        top3Ads: [],
        application: null,
    }
}

export const mockAd = {
    id: "mock",
    title: "Byggmax söker en ny medarbetare!",
    company_name: "Byggmax",
    small_image_pathname: "/images/hendigo/byggmax.jpg",
    location: "Stockholm",
    form_of_employment: "Values.FormOfEmployment.FullTime",
    time_created: "2023-03-28 00:00:00",
    deadline: "2023-04-28 00:00:00",
    company_description: "Company Description",
    position_description: "Position Description",
    applicant_description: "Applicant Description",
    product_type: "Product.Ad.Standard"
} as AdResponse;

export const premiumMockAd = {
    id: "premiummock",
    title: "TL Bygg söker en anläggare!",
    company_name: "TL Bygg",
    small_image_pathname: "/images/hendigo/tlbygg.png",
    location: "Stockholm",
    form_of_employment: "Values.FormOfEmployment.FullTime",
    time_created: "2023-03-28 12:00:00",
    deadline: "2023-04-28 12:00:00",
    company_description: "Company Description",
    position_description: "Position Description",
    applicant_description: "Applicant Description",
    product_type: "Product.Ad.Premium"
} as AdResponse;

export const mockAdRequirements = [
    {
        ad_id: "mock",
        description: "B-körkort",
    },
    {
        ad_id: "mock",
        description: "Säkerhetskurs X",
    }
] as AdRequirement[];
