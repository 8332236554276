import { createUseStyles } from "react-jss";
import { HendigoTheme } from "~base/Resources/Styling/HendigoTheme";

export const ProfileStyles = createUseStyles((theme : HendigoTheme) => ({
    background_image: {
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        height: "250px",
        borderRadius: "10px 10px 0px 0px",
    },
    yourBackground: {
        '&:hover': {
            cursor: "pointer"
        }
    },
    avatar: {
        height: "150px !important",
        width: "150px !important",
        fontSize: "50px !important",
        marginTop: "75px",
        marginLeft: "30px",
        marginBottom: "30px",
    },
    yourAvatar: {
        '&:hover': {
            cursor: "pointer",
            outline: "2px solid lightgray",
        },
    },
    edit_button: {
        float: "right",
        marginTop: "5px",
        marginRight: "5px",
        fontSize: "25px",
        transition: "font-size 0.3s",
        color: theme.textSecondary,
        //border: "1px solid " + theme.border,
        borderRadius: "5px",
        '&:hover': {
            fontSize: "28px",
            cursor: "pointer",
        },
        padding: "2px"
    },
    ownerButton: {
        float: "right"
    },
    wrapper: {
    },
    aboutWrapper: {
        backgroundColor: theme.backgroundSecondary,
        padding: "10px",
        //border: "1px solid " + theme.border,
        //borderTop: "none",
        borderRadius: "0px 0px 10px 10px",
        margin: "0px 0px 20px 0px"
    },
    about_text: {
        width: "75%",
        whiteSpace: "pre-wrap",
        color: theme.textSecondary
    },
    button: {
        width: "120px",
        marginRight: "5px",
        marginBottom: "20px",
        textAlign: "start",
    },
    button_icon: {
        fontSize: "20px",
    },
    name: {
        color: theme.textPrimary,
        margin: "0px",
        fontWeight: "500"
    },
    location: {
        color: theme.textSecondary,
        marginBottom: "10px",
        fontWeight: "500"
    },
    contentWrapper: {
        backgroundColor: theme.backgroundSecondary,
        padding: "10px",
        borderRadius: "10px",
        //border: "1px solid " + theme.border,
        margin: "0px 0px 20px 0px"
    },
    title: {
        color: theme.textSecondary,
        fontWeight: "500",
        fontSize: "18px"
    },
    followButton: {
        marginRight: "5px",
        minWidth: "120px"
    },
    pill: {
        backgroundColor: theme.backgroundPrimary + " !important",
        color: theme.textSecondary + " !important",
    },
    socialLink: {
        color: theme.blue,
        fontSize: "14px",
        '&:hover': {
            cursor: "pointer",
            textDecoration: "underline"
        }
    }
}))