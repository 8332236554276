export type Text = { code: string, sv: string, en: string }

/**
 * Add texts here. Can be fetched with formatText().
 */
export const Texts = [
    {
        code: "January.Long",
        sv: "Januari",
        en: "January"
    },
    {
        code: "February.Long",
        sv: "Februari",
        en: "February"
    },
    {
        code: "March.Long",
        sv: "Mars",
        en: "March"
    },
    {
        code: "April.Long",
        sv: "April",
        en: "April"
    },
    {
        code: "May.Long",
        sv: "Maj",
        en: "May"
    },
    {
        code: "June.Long",
        sv: "Juni",
        en: "June"
    },
    {
        code: "July.Long",
        sv: "Juli",
        en: "July"
    },
    {
        code: "August.Long",
        sv: "Augusti",
        en: "August"
    },
    {
        code: "September.Long",
        sv: "September",
        en: "September"
    },
    {
        code: "October.Long",
        sv: "Oktober",
        en: "October"
    },
    {
        code: "November.Long",
        sv: "November",
        en: "November"
    },
    {
        code: "December.Long",
        sv: "December",
        en: "December"
    },
    {
        code: "January.Short",
        sv: "Jan",
        en: "Jan"
    },
    {
        code: "February.Short",
        sv: "Feb",
        en: "Feb"
    },
    {
        code: "March.Short",
        sv: "Mar",
        en: "Mar"
    },
    {
        code: "April.Short",
        sv: "Apr",
        en: "Apr"
    },
    {
        code: "May.Short",
        sv: "Maj",
        en: "May"
    },
    {
        code: "June.Short",
        sv: "Jun",
        en: "Jun"
    },
    {
        code: "July.Short",
        sv: "Jul",
        en: "Jul"
    },
    {
        code: "August.Short",
        sv: "Aug",
        en: "Aug"
    },
    {
        code: "September.Short",
        sv: "Sep",
        en: "Sep"
    },
    {
        code: "October.Short",
        sv: "Okt",
        en: "Oct"
    },
    {
        code: "November.Short",
        sv: "Nov",
        en: "Nov"
    },
    {
        code: "December.Short",
        sv: "Dec",
        en: "Dec"
    },
    {
        code: "LoremIpsum.Full",
        sv: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
        en: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
    },
    {
        code: "View.Ads.SearchBar.Placeholder",
        sv: "Sök ditt drömjobb här, t.ex Anläggare...",
        en: "Search for your dream job here, for example: Construction Worker"
    },
    {
        code: "View.Home.LoginButton",
        sv: "Logga in",
        en: "Log in"
    },
    {
        code: "View.Home.Swedens",
        sv: "sveriges ",
        en: "swedens "
    },
    {
        code: "View.Home.First",
        sv: "första ",
        en: "first "
    },
    {
        code: "View.Home.Network",
        sv: "nätverk för byggare!",
        en: "network for cunstruction workers!"
    },
    {
        code: "View.Home.Ingress",
        sv: "Plattformen där byggföretag får möjligheten till att hitta nya medarbetare, nya och flera affärer på ett enkelt och tryggt sätt.",
        en: "The platform where construction companies get the opportunity to find new employees, new and more business in an easy and safe way."
    },
    {
        code: "View.Home.RegisterButton",
        sv: "Registrera dig",
        en: "Register"
    },
    {
        code: "View.Home.FeedButton",
        sv: "Gå till flöde",
        en: "Go to feed"
    },
    {
        code: "Views.Navbar.Home",
        sv: "Hem",
        en: "Home"
    },
    {
        code: "Views.Navbar.Jobs",
        sv: "Jobb",
        en: "Jobs"
    },
    {
        code: "Views.Navbar.Services",
        sv: "Tjänster",
        en: "Services"
    },
    {
        code: "Views.Navbar.Pricing",
        sv: "Priser",
        en: "Pricing"
    },
    {
        code: "View.Navbar.SearchBar.Placeholder",
        sv: "Sök jobb, personer, företag...",
        en: "Search for jobs, people, companies..."
    },
    {
        code: "Notification.ContactRequest",
        sv: " har skickat dig en kontaktbegäran",
        en: " has sent you a contact request"
    },
    {
        code: "View.User.ContactButton.Sent",
        sv: "Förfrågan skickad",
        en: "Request sent"
    },
    {
        code: "View.User.ContactButton.MakeContact",
        sv: "Skapa kontakt",
        en: "Make contact"
    },
    {
        code: "View.User.ContactButton.IsContacts",
        sv: "Avbryt kontakt",
        en: "Unfollow"
    },
    {
        code: "View.Company.FollowButton.Follow",
        sv: "+ Följ",
        en: "Follow"
    },
    {
        code: "View.Company.FollowButton.Unfollow",
        sv: "Sluta följa",
        en: "Unfollow"
    },
    {
        code: "Notification.Follow",
        sv: " började följa dig",
        en: " started following you"
    },
    {
        code: "Notification.ContactRequestAccepted",
        sv: " har accepterat din kontaktbegäran",
        en: " has accepted your contact request"
    },
    {
        code: "View.Network.ContactSentReceived.Sent",
        sv: "Skickade",
        en: "Sent"
    },
    {
        code: "View.Network.ContactSentReceived.Received",
        sv: "Mottagna",
        en: "Received"
    },
    {
        code: "View.Network.ContactSentReceived.Accept",
        sv: "Acceptera",
        en: "Accept"
    },
    {
        code: "View.Network.ContactSentReceived.Cancel",
        sv: "Avbryt",
        en: "Cancel"
    },
    {
        code: "View.Network.ContactSentReceived.ShowMore",
        sv: "Visa fler...",
        en: "Show more..."
    },
    {
        code: "View.Network.ContactSentReceived.ShowLess",
        sv: "Visa färre...",
        en: "Show less..."
    },
    {
        code: "View.Network.ContactSentReceived.Requests",
        sv: "Förfrågningar",
        en: "Requests"
    },
    {
        code: "View.Network.Contacts.Message",
        sv: "Meddelande",
        en: "Message"
    },
    {
        code: "View.Network.Contacts.Contacts",
        sv: "Kontakter",
        en: "Contacts"
    },
    {
        code: "View.Network.Contacts.Search",
        sv: "Sök efter en kontakt...",
        en: "Search for a contact..."
    },
    {
        code: "FormatTime.Year",
        sv: " år",
        en: " y"
    },
    {
        code: "FormatTime.Month",
        sv: " m",
        en: " m"
    },
    {
        code: "FormatTime.Day",
        sv: " d",
        en: " d"
    },
    {
        code: "FormatTime.Hour",
        sv: " t",
        en: " h"
    },
    {
        code: "FormatTime.Minute",
        sv: " min",
        en: " min"
    },
    {
        code: "FormatTime.Second",
        sv: " s",
        en: " s"
    },
    {
        code: "FormatTime.MinuteAgo",
        sv: " minuter sedan",
        en: " minutes ago"
    },
    {
        code: "FormatTime.HourAgo",
        sv: " timmar sedan",
        en: " hours ago"
    },
    {
        code: "FormatTime.DayAgo",
        sv: " dagar sedan",
        en: " days ago"
    },
    {
        code: "FormatTime.WeekAgo",
        sv: " veckor sedan",
        en: " weeks ago"
    },
    {
        code: "FormatTime.MonthAgo",
        sv: " månader sedan",
        en: " months ago"
    },
    {
        code: "Elements.DataDisplacement.InformationBox.ErrorMessage",
        sv: "Felmeddelande",
        en: "Error message"
    },
    {
        code: "Elements.DataDisplacement.InformationBox.Information",
        sv: "Information",
        en: "Information"
    },
    {
        code: "Elements.Input.TextInput.Placeholder",
        sv: "Dela med dig av något",
        en: "Share something"
    },
    {
        code: "Shared.AdCard.PlaceHolderTitle",
        sv: "Anläggare till TL Bygg i Stockholm",
        en: "Construction worker at TL Bygg in Stockholm"
    },
    {
        code: "Shared.AdCard.Recommended",
        sv: "Rekommenderad",
        en: "Recommended"
    },
    {
        code: "Shared.AdCard.TwoDaysAgo",
        sv: "2 dagar sedan",
        en: "2 days ago"
    },
    {
        code: "Shared.EditProfilePictureOverlay.ChangePicture",
        sv: "Ändra profilbild",
        en: "Change profile picture"
    },
    {
        code: "Shared.EditProfilePictureOverlay.ChooseFile",
        sv: "Välj en fil",
        en: "Choose a file"
    },
    {
        code: "Shared.Post.Edit",
        sv: "Redigera",
        en: "Edit",
    },
    {
        code: "Shared.Post.Delete",
        sv: "Ta bort",
        en: "Delete"
    },
    {
        code: "Shared.EditProfilePictureOverlay.ProfilePicture",
        sv: "Profilbild",
        en: "Profile picture"
    },
    {
        code: "Shared.EditProfilePictureOverlay.Background",
        sv: "Bakgrund",
        en: "Background"
    },
    {
        code: "Shared.Post.CloseImage",
        sv: "Stäng bild",
        en: "Close image"
    },
    {
        code: "Shared.Post.ShowMore",
        sv: "Visa mer",
        en: "Show more"
    },
    {
        code: "Views.Ad.Requirement",
        sv: "Krav",
        en: "Requirement"
    },
    {
        code: "Views.Ad.Recommended",
        sv: "Rekommenderad",
        en: "Recommended"
    },
    {
        code: "Views.Ad.Uploaded",
        sv: "Lades upp för",
        en: "Uploaded"
    },
    {
        code: "Views.Ad.ApplyLatest",
        sv: "Ansök senast",
        en: "Apply latest"
    },
    {
        code: "Views.Ad.About",
        sv: "Om",
        en: "About"
    },
    {
        code: "Views.Ad.TwoDaysAgo",
        sv: "2 dagar sedan",
        en: "2 days ago"
    },
    {
        code: "Views.Ad.Fulltime",
        sv: "Heltid",
        en: "Fulltime"
    },
    {
        code: "Views.Ad.Apply",
        sv: "Ansök",
        en: "Apply"
    },
    {
        code: "Views.Ad.AboutThePosition",
        sv: "Om tjänsten",
        en: "About the position"
    },
    {
        code: "Views.Ad.AboutYou",
        sv: "Om dig",
        en: "About you"
    },
    {
        code: "Views.Ad.Requirements",
        sv: "Krav",
        en: "Requirements"
    },
    {
        code: "Views.Ad.Contact",
        sv: "Kontakt",
        en: "Contact"
    },
    {
        code: "Views.Ad.Contact.SubTitle",
        sv: "Klicka här för att komma till profil.",
        en: "Click here to go to profile."
    },
    {
        code: "Views.Ad.Contact.SubSubTitle",
        sv: "Du kan kontakta företaget via profilen.",
        en: "You can contact the company via the profile."
    },
    {
        code: "Views.Ad.SendMessage",
        sv: "Skicka meddelande",
        en: "Send message"
    },
    {
        code: "Views.Ad.RelatedAds",
        sv: "Relaterade annonser",
        en: "Related ads"
    },
    {
        code: "Views.Ad.ShowMoreAds",
        sv: "Visa fler annonser",
        en: "Show more ads"
    },
    {
        code: "Views.AdSearchResult.SetLocationError",
        sv: "Ange en giltig plats",
        en: "Enter a valid location"
    },
    {
        code: "Views.AdSearchResult.SearchAd",
        sv: "Sök annons",
        en: "Search ad"
    },
    {
        code: "Views.AdSearchResult.SetCounty",
        sv: "Ange län",
        en: "Set county"
    },
    {
        code: "Views.AdSearchResult.ShowMoreAds",
        sv: "Visa fler annonser",
        en: "Show more ads"
    },
    {
        code: "Views.AdSearchResult.Title",
        sv: "Jobbannonser inom",
        en: "Job ads within"
    },
    {
        code: "Views.AdSearchResult.TitlePartTwo",
        sv: "och",
        en: "and"
    },
    {
        code: "Views.Company.CompanyNotFound",
        sv: "Företaget hittades inte",
        en: "Company not found"
    },
    {
        code: "Views.Company.EditCompanyOverlays.EditCompanyProfile",
        sv: "Redigera företagsprofil",
        en: "Edit company profile"
    },
    {
        code: "Views.Company.EditCompanyOverlays.CheckCorrection",
        sv: "Kolla så att du har fyllt i rätt!",
        en: "Check that you have filled in correctly!"
    },
    {
        code: "Views.Company.EditCompanyOverlays.CompanyCounty",
        sv: "Verksamhetslän",
        en: "Company county"
    },
    {
        code: "Views.Company.EditCompanyOverlays.CompanyName",
        sv: "Företagsnamn",
        en: "Company name"
    },
    {
        code: "Views.Company.EditCompanyOverlays.Address",
        sv: "Adress",
        en: "Address"
    },
    {
        code: "Views.Company.EditCompanyOverlays.ZipCode",
        sv: "Postnummer",
        en: "Zip code"
    },
    {
        code: "Views.Company.EditCompanyOverlays.City",
        sv: "Stad",
        en: "City"
    },
    {
        code: "Views.Company.EditCompanyOverlays.CompanyDescription",
        sv: "Företagsbeskrivning",
        en: "Company description"
    },
    {
        code: "Views.Company.EditCompanyOverlays.NoDescription",
        sv: "Ingen description ännu? Skriv en här!",
        en: "No description yet? Write one here!"
    },
    {
        code: "Views.Company.CopyToClipboard",
        sv: "Kopiera till urklipp",
        en: "Copy to clipboard"
    },
    {
        code: "Views.Company.NoDescription",
        sv: "Ingen beskrivning ännu",
        en: "No description yet"
    },
    {
        code: "Views.Company.LookingForFloorInstallers",
        sv: "Vi söker golvläggare",
        en: "We are looking for floor installers"
    },
    {
        code: "Views.Company.Ads",
        sv: "Annonser",
        en: "Ads"
    },
    {
        code: "Views.Company.About",
        sv: "Om",
        en: "About"
    },
    {
        code: "Views.Company.post",
        sv: "Inlägg",
        en: "Posts"
    },
    {
        code: "Views.Company.CreateAd",
        sv: "Skapa annons",
        en: "Create ad"
    },
    {
        code: "Views.Company.Employees",
        sv: "Anställda",
        en: "Employees"
    },
    {
        code: "Views.CompleteRegistrationCompany.EnterAtLeastThreeCharacters",
        sv: "Ange minst tre tecken",
        en: "Enter at least three characters"
    },
    {
        code: "Views.CompleteRegistrationCompany.ChooseAAlternative",
        sv: "Välj ett alternativ",
        en: "Choose an alternative"
    },
    {
        code: "Views.CompleteRegistrationCompany.TheFieldAreInvalid",
        sv: "Fälten är ogiltiga",
        en: "The fields are invalid"
    },
    {
        code: "Views.CompleteRegistrationCompany.PlaceOfBusiness",
        sv: "Vad har ni för verksamhetsort?",
        en: "What is your place of business?"
    },
    {
        code: "Views.CompleteRegistrationCompany.CompanyDescription",
        sv: "Beskriv din verksamhet",
        en: "Describe your business"
    },
    {
        code: "Views.CompleteRegistrationCompany.EnterCounty",
        sv: "Ange län",
        en: "Enter county"
    },
    {
        code: "Views.CompleteRegistrationCompany.Address",
        sv: "Adress",
        en: "Address"
    },
    {
        code: "Views.CompleteRegistrationCompany.City",
        sv: "Ort",
        en: "City"
    },
    {
        code: "Views.CompleteRegistrationCompany.ZipCode",
        sv: "Postnummer",
        en: "Zip code"
    },
    {
        code: "Views.CompleteRegistrationCompany.Type",
        sv: "Vad har ni för typ av företag?",
        en: "What type of company do you have?"
    },
    {
        code: "Views.CompleteRegistrationCompany.CompanyType",
        sv: "Företagstyp",
        en: "Company type"
    },
    {
        code: "Views.CompleteRegistrationCompany.WriteHere",
        sv: "Skriv här",
        en: "Write here"
    },
    {
        code: "Views.CompleteRegistrationCompany.EverythingIsCorrect",
        sv: "Nu är allt klart!",
        en: "Everything is ready!"
    },
    {
        code: "Views.CompleteRegistrationCompany.Text",
        sv: "Tryck på nästa om du är nöjd med de uppgifter du har lämnat. Du kan också gå tillbaka och ändra dina uppgifter om du känner för det.",
        en: "Press next if you are satisfied with the information you have provided. You can also go back and change your information if you want to."
    },
    {
        code: "Views.CompleteRegistrationUser.WhereDoYouWork",
        sv: "I vilket län arbetar du?",
        en: "In which county do you work?"
    },
    {
        code: "Views.CompleteRegistrationUser.WhatIsYourOccupation",
        sv: "Vilken yrkesroll har du?",
        en: "What is your occupation?"
    },
    {
        code: "Views.CompleteRegistrationUser.SetADescription",
        sv: "Ge dig själv en beskrivning",
        en: "Give yourself a description"
    },
    {
        code: "Views.CompleteRegistrationUser.WhatDoYouWorkWith",
        sv: "Vad arbetar du med?",
        en: "What do you work with?"
    },
    {
        code: "Views.CompleteRegistrationUser.EnterWorkTitle",
        sv: "Ange arbetstitel",
        en: "Enter work title"
    },
    {
        code: "Views.Feed.CouldNotLikePost",
        sv: "Kunde inte gilla inlägget",
        en: "Could not like post"
    },
    {
        code: "Views.Post.Comment.Placeholder",
        sv: "Kommentera",
        en: "Comment"
    },
    {
        code: "Views.Feed.CouldNotUnlikePost",
        sv: "Kunde inte ogilla inlägget",
        en: "Could not unlike post"
    },
    {
        code: "Views.Feed.Share",
        sv: "Dela med dig av något",
        en: "Share something"
    },
    {
        code: "Views.Feed.AddSomething",
        sv: "Lägg upp",
        en: "Add post"
    },
    {
        code: "Views.Feed.CouldNotUploadImage",
        sv: "För tillfället kan du inte ladda upp bilder.",
        en: "You can not upload images at the moment."
    },
    {
        code: "Views.Footer.AboutUs",
        sv: "Om oss",
        en: "About us"
    },
    {
        code: "Views.Footer.AboutUsText",
        sv: `Vi på HendiGO AB har en vision om att skapa det bästa 
        digitala vertyget för bygg-branschen. Plattformen där 
        byggföretag får möjligheten till att hitta nya medarbetare, 
        nya och flera affärer på ett enkelt och tryggt sätt.`,
        en: `We at HendiGO AB have a vision of creating the best
        digital tool for the construction industry. The platform where
        construction companies have the opportunity to find new employees,
        new and more business in an easy and safe way.`
    },
    {
        code: "Views.Footer.Links",
        sv: "Länkar",
        en: "Links"
    },
    {
        code: "Views.Footer.Home",
        sv: "Hem",
        en: "Home"
    },
    {
        code: "Views.Footer.Services",
        sv: "Tjänster",
        en: "Services"
    },
    {
        code: "Views.Footer.Ads",
        sv: "Annonser",
        en: "Ads"
    },
    {
        code: "Views.Footer.Prices",
        sv: "Priser",
        en: "Prices"
    },
    {
        code: "Views.Footer.PrivacyPolicy",
        sv: "Integritetspolicy",
        en: "Privacy policy"
    },
    {
        code: "Views.Footer.SocialMedia",
        sv: "Sociala medier & kontakt",
        en: "Social media & contact"
    },
    {
        code: "Views.Handy.HandyAds.WeHaveEverythingWithinBuilding",
        sv: "Vi har allt inom bygg",
        en: "We have everything within building"
    },
    {
        code: "Views.Handy.HandyAds.AdItTech",
        sv: "IT-Tekniker - HendiGO AB - Heltid",
        en: "IT Technician - HendiGO AB - Full time"
    },
    {
        code: "Views.Handy.HandyAds.AdItTechText",
        sv: "Vi söker en driven IT-Tekniker",
        en: "We are looking for a driven IT Technician"
    },
    {
        code: "Views.Handy.HandyAds.Ads",
        sv: "Annonser",
        en: "Ads"
    },
    {
        code: "Views.Handy.HandyAds.NoAds",
        sv: "Inga annonser",
        en: "No ads"
    },
    {
        code: "Views.Handy.LatestNotifications.Notifications",
        sv: "Notiser",
        en: "Notifications"
    },
    {
        code: "Views.Handy.LatestNotifications.NoNotifications",
        sv: "Inga notiser",
        en: "No notifications"
    },
    {
        code: "Views.Job.FindYourDreamJobFastAndSimple",
        sv: "Hitta ditt drömjobb snabbt och enkelt.",
        en: "Find your dream job fast and easy."
    },
    {
        code: "Views.Job.RightNowThere",
        sv: "Just nu finns det",
        en: "Right now there are"
    },
    {
        code: "Views.Job.JobAds",
        sv: "jobbannonser",
        en: "job ads"
    },
    {
        code: "Views.Job.TopAdsWithinYourProfessionAndArea",
        sv: "Toppannonser inom din yrkesroll och område",
        en: "Top ads within your profession and area"
    },
    {
        code: "Views.Job.ViewingWithin",
        sv: "Visar inom",
        en: "Viewing within"
    },
    {
        code: "Views.Job.ShowMoreAds",
        sv: "Visa fler annonser",
        en: "Show more ads"
    },
    {
        code: "Views.Job.TopAds",
        sv: "Toppannonser",
        en: "Top ads"
    },
    {
        code: "Views.Job.ShowingWithinTopAds",
        sv: "Visar inom toppannonser",
        en: "Showing within top ads"
    },
    {
        code: "Views.Job.ThisPageAreForPrivateUsers",
        sv: "Denna sida är för privatpersoner",
        en: "This page is for private individuals"
    },
    {
        code: "Views.Job.LogInAsPrivateUserToSearchForJobs",
        sv: "Logga in som privatperson för att söka jobb",
        en: "Log in as a private individual to search for jobs"
    },
    {
        code: "Views.Login.Login",
        sv: "Logga in",
        en: "Log in"
    },
    {
        code: "Views.User.Contacts",
        sv: "Kontakter",
        en: "Contacts"
    },
    {
        code: "Views.User.Followers",
        sv: "Följare",
        en: "Followers"
    },
    {
        code: "Views.Login.Company",
        sv: "Företag?",
        en: "Company?"
    },
    {
        code: "Views.Company.CopyToClipboard",
        sv: "Kopierade länk",
        en: "Copied link"
    },
    {
        code: "Views.Login.SetValidOrganizationNumber",
        sv: "Ange giltigt organisationsnummer",
        en: "Enter a valid organization number"
    },
    {
        code: "Views.Login.SetOrganizationNumber",
        sv: "Ange Org.Nr",
        en: "Enter Org.Nr"
    },
    {
        code: "Views.Login.SetValidInput",
        sv: "Ange giltig e-post eller Org.Nr",
        en: "Enter valid email or Org.Nr"
    },
    {
        code: "Views.Login.SetEmailOrOrganizationNumber",
        sv: "Ange e-post eller Org.Nr",
        en: "Enter email or Org.Nr"
    },
    {
        code: "Views.Login.SetEmail",
        sv: "Ange e-post",
        en: "Enter email"
    },
    {
        code: "Views.AdApply.LoadingAd",
        sv: "Laddar annons...",
        en: "Loading ad..."
    },
    {
        code: "Views.AdApply.Title",
        sv: "Nu börjar vi med din ansökan!",
        en: "Now we start with your application!"
    },
    {
        code: "Views.AdApply.CoverLetter.Label",
        sv: "Skriv här",
        en: "Write here"
    },
    {
        code: "Views.MyApplications.LoadingMessage",
        sv: "Laddar dina ansökningar...",
        en: "Loading your applications..."
    },
    {
        code: "Views.AdApply.Apply",
        sv: "Ansök",
        en: "Apply"
    },
    {
        code: "Views.AdApply.CoverLetter.Description",
        sv: "Skriv lite om dig själv och varför du söker jobbet.",
        en: "Write a little about yourself and why you are applying for the job."
    },
    {
        code: "Views.AdApply.Description",
        sv: "Hos hendigo söker du enbart med din profil. Detta är tjänsten som du söker nu:",
        en: "At hendigo you only apply with your profile. This is the service you are looking for now:"
    },
    {
        code: "Views.Ad.AlreadyApplied",
        sv: "Företaget har fått din ansökan",
        en: "The company has received your application"
    },
    {
        code: "Views.Ad.ViewApplication",
        sv: "Visa ansökan →",
        en: "View application →"
    },
    {
        code: "Views.AdApply.LoadingCreateApplication",
        sv: "Skapar ansökan...",
        en: "Creating application..."
    },
    {
        code: "Views.AdApply.ApplicationCreated",
        sv: "Ansökan skapad är nu skapad. Du får besked när företaget har behandlat din ansökan.",
        en: "Application created is now created. You will be notified when the company has processed your application."
    },
    {
        code: "Views.Login.PasswordMinLength",
        sv: "Lösenordet måste vara minst 8 tecken",
        en: "The password must be at least 8 characters"
    },
    {
        code: "Views.Login.SetPassword",
        sv: "Ange lösenord",
        en: "Enter password"
    },
    {
        code: "Views.Login.ForgetPassword",
        sv: "Glömt lösenord?",
        en: "Forgot password?"
    },
    {
        code: "Views.Login.Or",
        sv: "Eller",
        en: "Or"
    },
    {
        code: "Views.Login.Register",
        sv: "Registrera",
        en: "Register"
    },
    {
        code: "Views.ManageAds.EditAdOverlay.EditAd",
        sv: "Redigera annons",
        en: "Edit ad"
    },
    {
        code: "Views.ManageAds.GeneralStatistics.Viewings",
        sv: "Visningar",
        en: "Viewings"
    },
    {
        code: "Views.ManageAds.GeneralStatistics.Searchers",
        sv: "Sökande",
        en: "Searchers"
    },
    {
        code: "Views.ManageAds.Ads",
        sv: "Annonser",
        en: "Ads"
    },
    {
        code: "Views.ManageAds.ClosedAds",
        sv: "Avslutade annonser",
        en: "Closed ads"
    },
    {
        code: "Views.ManageAds.Statistics",
        sv: "Statistik",
        en: "Statistics"
    },
    {
        code: "Views.ManageAds.YourAds",
        sv: "Dina annonser",
        en: "Your ads"
    },
    {
        code: "Views.Navbar.Register",
        sv: "Registrera",
        en: "Register"
    },
    {
        code: "Views.Navbar.Login",
        sv: "Logga in / Registrera",
        en: "Log in / Register"
    },
    {
        code: "Views.Navbar.Or",
        sv: "Eller",
        en: "Or"
    },
    {
        code: "Views.Navbar.AboutUs",
        sv: "Om oss",
        en: "About us"
    },
    {
        code: "Views.Navbar.NavigationDrawer.DarkMode",
        sv: "Mörkt läge",
        en: "Dark mode"
    },
    {
        code: "Views.Navbar.NavigationDrawer.Services",
        sv: "Tjänster",
        en: "Services"
    },
    {
        code: "Views.Navbar.NavigationDrawer.Pricing",
        sv: "Priser",
        en: "Pricing"
    },
    {
        code: "Views.Navbar.NavigationDrawer.Settings",
        sv: "Inställningar",
        en: "Settings"
    },
    {
        code: "Views.Post.CouldNotGetPost",
        sv: "Kunde inte hämta inlägg",
        en: "Could not get post"
    },
    {
        code: "Views.Post.CouldNotGetComments",
        sv: "Kunde inte hämta kommentarer",
        en: "Could not get comments"
    },
    {
        code: "Views.Post.CouldNotUnlikePost",
        sv: "Kunde inte ta bort gilla",
        en: "Could not remove like"
    },
    {
        code: "Views.Post.CouldNotRemovePost",
        sv: "Kunde inte ta bort inlägg",
        en: "Could not remove post"
    },
    {
        code: "Views.Post.Comments",
        sv: "Kommentarer",
        en: "Comments"
    },
    {
        code: "Views.Post.Upload",
        sv: "Lägg upp",
        en: "Upload"
    },
    {
        code: "Views.Register.RegisterSuccess",
        sv: "Registrering lyckades",
        en: "Registration succeeded"
    },
    {
        code: "Views.Register.Register",
        sv: "Registrera",
        en: "Register"
    },
    {
        code: "Views.Register.Company",
        sv: "Företag?",
        en: "Company?"
    },
    {
        code: "Views.Register.OrganizationNumber",
        sv: "Org. Nummer, bara siffor",
        en: "Org. Number, only numbers"
    },
    {
        code: "Views.Register.CompanyName",
        sv: "Företagsnamn",
        en: "Company name"
    },
    {
        code: "Views.Register.FullName",
        sv: "Fullständigt namn",
        en: "Full name"
    },
    {
        code: "Views.Register.Email",
        sv: "E-post",
        en: "Email"
    },
    {
        code: "Views.Register.PasswordMinLength",
        sv: "Lösenordet måste vara minst 8 tecken",
        en: "The password must be at least 8 characters"
    },
    {
        code: "Views.Register.Password",
        sv: "Lösenord",
        en: "Password"
    },
    {
        code: "Views.Register.ConfirmPassword",
        sv: "Bekräfta lösenord",
        en: "Confirm password"
    },
    {
        code: "Views.Register.PasswordDoesNotMatch",
        sv: "Lösenorden matchar inte",
        en: "Passwords do not match"
    },
    {
        code: "Views.Register.AcceptTerms",
        sv: "Jag har läst och godkänner HendiGOs ",
        en: "I have read and accept HendiGOs "
    },
    {
        code: "Views.Register.PrivacyPolicy",
        sv: "Integritetspolicy",
        en: "Privacy Policy"
    },
    {
        code: "Views.Register.Register",
        sv: "Registrera",
        en: "Register"
    },
    {
        code: "Views.Register.AlreadyHaveAccount",
        sv: "Har du redan ett konto?",
        en: "Already have an account?"
    },
    {
        code: "Views.Register.Login",
        sv: "Logga in",
        en: "Log in"
    },
    {
        code: "Views.SearchResult.ShowResultFor",
        sv: "Visa resultat för ",
        en: "Show results for "
    },
    {
        code: "Views.SearchResult.People",
        sv: "Personer",
        en: "People"
    },
    {
        code: "Views.SearchResult.Company",
        sv: "Företag",
        en: "Company"
    },
    {
        code: "Views.SearchResult.Ads",
        sv: "Annonser",
        en: "Ads"
    },
    {
        code: "Views.SearchResult.Posts",
        sv: "Inlägg",
        en: "Posts"
    },
    {
        code: "Views.SearchResult.Groups",
        sv: "Grupper",
        en: "Groups"
    },
    {
        code: "Views.SideNavBar.Home",
        sv: "Hem",
        en: "Home"
    },
    {
        code: "Views.SideNavBar.Cooperation",
        sv: "Samarbete",
        en: "Cooperation"
    },
    {
        code: "Views.SideNavBar.MyCompany",
        sv: "Mitt företag",
        en: "My company"
    },
    {
        code: "Views.SideNavBar.Ads",
        sv: "Annonser",
        en: "Ads"
    },
    {
        code: "Views.SideNavBar.AdsTab",
        sv: "Annonsering",
        en: "Advertising"
    },
    {
        code: "Views.SideNavBar.Marketing",
        sv: "Marknadsföring",
        en: "Marketing"
    },
    {
        code: "Views.SideNavBar.Project",
        sv: "Projekt",
        en: "Project"
    },
    {
        code: "Views.SideNavBar.Subcontractor",
        sv: "Underleverantör",
        en: "Subcontractor"
    },
    {
        code: "Views.SideNavBar.TurnkeyContractor",
        sv: "Totalentreprenad",
        en: "Turnkey contractor"
    },
    {
        code: "Views.SideNavBar.CompetenceAndSecurity",
        sv: "Kompetens och säkerhet",
        en: "Competence and security"
    },
    {
        code: "Views.SideNavBar.MyProfile",
        sv: "Mitt konto",
        en: "My account"
    },
    {
        code: "Views.SideNavBar.Work",
        sv: "Jobb",
        en: "Work"
    },
    {
        code: "Views.SideNavBar.Applications",
        sv: "Ansökningar",
        en: "Applications"
    },
    {
        code: "Views.SideNavBar.Network",
        sv: "Nätverk",
        en: "Network"
    },
    {
        code: "Views.SideNavBar.Groups",
        sv: "Grupper",
        en: "Groups"
    },
    {
        code: "Views.SideNavBar.CouldNotLogOut",
        sv: "Kunde inte logga ut",
        en: "Could not log out"
    },
    {
        code: "Views.SideNavBar.LogOut",
        sv: "Logga ut",
        en: "Log out"
    },
    {
        code: "Views.UnderConstruction.Title",
        sv: "Sidan är under konstruktion",
        en: "The page is under construction"
    },
    {
        code: "Views.User.EditProfile",
        sv: "Redigera profil",
        en: "Edit profile"
    },
    {
        code: "Views.User.EditPostTitle",
        sv: "Redigera inlägg",
        en: "Edit post"
    },
    {
        code: "Views.User.PublicProfile",
        sv: "Vem som helst kan följa dig",
        en: "Anyone can follow you"
    },
    {
        code: "Views.User.Name",
        sv: "Namn",
        en: "Name"
    },
    {
        code: "Views.User.Locality",
        sv: "Ort",
        en: "Locality"
    },
    {
        code: "Views.User.Occupation",
        sv: "Arbetsroll",
        en: "Occupation"
    },
    {
        code: "Views.User.Description",
        sv: "Beskrivning",
        en: "Description"
    },
    {
        code: "Views.User.NoDescription",
        sv: "Ingen description ännu? Skriv en här!",
        en: "No description yet? Write one here!"
    },
    {
        code: "Views.User.OpenToWork",
        sv: "Öppen för nya jobb?",
        en: "Open to new jobs?"
    },
    {
        code: "Views.User.UpdatePassword",
        sv: "Uppdatera lösenord",
        en: "Update password"
    },
    {
        code: "Views.User.UserNotFound",
        sv: "Användaren kunde inte hittas",
        en: "User not found"
    },
    {
        code: "Views.User.CopyToClipboard",
        sv: "Kopiera till urklipp",
        en: "Copy to clipboard"
    },
    {
        code: "Views.User.NoDescription",
        sv: "Ingen beskrivning ännu",
        en: "No description yet"
    },
    {
        code: "Views.User.About",
        sv: "Om",
        en: "About"
    },
    {
        code: "Views.User.Experience",
        sv: "Erfarenhet",
        en: "Experience"
    },
    {
        code: "Views.User.Posts",
        sv: "Inlägg",
        en: "Posts"
    },
    {
        code: "Views.User.Certificates",
        sv: "Certifikat",
        en: "Certificates"
    },
    {
        code: "Views.User.CreateContact",
        sv: "Skapa kontakt",
        en: "Create contact"
    },
    {
        code: "Elements.Cosmetic.Overlay.ButtonSave",
        sv: "Spara",
        en: "Save"
    },
    {
        code: "Elements.Cosmetic.Overlay.ButtonCancel",
        sv: "Avbryt",
        en: "Cancel"
    },
    {
        code: "Values.FormOfEmployment.FullTime",
        sv: "Heltid",
        en: "Full time"
    },
    {
        code: "Views.User.CreateExperience.Title",
        sv: "Skapa erfarenhet",
        en: "Create experience"
    },
    {
        code: "Views.User.EditExperience.Title",
        sv: "Redigera erfarenhet",
        en: "Edit experience"
    },
    {
        code: "Views.User.CreateExperience.PositionTitle",
        sv: "Positionstitel",
        en: "Position title"
    },
    {
        code: "Views.User.CreateExperience.EmploymentType",
        sv: "Anställningstyp",
        en: "Employment type"
    },
    {
        code: "Views.User.CreateExperience.EmploymentType.FullTime",
        sv: "Heltid",
        en: "Full time"
    },
    {
        code: "Values.FormOfEmployment.PartTime",
        sv: "Deltid",
        en: "Part time"
    },
    {
        code: "Values.FormOfEmployment.Internship",
        sv: "Praktik",
        en: "Internship"
    },
    {
        code: "Values.FormOfEmployment.Volunteer",
        sv: "Frivillig",
        en: "Volunteer"
    },
    {
        code: "Views.User.CreateExperience.EmploymentType.PartTime",
        sv: "Deltid",
        en: "Part time"
    },
    {
        code: "Views.User.CreateExperience.EmploymentType.Internship",
        sv: "Praktik",
        en: "Internship"
    },
    {
        code: "Views.User.CreateExperience.EmploymentType.Volunteer",
        sv: "Frivillig",
        en: "Volunteer"
    },
    {
        code: "Views.User.CreateExperience.CompanyName",
        sv: "Företagsnamn",
        en: "Company name"
    },
    {
        code: "Views.User.CreateExperience.Month",
        sv: "Månad",
        en: "Month"
    },
    {
        code: "Views.User.CreateExperience.Year",
        sv: "År",
        en: "Year"
    },
    {
        code: "Views.User.CreateExperience.StartDate",
        sv: "Startdatum",
        en: "Start date"
    },
    {
        code: "Views.User.CreateExperience.EndDate",
        sv: "Slutdatum",
        en: "End date"
    },
    {
        code: "Views.User.CreateExperience.Description",
        sv: "Beskrivning",
        en: "Description"
    },
    {
        code: "Views.User.CreateExperience.ExternalLink",
        sv: "Extern länk",
        en: "External link"
    },
    {
        code: "Views.User.CreateExperience.AddMedia",
        sv: "Lägg till media",
        en: "Add media"
    },
    {
        code: "Views.User.EditExperience.SelectExperienceToEdit",
        sv: "Välj erfarenhet att redigera",
        en: "Select experience to edit"
    },
    {
        code: "Views.User.CreateExperience.Delete",
        sv: "Ta bort erfarenhet",
        en: "Delete experience"
    },
    {
        code: "Views.User.CreateExperience.CompanyName.NoOptions",
        sv: "Inga företag hittades",
        en: "No companies found"
    },
    {
        code: "Views.CreateAd.Step.Information",
        sv: "Fyll i information",
        en: "Fill in information"
    },
    {
        code: "Views.CreateAd.Step.Payment",
        sv: "Fakturering",
        en: "Payment"
    },
    {
        code: "Views.CreateAd.Step.Preview",
        sv: "Förhandsgranska annons",
        en: "Preview ad"
    },
    {
        code: "Views.CreateAd.Step.Create",
        sv: "Skapa annons",
        en: "Create ad"
    },
    {
        code: "Views.CreateAd.NextStep",
        sv: "Nästa steg",
        en: "Next step"
    },
    {
        code: "Views.CreateAd.PreviousStep",
        sv: "Tillbaka",
        en: "Previous step"
    },
    {
        code: "Views.CreateAd.EnterInformation.Description",
        sv: "Genom att skapa en annons på HendiGO kan du enkelt hitta rätt person för ditt företag. Vi matchar dig med personer som har rätt kompetens och erfarenhet för att passa just din tjänst. Annonsen når ut till tusentals personer som är intresserade av att jobba inom ditt område.",
        en: "By creating an ad on HendiGO you can easily find the right person for your company. We match you with people who have the right skills and experience to fit your job. The ad reaches out to thousands of people who are interested in working in your area."
    },
    {
        code: "Views.CreateAd.EnterInformation.Title",
        sv: "Titel",
        en: "Title"
    },
    {
        code: "Views.CreateAd.EnterInformation.JobTitle",
        sv: "Yrkesbenämning",
        en: "Job title"
    },
    {
        code: "Views.CreateAd.EnterInformation.BasicInformation",
        sv: "Grundläggande Information",
        en: "Basic information"
    },
    {
        code: "Views.CreateAd.EnterInformation.BasicInformation.Description",
        sv: "Lägg till grundläggande information om yrket.",
        en: "Add basic information about the job."
    },
    {
        code: "Views.CreateAd.EnterInformation.FormOfEmployment",
        sv: "Anställningsform",
        en: "Form of employment"
    },
    {
        code: "Views.CreateAd.EnterInformation.Location",
        sv: "Plats",
        en: "Location"
    },
    {
        code: "Views.CreateAd.EnterInformation.DueDate",
        sv: "Sista ansökningsdag",
        en: "Due date"
    },
    {
        code: "Views.CreateAd.EnterInformation.AboutTheJobSeeker",
        sv: "Om den sökande",
        en: "About the job seeker"
    },
    {
        code: "Views.CreateAd.EnterInformation.AboutTheCompany.Description",
        sv: "Lägg till information om företaget som är intressant för den sökande.",
        en: "Add information about the company that is interesting for the job seeker."
    },
    {
        code: "Views.CreateAd.EnterInformation.AboutThePosition.Description",
        sv: "Lägg till information om tjänsten som är intressant för den sökande.",
        en: "Add information about the position that is interesting for the job seeker."
    },
    {
        code: "Views.CreateAd.EnterInformation.AboutTheJobSeeker.Description",
        sv: "Lägg till information om vad ni söker hos den sökande.",
        en: "Add information about what you are looking for in the job seeker."
    },
    {
        code: "Views.CreateAd.EnterInformation.Requirement.Description",
        sv: "Lägg till krav som ni kräver hos den sökande. Det går att lägga till flera krav.",
        en: "Add requirements that you require from the job seeker. You can add multiple requirements."
    },
    {
        code: "Views.CreateAd.EnterInformation.Requirement",
        sv: "Krav ",
        en: "Requirement "
    },
    {
        code: "Views.CreateAd.EnterInformation.AddRequirement",
        sv: "+ Lägg till krav",
        en: "+ Add requirement"
    },
    {
        code: "Views.CreateAd.EnterInformation.Contact.Description",
        sv: "Kontakt sker via företagets kontaktuppgifter.",
        en: "Contact is made via the company's contact information."
    },
    {
        code: "Views.CreateAd.EnterInformation.Level",
        sv: "Nivå",
        en: "Level"
    },
    {
        code: "Views.CreateAd.EnterInformation.Level.Description",
        sv: "Välj vilken typ av annons ni vill ha.",
        en: "Select which type of ad you want."
    },
    {
        code: "Views.CreateAd.EnterInformation.AboutTheCompany",
        sv: "Beskriv företaget",
        en: "Describe the company"
    },
    {
        code: "Views.CreateAd.EnterInformation.AboutThePosition",
        sv: "Beskriv tjänsten",
        en: "Describe the position"
    },
    {
        code: "Views.CreateAd.EnterInformation.DescribeTheJobSeeker",
        sv: "Beskriv jobbsökande",
        en: "Describe the job seeker"
    },
    {
        code: "Views.CreateAd.Payment.Title",
        sv: "Fakturering",
        en: "Billing"
    },
    {
        code: "Views.CreateAd.Payment.Description",
        sv: "Fyll i dina uppgifter för fakturering.",
        en: "Fill in your billing information."
    },
    {
        code: "Views.CreateAd.Payment.Products",
        sv: "Produkter",
        en: "Products"
    },
    {
        code: "Views.CreateAd.Payment.Total",
        sv: "Totalt",
        en: "Total"
    },
    {
        code: "Views.CreateAd.Payment.Terms",
        sv: "Betalningsvillkor",
        en: "Payment terms"
    },
    {
        code: "Views.CreateAd.Payment.ShipmentDate",
        sv: "Beräknat leveransdatum",
        en: "Estimated delivery date"
    },
    {
        code: "Views.CreateAd.Payment.PaymentDueDate",
        sv: "Förfallodatum",
        en: "Due date"
    },
    {
        code: "Views.CreateAd.Payment.InvoiceInformation",
        sv: "Fakturauppgifter",
        en: "Invoice information"
    },
    {
        code: "Views.CreateAd.Payment.InvoiceInformation.Description",
        sv: "Fyll i faktureringsaddress-uppgifter. Vi har förifyllt med information från er profil.",
        en: "Fill in billing address information. We have pre-filled with information from your profile."
    },
    {
        code: "Views.CreateAd.Payment.InvoiceInformation.CompanyName",
        sv: "Företagsnamn",
        en: "Company name"
    },
    {
        code: "Views.CreateAd.Payment.InvoiceInformation.Address",
        sv: "Adress",
        en: "Address"
    },
    {
        code: "Views.CreateAd.Payment.InvoiceInformation.City",
        sv: "Stad",
        en: "City"
    },
    {
        code: "Views.CreateAd.Payment.InvoiceInformation.ZipCode",
        sv: "Postnummer",
        en: "Zip code"
    },
    {
        code: "Views.CreateAd.CreateAd.Title",
        sv: "Skapa annons",
        en: "Create ad"
    },
    {
        code: "View.MyApplications.GoToApplication",
        sv: "Gå till ansökan",
        en: "Go to application"
    },
    {
        code: "Views.CreateAd.CreateAd.Description",
        sv: "Genom att skapa en annons på HendiGO kan du enkelt hitta rätt person för ditt företag. Vi matchar dig med personer som har rätt kompetens och erfarenhet för att passa just din tjänst. Annonsen når ut till tusentals personer som är intresserade av att jobba inom ditt område.",
        en: "By creating an ad on HendiGO you can easily find the right person for your company. We match you with people who have the right skills and experience to fit your job. The ad reaches out to thousands of people who are interested in working in your area."
    },
    {
        code: "Views.CreateAd.CreateAd.Button",
        sv: "Skapa annons",
        en: "Create ad"
    },
    {
        code: "Views.ManageAds.Title",
        sv: "Hantera annonser",
        en: "Manage ads"
    },
    {
        code: "Views.ManageAds.CreateAd.Button",
        sv: "+ Skapa annons",
        en: "+ Create ad"
    },
    {
        code: "Views.AdApply.Letter",
        sv: "Personligt brev",
        en: "Personal letter"
    },
    {
        code: "Views.ManageAds.Search.Label",
        sv: "Sök annons",
        en: "Search ad"
    },
    {
        code: "Views.ManageAds.Description",
        sv: "Här kan du hantera dina annonser.",
        en: "Here you can manage your ads."
    },
    {
        code: "Views.User.CreateExperience.Companies",
        sv: "Företag",
        en: "Companies"
    },
    {
        code: "Product.Ad.Standard.Name",
        sv: "Standard",
        en: "Standard"
    },
    {
        code: "Product.Ad.Standard.Description",
        sv: "För dig som vill nå ut till en liten målgrupp.",
        en: "For you who want to reach out to a small audience."
    },
    {
        code: "Product.Ad.Premium.Name",
        sv: "Premium",
        en: "Premium"
    },
    {
        code: "Product.Ad.Premium.Description",
        sv: "För dig som vill nå ut till en större målgrupp.",
        en: "For you who want to reach out to a larger audience."
    },
    {
        code: "PageTitle.Groups",
        sv: "Grupper",
        en: "Groups"
    },
    {
        code: "PageTitle.User",
        sv: "Användare",
        en: "User"
    },
    {
        code: "PageTitle.AdApply",
        sv: "Ansökan",
        en: "Application"
    },
    {
        code: "PageTitle.UnderConstruction",
        sv: "Under uppbyggnad",
        en: "Under construction"
    },
    {
        code: "PageTitle.SearchResult",
        sv: "Sökresultat",
        en: "Search result"
    },
    {
        code: "PageTitle.Register",
        sv: "Registrera",
        en: "Register"
    },
    {
        code: "PageTitle.NotFound",
        sv: "Sidan kunde inte hittas",
        en: "Page not found"
    },
    {
        code: "PageTitle.Network",
        sv: "Nätverk",
        en: "Network"
    },
    {
        code: "PageTitle.ManageAds",
        sv: "Hantera annonser",
        en: "Manage ads"
    },
    {
        code: "PageTitle.Login",
        sv: "Logga in",
        en: "Login"
    },
    {
        code: "PageTitle.Job",
        sv: "Jobb",
        en: "Job"
    },
    {
        code: "PageTitle.Home",
        sv: "Hem",
        en: "Home"
    },
    {
        code: "PageTitle.Feed",
        sv: "Flöde",
        en: "Feed"
    },
    {
        code: "PageTitle.CompleteRegistrationUser",
        sv: "Slutför registrering",
        en: "Complete registration"
    },
    {
        code: "PageTitle.CompleteRegistrationCompany",
        sv: "Slutför registrering",
        en: "Complete registration"
    },
    {
        code: "PageTitle.Company",
        sv: "Företag",
        en: "Company"
    },
    {
        code: "PageTitle.AdsSearchResult",
        sv: "Resultat för annonser",
        en: "Ads search result"
    },
    {
        code: "PageTitle.Ad",
        sv: "Annons",
        en: "Ad"
    },
    {
        code: "PageTitle.Post",
        sv: "Inlägg",
        en: "Post"
    },
    {
        code: "Post.PostDeleted",
        sv: "Inlägget har tagits bort",
        en: "Post has been deleted"
    },
    {
        code: "Post.PostEdited",
        sv: "Inlägget har redigerats",
        en: "Post has been edited"
    },
    {
        code: "Invoice.PaymentTerms30Days",
        sv: "30 dagar",
        en: "30 days"
    },
    {
        code: "Component.Composite.RadioProduct.ReadMoreAbout",
        sv: "Läs mer om",
        en: "Read more about"
    },
    {
        code: "Component.Composite.AdCard.ControlPanel.Button",
        sv: "Kontrollpanel",
        en: "Control panel"
    },
    {
        code: "Company.Type.TurnkeyContractor",
        sv: "Totalentreprenad",
        en: "Turnkey contractor"
    },
    {
        code: "Company.Type.SubContractor",
        sv: "Underleverantör",
        en: "Subcontractor"
    },
    {
        code: "Company.Type.Contractor",
        sv: "Entreprenad",
        en: "Contractor"
    },
    {
        code: "Company.Type.Supplier",
        sv: "Leverantör / Byggvaruhus",
        en: "Supplier / Building retailer"
    },
    {
        code: "Company.Type.Recruitment",
        sv: "Rekryteringsföretag",
        en: "Recruitment company"
    },
    {
        code: "Company.Type.Organization",
        sv: "Branschorganisation / Fackförbund",
        en: "Industry organization / Union"
    },
    {
        code: "Company.Type.News",
        sv: "Nyhetssida / Branschtidning",
        en: "News page / Industry magazine"
    },
    {
        code: "Values.Location.Blekinge",
        sv: "Blekinge",
        en: "Blekinge"
    },
    {
        code: "Values.Location.Dalarna",
        sv: "Dalarna",
        en: "Dalarna"
    },
    {
        code: "Values.Location.Gotland",
        sv: "Gotland",
        en: "Gotland"
    },
    {
        code: "Values.Location.Gävleborg",
        sv: "Gävleborg",
        en: "Gävleborg"
    },
    {
        code: "Values.Location.Halland",
        sv: "Halland",
        en: "Halland"
    },
    {
        code: "Values.Location.Jämtland",
        sv: "Jämtland",
        en: "Jämtland"
    },
    {
        code: "Values.Location.Jönköping",
        sv: "Jönköping",
        en: "Jönköping",
    },
    {
        code: "Values.Location.Kalmar",
        sv: "Kalmar",
        en: "Kalmar"
    },
    {
        code: "Values.Location.Kronoberg",
        sv: "Kronoberg",
        en: "Kronoberg"
    },
    {
        code: "Values.Location.Norrbotten",
        sv: "Norrbotten",
        en: "Norrbotten"
    },
    {
        code: "Values.Location.Skåne",
        sv: "Skåne",
        en: "Skåne"
    },
    {
        code: "Values.Location.Stockholm",
        sv: "Stockholm",
        en: "Stockholm"
    },
    {
        code: "Values.Location.Södermanland",
        sv: "Södermanland",
        en: "Södermanland"
    },
    {
        code: "Values.Location.Uppsala",
        sv: "Uppsala",
        en: "Uppsala"
    },
    {
        code: "Values.Location.Värmland",
        sv: "Värmland",
        en: "Värmland"
    },
    {
        code: "Values.Location.Västerbotten",
        sv: "Västerbotten",
        en: "Västerbotten"
    },
    {
        code: "Values.Location.Västernorrland",
        sv: "Västernorrland",
        en: "Västernorrland"
    },
    {
        code: "Values.Location.Västmanland",
        sv: "Västmanland",
        en: "Västmanland"
    },
    {
        code: "Values.Location.VästraGötaland",
        sv: "Västra Götaland",
        en: "Västra Götaland"
    },
    {
        code: "Values.Location.Örebro",
        sv: "Örebro",
        en: "Örebro"
    },
    {
        code: "Values.Location.Östergötland",
        sv: "Östergötaland",
        en: "Östergötaland"
    },
    {
        code: "Values.Location.HelaSverige",
        sv: "Hela sverige",
        en: "Whole of sweden"
    },
    {
        code: "PageTitle.MyApplications",
        sv: "Mina ansökningar",
        en: "My applications"
    },
    {
        code: "Views.MyApplications.NoApplications",
        sv: "Du har inga ansökningar",
        en: "You have no applications"
    },
    {
        code: "Views.MyApplications.SearchForAds",
        sv: "Sök efter annonser",
        en: "Search for ads"
    },
    {
        code: "Views.MyApplication.Title",
        sv: "Din ansökan",
        en: "Your application"
    },
    {
        code: "Application.Status.Pending",
        sv: "Väntar",
        en: "Pending"
    },
    {
        code: "Application.Status.Handled",
        sv: "Hanterad",
        en: "Handled"
    },
    {
        code: "Application.Status.Rejected",
        sv: "Avslagen",
        en: "Rejected"
    },
    {
        code: "Application.Status.Accepted",
        sv: "Accepterad",
        en: "Accepted"
    },
    {
        code: "Views.ManageAd.Title",
        sv: "Hantera din annons",
        en: "Manage your ad"
    },
    {
        code: "Views.ManageAd.Description",
        sv: "Här kan du hantera din annons och dess ansökningar. Detta gäller annonsen:",
        en: "Here you can manage your ad and its applications. This is the ad:"
    },
    {
        code: "Views.ManageAd.Applicants",
        sv: "Sökande",
        en: "Applicants"
    },
    {
        code : "PageTitle.ManageAd",
        sv: "Hantera annons",
        en: "Manage ad"
    },
    {
        code: "Views.ManageAd.Settings",
        sv: "Inställningar",
        en: "Settings"
    },
    {
        code: "Views.Groups.Title",
        sv: "Dina grupper",
        en: "Your groups"
    },
    {
        code: "Views.Groups.Description",
        sv: "Här kan du se dina grupper och hantera dem.",
        en: "Here you can see your groups and manage them."
    },
    {
        code: "Views.Groups.CreateGroup",
        sv: "+ Skapa grupp",
        en: "+ Create group"
    },
    {
        code: "Views.Groups.SearchForGroups",
        sv: "Sök efter grupp",
        en: "Search for group"
    },
    {
        code: "Views.Groups.CreateGroupOverlay.Title",
        sv: "Skapa grupp",
        en: "Create group"
    },
    {
        code: "Views.Groups.CreateGroupOverlay.Name",
        sv: "Namn",
        en: "Name"
    },
    {
        code: "Views.Groups.CreateGroupOverlay.Description",
        sv: "Beskrivning",
        en: "Description"
    },
    {
        code: "Views.Groups.CreateGroupOverlay.DescriptionText",
        sv: "Här kan du skapa en grupp. Börja genom att namnge den och ge den en kort beskrivning. När du skapar en grupp går du automatiskt med i den och blir administratör.",
        en: "Here you can create a group. Start by naming it and giving it a short description. When you create a group, you automatically join it and become an administrator."
    }
] as Text[]