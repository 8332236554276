import React from "react";
import { LatestNotificationStyles } from "../Styling";
import { Session } from "~base/Model/Session";
import { Row } from "~base/Components/Elements/Cosmetic/Row";
import { apiv2 } from "~base/API/v2/apiv2";
import { useNavigate } from "react-router-dom";
import { NotificationResponse } from "~base/API/ResponseTypes/NotificationResponse";
import { Avatar } from "@mui/material";
import { useIntl } from "~base/Internalization/IntlContext";

interface LatestNotificationProps {
    session: Session;
}

export const LatestNotification: React.FC<LatestNotificationProps> = ({ session }) => {
    
    const intl = useIntl();
    const classes = LatestNotificationStyles();
    const navigate = useNavigate();

    const [notifications, setNotifications] = React.useState<NotificationResponse[]>([] as NotificationResponse[]);

    React.useEffect(() => {
        apiv2(`api/v2/get/notifications/${session.userId ?? session.companyId}`, "GET").then((res) => {
            if (res.error !== null) {
                session.setInformation("error", res.error);
            } else {
                const result = res.data as NotificationResponse[];
                setNotifications(result.sort((a, b) => new Date(b.time_created).getTime() - new Date(a.time_created).getTime()));
            }
        });
    // eslint-disable-next-line
    }, []);

    function removeNotification(id: string) {
        setNotifications(notifications.filter((n) => n.id !== id));
        apiv2(`api/v2/delete/notification/${id}`, "DELETE").then((res) => {
            if (res.error !== null) {
                session.setInformation("error", res.error);
            }
        });
    }

    return <div className={classes.wrapper}>
        <div className={classes.title}>{intl.formatText("Views.Handy.LatestNotifications.Notifications")}</div>
        {notifications?.length > 0 ? notifications.map((notification, index) => {
            return (
                <Row
                    key={notification.id}
                    onClick={() => {
                        if (notification.type === "contact_request") {
                            removeNotification(notification.id);
                            navigate("/natverk")
                        }
                        if (notification.type === "follow"){
                            removeNotification(notification.id);
                        }
                    }}
                    className={classes.notification_container}
                >
                    <Avatar 
                        src={notification.small_image_pathname}
                        className={classes.avatar}
                        alt="avatar"
                    />

                    <div className={classes.text}>
                        <div>{notification.title}</div>
                        <div className={classes.message}>
                            {intl.formatText(notification.message)}
                        </div>
                    </div>

                    <div className={classes.time}>
                        {intl.formatTimeShort(new Date(notification.time_created))}
                    </div>
                </Row>
            );
        }) : <div className={classes.noNotificationsText}>{intl.formatText("Views.Handy.LatestNotifications.NoNotifications")}</div>}
    </div>
}