import { CreateAdAction } from "../Store";
import { CreateAdViewState } from "../Types";
import { Button } from "@mui/material";
import { HiOutlineArrowNarrowLeft } from "react-icons/hi";
import { Row } from "~base/Components/Elements/Cosmetic/Row";
import { CreateAdStyles } from "../Styling";
import { useNavigate } from "react-router-dom";
import { createAd } from "../Fetch";
import { CreateAdRequest } from "~base/Model/RequestModels/CreateAdRequest";
import { Session } from "~base/Model/Session";
import { useIntl } from "~base/Internalization/IntlContext";

interface CreateAdProps {
    state: CreateAdViewState;
    dispatch: React.Dispatch<CreateAdAction>;
    session: Session;
}

export const CreateAd: React.FC<CreateAdProps> = ({ state, dispatch, session }) => {
    
    const intl = useIntl();
    const classes = CreateAdStyles();
    const navigate = useNavigate();

    return <>
        <div className={classes.sectionWrapper}>
            <Row className={classes.sectionTitleRow}>
                <h1 className={classes.sectionTitle}>
                    {intl.formatText("Views.CreateAd.CreateAd.Title")}
                </h1>
            </Row>

            <p className={classes.description}>
                {intl.formatText("Views.CreateAd.CreateAd.Description")}
            </p>

            <Button
                variant="contained"
                style={{ width: "100%", margin: "1rem 0 1rem 0" }}
                onClick={() => {
                    const createAdRequest = {
                        ad: state.ad,
                        invoice: state.invoice,
                        adRequirements: state.adRequirements,
                        product: state.chosenProduct,
                    } as CreateAdRequest;
                    createAd(session, createAdRequest, navigate);
                }}
            >
                {intl.formatText("Views.CreateAd.CreateAd.Button")}
            </Button>
        </div>

        <Row style={{ marginTop: "2rem" }}>
            <Button
                variant="contained"
                onClick={() => dispatch({ type: "setCurrentStep", value: state.invoice.amount > 0 ? "Views.CreateAd.Step.Payment" : "Views.CreateAd.Step.Preview" })}
            >
                <HiOutlineArrowNarrowLeft style={{ marginRight: "5px" }} />
                {intl.formatText("Views.CreateAd.PreviousStep")}
            </Button>
        </Row>
    </>
}