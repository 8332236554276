import { Card, CardActions, CardContent, FormControlLabel, Link, Radio, Typography } from "@mui/material";
import { createUseStyles } from "react-jss";
import { useIntl } from "~base/Internalization/IntlContext";
import { Product as ProductEntity } from "~base/Model/Entities/Product";
import { HendigoTheme } from "~base/Resources/Styling/HendigoTheme";

const useStyles = createUseStyles((theme: HendigoTheme) => ({
    content: {
        backgroundColor: theme.backgroundSecondary,
        color: theme.textPrimary,
    }
}));

interface ProductProps {
    value: string;
    checked: boolean;
    onChange: (value: string) => void;
    product: ProductEntity;
}

export const RadioProduct: React.FC<ProductProps> = ({ value, checked, onChange, product }) => {
    
    const intl = useIntl();
    const classes = useStyles();

    return <Card variant="outlined" >
        <CardContent className={classes.content}>
            <FormControlLabel
                control={
                    <Radio
                        value={value}
                        checked={checked}
                        onChange={e => onChange(e.target.value)}

                    />
                }
                label={intl.formatText(product.name)}
            />
            <Typography sx={{ mb: 1.5 }}>
                {intl.formatText(product.description)}
            </Typography>
            <Typography variant="h3" component="div">
                {product.price} kr
            </Typography>
            <CardActions style={{ padding: "10px 0 0 0" }}>
                <Link>{intl.formatText("Component.Composite.RadioProduct.ReadMoreAbout")} "{intl.formatText(product.name)}"</Link>
            </CardActions>
        </CardContent>
    </Card>
}