import { ViewBaseProps } from "~base/Model/ViewBaseProps";
import { useStyles } from "./Styling";
import React from "react";
import { useNavigate } from "react-router-dom";
import { initialState, reducer } from "./Store";
import { useIntl } from "~base/Internalization/IntlContext";
import { MiddleWrapper } from "~base/Components/Elements/Cosmetic/MiddleWrapper";
import { Row } from "~base/Components/Elements/Cosmetic/Row";
import { Button, TextField } from "@mui/material";
import { muiStyles } from "~base/Resources/Styling/MuiTheme";
import { CreateGroupOverlay } from "./ChildComponents/CreateGroupOverlay";
import { getUserGroups } from "./Fetch";
import { Listing } from "~base/Components/Elements/DataVisualization/Listing";
import { Divider } from "~base/Components/Elements/Cosmetic/Divider";
import { GROUP } from "~base/Routing/Paths";

interface ViewProps extends ViewBaseProps {

}

export const Groups: React.FC<ViewProps> = ({ session }) => {
    window.view = "Groups";

    const intl = useIntl();

    intl.formatPageTitle("PageTitle.Groups");

    // Styling Classes
    const classes = useStyles();
    const muiClasses = muiStyles();

    // Navigation
    const navigate = useNavigate();

    // State and dispatch
    const [state, dispatch] = React.useReducer(reducer, initialState());

    React.useEffect(() => {
        getUserGroups(dispatch, session);
    }, []);

    const filteredGroupsBySearch = state.groups.filter(group => {
        return (
            group.name.toLowerCase().includes(state.search.toLowerCase())
            || group.description.toLowerCase().includes(state.search.toLowerCase())
        );
    });

    return <MiddleWrapper>
        <CreateGroupOverlay
            showOverlay={state.createGroupOverlayState.open}
            session={session}
            onCancel={() => dispatch({ type: "setCreateGroupOverlayOpen", value: false })}
        />

        <div className={classes.sectionWrapper}>
            <h2 className={classes.sectionTitle}>
                {intl.formatText("Views.Groups.Title")}
            </h2>
            <p className={classes.sectionDescription}>
                {intl.formatText("Views.Groups.Description")}
            </p>

            <Row>
                <Button
                    variant="contained"
                    onClick={() => dispatch({ type: "setCreateGroupOverlayOpen", value: true })}
                    className={muiClasses.button}
                >
                    {intl.formatText("Views.Groups.CreateGroup")}
                </Button>

                <TextField
                    className={muiClasses.textField}
                    size="small"
                    id="outlined-search"
                    label={intl.formatText("Views.Groups.SearchForGroups")}
                    type="search"
                    style={{ marginLeft: "auto" }}
                    value={state.search}
                    onChange={(e) => dispatch({ type: "setSearch", value: e.target.value })}
                />
            </Row>
        </div>

        <div className={classes.sectionWrapper}>
            {filteredGroupsBySearch.map((group, index) => {
                // TODO Get group image
                //const imagePath = filesAPIService.getProfileImageSmall(company.small_image_pathname);

                return (
                    <React.Fragment key={"group" + index}>
                        <Listing
                            title={group.name}
                            subTitle={group.nrOfMembers + " medlemmar"}
                            subSubTitle={group.description.substring(0, 100) + "..."}
                            imagePath={""}
                            onClick={() => navigate(`${GROUP}/${group.id}`)}
                        />
                        {index !== filteredGroupsBySearch.length - 1 && <Divider />}
                    </React.Fragment>
                );
            })}
        </div>
    </MiddleWrapper>
}