// ----------------------------------------------------
// Imports
// ----------------------------------------------------

import { Session } from "~base/Model/Session";
import { ViewState } from "./Types";
import { AdResponse } from "~base/Model/ResponseModels/AdResponse";

// ----------------------------------------------------
// Actions
// ----------------------------------------------------

type setSearchValue = { type: "setSearchValue", value: string }
type setTop3Ads = { type: "setTop3Ads", value: AdResponse[] }

export type Actions = setSearchValue | setTop3Ads;

// ----------------------------------------------------
// Reducer
// ----------------------------------------------------

export const reducer = (state: ViewState, action: Actions): ViewState => {
    switch(action.type){
        case "setSearchValue": {
            return {
                ...state,
                searchValue: action.value
            }
        }

        case "setTop3Ads": {
            return {
                ...state,
                top3Ads: action.value
            }
        }

        default:
            return {
                ...state
            }
    }
}

// ----------------------------------------------------
// Initial State
// ----------------------------------------------------

export const initialState = (session: Session): ViewState => {
    return {
        searchValue: "",
        top3Ads: []
    }
}