import React from "react";
import { createUseStyles } from "react-jss"
import { HendigoBaseTheme } from "../../../Resources/Styling/HendigoBaseTheme";
import { HendigoTheme } from "~base/Resources/Styling/HendigoTheme";

const useStyles = createUseStyles((theme: HendigoTheme) => ({
    p: {
        fontSize: "15px",
        fontFamily: "system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Ubuntu, 'Helvetica Neue', sans-serif",
        fontWeight: "bold",
        margin: "0",
        color: theme.textPrimary,
        marginBottom: "4px"
    },
    input: {
        backgroundColor: theme.inputBackgroundColor,
        border: "2px solid transparent",
        borderRadius: "8px",
        fontSize: "15px",
        padding: "10px",
        
        color: theme.inputTextColor,
        '&:focus': {
            outline: "none",
        }
    },
    inputDark: {
        background: HendigoBaseTheme.colors.hendigoDark,
        border: "1px solid dimgray",
        borderRadius: "8px",
        fontSize: "15px",
        padding: "10px",
        color: "lightgray",
        '&:focus': {
            outline: "none",
            border: "1px solid lightgray"
        }
    },
    inputInvalid: {
        border: "1px solid #ff726f",
    },
    error: {
        fontSize: "12px",
        color: "#ffcccb",
    }
}))

interface InputProps {
    placeholder?: string;
    type?: "password" | "text" | "number",
    title?: string,
    valid?: boolean,
    value?: string,
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void,
    error?: string,
    titleStyle?: any,
    titleClassName?: string,
    cssHeight?: string,
    cssWidth?: string,
    disabled?: boolean
    colorType?: "light" | "dark",
    className?: string
}

export const Input: React.FC<InputProps> = ({ className = "", colorType = "light", placeholder = "", type = "text", title = "", valid = true, value = "", onChange = () => { }, error = "", cssWidth = "calc(100%)", titleStyle, titleClassName, cssHeight, disabled = false }) => {
    const classes = useStyles();
    const cssClass = className + " " + (colorType === "light" ? classes.input : classes.inputDark);
    return <div>
        <p style={titleStyle} className={classes.p + " " + titleClassName}>{title}</p>
        <input
            style={{ height: cssHeight, width: cssWidth }}
            type={type}
            value={value}
            onChange={onChange}
            pattern={type === "number" ? "[0-9]*" : undefined}
            className={valid ? cssClass : cssClass + " " + classes.inputInvalid}
            placeholder={placeholder}
            disabled={disabled}
        />
        <p className={classes.error}>{error}</p>
    </div>
}