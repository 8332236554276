import React from "react";
import { BsArrowLeftShort, BsArrowRightShort, BsPerson, BsPencil } from "react-icons/bs";
import { MdOutlinePlace } from "react-icons/md";
import { TbConfetti } from "react-icons/tb";
import { useNavigate } from "react-router-dom";
import { InputAlternative } from "~base/Components/Elements/Input/InputAlternative";
import { TextArea } from "~base/Components/Elements/Input/TextArea";
import { Row } from "~base/Components/Elements/Cosmetic/Row";
import { ProgressBar } from "~base/Components/Elements/DataVisualization/ProgressBar";
import { Counties } from "~base/Resources/Data/Counties";
import { Professions } from "~base/Resources/Data/Professions";
import { CompleteRegistrationStyles } from "~base/Components/Shared/Styling/CompleteRegistrationStyling";
import { ViewBaseProps } from "~base/Model/ViewBaseProps";
import { apiv2 } from "~base/API/v2/apiv2";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { muiStyles } from "~base/Resources/Styling/MuiTheme";
import { useIntl } from "~base/Internalization/IntlContext";

interface Slide {
    id: string,
    titleIcon: React.ReactNode,
    title: string,
    inputValue: string,
    alternatives?: string[]
}

export const CompleteRegistrationUser: React.FC<ViewBaseProps> = ({ session }) => {
    
    window.view = "CompleteRegistrationUser";

    const intl = useIntl();

    intl.formatPageTitle("PageTitle.CompleteRegistrationUser");

    const classes = CompleteRegistrationStyles();
    const muiClasses = muiStyles();
    let navigate = useNavigate();

    // Values
    //const [county, setCounty] = React.useState("");
    const [locationId, setLocationId] = React.useState<number>();
    const [title, setTitle] = React.useState("");
    const [description, setDescription] = React.useState("");
    // TODO: Fix error
    // eslint-disable-next-line
    const [company, setCompany] = React.useState("");

    // Slide progress values
    const [index, setIndex] = React.useState(0);
    const [progress, setProgress] = React.useState<number>(0);

    const [currentValid, setCurrentValid] = React.useState(false);
    const [done, setDone] = React.useState(false);

    const countys = Counties;
    const professions = Professions;

    /**
     * Error messages.
     * Validation messages.
     */
    const printErrorMessages = () => {
        switch (slides[index].id) {
            case "user_description":
                session.setInformation("error", intl.formatText("Views.CompleteRegistrationCompany.EnterAtLeastThreeCharacters"));
                break;
            case "location_id" || "title":
                session.setInformation("error", intl.formatText("Views.CompleteRegistrationCompany.ChooseAAlternative"));
                break;
            default:
                session.setInformation("error", intl.formatText("Views.CompleteRegistrationCompany.TheFieldAreInvalid"));
        }
    }

    /**
     * Slides.
     */
    const [slides, setSlides] = React.useState<Slide[]>([
        {
            id: "location_id",
            titleIcon: <MdOutlinePlace className={classes.titleIcon} />,
            title: intl.formatText("Views.CompleteRegistrationUser.WhereDoYouWork"),
        },
        {
            id: "title",
            titleIcon: <BsPerson className={classes.titleIcon} />,
            title: intl.formatText("Views.CompleteRegistrationUser.WhatIsYourOccupation"),
            inputValue: title,
            alternatives: professions
        },
        {
            id: "user_description",
            titleIcon: <BsPencil className={classes.titleIcon} />,
            title: intl.formatText("Views.CompleteRegistrationUser.SetADescription"),
            inputValue: description,
        },
        {
            id: "current_company_id",
            titleIcon: <BsPerson className={classes.titleIcon} />,
            title: intl.formatText("Views.CompleteRegistrationUser.WhatDoYouWorkWith"),
            inputValue: company,
        }
    ] as Slide[]);

    /**
     * Setup active slides.
     */
    React.useEffect(() => {
        //nullIds is an array containing all of the ids (which are the object keys) for the slides that have null values.
        // för tillfället filtrerar vi bort current_company_id då vi inte har någon användning för det.
        const nullIds = Object.entries(session.userProfile!).filter((entry) => (entry[1] == null || entry[1] === "") && entry[0] !== "current_company_id").map((entry) => entry[0]);
        setSlides(slides.filter(slide => nullIds.includes(slide.id)));
        // eslint-disable-next-line
    }, []);

    /**
     * Slide validation.
     */
    React.useEffect(() => {
        if (index !== slides.length) {
            setProgress(index * (100 / slides.length));

            // Put special validation
            switch (slides[index].id) {
                case "user_description":
                    setCurrentValid(description.length > 2);
                    break;
                case "location_id":
                    setCurrentValid(true);
                    break;
                case "title":
                    setCurrentValid(professions.includes(title));
                    break;
                default:
                    setCurrentValid(true);
                    break;
            }
        }
        else {
            setProgress(100);
            setDone(true);
        }
        // eslint-disable-next-line
    }, [index, slides, description, locationId, title, company]);

    /**
     * Modify company profile when done.
     * These are the parameters that have been changed.
     */
    const modifyUserProfile = () => {
        if (session.userProfile !== null) {
            session.setUserProfile({
                ...session.userProfile,
                location_id: locationId ?? 0,
                location: session.data.locations.find(l => l.id === locationId)?.location ?? "",
                user_description: description,
                title: title
            });
        }
    }

    return <div className={classes.wrapper} key={index + "CompleteRegistrationCompany"}>
        {!done ? <Row className={classes.row}>

            {index > 0 ? <div // LEFT ARROW
                className={classes.arrowWrapper}
                onClick={() => {
                    if (index - 1 >= 0) {
                        setIndex(index - 1);
                        setCurrentValid(true);
                    }
                }}
            >
                <BsArrowLeftShort />
            </div> : <div style={{ width: "54px" }}></div>}

            <div className={classes.middleWrapper}>
                <Row className={classes.title}>
                    <div>{slides[index]?.titleIcon}{slides[index]?.title}</div>
                </Row>

                <div className={classes.input}>
                    {slides[index]?.id === "location_id" ?
                        <FormControl className={muiClasses.select} fullWidth>
                            <InputLabel id="location-select-label">
                                {intl.formatText("Views.CompleteRegistrationCompany.EnterCounty")}
                            </InputLabel>
                            <Select
                                labelId="location-select-label"
                                id="location-select"
                                value={locationId}
                                label={intl.formatText("Views.CompleteRegistrationCompany.EnterCounty")}
                                onChange={value => setLocationId(value.target.value as number)}
                            >
                                {session.data.locations.map((l, index) => {
                                    return <MenuItem
                                        key={"CompleteRigstrationCompanyLocation" + index}
                                        value={l.id}
                                    >
                                        {intl.formatText(l.location)}
                                    </MenuItem>
                                })}
                            </Select>
                        </FormControl>
                        : null}

                    {slides[index]?.id === "user_description" ?
                        <TextArea
                            key={"userDescriptionInput"}
                            cssWidth="300px"
                            placeholder={intl.formatText("Views.CompleteRegistrationCompany.WriteHere")}
                            value={description}
                            onChange={e => setDescription(e.target.value)}
                            valid={currentValid}
                        /> : null}

                    {slides[index]?.id === "title" ?
                        <InputAlternative
                            key={"userTitleInput"}
                            cssWidth="300px"
                            placeholder={intl.formatText("Views.CompleteRegistrationUser.EnterWorkTitle")}
                            value={title}
                            setValue={value => {
                                setTitle(value);
                            }}
                            valid={currentValid}
                            alternativeList={professions}
                        /> : null}
                </div>
                <ProgressBar progress={progress} />
            </div>

            <div //RIGHT ARROW
                className={classes.arrowWrapper}
                onClick={() => {
                    if (currentValid) {
                        setIndex(index + 1);
                    } else {
                        printErrorMessages();
                    }
                }}
            >
                <BsArrowRightShort />
            </div>
        </Row> : <Row className={classes.row}>

            <div //LEFT ARROW
                className={classes.arrowWrapper}
                onClick={() => {
                    if (index - 1 >= 0) {
                        setIndex(index - 1);
                        setCurrentValid(true);
                        setDone(false);
                    }
                }}
            >
                <BsArrowLeftShort />
            </div>

            <div className={classes.middleWrapper} style={{ width: "75%" }}>
                <Row className={classes.title} style={{ width: "100%", textAlign: "center" }}>
                    <TbConfetti className={classes.titleIcon} />
                    <div>{intl.formatText("Views.CompleteRegistrationCompany.EverythingIsCorrect")}</div>
                    <div className={classes.doneText}>{intl.formatText("Views.CompleteRegistrationCompany.Text")}</div>
                    <ProgressBar progress={progress} />
                </Row>
            </div>

            <div //RIGHT ARROW
                className={classes.arrowWrapper}
                onClick={() => {
                    apiv2(`api/v2/put/userProfile/${session.userProfile?.user_id}`, "PUT", {
                        fullname: session.userProfile?.fullname,
                        location_id: locationId,
                        user_description: description,
                        title: title,
                        public: session.userProfile?.public,
                    }).then((response) => {
                        if (response.error != null) {
                            session.setInformation("error", response.error);
                        } else {
                            modifyUserProfile();
                            navigate("/");
                        }
                    });
                }}
            >
                <BsArrowRightShort />
            </div>

        </Row>}
    </div>
}