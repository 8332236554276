interface Response {
    error: string | null,
    status: number | null,
    data: any
}

// ----------------------------------------------------------------------
// API
// ----------------------------------------------------------------------
/**
 * 
 * @param url For example: "api/v2/get/post/123123123"
 * @param method "GET", "POST", "PUT", "DELETE"
 * @param data Any data you want to send to the backend.
 * @returns 
 */
export function apiv2(url: string, method: string): Promise<Response>;
export function apiv2(url: string, method: string, data: Object): Promise<Response>;
export async function apiv2(url: string, ...args: any[]): Promise<Response> {
    let authResponse = "";
    const method = args[0] as string;
    const data = args[1] as Object;
    // window.location.origin.replace("3000", "") byter endast bort 3000 om vi är i lokal
    // utvecklingsmiljö.
    await fetch(window.location.origin.replace("3000", "") + `/server/` + url, {
        method: method,
        credentials: 'include',
        /*headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },*/
        body: data ? JSON.stringify({
            ...data
        }) : null
    }).then(response =>
        response.body?.getReader().read().then(res => {
            authResponse = new TextDecoder().decode(res.value).replaceAll('<br />', '').replaceAll('<b>', '').replaceAll('</b>', '');
        })).catch(error => {
            authResponse = "Error.Common.CouldNotConnect";
        });

    try {
        return JSON.parse(authResponse);
    } catch (e) {
        // Om vi får detta beror det på ett SYNTAX ERROR i PHP.
        return {
            error: String(authResponse)
        } as Response
    }
}