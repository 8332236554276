import { createUseStyles } from "react-jss";
import { HendigoBaseTheme } from "../../../Resources/Styling/HendigoBaseTheme";

export const useInformationBoxStyles = createUseStyles({
    '@keyframes slideIn': {
        from: {
            transform: "translateY(0)",
            opacity: "0"
        },
        to: {
            transform: "translateY(-50px)",
            opacity: "1"
        }

    },
    wrapper: {
        width: "auto",
        height: "auto",
        position: "fixed",
        zIndex: "5",
        bottom: "0",
        right: "50px",
        transform: "translateY(-50px)",
        animationName: "$slideIn",
        animation: "1s slideIn",
        color: "white",
        padding: "10px"
    },
    '@keyframes slideOut': {
        from: {
            display: "block",
            opacity: "1",
            transform: "translateY(-50px)",
        },
        to: {
            opacity: "0",
            transform: "translateY(0)",
            display: "none"
        }
    },
    out: {
        width: "auto",
        height: "auto",
        position: "fixed",
        zIndex: "3",
        bottom: "0px",
        right: "50px",
        display: "block",
        animationName: "$slideOut",
        animation: "1s slideOut",
        color: "white",
        padding: "10px"
    },
    exitButton: {
        fontSize: "25px",
        marginLeft: "auto",
        '&:hover': {
            cursor: "pointer"
        },
        color: HendigoBaseTheme.colors.hendigoDark
    },
    '@media screen and (max-width: 850px)': {
        wrapper: {
          width: "85%"
        },
        out: {
            width: "85%"
        }
    },
    icon: {
        fontSize: "20px",
        margin: "2px 10px 0px 0px"
    }
});