// ----------------------------------------------------
// Imports
// ----------------------------------------------------

import { Session } from "~base/Model/Session";
import { ViewState } from "./Types";
import { UserProfileResponse } from "~base/Model/ResponseModels/UserProfileResponse";
import { CompanyProfileResponse } from "~base/Model/ResponseModels/CompanyProfileResponse";

// ----------------------------------------------------
// Actions
// ----------------------------------------------------

type SetNavigationDrawerOpenAction = { type: "setNavigationDrawerOpen", value: boolean }
type SetUserProfileAction = { type: "setUserProfile", value: UserProfileResponse | null }
type SetCompanyProfileAction = { type: "setCompanyProfile", value: CompanyProfileResponse | null }
export type Actions = 
    SetNavigationDrawerOpenAction |
    SetUserProfileAction |
    SetCompanyProfileAction;

// ----------------------------------------------------
// Reducer
// ----------------------------------------------------

export const reducer = (state: ViewState, action: Actions): ViewState => {
    switch(action.type){
        case "setNavigationDrawerOpen": {
            return {
                ...state,
                navigationDrawerOpen: action.value
            }
        }

        case "setUserProfile": {
            return {
                ...state,
                userProfile: action.value
            }
        }

        case "setCompanyProfile": {
            return {
                ...state,
                companyProfile: action.value
            }
        }

        default:
            return {
                ...state
            }
    }
}

// ----------------------------------------------------
// Initial State
// ----------------------------------------------------

export const initialState = (session: Session): ViewState => {
    return {
        navigationDrawerOpen: false,
        userProfile: session.userProfile,
        companyProfile: session.companyProfile
    }
}