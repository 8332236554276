import { TextField } from "@mui/material";
import { RegisterAction } from "../Store";
import { RegisterViewState } from "../Types";
import { isEmail, isPassword } from "~base/Internalization/Validation";
import Grid2 from "@mui/material/Unstable_Grid2";
import { muiStyles } from "~base/Resources/Styling/MuiTheme";
import { useIntl } from "~base/Internalization/IntlContext";

interface RegisterUserProps {
    state: RegisterViewState,
    dispatch: React.Dispatch<RegisterAction>
}

export const RegisterUser: React.FC<RegisterUserProps> = ({ state, dispatch }) => {

    const intl = useIntl();
    const muiClasses = muiStyles();
    
    return (
        <Grid2 container spacing={2}>
            <Grid2 xs={12}>
                <TextField
                    onChange={(e) => dispatch({ type: "setFullname", value: e.target.value })}
                    value={state.fullname}
                    error={state.fullname.length < 2 && state.fullname.length > 0}
                    helperText={state.fullname.length < 2 && state.fullname.length > 0 ? intl.formatText("Views.Register.InvalidFullName") : ""}
                    label={intl.formatText("Views.Register.FullName")}
                    placeholder={"ex. Fredrik Johansson"}
                    fullWidth
                    className={muiClasses.textField}
                    variant="filled"
                />
            </Grid2>

            <Grid2 xs={12}>
                <TextField
                    onChange={(e) => dispatch({ type: "setEmail", value: e.target.value })}
                    value={state.email}
                    error={!isEmail(state.email) && state.email.length > 0}
                    label={intl.formatText("Views.Register.Email")}
                    placeholder="ex. fredrik@hendigo.se"
                    fullWidth
                    type="email"
                    className={muiClasses.textField}
                    variant="filled"
                />
            </Grid2>

            <Grid2 xs={12}>
                <TextField
                    onChange={(e) => dispatch({ type: "setPassword", value: e.target.value })}
                    value={state.password}
                    label={intl.formatText("Views.Register.Password")}
                    type="password"
                    error={!isPassword(state.password) && state.password.length > 0}
                    helperText={!isPassword(state.password) && state.password.length > 0 ? intl.formatText("Views.Register.PasswordMinLength") : ""}
                    fullWidth
                    className={muiClasses.textField}
                    variant="filled"
                />
            </Grid2>

            <Grid2 xs={12}>
                <TextField
                    onChange={(e) => dispatch({ type: "setPasswordConfirm", value: e.target.value })}
                    value={state.passwordConfirm}
                    error={state.password !== state.passwordConfirm && state.passwordConfirm.length > 0}
                    helperText={state.password !== state.passwordConfirm && state.passwordConfirm.length > 0 ? intl.formatText("Views.Register.PasswordDoesNotMatch") : ""}
                    label={intl.formatText("Views.Register.ConfirmPassword")}
                    type="password"
                    fullWidth
                    className={muiClasses.textField}
                    variant="filled"
                />
            </Grid2>
        </Grid2>
    );
}