import { Session } from "~base/Model/Session";
import { ViewAction } from "./Store";
import { CreateApplicationRequest } from "~base/Model/RequestModels/CreateApplicationRequest";
import { NavigateFunction } from "react-router-dom";
import { apiv2 } from "~base/API/v2/apiv2";

export function getExample(adId: string, dispatch: React.Dispatch<ViewAction>, session: Session) {
}

export function createExample(createApplicationRequest: CreateApplicationRequest, dispatch: React.Dispatch<ViewAction>, session: Session, navigate: NavigateFunction) {
}

export function getUserGroups(dispatch: React.Dispatch<ViewAction>, session: Session) {
    apiv2(`api/v2/get/userGroups/${session.userId}`, "GET").then((response) => {
        if (response.error) {
            session.setInformation("error", response.error);
        } else {
            dispatch({ type: "setGroups", value: response.data })
        }
    });
}