import { ViewBaseProps } from "~base/Model/ViewBaseProps";
import { useStyles } from "./Styling";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { initialState, reducer } from "./Store";
import { Chip, Typography } from "@mui/material";
import { AdCard } from "~base/Components/Composite/AdCard/AdCard";
import { Loader } from "~base/Components/Elements/Cosmetic/Loader";
import { Row } from "~base/Components/Elements/Cosmetic/Row";
import { BsInfoCircle } from "react-icons/bs";
import { MUITextField } from "~base/Components/Elements/MUI/MUITextField";
import { getApplication } from "./Fetch";
import { useIntl } from "~base/Internalization/IntlContext";
import { MiddleWrapper } from "~base/Components/Elements/Cosmetic/MiddleWrapper";

interface ViewProps extends ViewBaseProps {

}

export const MyApplication: React.FC<ViewProps> = ({ session }) => {

    const intl = useIntl();

    window.view = "Template";
    intl.formatPageTitle("PageTitle.Template");

    // Params
    const { id } = useParams();

    // Styling Classes
    const classes = useStyles();

    // Navigation
    const navigate = useNavigate();

    // State and dispatch
    const [state, dispatch] = React.useReducer(reducer, initialState());

    React.useEffect(() => {
        getApplication(id ?? "", dispatch, session);
    }, []);

    const status = session.data.applicationStatus.find(
        as => as.id === Number(state.application?.status_id)
    )?.status ?? "";

    let statusColor = "primary";

    switch (status) {
        case "Application.Status.Handled":
            statusColor = "warning";
            break;
        case "Application.Status.Rejected":
            statusColor = "error";
            break;
        case "Application.Status.Accepted":
            statusColor = "success";
            break;
    }

    return <MiddleWrapper>

        {state.loading && (
            <div style={{
                display: "flex",
                flexDirection: "column",
                rowGap: "25px",
                alignItems: "center"
            }}
            >
                <Typography variant={"h5"}>
                    {intl.formatText(state.loadingMessage)}
                </Typography>
                <Loader />
            </div>
        )}

        {!state.loading && <>
            <div className={classes.sectionWrapper}>

                <Typography variant="h4">
                    {intl.formatText("Views.MyApplication.Title")}
                </Typography>

                <br />

                <Row style={{ marginLeft: "auto", alignItems: "center", gap: "10px" }}>
                    Status:
                    <Chip label={intl.formatText(status)} color={statusColor as any} />
                </Row>

                <br />

                <p className={classes.description}>
                    {intl.formatText("Views.AdApply.Description")}
                </p>
            </div>

            <AdCard
                ad={state.ad}
            />

            <div className={classes.sectionWrapper}>
                <Row className={classes.sectionTitleRow}>
                    <BsInfoCircle className={classes.icon} />

                    <h3 className={classes.sectionTitle}>
                        {intl.formatText("Views.AdApply.Letter")}
                    </h3>
                </Row>

                <br />

                <MUITextField
                    label={intl.formatText("Views.AdApply.CoverLetter.Label")}
                    multiline
                    rows={10}
                    fullWidth
                    value={state.application?.letter ?? ""}
                    disabled
                />
            </div>
        </>}
    </MiddleWrapper>
}