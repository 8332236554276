// ----------------------------------------------------
// Imports
// ----------------------------------------------------

import React from "react";
import { createUseStyles } from "react-jss"
import { Session } from "~base/Model/Session";
import { HendigoBaseTheme } from "~base/Resources/Styling/HendigoBaseTheme";
import { Overlay } from "~base/Components/Elements/Cosmetic/Overlay";
import { CompanyProfileResponse } from "~base/Model/ResponseModels/CompanyProfileResponse";
import { apiv2 } from "~base/API/v2/apiv2";
import { FormControl, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import { muiStyles } from "~base/Resources/Styling/MuiTheme";
import { useIntl } from "~base/Internalization/IntlContext";

// ----------------------------------------------------
// Styling
// ----------------------------------------------------

const useStyles = createUseStyles({
    container: {
        display: "flex",
        flexDirection: "column",
        rowGap: "20px",
    },
    updatePasswordButton: {
        marginTop: "20px"
    },
    alternativeList: {
        backgroundColor: HendigoBaseTheme.colors.hendigoDark,
        opacity: 0.96
    }
});

// ----------------------------------------------------
// Props
// ----------------------------------------------------

interface EditCompanyOverlayProps {
    profile: CompanyProfileResponse | null,
    onCancel: () => void,
    onError: (error: string) => void,
    session: Session,
    showOverlay: boolean,
}

// ----------------------------------------------------
// Component
// ----------------------------------------------------

export const EditCompanyOverlay: React.FC<EditCompanyOverlayProps> = ({ profile, onCancel, onError, session, showOverlay }) => {

    const intl = useIntl();
    const classes = useStyles();

    const limit = 2000;

    const muiClasses = muiStyles();

    const [locationId, setLocationId] = React.useState<number>(profile?.location_id ?? 0);
    const [companyName, setCompanyName] = React.useState<string>(profile?.company_name ?? "");
    const [description, setDescription] = React.useState<string>(profile?.company_description ?? "");
    const [address, setAddress] = React.useState<string>(profile?.address ?? "");
    const [zipCode, setZipCode] = React.useState<string>(profile?.zip_code ?? "");
    const [city, setCity] = React.useState<string>(profile?.city ?? "");

    const [locationIdvalid, setLocationIdValid] = React.useState<boolean>(true);
    const [companyNameValid, setCompanyNameValid] = React.useState<boolean>(true);
    const [descriptionValid, setDescriptionValid] = React.useState<boolean>(true);
    const [addressValid, setAddressValid] = React.useState<boolean>(true);
    const [zipCodeValid, setZipCodeValid] = React.useState<boolean>(true);
    const [cityValid, setCityValid] = React.useState<boolean>(true);

    React.useEffect(() => {
        setLocationId(profile?.location_id ?? 0);
        setCompanyName(profile?.company_name ?? "");
        setDescription(profile?.company_description ?? "");
        setAddress(profile?.address ?? "");
        setZipCode(profile?.zip_code ?? "");
        setCity(profile?.city ?? "");
    }, [profile])

    React.useEffect(() => {
        setLocationIdValid(
            true
        );
        setCompanyNameValid(
            companyName.length > 2 && companyName.length < 50
        );
        setDescriptionValid(
            description.length > 2 && description.length < limit
        );
        setAddressValid(
            address.length > 2 && address.length < 50
        );
        setZipCodeValid(
            zipCode.length > 2 && zipCode.length < 50
        );
        setCityValid(
            city.length > 2 && city.length < 50
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [companyName, description, address, zipCode, city]);

    return <Overlay
        show={showOverlay}
        title={intl.formatText("Views.Company.EditCompanyOverlays.EditCompanyProfile")}
        onCancel={onCancel}
        cssWidth="600px"
        onSave={() => {
            if (
                companyName !== profile?.company_name ||
                locationId !== profile?.location_id ||
                description !== profile?.company_description ||
                address !== profile?.address ||
                zipCode !== profile?.zip_code ||
                city !== profile?.city
            ) {
                if (descriptionValid && locationIdvalid && companyNameValid && addressValid && zipCodeValid && cityValid) {
                    apiv2("api/v2/put/companyProfile/", "PUT", {
                        company_name: companyName === profile?.company_name ? null : companyName,
                        location_id: locationId === profile?.location_id ? null : locationId,
                        company_description: description === profile?.company_description ? null : description,
                        address: address === profile?.address ? null : address,
                        zip_code: zipCode === profile?.zip_code ? null : zipCode,
                        city: city === profile?.city ? null : city,
                    }).then(response => {
                        if (response.error != null) {
                            onError(response.error);
                        } else {
                            onCancel();
                            window.location.reload();
                        }
                    });
                } else {
                    session.setInformation("error", intl.formatText("Views.Company.EditCompanyOverlays.CheckCorrection"));
                }
            } else {
                onCancel();
            }

        }}
    >
        <div className={classes.container}>
            <FormControl className={muiClasses.select} fullWidth>
                <InputLabel id="demo-simple-select-label">
                    {intl.formatText("Views.Company.EditCompanyOverlays.CompanyCounty")}
                </InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={locationId}
                    label={intl.formatText("Views.Company.EditCompanyOverlays.CompanyCounty")}
                    onChange={value => setLocationId(value.target.value as number)}
                >
                    {session.data.locations.map((location, index) => {
                        return <MenuItem
                            key={"CompleteRigstrationCompanyCompanyType" + index}
                            value={location.id}
                        >
                            {intl.formatText(location.location)}
                        </MenuItem>
                    })}
                </Select>
            </FormControl>

            <TextField
                label={intl.formatText("Views.Company.EditCompanyOverlays.CompanyName")}
                variant="outlined"
                value={companyName}
                onChange={value => setCompanyName(value.target.value)}
                error={!companyNameValid}
                fullWidth={true}
                className={muiClasses.textField}
            />

            <TextField
                label={intl.formatText("Views.Company.EditCompanyOverlays.CompanyDescription")}
                placeholder={intl.formatText("Views.Company.EditCompanyOverlays.NoDescription")}
                variant="outlined"
                value={description}
                onChange={value => {
                    if (value.target.value.length <= limit)
                        setDescription(value.target.value)
                }}
                error={!descriptionValid}
                fullWidth={true}
                className={muiClasses.textField}
                multiline={true}
                rows={5}
            />

            <TextField
                label={intl.formatText("Views.Company.EditCompanyOverlays.Address")}
                variant="outlined"
                value={address}
                onChange={value => setAddress(value.target.value)}
                error={!addressValid}
                fullWidth={true}
                className={muiClasses.textField}
            />

            <TextField
                label={intl.formatText("Views.Company.EditCompanyOverlays.City")}
                variant="outlined"
                value={city}
                onChange={value => setCity(value.target.value)}
                error={!cityValid}
                fullWidth={true}
                className={muiClasses.textField}
            />

            <TextField
                label={intl.formatText("Views.Company.EditCompanyOverlays.ZipCode")}
                variant="outlined"
                value={zipCode}
                onChange={value => setZipCode(value.target.value)}
                error={!zipCodeValid}
                fullWidth={true}
                className={muiClasses.textField}
            />
        </div>
    </Overlay>
}