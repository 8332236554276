import { createUseStyles, useTheme } from "react-jss"
import { HendigoTheme } from "~base/Resources/Styling/HendigoTheme"

const useStyles = createUseStyles((theme: HendigoTheme) => {
    return {
        divider: {
            width: "100%",
            borderTop: "1px solid " + theme.border
        }
    }
})

interface DividerProps {
    style?: React.CSSProperties
}

export const Divider: React.FC<DividerProps> = ({ style }) => {
    const theme: HendigoTheme = useTheme();
    const classes = useStyles({ theme });

    return <div style={style} className={classes.divider} />
}