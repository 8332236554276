import { createUseStyles } from "react-jss";
import { PostResponse } from "~base/Model/ResponseModels/PostResponse";
import { HendigoBaseTheme } from "~base/Resources/Styling/HendigoBaseTheme";
import { HendigoTheme } from "~base/Resources/Styling/HendigoTheme";

export const usePostStyles = createUseStyles((theme: HendigoTheme) => ({
    wrapper: {
        marginTop: "10px",
        borderRadius: "10px",
        backgroundColor: theme.backgroundSecondary,
        color: theme.textColor,
        padding: "10px",
    },
    avatar: {
        height: "40px",
        width: "40px",
        alignSelf: "center",
        margin: "0px 10px 0px 0px",
        textDecoration: "none",
        '&:hover': {
            cursor: "pointer"
        },
    },
    companyName: {
        fontSize: "16px",
        fontWeight: "500",
        color: theme.textPrimary,
        margin: "0",
        '&:hover': {
            cursor: "pointer",
            textDecoration: "underline"
        }
    },
    authorRow: {
        marginBottom: "10px",
        position: "relative"
    },
    adIcon: {
        float: "right",
        fontSize: "25px",
        color: HendigoBaseTheme.colors.hendigoBlue,
        padding: "0px 2px 0px 2px",
        borderRadius: "5px",
        alignSelf: "center",
        marginLeft: "auto"
    },
    image: {
        marginTop: "10px",
        height: "300px",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        borderRadius: "5px",
        '&:hover': {
            cursor: "pointer",
            outline: "2px solid " + HendigoBaseTheme.colors.hendigoLightGray
        }
    },
    content: {
        fontSize: "15px",
        whiteSpace: "pre-wrap"
    },
    title: {
        margin: "5px 0px 5px 0px",
        color: theme.textPrimary
    },
    text: {
        fontWeight: "400",
        color: theme.textSecondary
    },
    time: {
        fontWeight: "500",
        fontSize: "12px",
        color: theme.textSecondary,

    },
    socialIcon: {
        fontSize: "25px",
        margin: "0px 10px 0px 0px",
        color: theme.textSecondary,
        '&:hover': {
            cursor: "pointer",
            color: theme.inputTextColor
        }
    },
    likeCommentSectionDivider: {
        border: "1px solid lightgray"
    },
    showMore: {
        color: HendigoBaseTheme.colors.hendigoBlue,
        '&:hover': {
            cursor: "pointer",
            textDecoration: "underline"
        }
    },
    socialSection: {
        marginTop: "10px",
        paddingTop: "5px",
        borderTop: "1px solid " + theme.border
    },
    likedIcon: {
        fontSize: "25px",
        margin: "0px 10px 0px 0px",
        color: theme.postLikedButton,
        '&:hover': {
            cursor: "pointer"
        }
    },
    numberOf: {
        fontSize: "14px",
        margin: "3px 10px 0px -7px",
        color: "dimgray"
    },
    dotsIcon: {
        float: "right",
        fontSize: "25px",
        color: theme.textSecondary,
        padding: "2px",
        borderRadius: "5px",
        alignSelf: "center",
        marginLeft: "auto",
        marginBottom: "auto",
        '&:hover': {
            cursor: "pointer",
            backgroundColor: theme.backgroundPrimary
        }
    },
    manage: {
        position: "absolute",
        backgroundColor: theme.backgroundPrimary,
        color: theme.textColor,
        borderRadius: "5px",
        padding: "5px",
        border: "1px solid " + theme.border,
        '&:hover': {
            cursor: "pointer"
        },
        right: -30,
        marginTop: 10,
    },
    manageItem: {
        '&:hover': {
            cursor: "pointer",
            textDecoration: "underline"
        },
        '&:not(:last-child)': {
            borderBottom: "1px solid " + theme.border
        },
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: "5px"
    },
    manageWrapper: {
        position: "relative",
        display: "inline-block"
    },
    managePost: {
        position: "absolute",
        right: 0,
    },
    commentSection: {
        display: "flex", 
        flexDirection: "column", 
        rowGap: "15px",
    },
    commentInput: {
        marginTop: "10px",
    },
    postCommentButton: {
        height: "39px",
        margin: "auto 0px auto 10px"
    },
    '@media screen and (max-width: 440px)': {
        postCommentButton: {
            marginLeft: "0",
            marginBottom: "10px"
        }
    },
    commentTitle: {
        margin: "10px 0px 0px 0px",
        fontWeight: "500",
        color: theme.textPrimary
    },
    postImage: {
        maxWidth: "100%",
        height: "400px",
    },
    postImageWrapper: {
        display: "flex",
        justifyContent: "center",
        background: "#222",
        borderRadius: "5px",
        padding: "5px",
        marginBottom: "10px",
    }
}));