import React from "react";
import { AiOutlineStar } from "react-icons/ai";
import { FiClock } from "react-icons/fi";
import { MdOutlinePlace } from "react-icons/md";
import { BounceLoader } from "react-spinners";
import { AdStyles } from "./Styling"
import { Placeholder } from "~base/Components/Elements/Cosmetic/Placeholder";
import { Row } from "~base/Components/Elements/Cosmetic/Row";
import { HendigoBaseTheme } from "~base/Resources/Styling/HendigoBaseTheme";
import { BiBuilding, BiMessageAltDetail } from "react-icons/bi";
import { BsInfoCircle, BsPerson } from "react-icons/bs";
import { Avatar, Button } from "@mui/material";
import { Listing } from "~base/Components/Elements/DataVisualization/Listing";
import { AdResponse } from "~base/Model/ResponseModels/AdResponse";
import { AdRequirement } from "~base/Model/Entities/AdRequirement";
import { useIntl } from "~base/Internalization/IntlContext";
import { useFilesAPIService } from "~base/Services/FilesAPIServiceContext";

interface AdProps {
    ad: AdResponse;
    adRequirements: AdRequirement[];
    loading: boolean;
    applyButtonDisabled?: boolean;
    onApply?: () => void;
}

export const CompositeAd: React.FC<AdProps> = ({ ad, loading, applyButtonDisabled = false, adRequirements, onApply = () => null }) => {
    const intl = useIntl();
    const classes = AdStyles();
    const filesAPIService = useFilesAPIService();

    const buildSectionText = (text: string) => {
        return loading === true ?
            <>
                <Placeholder cssWidth="100%" cssHeight="20px" style={{ marginTop: "5px" }} />
                <Placeholder cssWidth="100%" cssHeight="20px" style={{ marginTop: "5px" }} />
                <Placeholder cssWidth="75%" cssHeight="20px" style={{ marginTop: "5px" }} />
            </>
            :
            <p className={classes.description}>{text}</p>
    }

    const profilePicture = filesAPIService.getProfileImageSmall(ad?.small_image_pathname);
    const firstLetterInName = ad?.company_name?.substring(0, 1);

    return <>
        <div className={classes.sectionWrapper}>
            <Row>
                {loading === true ?
                    <div className={classes.loadingImageWrapper}>
                        <BounceLoader color={"lightgray"} />
                    </div> :
                    <Avatar
                        src={profilePicture}
                        className={classes.authorAvatar}
                    >
                        {firstLetterInName}
                    </Avatar>
                }


                <div className={classes.titleWrapper}>
                    {loading === true ?
                        <Placeholder cssWidth="100%" cssHeight="40px" style={{ marginBottom: "5px" }} />
                        :
                        <h1 className={classes.title}>{ad.title}</h1>
                    }

                    <Row>
                        {ad.product_type === "Product.Ad.Premium" && loading === false ?
                            <>
                                <AiOutlineStar className={classes.bulletIcon} color={HendigoBaseTheme.colors.hendigoPrimary} />
                                <div className={classes.bulletText} style={{ color: HendigoBaseTheme.colors.hendigoPrimary }}>{intl.formatText("Views.Ad.Recommended")}</div>
                            </> : null
                        }
                    </Row>

                    <Row>
                        <MdOutlinePlace className={classes.bulletIcon} />
                        {loading === true ? <Placeholder cssWidth="100px" /> : <div className={classes.bulletText}>{intl.formatText(ad.location ?? "")} - {intl.formatText(ad.form_of_employment ?? "")}</div>}
                    </Row>

                    <Row>
                        <FiClock className={classes.bulletIcon} />
                        {loading === true ? <Placeholder cssWidth="100px" /> :
                            <div className={classes.bulletText}>
                                {intl.formatText("Views.Ad.Uploaded")} {intl.formatTimeLong(new Date(ad.time_created!))}
                            </div>
                        }
                    </Row>

                    <Row>
                        <FiClock className={classes.bulletIcon} />
                        <div className={classes.bulletText}>{intl.formatText("Views.Ad.ApplyLatest")} {intl.formatDateLong(new Date(ad.deadline!))}</div>
                    </Row>
                </div>
            </Row>

            <Button
                variant="contained"
                style={{ width: "100%", margin: "1rem 0 0 0" }}
                disabled={applyButtonDisabled || loading}
                onClick={onApply}
            >
                {intl.formatText("Views.Ad.Apply")}
            </Button>
        </div>

        <div className={classes.sectionWrapper}>
            <Row className={classes.sectionTitleRow}>
                <BiBuilding className={classes.icon} />
                <h3 className={classes.sectionTitle}>{intl.formatText("Views.Ad.About")} {ad.company_name}</h3>
            </Row>
            {buildSectionText(ad.company_description ?? "No text provided")}
        </div>

        <div className={classes.sectionWrapper}>
            <Row className={classes.sectionTitleRow}>
                <BsInfoCircle className={classes.icon} />
                <h3 className={classes.sectionTitle}>{intl.formatText("Views.Ad.AboutThePosition")}</h3>
            </Row>
            {buildSectionText(ad.position_description ?? "No text provided")}
        </div>

        <div className={classes.sectionWrapper}>
            <Row className={classes.sectionTitleRow}>
                <BsPerson className={classes.icon} />
                <h3 className={classes.sectionTitle}>{intl.formatText("Views.Ad.AboutYou")}</h3>
            </Row>
            {buildSectionText(ad.applicant_description ?? "No text provided")}
        </div>

        <div className={classes.sectionWrapper}>
            <Row className={classes.sectionTitleRow}>
                <BsInfoCircle className={classes.icon} />
                <h3 className={classes.sectionTitle}>{intl.formatText("Views.Ad.Requirements")}</h3>
            </Row>
            <ul>
                {adRequirements?.map((requirement, index) => {
                    return <li key={index} className={classes.description}>{requirement.description}</li>
                })}
            </ul>
        </div>

        <div className={classes.sectionWrapper}>
            <Row className={classes.sectionTitleRow}>
                <BiMessageAltDetail className={classes.icon} />
                <h3 className={classes.sectionTitle}>{intl.formatText("Views.Ad.Contact")}</h3>
            </Row>

            {loading === true ?
                <Placeholder 
                    cssHeight="20px" 
                    cssWidth="100px" 
                />
                : <Listing
                    title={ad.company_name ?? ""}
                    imagePath={profilePicture}
                    subTitle={intl.formatText("Views.Ad.Contact.SubTitle")}
                    subSubTitle={intl.formatText("Views.Ad.Contact.SubSubTitle")}
                    link={`/foretag/${ad.company_id}`}
                />
            }
        </div>
    </>
}