import { createUseStyles } from "react-jss";
import { HendigoBaseTheme } from "~base/Resources/Styling/HendigoBaseTheme";
import { HendigoTheme } from "~base/Resources/Styling/HendigoTheme";

export const HandyStyles = createUseStyles((theme: HendigoTheme) => {
    return {
        wrapper: {
            display: "flex",
            flexDirection: "column",
            width: "20%",
            paddingTop: "0",
            maxHeight: "500px",
            position: "sticky !important",
            top: "20px !important",
        },
        '@media screen and (max-width: 1100px)': {
            wrapper: {
                display: 'none',
            }
        }
    }
});

export const LatestNotificationStyles = createUseStyles((theme: HendigoTheme) => {
    return {
        wrapper: {
        },
        avatar: {
            width: "35px",
            height: "35px",
            borderRadius: "100%",
            marginRight: "10px",
        },
        text: {
            fontSize: "14px",
            color: theme.textPrimary,
            marginTop: "-3px",
            maxWidth: "70%"
        },
        message: {
            color: theme.textSecondary,
        },
        time: {
            marginLeft: "auto",
            marginTop: "-5px",
            color: HendigoBaseTheme.colors.hendigoBlue,
            fontSize: "14px"
        },
        title: {
            color: theme.textSecondary,
            fontWeight: "500",
            fontSize: "17px",
            '&:hover': {
                cursor: "pointer",
                textDecoration: "underline"
            }
        },
        notification_container: {
            marginTop: "10px",
            padding: "5px",
            borderRadius: "10px",
            '&:hover': {
                background: "rgba(255, 255, 255, 0.19)",
                cursor: "pointer",
            }
        },
        noNotificationsText: {
            color: theme.textSecondary,
            textAlign: "center",
            marginTop: "10px",
        }
    }
});

export const HandyAdsStyles = createUseStyles((theme: HendigoTheme) => {
    return {
        wrapper: {
        },
        adIcon: {
            display: "block",
            color: "gray",
            fontSize: "25px",
            marginLeft: "auto"
        },
        ad: {
            marginTop: "10px",
            width: "100%",
            height: "200px",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            opacity: 0.8,
            position: "relative",
            '&:hover': {
                cursor: "pointer"
            }
        },
        bottom: {
            position: "absolute",
            height: "50px",
            width: "100%",
            backgroundColor: HendigoBaseTheme.colors.hendigoDark + "ba",
            bottom: "0px",
            color: "white",
        },
        title: {
            fontWeight: "500",
            color: theme.textSecondary,
            fontSize: "17px"
        },
        url: {
            marginLeft: "5px"
        },
        noAdsText: {
            color: theme.textSecondary,
            textAlign: "center",
            marginTop: "10px",
        }
    }
});