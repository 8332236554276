// ----------------------------------------------------
// Imports
// ----------------------------------------------------

import { BsX } from "react-icons/bs";
import { createUseStyles } from "react-jss"
import { HendigoBaseTheme } from "../../../Resources/Styling/HendigoBaseTheme";
import { HendigoTheme } from "../../../Resources/Styling/HendigoTheme";
import { Row } from "./Row";
import { Button } from "@mui/material";
import { useIntl } from "~base/Internalization/IntlContext";

// ----------------------------------------------------
// Styling
// ----------------------------------------------------

const useStyles = createUseStyles((theme: HendigoTheme) => {
    return {
        overlay: {
            position: "fixed",
            justifyContent: "center",
            alignContent: "center",
            width: "100%",
            height: "100%",
            zIndex: "3",
            top: "0",
            left: "0",
            right: "0",
            bottom: "0",
            backgroundColor: "rgba(0,0,0,0.8)",
        },
        content: {
            background: theme.backgroundSecondary,
            opacity: "0.95",
            borderRadius: "8px",
            margin: "auto 0",
            padding: "10px",
            zIndex: "4",
            overflow: "auto",
        },
        exitButton: {
            fontSize: "25px",
            marginLeft: "auto",
            '&:hover': {
                cursor: "pointer",
            },
            color: theme.inputTextColor
        },
        title: {
            fontSize: "25px",
            marginLeft: "10px",
            color: theme.inputTextColor
        },
        saveRow: {
            justifyContent: "center",
        },
        saveButton: {
            width: "100px",
            backgroundColor: HendigoBaseTheme.colors.hendigoBlue,
            '&:hover': {
                borderColor: HendigoBaseTheme.colors.hendigoDarkBlue,
                backgroundColor: HendigoBaseTheme.colors.hendigoDarkBlue
            },
        },
        children: {
            padding: "50px 10px 50px 10px"
        }
    }
});

// ----------------------------------------------------
// Props
// ----------------------------------------------------

interface OverlayProps {
    show: boolean,
    onCancel?: () => void,
    onSave?: () => void,
    children?: JSX.Element[] | JSX.Element,
    showSaveButton?: boolean,
    saveButtonText?: string,
    showAbortButton?: boolean,
    abortButtonText?: string,
    title?: string,
    cssWidth?: string,
    cssHeight?: string,
    saveErrorButtonText?: string
    cssMaxWidth?: string
    cssMaxHeight?: string
}

// ----------------------------------------------------
// Component
// ----------------------------------------------------

export const Overlay: React.FC<OverlayProps> = (props) => {
    
    const intl = useIntl();
    const classes = useStyles();

    const {
        saveErrorButtonText, 
        title, 
        show,
        onSave, 
        onCancel, 
        children, 
        showSaveButton = true, 
        saveButtonText = intl.formatText("Elements.Cosmetic.Overlay.ButtonSave"), 
        showAbortButton = true, 
        abortButtonText = intl.formatText("Elements.Cosmetic.Overlay.ButtonCancel"), 
        cssWidth = "500px", 
        cssHeight = "auto", 
        cssMaxWidth = "", 
        cssMaxHeight = ""
    } = props;

    return <div
        style={show ? { display: "flex" } : { display: "none" }}
        className={classes.overlay}
    >
        <div style={{width: cssWidth, height: cssHeight, maxWidth: cssMaxWidth, maxHeight: cssMaxHeight}} className={classes.content}>
            <Row>
                <div className={classes.title}>{title}</div>
                <BsX className={classes.exitButton} onClick={onCancel} />
            </Row>
            <div 
                className={classes.children}
            >
                {children}
            </div>
            <Row className={classes.saveRow}>
                {showSaveButton ?
                    <Button
                        style={showAbortButton ? {marginRight: "5px"} : {}}
                        className={classes.saveButton}
                        onClick={onSave}
                        variant="contained"
                    >
                        {saveButtonText}
                    </Button> : null}
                {showAbortButton ?
                    <Button
                        style={showSaveButton ? {marginLeft: "5px"} : {}}
                        onClick={onCancel}
                    >
                        {abortButtonText}
                    </Button> : null}
            </Row>
        </div>
    </div>
}