import { AiOutlineStar } from "react-icons/ai";
import { FiClock } from "react-icons/fi";
import { MdOutlinePlace } from "react-icons/md";
import { createUseStyles } from "react-jss"
import { BounceLoader } from "react-spinners";
import { HendigoBaseTheme } from "~base/Resources/Styling/HendigoBaseTheme";
import { Placeholder } from "../../Elements/Cosmetic/Placeholder";
import { Row } from "../../Elements/Cosmetic/Row";
import { HendigoTheme } from "~base/Resources/Styling/HendigoTheme";
import { Avatar, Button } from "@mui/material";
import { AdResponse } from "~base/Model/ResponseModels/AdResponse";
import { useNavigate } from "react-router-dom";
import { useIntl } from "~base/Internalization/IntlContext";
import { useFilesAPIService } from "~base/Services/FilesAPIServiceContext";

const useStyles = createUseStyles((theme: HendigoTheme) => ({
    wrapper: {
        minHeight: "100px",
        backgroundColor: theme.backgroundSecondary,
        padding: "10px",
        borderRadius: "5px",
        marginBottom: "10px",
        width: "calc(100% - 20px)",
        '&:hover': {
            cursor: "pointer",
        }
    },
    title: {
        margin: "0",
        color: theme.textPrimary,
    },
    subTitle: {
        margin: "5px 0px",
        color: theme.textSecondary,
        fontWeight: "500"
    },
    subTitleIcon: {
        marginRight: "5px"
    },
    image: {
        height: "100px !important",
        width: "100px !important",
        fontSize: "40px !important",
        borderRadius: "5px !important",
        marginRight: "10px",
    },
    text: {
        margin: "0",
        color: theme.textSecondary
    },
    contentWrapper: {
        width: "calc(100% - 130px)"
    },
    loadingImageWrapper: {
        width: "100px",
        height: "100px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center"
    }
}));

interface AdCardProps {
    loading?: boolean,
    onClickControlPanel?: () => void,
    ad?: AdResponse | null,
    controlPanelTextCode?: string,
}

export const AdCard: React.FC<AdCardProps> = (props) => {
    const {
        loading = false,
        onClickControlPanel,
        ad = null,
        controlPanelTextCode = "Component.Composite.AdCard.ControlPanel.Button"
    } = props;

    const intl = useIntl();
    const classes = useStyles();
    const navigate = useNavigate();
    const filesAPIService = useFilesAPIService();

    const profilePicture = filesAPIService.getProfileImageSmall(ad?.small_image_pathname);
    const firstLetterInName = ad?.company_name?.substring(0, 1);

    return <div
        className={classes.wrapper}
        style={ad?.product_type === "Product.Ad.Premium" && !loading ? { border: "1px solid " + HendigoBaseTheme.colors.hendigoPrimary } : {}}
    >
        <Row onClick={() => navigate(`/jobb/annons/${ad?.id}`)}>
            {loading ?
                <div className={classes.loadingImageWrapper}>
                    <BounceLoader color={"lightgray"} />
                </div> 
                // : <div
                //     className={classes.image}
                //     style={{
                //         backgroundImage: "url('" + ad?.small_image_pathname + "')"
                //     }}
                // />
                : <Avatar
                    src={profilePicture}
                    className={classes.image}
                    
                >
                    {firstLetterInName}
                </Avatar>
            }

            <div className={classes.contentWrapper}>

                {loading ?
                    <Placeholder 
                        cssHeight="20px" 
                        cssWidth="75%" 
                    />
                    : <h4 className={classes.title}>{ad?.title}</h4>
                }

                <Row className={classes.subTitle}>
                    {ad?.product_type === "Product.Ad.Premium" && !loading ?
                        <>
                            <AiOutlineStar className={classes.subTitleIcon} color={HendigoBaseTheme.colors.hendigoPrimary} />
                            <span style={{ color: HendigoBaseTheme.colors.hendigoPrimary }}>{intl.formatText("Shared.AdCard.Recommended")}</span>
                        </> : null
                    }

                    <FiClock
                        className={classes.subTitleIcon}
                        style={ad?.product_type === "Product.Ad.Premium" ? { marginLeft: "5px" } : {}}
                    />

                    {loading ?
                        <Placeholder cssHeight="20px" cssWidth="100px" />
                        : intl.formatTimeLong(new Date(ad?.time_created ?? ""))
                    }

                    <MdOutlinePlace
                        className={classes.subTitleIcon}
                        style={{ marginLeft: "5px" }}
                    />

                    {loading ?
                        <Placeholder cssHeight="20px" cssWidth="100px" />
                        : intl.formatText(ad?.location ?? "")
                    }
                </Row>

                {loading ?
                    <>
                        <Placeholder 
                            cssHeight="20px" 
                            cssWidth="100%" 
                        />

                        <Placeholder 
                            cssHeight="20px" 
                            cssWidth="100%" 
                            style={{ marginTop: "4px" }} 
                        />
                    </>
                    : <p className={classes.text}>{ad?.position_description?.substring(0, 150) + "..."}</p>
                }
            </div>
        </Row>

        {onClickControlPanel && (
            <Row style={{ marginTop: "10px" }}>
                <Button onClick={onClickControlPanel}>
                    {intl.formatText(controlPanelTextCode)}
                </Button>
            </Row>
        )}
    </div>
}