import React, { useEffect } from "react";
import { createUseStyles } from "react-jss"
import { HendigoTheme } from "~base/Resources/Styling/HendigoTheme";
import { ContactWithProfile } from "~base/API/ResponseTypes/ContactResponse";
import { Link, useNavigate } from "react-router-dom";
import { Button } from "~base/Components/Elements/Input/Button";
import { Input } from "~base/Components/Elements/Input/Input";
import { BsFillChatFill } from "react-icons/bs";
import { useIntl } from "~base/Internalization/IntlContext";
import { useFilesAPIService } from "~base/Services/FilesAPIServiceContext";
import { Avatar } from "@mui/material";

const useStyles = createUseStyles((theme: HendigoTheme) => ({
	contentWrapper: {
		backgroundColor: theme.backgroundSecondary,
		padding: "10px",
		borderRadius: "10px",
		margin: "0px 0px 20px 0px",
	},
	contactWrapper: {
	},
	contactsHeader: {
		display: "flex",
		alignItems: "center",
	},
	contactInnerWrapper: {
		backgroundColor: theme.backgroundThird,
		borderRadius: "10px",
		padding: "25px",
		marginTop: "10px",
		display: "grid",
		gridTemplateColumns: "75px 1fr 1fr",
		columnGap: "15px",
		alignItems: "center",
		border: "1px solid " + theme.border,
	},
	avatar: {
		height: "75px !important",
		width: "75px !important",
		textDecoration: "none",
		fontSize: "40px !important",
		'&:hover': {
			cursor: "pointer",
			opacity: "0.8"
		},
		margin: "auto 0",
	},
	contactName: {
		fontSize: "18px",
		fontWeight: "500",
		marginBottom: "-10px",
		color: theme.textPrimary,
		'&:hover': {
			cursor: "pointer",
			textDecoration: "underline"
		},
	},
	contactButtonWrapper: {
		justifySelf: "end",
	},
	searchBox: {
		margin: "10px 25px 0px auto",
		display: "flex",
		alignItems: "center"
	},
	title: {
		margin: "0",
		fontWeight: "500",
		color: theme.textPrimary,
	},
	contactsSince: {
		color: theme.textSecondary,
	}
}));

export const Contacts: React.FC<{ contacts: ContactWithProfile[] }> = ({ contacts }) => {

	const intl = useIntl();
	const classes = useStyles();
	const navigate = useNavigate();
	const filesAPIService = useFilesAPIService();

	const [contactsDisplayed, setContactsDisplayed] = React.useState<ContactWithProfile[]>(contacts);
	const [search, setSearch] = React.useState<string>("");

	useEffect(() => {
		if (search.length > 0) {
			const filteredContacts = contacts.filter((contact) => {
				return contact.fullname.toLowerCase().includes(search.toLowerCase());
			});
			setContactsDisplayed(filteredContacts);
		} else {
			setContactsDisplayed(contacts);
		}
	}, [search, contacts]);

	const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
		setSearch(e.target.value);
	};

	return <div className={classes.contentWrapper} >
		<div className={classes.contactsHeader}>
			<h2 className={classes.title}>{intl.formatText("View.Network.Contacts.Contacts")} ({contacts.length})</h2>
			<div className={classes.searchBox}>
				<Input
					placeholder={intl.formatText("View.Network.Contacts.Search")}
					value={search}
					onChange={handleSearch}
				/>
			</div>
		</div>

		<div className={classes.contactWrapper}>
			{contactsDisplayed.map((contact, index) => {

				const profilePicture = filesAPIService.getProfileImageSmall(contact.small_image_pathname);

				const firstLetterInName = contact?.fullname.substring(0, 1);

				const profileLink = `/anvandare/${contact?.user_id}`;

				return <div key={index} className={classes.contactInnerWrapper}>

					<Avatar
						src={profilePicture}
						className={classes.avatar}
						component={Link}
						to={profileLink}
					>
						{firstLetterInName}
					</Avatar>

					<div>
						<p className={classes.contactName} onClick={() => navigate("/anvandare/" + contact.user_id)}>{contact.fullname}</p>
						<p className={classes.contactsSince}>{intl.formatTimeLong(new Date(contact.time_created))}</p>
					</div>

					<div className={classes.contactButtonWrapper}>
						<Button
							colorType="blue"
							style={{ width: "110%", margin: "auto 0px" }}
						>
							{intl.formatText("View.Network.Contacts.Message")} <BsFillChatFill style={{ marginLeft: "5px" }} />
						</Button>
					</div>
				</div>
			})}
		</div>
	</div>
}