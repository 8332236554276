import { createUseStyles } from "react-jss"
import { HendigoTheme } from "~base/Resources/Styling/HendigoTheme";

export const RegisterStyles = createUseStyles((theme: HendigoTheme) => ({
    registerCard: {
        width: "400px",
        height: "600px",
        padding: "0px 20px 20px 20px",
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        background: theme.loginRegisterBackgroundRGBA,
        borderRadius: "5px",
        color: theme.textPrimary
    },
    title: {
        fontWeight: "500",
        textAlign: "center",
    },
    success: {
        textAlign: "center",
    },
    or: {
        textAlign: "center",
        fontSize: "15px",
        width: "100%",
    },
    policy: {
        textDecoration: "underline",
        '&:hover': {
            cursor: "pointer",
        },
    },
    errorText: {
        color: "red",
        opacity: "0.65",
        textAlign: "center",
        width: "100%",
        margin: "0"
    }
}));