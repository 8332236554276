// ----------------------------------------------------
// Imports
// ----------------------------------------------------

import { createUseStyles } from "react-jss"
import { useIntl } from "~base/Internalization/IntlContext";
import { HendigoBaseTheme } from "~base/Resources/Styling/HendigoBaseTheme";

// ----------------------------------------------------
// Styling
// ----------------------------------------------------

const useStyles = createUseStyles({
    wrapper: {
        color: HendigoBaseTheme.colors.hendigoLightGray,
        textAlign: "center", 
        fontSize: "25px", 
        marginTop: "100px"
    }
});

// ----------------------------------------------------
// Component
// ----------------------------------------------------

export const CompanyNotFound: React.FC = ({ }) => {

    const intl = useIntl();
    const classes = useStyles();

    return <div className={classes.wrapper} >
        {intl.formatText("Views.Company.CompanyNotFound")}
    </div>
}