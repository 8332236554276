import React from "react";
import { createUseStyles } from "react-jss"
import { Overlay } from "~base/Components/Elements/Cosmetic/Overlay";
import { Row } from "~base/Components/Elements/Cosmetic/Row";
import { Session } from "~base/Model/Session";
import { HendigoTheme } from "~base/Resources/Styling/HendigoTheme";
import { apiv2 } from "~base/API/v2/apiv2";
import { UserExperienceResponse } from "~base/Model/ResponseModels/UserExperienceResponse";
import { Divider } from "~base/Components/Elements/Cosmetic/Divider";
import { BiEdit } from "react-icons/bi";
import { Button } from "~base/Components/Elements/Input/Button";
import { ExperienceField } from "./ExperienceFields";
import { useIntl } from "~base/Internalization/IntlContext";

const useStylesInline = createUseStyles((theme: HendigoTheme) => {
    return {
        experienceEditButtonWrapper: {
            position: "absolute",
            right: "0",
            cursor: "pointer",
            top: "50%",
            transform: "translateY(-50%)",
        },
        wrapper: {
            position: "relative",
        },
        editButton: {
            marginBottom: -5,
            paddingRight: 10,
        }
    }
});

interface EditUserExperienceOverlayProps {
    session: Session,
    onCancel: () => void,
    onSave: () => void,
    show: boolean,
    userExperience: UserExperienceResponse[],
    classesExperience: any
}

export const EditUserExperienceOverlay: React.FC<EditUserExperienceOverlayProps> = ({ session, show, onCancel, onSave, userExperience, classesExperience }) => {
    
    const intl = useIntl();
    
    const classes = useStylesInline();
    const [experience, setExperience] = React.useState(null as UserExperienceResponse | null);

    return <Overlay
        show={show}
        title={intl.formatText("Views.User.EditExperience.SelectExperienceToEdit")}
        onCancel={() => {
            setExperience(null);
            onCancel();
        }}
        onSave={() => {
            if (experience && experience.title && experience.company_name && experience.position_type && experience.time_start) {
                const time_start = new Date(experience.time_start)
                time_start.setMonth(time_start.getMonth() + 1);
                experience.time_start = time_start.toISOString().slice(0, 19).replace('T', ' ');
                if (experience.time_end) {
                    const time_end = new Date(experience.time_end)
                    time_end.setMonth(time_end.getMonth() + 1);
                    experience.time_end = time_end.toISOString().slice(0, 19).replace('T', ' ');
                }

                apiv2(`api/v2/put/userExperience/${experience.id}`, "PUT", experience).then((response) => {
                    if (response.error !== null) {
                        session.setInformation("error", response.error);
                    } else {
                        setExperience(null);
                        onSave();
                    }
                });
            } else {
                session.setInformation("error", "Please fill in all the fields");
            }
        }}
        showAbortButton={true}
        showSaveButton={experience ? true : false}
        cssWidth="60%"
        cssMaxWidth="800px"
        cssMaxHeight="80%"
    >
        {experience ?
        <ExperienceField
            session={session}
            experience={experience}
            setExperience={setExperience}
            onDelete={onSave}
        />
        :
        <div>
            {userExperience.map((experience, index) => {
                return (
                    <div key={experience.id} className={classes.wrapper} >
                        <Row className={classesExperience.experienceWrapper}>
                            <div className={classesExperience.experienceImageWrapper}>
                                <img className={classesExperience.experienceImage} alt={experience.company_name + index.toString()} src={experience.company_image ? experience.company_image : "/images/hendigo/default_small.png"} />
                            </div>
                            <div className={classesExperience.experienceTextWrapper}>
                                <div className={classesExperience.experienceTitle}>
                                    <p className={classesExperience.experienceTitleHeader}>{experience.title}</p>
                                    <p className={classesExperience.experienceTitleSubHeader}>- {intl.formatText(experience.position_type)}</p>
                                </div>
                                <div className={classesExperience.experienceSubTitle}>{experience.company_name}</div>
                                <div className={classesExperience.experienceSubSubTitle}>{intl.formatDateShort(experience.time_start)} - {experience.time_end ? intl.formatDateShort(experience.time_end) : "current"}</div>
                                <div className={classesExperience.experienceDescription}>{experience.description}</div>
                            </div>
                            <div className={classes.experienceEditButtonWrapper}>
                                <Button
                                    onClick={() => {
                                        setExperience(experience);
                                    }}
                                    colorType="blue"
                                >
                                    <BiEdit size={20} className={classes.editButton} />
                                    Edit
                                </Button>
                            </div>
                        </Row>
                        {index !== userExperience.length - 1 ? <Divider /> : null}
                    </div>
                )
            })}
        </div>
        }
</Overlay>
}