import { apiv2 } from "~base/API/v2/apiv2";
import { CompanyType } from "~base/Model/Entities/CompanyType";

export function getCompanyTypes(setCompanyTypes: (companyTypes: CompanyType[]) => void) {
    apiv2("api/v2/get/companyTypes/", "GET").then((res) => {
        console.log(res);
        if(res.error){

        } else {
            setCompanyTypes(res.data);
        }
    });
}