export const Professions = [
    "Anläggare",
    "Anläggningsarbetare",
    "Anläggningsdykare",
    "Anläggningsprojektör-BIM",
    "Beläggningsarbetare",
    "Bergarbetare",
    "Bergsprängare",
    "Besiktningsman",
    "Betongarbetare",
    "Betongingenjör",
    "Borrtekniker",
    "Broexpert",
    "Bygg- och anläggningsmaskinförare",
    "Byggarbetsledare",
    "Byggchef",
    "Byggledare",
    "Bygglovsprojektör",
    "Byggnadsantikvarie",
    "Byggnadsarbetare",
    "Byggnadskonstruktör",
    "Byggnadsplåtslagare",
    "Byggnadssnickare",
    "Byggproduktionsledare",
    "Byggprojektledare",
    "Byggprojektör",
    "Båtbyggare",
    "Djurstallsplanerare",
    "Elektriker",
    "Elkrafttekniker",
    "Fastighetsutvecklare",
    "Gipstekniker inom bygg",
    "Glastekniker",
    "Glasmästare",
    "Golvläggare",
    "Grovarbetare",
    "Håltagare",
    "Ingenjör inom bygg",
    "Kranbilsförare",
    "Kranförare",
    "Kyl- och värmepumptekniker",
    "Linjemålare",
    "Ljusdesigner",
    "Maskinförare",
    "Maskinoperatör",
    "Murare",
    "Målare",
    "Platschef",
    "Plattsättare",
    "Plåtslagare",
    "Projektmanager",
    "Rivningsarbetare",
    "Rörläggare",
    "Samhällsbyggare",
    "Signalvakt",
    "Småhusmontör",
    "Snickare",
    "Stenmontör",
    "Stensättare",
    "Ställningsbyggare",
    "Takläggare",
    "Takmontör",
    "Timmerman",
    "Trafikplanerare",
    "Trappmakare",
    "Träarbetare",
    "Trädgårdsanläggare",
    "Undertaksmontör",
    "Undervattensvetsare",
    "Ventilationsmontör",
    "Verkstadssnickare",
    "Maskinsnickare"
];