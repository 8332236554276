// ----------------------------------------------------
// Imports
// ----------------------------------------------------

import { ViewState } from "./Types";

// ----------------------------------------------------
// Actions
// ----------------------------------------------------

type SetExample = { type: "setExample", value: string }

export type ViewAction = SetExample;

// ----------------------------------------------------
// Reducer
// ----------------------------------------------------

export const reducer = (state: ViewState, action: ViewAction): ViewState => {
    switch (action.type) {
        case "setExample":
            return {
                ...state
            }

        default:
            return {
                ...state
            }
    }
}

// ----------------------------------------------------
// Initial State
// ----------------------------------------------------

export const initialState = (): ViewState => {
    return {
    }
}