import React from 'react';
import { FeedNavbarStyles } from './Styling';
import { useTheme } from 'react-jss';
import { HendigoTheme } from '~base/Resources/Styling/HendigoTheme';
import { ViewBaseProps } from '~base/Model/ViewBaseProps';
import { DesktopNavbar } from './ChildComponents/DesktopNavbar';
import { initialState, reducer } from './Store';
import { NavigationDrawer } from './ChildComponents/NavigationDrawer';

export const Navbar: React.FC<ViewBaseProps> = ({ session }) => {
    
    const theme: HendigoTheme = useTheme();
    const classes = FeedNavbarStyles({ theme });

    // State and dispatch
    const [state, dispatch] = React.useReducer(reducer, initialState(session));

    /**
     * Set up profile for poster area.
     */
    React.useEffect(() => {
        const obs = () => {
            dispatch({ type: "setCompanyProfile", value: session.companyProfile });
            dispatch({ type: "setUserProfile", value: session.userProfile });
        }
        session.addObserver(obs);
        return () => {
            session.removeObserver(obs);
        }
    }, [session, session.companyProfile, session.userProfile, dispatch]);

    return <>
        <DesktopNavbar 
            classes={classes}
            session={session}
            state={state}
            dispatch={dispatch}
        />

        <NavigationDrawer 
            state={state} 
            dispatch={dispatch}
            classes={classes}
            session={session}
        />
    </>
}