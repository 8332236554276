import { Session } from "~base/Model/Session";
import { ManageAdAction } from "./Store";
import { apiv2 } from "~base/API/v2/apiv2";
import { GetAdResponse } from "~base/Model/ResponseModels/GetAdResponse";
import { ApplicationResponse } from "~base/Model/ResponseModels/ApplicationResponse";

export function getAd(adId: string, dispatch: React.Dispatch<ManageAdAction>, session: Session){
    apiv2(`api/v2/get/ad/${adId}`, "GET").then(response => {
        if(response.error !== null){
            session.setInformation("error", response.error);
        } else {
            const getAdResponse = response.data as GetAdResponse;
            console.log(getAdResponse);
            dispatch({type: "setAd", value: getAdResponse.ad});
            dispatch({type: "setAdRequirements", value: getAdResponse.ad_requirements});
        }
    });
}

export function getApplications(adId: string, dispatch: React.Dispatch<ManageAdAction>, session: Session){
    apiv2(`api/v2/get/applications/${adId}`, "GET").then(response => {
        if(response.error !== null){
            session.setInformation("error", response.error);
        } else {
            const getApplicationsResponse = response.data as ApplicationResponse[];
            console.log(getApplicationsResponse);
            dispatch({type: "setApplications", value: getApplicationsResponse});
        }
    });
}

export function postStatus(request: {ad_id: string, application_id: string, status_id: number}, dispatch: React.Dispatch<ManageAdAction>, session: Session){
    console.log("request: ", request)
    dispatch({type: "setStatusLoading", value: true});
    apiv2(`api/v2/post/applicationStatus`, "POST", request).then(response => {
        if(response.error !== null){
            session.setInformation("error", response.error);
            dispatch({type: "setStatusError", value: true});
        } else {
            const applicationResponse = response.data as ApplicationResponse;
            console.log(applicationResponse);

            dispatch({type: "updateApplicationStatus", value: request.status_id, id: request.application_id})
        }
    });
    dispatch({type: "setStatusLoading", value: false});
}