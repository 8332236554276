import React, { useEffect, useState } from "react";
import { createUseStyles } from "react-jss"
import { Row } from "~base/Components/Elements/Cosmetic/Row";
import { Session } from "~base/Model/Session";
import { HendigoTheme } from "~base/Resources/Styling/HendigoTheme";
import { apiv2 } from "~base/API/v2/apiv2";
import { UserExperienceResponse } from "~base/Model/ResponseModels/UserExperienceResponse";
import { CompanyProfileResponse } from "~base/Model/ResponseModels/CompanyProfileResponse";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import { Button } from "~base/Components/Elements/Input/Button";
import { BiTrash } from "react-icons/bi";
import { muiStyles } from "~base/Resources/Styling/MuiTheme";
import { MenuItem, Select } from "@mui/material";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { FormsOfEmployment } from "~base/Resources/Data/FormsOfEmployment";
import { useIntl } from "~base/Internalization/IntlContext";

export const useStyles = createUseStyles((theme: HendigoTheme) => ({
    container: {
        display: "flex",
        flexDirection: "column",
        height: "100%",
        paddingTop: 0,
        color: theme.inputTextColor,
    },
    inputContainer: {
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "100%",
        padding: "1rem",
    },
    input: {
        width: "calc(100% - 2rem)",
        height: "2rem",
        padding: "0.5rem",
        border: "1px solid #ccc",
        borderRadius: "0.25rem",
        "&:focus": {
            outline: "none",
            border: "1px solid #000"
        },
        backgroundColor: theme.inputBackgroundColor,
        color: theme.inputTextColor,
    },
    inputTextBox: {
        width: "calc(100% - 1rem)",
        height: "4rem",
        border: "1px solid #ccc",
        borderRadius: "0.25rem",
        "&:focus": {
            outline: "none",
            border: "1px solid #000"
        },
        resize: "vertical",
        backgroundColor: theme.inputBackgroundColor,
        color: theme.inputTextColor,
    },
    inputDropDown: {
        width: "calc(100% - 1rem)",
        height: "3.5rem",
        padding: "0.5rem",
        border: "1px solid #ccc",
        borderRadius: "0.25rem",
        "&:focus": {
            outline: "none",
            border: "1px solid #000"
        },
        backgroundColor: theme.inputBackgroundColor,
        color: theme.inputTextColor,
    },
    inputFile: {
        "&:focus": {
            outline: "none",
            border: "1px solid #000"
        },
    },
    inputAutoComplete: {
        width: "calc(100% - 1rem)",
    },
    label: {
        fontSize: "1rem",
        marginBottom: "1rem"
    },
    inputDate: {
        display: "flex",
        width: "100%",
    },
    buttonContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "100%",
        paddingTop: "1rem",
    },
    editButton: {
        marginBottom: -5,
        paddingRight: 10,
    }
}));

interface ExperienceFieldProps {
    session: Session,
    experience: UserExperienceResponse,
    setExperience: (experience: UserExperienceResponse) => void,
    onDelete?: () => void,
}

export const ExperienceField: React.FC<ExperienceFieldProps> = ({ session, experience, setExperience, onDelete }) => {
    
    const intl = useIntl();

    const [companiesSearch, setCompaniesSearch] = React.useState([] as CompanyProfileResponse[]);

    const classes = useStyles();
    const muiClasses = muiStyles();

    const [inputValue, setInputValue] = useState(experience.company_name ? experience.company_name : "");

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            if (inputValue === experience.company_name || !experience.company_name || (experience.company_name && experience.company_name.length < 3)) {
                setCompaniesSearch([]);
                return;
            }

            console.log(`api/v2/get/companyProfileByName/${experience.company_name}`)
            setInputValue(experience.company_name || "");
            apiv2(`api/v2/get/companyProfileByName/${experience.company_name}`, "GET").then((response) => {
                console.log(response)
                if (response.error !== null) {
                    session.setInformation("error", response.error);
                } else {
                    setCompaniesSearch(response.data as CompanyProfileResponse[]);
                }
            });
        }, 500);
        return () => clearTimeout(timeoutId);
    }, [experience.company_name]);

    return (
        <div className={classes.container}>
            <form>
                <Row>
                    <div className={classes.inputContainer}>
                        <label className={classes.label}>{intl.formatText("Views.User.CreateExperience.PositionTitle")}</label>
                        <TextField
                            onChange={(e) => {
                                setExperience({ ...experience, title: e.target.value });
                            }}
                            className={muiClasses.textField}
                            value={experience.title ? experience.title : ""}
                            label={intl.formatText("Views.User.CreateExperience.PositionTitle")}
                        />
                    </div>
                </Row>
                <Row>
                    <div className={classes.inputContainer}>
                        <label className={classes.label}>{intl.formatText("Views.User.CreateExperience.EmploymentType")}</label>
                        <Select
                            className={muiClasses.select}
                            value={experience.position_type ? experience.position_type : "fulltime"}
                            onChange={(e) => {
                                setExperience({ ...experience, position_type: e.target.value });
                            }}
                        >
                            {FormsOfEmployment.map((form) => {
                                return (
                                    <MenuItem
                                        value={form}
                                        className={muiClasses.menuItem}
                                    >
                                        {intl.formatText(form)}
                                    </MenuItem>
                                )
                            })}
                        </Select>
                    </div>
                </Row>
                <Row>
                    <div className={classes.inputContainer}>
                        <label className={classes.label}>{intl.formatText("Views.User.CreateExperience.CompanyName")}</label>
                        <Autocomplete
                            className={muiClasses.autocomplete}
                            options={companiesSearch}
                            value={experience.company_id ? { company_id: experience.company_id, company_name: experience.company_name } as CompanyProfileResponse : { company_id: "", company_name: experience.company_name ? experience.company_name : "" } as CompanyProfileResponse}
                            getOptionLabel={(option: { company_name: any; }) => option.company_name}
                            onChange={(_event, newValue) => {
                                if (newValue) {
                                    setExperience({ ...experience, company_id: newValue.company_id, company_name: newValue.company_name });
                                }
                            }}
                            onInputChange={(_event, newInputValue) => {
                                setExperience({ ...experience, company_name: newInputValue, company_id: "" });
                            }}
                            renderInput={(params) => <TextField {...params} label={intl.formatText("Views.User.CreateExperience.Companies")} variant="outlined" />}
                            renderOption={(props, option) => (
                                <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                    <img
                                        width="30"
                                        src={option.small_image_pathname}
                                        srcSet={`${option.small_image_pathname} 2x`}
                                        alt=""
                                    />
                                    {option.company_name}
                                </Box>
                            )}
                            noOptionsText={intl.formatText("Views.User.CreateExperience.CompanyName.NoOptions")}
                            isOptionEqualToValue={(option, value) => true}
                        />
                    </div>
                </Row>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <div className={classes.inputDate}>
                        <div className={classes.inputContainer}>
                            <label className={classes.label}>{intl.formatText("Views.User.CreateExperience.StartDate")}</label>
                            <DatePicker
                                className={muiClasses.datePicker}
                                value={experience.time_start ? new Date(experience.time_start) : null}
                                onChange={(date: Date | null) => {
                                    date = new Date(date as Date);
                                    setExperience({ ...experience, time_start: date ? date.getFullYear() + "-" + (date.getMonth() + 1).toString() : "" });
                                }}
                                views={["year", "month"]}
                                renderInput={(params) => <TextField {...params} />}
                                maxDate={experience.time_end ? new Date(experience.time_end) : new Date()}
                            />
                        </div>
                        <div className={classes.inputContainer}>
                            <label className={classes.label}>{intl.formatText("Views.User.CreateExperience.EndDate")}</label>
                            <DatePicker
                                className={muiClasses.datePicker}
                                value={experience.time_end ? new Date(experience.time_end) : null}
                                onChange={(date: Date | null) => {
                                    date = new Date(date as Date);
                                    setExperience({ ...experience, time_end: date ? date.getFullYear() + "-" + (date.getMonth() + 1).toString() : "" });
                                }}
                                views={["year", "month"]}
                                renderInput={(params) => <TextField {...params} />}
                                minDate={experience.time_start ? new Date(experience.time_start) : new Date(1900)}
                            />
                        </div>
                    </div>
                </LocalizationProvider>
                <Row>
                    <div className={classes.inputContainer}>
                        <label className={classes.label}>{intl.formatText("Views.User.CreateExperience.Description")}</label>
                        <TextField
                            className={muiClasses.textField}
                            value={experience.description ? experience.description : ""}
                            label={intl.formatText("Views.User.CreateExperience.Description")}
                            multiline
                            rows={4}
                            onChange={(e) => {
                                setExperience({ ...experience, description: e.target.value });
                            }}
                        />
                    </div>
                </Row>
                <Row>
                    <div className={classes.inputContainer}>
                        <label className={classes.label}>{intl.formatText("Views.User.CreateExperience.ExternalLink")}</label>
                        <TextField
                            className={muiClasses.textField}
                            value={experience.link ? experience.link : ""}
                            label={intl.formatText("Views.User.CreateExperience.ExternalLink")}
                            onChange={(e) => {
                                setExperience({ ...experience, link: e.target.value });
                            }}
                        />
                    </div>
                </Row>
                {/*<Row>
                    <div className={classes.inputContainer}>
                        <label className={classes.label}>{intl.formatText("Views.User.CreateExperience.AddMedia")}</label>
                        <input className={classes.inputFile}
                            type="file"
                            accept="image/*, application/pdf"
                            value={experience.media ? experience.media : ""}
                            onChange={(e) => { }}
                        />
                    </div>
                        </Row>*/}
            </form>
            {onDelete &&
                <div className={classes.buttonContainer}>
                    <Button
                        onClick={() => {
                            apiv2(`api/v2/delete/userExperience/${experience.id}`, "DELETE", experience).then((response) => {
                                if (response.error !== null) {
                                    session.setInformation("error", response.error);
                                } else {
                                    setExperience({} as UserExperienceResponse);
                                    onDelete();
                                }
                            });
                        }}
                        colorType="red"
                    >
                        <BiTrash size={20} className={classes.editButton} />
                        {intl.formatText("Views.User.CreateExperience.Delete")}
                    </Button>
                </div>
            }
        </div>
    );
}