import { ViewBaseProps } from "~base/Model/ViewBaseProps";
import { useStyles } from "./Styling";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { initialState, reducer } from "./Store";
import { useIntl } from "~base/Internalization/IntlContext";
import { Box, Grid, Typography, Stack, Avatar, Paper, Card } from "@mui/material";
import { Row } from "~base/Components/Elements/Cosmetic/Row";

interface ViewProps extends ViewBaseProps {

}

export const AboutUs: React.FC<ViewProps> = ({ }) => {
    window.view = "AboutUs";

    const intl = useIntl();

    intl.formatPageTitle("PageTitle.AboutUs");

    // Params
    const { id } = useParams();

    // Styling Classes
    const classes = useStyles();

    // Navigation
    const navigate = useNavigate();

    // State and dispatch
    const [state, dispatch] = React.useReducer(reducer, initialState());

    return (
        <>
            <Box sx={{ bgcolor: '#6518ce', height: '50vh', width: '100%', paddingTop: '100px' }}>
                <Row className={classes.row}>
                    <div>
                        <Typography color={'#eff4f3'} variant="h3" align="left" sx={{ maxWidth: "500px", marginBottom: "25px" }}>
                            Byggnadsbranschens <span className={classes.span}>första</span> och <span className={classes.span}>största</span> digitala nätverks- och annonsplattform.
                        </Typography>

                        <Typography color={'#eff4f3'} variant="subtitle1" align="left" sx={{ maxWidth: "650px" }}>
                            HendiGO erbjuder en digital nätverksplattform för nätverkande,
                            arbetssökande och företag. Våra användare kan utöka sitt nätverk,
                            söka nya jobb och ta del av senaste nyheterna inom sin bransch.
                            Vår plattform hjälper även företag att hitta nya talanger.
                        </Typography>
                    </div>

                    <img
                        className={classes.image}
                        src="images/hendigo/power-plant-construction_1127-2891.jpg"
                        alt="lyftkran"
                    />
                </Row>
            </Box>

            <Box sx={{ bgcolor: '#ffffff', height: '500px', width: '100%', alignContent: 'center', paddingTop: '100px' }}>
                <Row className={classes.row}>
                    <div style={{width: "650px"}} />
                    <div style={{ textAlign: 'left', borderRight: "1px solid #333", paddingRight: "10px", width: "592px" }}>
                        <Typography variant="h5" align="right" sx={{ fontSize: "70px" }}>
                            Vår vision
                        </Typography>

                        <Typography color={'#36454f'} variant="subtitle1" align="right">
                            Vår app och hemsida är användarvänlig och enkel att navigera. Med
                            hjälp av algoritmer kan personer med angivna profiler enkelt hitta
                            matchande företag och kollegor i branschen, och vice versa. Dessutom
                            är det enkelt och gratis att skapa annonser - både vanliga och betalda
                            för större spridning och snabbare rekrytering. Plattformen är
                            tillgänglig och väl anpassad för både smartphones och datorer.
                        </Typography>
                    </div>
                </Row>
            </Box>


            <Box sx={{ bgcolor: '#eff4f3', height: '100%', width: '100%', paddingTop: '50px', paddingBottom: '50px', textAlign: 'center' }}>
                <Box margin={'50px'}>
                    <Stack spacing={16}>
                        <Grid container spacing={2} alignItems={'center'}>
                            <Grid item xs={6} sm={6} >
                                <img width="500" height="410" src="images/hendigo/engineer-meeting-architectural-project-working-with-partner_1421-70.jpg" />
                            </Grid>

                            <Grid item xs={6} sm={5}>
                                <Typography variant="h5" textAlign="left" sx={{ marginBottom: "10px" }}>
                                    Rekrytering för framtidens samhällsbyggnad
                                </Typography>

                                <Typography color={'#36454f'} variant="subtitle1" textAlign={'left'}>
                                    Kompetensförsörjningen i samhällsbyggnadsbranschen är en stor och viktig fråga.
                                    Statistik påvisar att inom de närmsta åren (2026-2030) kommer en stor andel
                                    inom hantverksyrkena att gå i pension (Byggföretagen, 2023).
                                </Typography>

                                <br />

                                <Typography color={'#36454f'} variant="subtitle1" textAlign={'left'}>
                                    Det finns ett särskilt stort behov för rekrytering inom hantverksyrkena,
                                    men det finns även ett stort behov av rekrytering i samtliga yrkeskategorier
                                    där branschen behöver öka både jämställdhet och mångfald för att kunna
                                    spegla och bygga det samhälle som vi lever i. För att hitta de allra bästa
                                    medarbetarna med rätt kompetens tror vi, på HendiGo, på fördomsfri
                                    rekrytering. Genom att använda HendiGO i sitt första steg i sin rekrytering
                                    erbjuder HendiGo effektiva verktyg som ska bidra till en fördomsfri rekrytering.
                                </Typography>
                            </Grid>
                        </Grid>

                        <Grid container spacing={2} alignItems={'center'}>
                            <Grid item xs={6} sm={6} >
                                <Typography variant="h5" textAlign="left" sx={{ marginBottom: "10px" }}>
                                    Central Hub för branschens nätverk och rekrytering
                                </Typography>

                                <Typography color={'#36454f'} variant="subtitle1" textAlign={'left'}>
                                    Denna plattform som HendiGO erbjuder kommer att spela en viktig roll
                                    för kompetensförsörjningen i samhällsbyggnadsbranschen. Den används av
                                    alla nyutbildade och unga inom branschen som vill bygga sitt nätverk
                                    och hitta jobb eller utbildning.
                                </Typography>

                                <br />

                                <Typography color={'#36454f'} variant="subtitle1" textAlign={'left'}>
                                    Dessutom är HendiGO ett verktyg för företag
                                    som strävar efter modernitet, vill delta i branschspecifika forum och genomföra
                                    smidiga rekryteringar. Slutligen är den också värdefull för de redan
                                    etablerade i branschen som vill nätverka, hålla kontakten med gamla kollegor
                                    och hålla sig uppdaterade om branschens utveckling.
                                </Typography>

                            </Grid>

                            <Grid item xs={6} sm={5}>
                                <img width="600" height="400" src="images/hendigo/worker-from-dressed-work-clothes-observing-some-excavators-construction-site-ai-generative_123827-23544.jpg" />
                            </Grid>
                        </Grid>
                    </Stack>
                </Box>
            </Box>


            <Box sx={{ bgcolor: '#6518ce', height: '100%', width: '100%', paddingTop: '100px', justifyContent: 'space-between', paddingBottom: '100px' }}>
                <Box sx={{ alignItems: 'flex-start', marginX: '50px' }}>

                    <Typography variant="h2" color={'#eff4f3'} textAlign={'center'} paddingBottom={'50px'}>
                        Meet the team
                    </Typography>

                    <Grid container spacing={3} alignItems="center" justifyContent="center">
                        <Grid item xs={10} sm={4}>
                            <Card style={{ backgroundColor: "#6518ff", padding: "50px" }}>
                                <Stack alignItems={'center'}>
                                    <Avatar sx={{ width: 56, height: 56 }} />
                                    <Typography variant="subtitle1" textAlign={'left'} color={'#eff4f3'}>
                                        Amanda Eklund
                                    </Typography>

                                    <Typography variant="body1" textAlign={'left'} color={'#eff4f3'}>
                                        Medgrundare, Affärs- och produktutveckling
                                    </Typography>

                                    <Typography variant="body2" textAlign={'left'} color={'#eff4f3'}>
                                        Amanda har läst Affärsutveckling och entreprenörskap inom
                                        samhällsbyggnadsteknik på Chalmers. Hon har varit verksam i
                                        byggbranschen sedan 2018 och hon känner en stark drivkraft
                                        till att förändra och bidra till att branschen utvecklas till
                                        det bättre. Till vardags jobbar Amanda som entreprenadingenjör
                                        på byggbolaget CoForma AB, men hon försöker förändra branschen
                                        på heltid genom att driva sin podcast “Byggbranschens Förebilder”
                                        samt föreläsa för sina branschkollegor inom frågor som berör
                                        jämställdhet, inkludering och normer. För att kunna förändra
                                        beteenden och attityder behöver vi få ökad kunskap och medvetenhet
                                        kring frågorna, men Amanda är också övertygad om att verktyget
                                        HendiGo kommer att kunna bidra till ökad inkludering i byggbranschen
                                        eftersom verktyget är relevant för alla yrkeskategorier att använda.
                                        Branschen behöver också ha en plattform där vi kan fånga upp den yngre
                                        generationen som ska inträda byggbranschen och här kommer HendiGo
                                        kunna spela en viktig roll, anser Amanda. “Det ska vara enkelt att
                                        bygga nätverk och det ska vara tillgängligt, och det erbjuder HendiGo”,
                                        säger hon.
                                    </Typography>
                                </Stack>
                            </Card>
                        </Grid>
                    </Grid>

                    <Grid container spacing={15} alignItems="center" justifyContent="center">
                        <Grid item xs={10} sm={4}>
                            <Card style={{ backgroundColor: "#6518ff", padding: "50px", marginTop: "50px" }}>
                                <Stack alignItems={'center'}>
                                    <Avatar sx={{ width: 56, height: 56 }} />
                                    <Typography variant="subtitle1" textAlign={'left'} color={'#eff4f3'}>
                                        Fredrik Lundström
                                    </Typography>

                                    <Typography variant="body1" textAlign={'left'} color={'#eff4f3'}>
                                        Medgrundare, Mjukvaruutvecklare
                                    </Typography>

                                    <Typography variant="body2" textAlign={'left'} color={'#eff4f3'}>
                                        In his role as a board member and software developer in the company,
                                        Philip contributes with expertise and broad experience to the team.
                                        Philip's background in fintech as a software engineer has given him a
                                        deep understanding of the role of technology and its applications in
                                        the financial sector. His skills extend beyond development and also
                                        include sales and data analysis, which he has acquired through experiences
                                        in the telecommunications and manufacturing industries.
                                    </Typography>
                                </Stack>
                            </Card>
                        </Grid>

                        <Grid item xs={10} sm={4}>
                            <Card style={{ backgroundColor: "#6518ff", padding: "50px", marginTop: "50px" }}>
                                <Stack alignItems={'center'}>
                                    <Avatar sx={{ width: 56, height: 56 }} />
                                    <Typography variant="subtitle1" textAlign={'left'} color={'#eff4f3'}>
                                        Philip Hägg
                                    </Typography>

                                    <Typography variant="body1" textAlign={'left'} color={'#eff4f3'}>
                                        Medgrundare, Mjukvaruutvecklare
                                    </Typography>

                                    <Typography variant="body2" textAlign={'left'} color={'#eff4f3'}>
                                        In his role as a board member and software developer in the company,
                                        Philip contributes with expertise and broad experience to the team.
                                        Philip's background in fintech as a software engineer has given him a
                                        deep understanding of the role of technology and its applications in
                                        the financial sector. His skills extend beyond development and also
                                        include sales and data analysis, which he has acquired through
                                        experiences in the telecommunications and manufacturing industries.
                                    </Typography>
                                </Stack>
                            </Card>
                        </Grid>
                    </Grid>

                </Box>
            </Box>
        </>
    );
}