import { Card, CardContent, Typography } from "@mui/material";
import { createUseStyles } from "react-jss";
import { useIntl } from "~base/Internalization/IntlContext";
import { Product } from "~base/Model/Entities/Product";
import { HendigoTheme } from "~base/Resources/Styling/HendigoTheme";

const useStyles = createUseStyles((theme: HendigoTheme) => ({
    content: {
        backgroundColor: theme.backgroundSecondary,
        color: theme.textPrimary,
    }
}));

interface CheckoutProductProps {
    product: Product;
}

export const CheckoutProduct: React.FC<CheckoutProductProps> = ({ product }) => {
    const intl = useIntl();
    const classes = useStyles();

    return <Card variant="outlined">
        <CardContent className={classes.content} >
            <Typography sx={{ mb: 1.5 }} >
                {intl.formatText(product.name)} - {intl.formatText(product.description)}
            </Typography>
            <Typography variant="h6" component="div">
                {product.price} kr
            </Typography>
        </CardContent>
    </Card>
}