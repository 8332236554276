// ----------------------------------------------------
// Imports
// ----------------------------------------------------

import { isEmail, isOrgNr, isPassword } from "~base/Internalization/Validation";
import { RegisterViewState } from "./Types";



// ----------------------------------------------------
// Actions
// ----------------------------------------------------

// Company actions
type SetCompany = { type: "setCompany", value: boolean }
type SetOrgNumber = { type: "setOrgNumber", value: string }
type SetCompanyName = { type: "setCompanyName", value: string }

// User actions
type SetFullname = { type: "setFullname", value: string }

// Both
type SetEmail = { type: "setEmail", value: string }
type SetPassword = { type: "setPassword", value: string }
type SetPasswordConfirm = { type: "setPasswordConfirm", value: string }
type SetPolicy = { type: "setPolicy", value: boolean }
type SetValid = { type: "setValid", value: boolean }
type SetError = { type: "setError", value: string }

export type RegisterAction =
    SetCompany |
    SetOrgNumber |
    SetCompanyName |
    SetFullname |
    SetEmail |
    SetPassword |
    SetPasswordConfirm |
    SetPolicy |
    SetValid |
    SetError;

// ----------------------------------------------------
// Reducer
// ----------------------------------------------------

export const registerReducer = (state: RegisterViewState, action: RegisterAction): RegisterViewState => {
    switch (action.type) {
        case "setCompany": {
            const newState = {
                ...state,
                company: action.value
            }

            return {
                ...newState,
                valid: validateState(newState)
            }
        }

        case "setOrgNumber": {
            const newState = {
                ...state,
                orgNumber: action.value
            }

            return {
                ...newState,
                valid: validateState(newState)
            }
        }

        case "setCompanyName": {
            const newState = {
                ...state,
                companyName: action.value
            }

            return {
                ...newState,
                valid: validateState(newState)
            }
        }

        case "setFullname": {
            const newState = {
                ...state,
                fullname: action.value
            }

            return {
                ...newState,
                valid: validateState(newState)
            }
        }

        case "setEmail": {
            const newState = {
                ...state,
                email: action.value
            }

            return {
                ...newState,
                valid: validateState(newState)
            }
        }

        case "setPassword": {
            const newState = {
                ...state,
                password: action.value
            }

            return {
                ...newState,
                valid: validateState(newState)
            }
        }

        case "setPasswordConfirm": {
            const newState = {
                ...state,
                passwordConfirm: action.value
            }

            return {
                ...newState,
                valid: validateState(newState)
            }
        }

        case "setPolicy": {
            const newState = {
                ...state,
                policy: action.value
            }

            return {
                ...newState,
                valid: validateState(newState)
            }
        }

        case "setValid":
            return {
                ...state,
                valid: action.value
            }

        case "setError":
            return {
                ...state,
                error: action.value
            }
    }
}

// ----------------------------------------------------
// Initial state
// ----------------------------------------------------

export const initialRegisterState = (): RegisterViewState => {
    return {
        company: false,
        orgNumber: "",
        companyName: "",
        fullname: "",
        email: "",
        password: "",
        passwordConfirm: "",
        policy: false,
        valid: false,
        error: ""
    }
}

// ----------------------------------------------------
// Validation of state
// ----------------------------------------------------

function validateState(state: RegisterViewState): boolean {
    return (
        state.company &&
        isOrgNr(state.orgNumber) &&
        state.companyName.length > 2 &&
        isEmail(state.email) &&
        isPassword(state.password) &&
        state.passwordConfirm === state.password &&
        state.policy === true
    ) ||
        (
            !state.company &&
            state.fullname.length > 2 &&
            isEmail(state.email) &&
            isPassword(state.password) &&
            state.passwordConfirm === state.password &&
            state.policy
        )
}