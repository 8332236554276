import React from "react";
import { AiOutlineEdit } from "react-icons/ai";
import { createUseStyles } from "react-jss";
import { Row } from "../Cosmetic/Row";

const useStyles = createUseStyles({
    fill: {
        width: "100%",
        height: "100%",
        backgroundColor: "black",
        opacity: "0.3",
        borderRadius: "10px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },preview: {
        height: "150px",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        borderRadius: "10px",
        backgroundColor: "black",
        '&:hover': {
            cursor: "pointer"
        }
    },
    editIcon: {
        fontSize: "35px"
    }
})

interface UploadFileButtonProps {
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void,
    fileTypes: string,
    name: string,
    id: string,
    originalURL: string,
    previewCSSWidth?: string
}

export const UploadFileButton: React.FC<UploadFileButtonProps> = ({ id, name, onChange, fileTypes, originalURL, previewCSSWidth = "150px" }) => {
    const [imageURL, setImageURL] = React.useState<string>(originalURL);
    const classes = useStyles();

    const input = React.useRef<HTMLInputElement>(null);

    const [showFill, setShowFill] = React.useState<boolean>(false);

    return <Row>
        
        <div 
            className={classes.preview} 
            style={{ 
                backgroundImage: "url(" + imageURL + ")",
                width: previewCSSWidth
            }}  
            onClick={() => input.current?.click()}
            onMouseEnter={() => setShowFill(true)}
            onMouseLeave={() => setShowFill(false)}
        >
            {showFill ? 
                <div className={classes.fill}>
                    <AiOutlineEdit className={classes.editIcon} />
                </div>
            : null}
        </div>

        <input
            onChange={e => {
                setImageURL(URL.createObjectURL(e.target.files![0]));
                onChange(e);
            }}
            ref={input}
            type="file"
            id={id} 
            name={name} 
            accept={fileTypes} 
            style={{display: "none"}}
        />
    </Row>
}