// ----------------------------------------------------
// Imports
// ----------------------------------------------------

import { Application } from "~base/Model/Entities/Application";
import { ViewState } from "./Types";
import { AdResponse } from "~base/Model/ResponseModels/AdResponse";

// ----------------------------------------------------
// Actions
// ----------------------------------------------------

type SetLoading = { type: "setLoading", value: boolean }
type SetLoadingMessage = { type: "setLoadingMessage", value: string }
type SetApplication = { type: "setApplication", value: Application }
type SetAd = { type: "setAd", value: AdResponse }

export type ViewAction = SetLoading | SetLoadingMessage | SetApplication | SetAd;

// ----------------------------------------------------
// Reducer
// ----------------------------------------------------

export const reducer = (state: ViewState, action: ViewAction): ViewState => {
    switch (action.type) {
        case "setLoading":
            return {
                ...state,
                loading: action.value
            }

        case "setLoadingMessage":
            return {
                ...state,
                loadingMessage: action.value
            }

        case "setApplication":
            return {
                ...state,
                application: action.value
            }

        case "setAd":
            return {
                ...state,
                ad: action.value
            }

        default:
            return {
                ...state
            }
    }
}

// ----------------------------------------------------
// Initial State
// ----------------------------------------------------

export const initialState = (): ViewState => {
    return {
        ad: null,
        loading: false,
        loadingMessage: "",
        coverLetter: "",
        application: null
    }
}