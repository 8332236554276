import { RegisterStyles } from "./Styling";
import { useNavigate } from "react-router-dom";
import React from "react";
import { Row } from "~base/Components/Elements/Cosmetic/Row";
import { ViewBaseProps } from "~base/Model/ViewBaseProps";
import { initialRegisterState, registerReducer } from "./Store";
import { RegisterCompany } from "./ChildComponents/RegisterCompany";
import { Button, Checkbox, FormControlLabel, Link, Switch } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";
import { Link as RouterLink } from "react-router-dom";
import { RegisterUser } from "./ChildComponents/RegisterUser";
import { apiv2 } from "~base/API/v2/apiv2";
import { RegisterCompanyRequest } from "~base/Model/RequestModels/RegisterCompanyRequest";
import { RegisterUserRequest } from "~base/Model/RequestModels/RegisterUserRequest";
import { muiStyles } from "~base/Resources/Styling/MuiTheme";
import { LOGIN_PATH } from "~base/Routing/Paths";
import { useIntl } from "~base/Internalization/IntlContext";

export const Register: React.FC<ViewBaseProps> = ({ session }) => {

    const intl = useIntl();

    window.view = "Register";
    intl.formatPageTitle("PageTitle.Register");

    const redirect = new URLSearchParams(window.location.search).get("redirect");

    const [state, dispatch] = React.useReducer(registerReducer, initialRegisterState());

    window.dispState = state;

    //Navigation
    const navigate = useNavigate();

    // Styling
    const classes = RegisterStyles();
    const muiClasses = muiStyles();

    return <div className={classes.registerCard}>
        <h2 className={classes.title} >{intl.formatText("Views.Register.Register")}</h2>

        <Row style={{ alignItems: "center", marginBottom: "1rem" }}>
            <div>{intl.formatText("Views.Register.Company")}</div>

            <Switch
                checked={state.company}
                onChange={(e) => {
                    dispatch({ type: "setCompany", value: e.target.checked });
                }}
                className={muiClasses.switch}
            />
        </Row>

        {state.company ?
            <RegisterCompany
                state={state}
                dispatch={dispatch}
            />
            :
            <RegisterUser
                state={state}
                dispatch={dispatch}
            />
        }

        <Grid2 container spacing={2} marginTop={"1rem"}>
            <Grid2 xs={12}>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={state.policy}
                            onChange={(e) => dispatch({ type: "setPolicy", value: e.target.checked })}
                        />
                    }
                    label={
                        <>
                            {intl.formatText("Views.Register.AcceptTerms")}
                            <Link
                                className={classes.policy}
                                href={"https://hendigo.se/integritetspolicy.pdf"}
                                target="_blank"
                            >
                                {intl.formatText("Views.Register.PrivacyPolicy")}
                            </Link>
                        </>
                    }
                />
            </Grid2>

            <Grid2 xs={12}>
                <Button
                    variant="contained"
                    style={{ display: "block", margin: "0 auto", width: "250px" }}
                    className={muiClasses.button}
                    onClick={() => {
                        dispatch({ type: "setError", value: "" });
                        session.setIsLoading(true);
                        if (state.company) {
                            apiv2("api/v2/post/registerCompany", "POST", {
                                company_name: state.companyName,
                                org_number: state.orgNumber,
                                email: state.email,
                                password: state.password
                            } as RegisterCompanyRequest).then((response) => {
                                if (response.error != null) {
                                    //props.session.setInformation("error", response.error);
                                    
                                    dispatch({ type: "setValid", value: false });
                                    dispatch({ type: "setError", value: response.error });
                                } else {
                                    if(redirect){
                                        navigate(`${LOGIN_PATH}?redirect=${redirect}`);
                                    } else {
                                        navigate(LOGIN_PATH);
                                    }
                                }
                            });
                        } else {
                            apiv2("api/v2/post/registerUser", "POST", {
                                fullname: state.fullname,
                                email: state.email,
                                password: state.password
                            } as RegisterUserRequest).then((response) => {
                                if (response.error != null) {
                                    //props.session.setInformation("error", response.error);
                                    dispatch({ type: "setValid", value: false });
                                    dispatch({ type: "setError", value: response.error });
                                } else {
                                    if(redirect){
                                        navigate(`${LOGIN_PATH}?redirect=${redirect}`);
                                    } else {
                                        navigate(LOGIN_PATH);
                                    }
                                }
                            });
                        }
                        session.setIsLoading(false);
                    }}
                    disabled={!state.valid}
                >
                    {intl.formatText("Views.Register.Register")}
                </Button>
            </Grid2>

            <p className={classes.errorText}>{intl.formatText(state.error)}</p>

            <Grid2 xs={12}>
                <Link
                    style={{ display: "block", textAlign: "center" }}
                    component={RouterLink}
                    to={redirect ? `${LOGIN_PATH}?redirect=${redirect}` : LOGIN_PATH}
                >
                    {intl.formatText("Views.Register.AlreadyHaveAccount")}
                </Link>
            </Grid2>
        </Grid2>
    </div>
}