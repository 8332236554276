// ----------------------------------------------------
// Imports
// ----------------------------------------------------

import React from "react";
import { createUseStyles } from "react-jss"
import { HendigoBaseTheme } from "~base/Resources/Styling/HendigoBaseTheme";
import { Overlay } from "~base/Components/Elements/Cosmetic/Overlay";
import { Row } from "~base/Components/Elements/Cosmetic/Row";
import { Session } from "~base/Model/Session";
import { HendigoTheme } from "~base/Resources/Styling/HendigoTheme";
import { apiv2 } from "~base/API/v2/apiv2";
import { PostResponse } from "~base/Model/ResponseModels/PostResponse";
import { useIntl } from "~base/Internalization/IntlContext";

// ----------------------------------------------------
// Styling
// ----------------------------------------------------

const useStyles = createUseStyles((theme: HendigoTheme) => {
    return {
        title: {
            color: HendigoBaseTheme.colors.hendigoLightGray + " !important"
        },
        updatePasswordButton: {
            marginTop: "20px"
        },
        alternativeList: {
            backgroundColor: HendigoBaseTheme.colors.hendigoDark,
            opacity: 0.96
        },
        openForNewJobsText: {
            marginLeft: "8px"
        },
        openForNewJobsRow: {
            marginTop: "20px"
        },
        poster_textarea: {
            width: "100%",
            minHeight: "18px",
            height: "250px",
            overflow: "auto",
            outline: "1px solid " + theme.border,
            border: "none",
            borderRadius: "20px",
            backgroundColor: theme.inputBackgroundColor,
            padding: "12px",
            resize: "none",
            fontFamily: "Arial",
            fontSize: "14px",
            margin: "10px 0px 10px 0px",
            color: theme.inputTextColor,
            '&:focus': {
                outline: "1px solid " + theme.inputTextColor,
                resize: "vertical",
                borderRadius: "20px 20px 0px 20px"
            },
        },
    }
});

// ----------------------------------------------------
// Props
// ----------------------------------------------------

interface EditCompanyOverlayProps {
    session: Session,
    onCancel: () => void,
    onSave: () => void,
    post: PostResponse | null,
    show: boolean,
}

// ----------------------------------------------------
// Component
// ----------------------------------------------------

export const EditCompanyPostOverlay: React.FC<EditCompanyOverlayProps> = ({ session, show, onCancel, onSave, post }) => {
    
    const intl = useIntl();
    const classes = useStyles();
    const [text, setText] = React.useState(post?.content);

    React.useEffect(() => {
        setText(post?.content);
    }, [post]);

    return <Overlay
        show={show}
        title={intl.formatText("Views.User.EditPostTitle")}
        onCancel={onCancel}
        onSave={() => {
            if (text && post) {
                const obj = {
                    postContent: text
                };
                apiv2(`api/v2/put/companyPost/${post.id}`, "PUT", obj).then((response) => {
                    if (response.error !== null) {
                        session.setInformation("error", response.error);
                    } else {
                        onCancel();
                    }
                });
            }
        }}
    >
        <Row>
            <textarea
                className={classes.poster_textarea}
                placeholder={intl.formatText("Views.Feed.Share")}
                value={text}
                onChange={(e) => {
                    if (e.target.value.length <= 2000)
                        setText(e.target.value);
                }}
            />
        </Row>
    </Overlay>
}