import React from "react";
import { BsSearch } from "react-icons/bs";
import { createUseStyles, useTheme } from "react-jss";
import { HendigoBaseTheme } from "../../../Resources/Styling/HendigoBaseTheme";
import { Row } from "../Cosmetic/Row";
import { HendigoTheme } from "~base/Resources/Styling/HendigoTheme";

const useStyles = createUseStyles((theme: HendigoTheme) => ({
    search_button: {
        color: "white",
        backgroundColor: HendigoBaseTheme.colors.hendigoBlue,
        padding: "10px",
        width: "20px",
        borderRadius: "0px 20px 20px 0px",
        margin: "auto 0",
        '&:hover': {
            cursor: "pointer",
            backgroundColor: HendigoBaseTheme.colors.hendigoDarkBlue,
        },
    },
    searchbar: {
        border: '2px solid transparent',
        borderRadius: '20px 0px 0px 20px',
        backgroundColor: HendigoBaseTheme.colors.hendigoDark,
        color: "white",
        padding: '8px',
        paddingLeft: "12px",
        height: '20px',
        width: 'calc(100% - 75px)',
        maxWidth: "625px",
        outline: "none",
        margin: "auto 0",
        '&:focus, &:focus + $search_button': {
            boxShadow: "none",
            outline: "1px solid " + HendigoBaseTheme.colors.hendigoLightGray + " !important"
        }
    },
    searchbarLight: {
        border: "5px solid " + theme.inputBackgroundColor,
        borderRadius: '20px 0px 0px 20px',
        backgroundColor: theme.inputBackgroundColor,
        color: theme.inputTextColor,
        padding: '8px',
        paddingLeft: "12px",
        height: '20px',
        width: 'calc(100% - 75px)',
        margin: "auto 0",
        '&:focus, &:focus + $search_button': {
            boxShadow: "none",
            outline: "none"
        }
    }
}));

interface SearchBarProps {
    placeholder?: string,
    value?: string,
    onChange?: (value: string) => void,
    onSearch?: () => void,
    colorType?: "light" | "dark"
}

export const SearchBar: React.FC<SearchBarProps> = ({ placeholder, value, onChange = () => { }, colorType = "dark", onSearch = () => {} }): JSX.Element => {
    const classes = useStyles();
    const theme: HendigoTheme = useTheme();
    
    return <Row style={{ width: "100%", justifyContent: "center" }}>
        <input
            value={value}
            className={colorType === "dark" ? classes.searchbar : classes.searchbarLight}
            onChange={e => {
                onChange(e.target.value)
            }}
            type="text"
            placeholder={placeholder}
            onKeyDown={e => e.key === 'Enter' ? onSearch() : null}
        />
        <BsSearch
            onClick={onSearch}
            className={classes.search_button}
            style={{
                outline: (colorType === "dark" ? "none" : "5px solid " + theme.inputBackgroundColor),
                height: (colorType === "dark" ? "20px" : "16px")
            }}
        />
    </Row>
}