import { createUseStyles } from "react-jss"

const useStyles = createUseStyles({
    'placeholder': {
        backgroundColor: "#eee",
    },
    '@keyframes placeHolderShimmer': {
        "0%": {
            backgroundPosition: "-468px 0"
        },
        "100%": {
            backgroundPosition: "468px 0"
        }
    },
    'animatedBackground': {
        animationDuration: "1.25s",
        animationFillMode: "forwards",
        animationIterationCount: "infinite",
        animationName: "$placeHolderShimmer",
        animationTimingFunction: "linear",
        //background: "darkgray",
        background: "linear-gradient(to right, #eeeeee 10%, #dddddd 18%, #eeeeee 33%)",
        backgroundSize: "800px 104px",
        position: "relative"
    }
});

interface PlaceholderProps {
    cssHeight?: string,
    cssWidth?: string,
    style?: React.CSSProperties
}

export const Placeholder: React.FC<PlaceholderProps> = ({ cssHeight = "20px", cssWidth = "100%", style }) => {
    const classes = useStyles();

    return <div
        style={{ ...style, height: cssHeight, width: cssWidth }}
        className={classes.placeholder}
    >
        <div 
            style={{height: cssHeight}}
            className={classes.animatedBackground}
        />
    </div>
}