import { createUseStyles } from "react-jss"
import { HendigoTheme } from './HendigoTheme';

export const muiStyles = createUseStyles((theme: HendigoTheme) => ({
  textField: {
    "& .MuiInputBase-input": {
      backgroundColor: theme.inputBackgroundColor,
      border: theme.border,
      borderRadius: "4px",
      color: theme.inputTextColor,
    },
    "& .MuiInputLabel-root": {
      color: theme.textSecondary,
    },
    "& .MuiInputBase-root": {
      backgroundColor: theme.inputBackgroundColor,
      border: theme.border,
      color: theme.inputTextColor,
      "&:hover": {
        borderColor: theme.blue,
      },
    },
    "& .MuiInputBase-input:-webkit-autofill": {
      WebkitBoxShadow: `0 0 0 100px ${theme.inputBackgroundColor} inset !important`,
      WebkitTextFillColor: theme.inputTextColor,
      borderRadius: "0",
    }
  },
  autocomplete: {
    "& .MuiInputBase-input": {
      backgroundColor: theme.inputBackgroundColor,
      border: theme.border,
      color: theme.inputTextColor,
    },
    "& .MuiInputLabel-root": {
      color: theme.textSecondary,
    },
    "& MuiInputBase-root": {
      backgroundColor: theme.inputBackgroundColor,
      border: theme.border,
      color: theme.inputTextColor,
      "&:hover": {
        borderColor: theme.blue,
      },
    },
    "& .MuiAutocomplete-inputRoot": {
      backgroundColor: theme.inputBackgroundColor,
      border: theme.border,
      color: theme.inputTextColor,
      "&:hover": {
        borderColor: theme.blue,
      },
    },
  },
  button: {
    backgroundColor: theme.blue,
  },
  checkbox: {
    "& .MuiTypography-root": {
      color: theme.textPrimary,
    },
  },
  select: {
    "& .MuiSelect-select": {
      backgroundColor: theme.inputBackgroundColor,
      border: theme.border,
      color: theme.textPrimary,
      "&:hover": {
        borderColor: theme.blue,
      },
      "&:focus": {
        borderColor: theme.blue,
        boxShadow: `0 0 0 0.1rem ${theme.blue}50`,
      },
    },
    "& .MuiInputLabel-root": {
      color: theme.textSecondary,
    },
  },
  switch: {
    "& .MuiSwitch-thumb": {
      backgroundColor: theme.blue,
    },
    "& .MuiSwitch-track": {
      backgroundColor: theme.textPrimary,
    },
    "& .MuiTypography-root": {
      color: theme.textPrimary,
    },
  },
  buttonGroup: {
    "& .MuiButton-root": {
      backgroundColor: theme.inputBackgroundColor,
      border: theme.border,
      color: theme.textPrimary,
      "&:hover": {
        backgroundColor: theme.blue,
        border: theme.blue,
      },
      "&.Mui-selected": {
        backgroundColor: theme.blue,
        border: theme.blue,
        color: theme.textPrimary,
        "&:hover": {
          backgroundColor: theme.blue,
          border: theme.blue,
        },
      },
    },
  },
  datePicker: {
    "& .MuiInputBase-input": {
      backgroundColor: theme.backgroundPrimary,
      border: theme.border,
      color: theme.textPrimary,
      width: "100%",
      "&:hover": {
        borderColor: theme.blue,
      },
      "&:focus": {
        borderColor: theme.blue,
        boxShadow: `0 0 0 0.1rem ${theme.blue}50`,
      },
    },
    "& .MuiInputLabel-root": {
      color: theme.textSecondary,
    },
    "& .MuiPickersDay-day:not(.MuiPickersDay-dayDisabled)": {
      color: theme.textPrimary,
    },
    "& .MuiPickersDay-daySelected:not(.MuiPickersDay-dayDisabled)": {
      backgroundColor: theme.blue,
      color: theme.textPrimary,
      "&:hover": {
        backgroundColor: theme.blue,
      },
    },
    "& .MuiPickersCalendarHeader-switchHeader": {
      color: theme.textPrimary,
    },
    "& .MuiIconButton-root": {
      color: theme.textPrimary,
    },
    "& .MuiPickersCalendarHeader-iconButton": {
      color: theme.textPrimary,
    },
    "& .MuiIconButton-root:hover": {
      backgroundColor: "transparent",
    },
    "& .MuiTypography-root.MuiPickersDatePickerHeader-toolbarText": {
      color: theme.textPrimary,
    },
    "& .MuiPickersYear-yearSelected": {
      backgroundColor: theme.blue,
      color: theme.textPrimary,
      "&:hover": {
        backgroundColor: theme.blue,
      },
    },
    "& .MuiPickersYear-year": {
      color: theme.textPrimary,
    },
  },
  menuItem: {
    // backgroundColor: theme.inputBackgroundColor,
    // color: theme.textPrimary,
    // "& .MuiMenuItem-root": {
    //   backgroundColor: theme.inputBackgroundColor,
    //   color: theme.textPrimary,
    //   "&:hover": {
    //     backgroundColor: theme.inputBackgroundColor,
    //     color: theme.textPrimary,
    //   },
    //   "&.Mui-selected": {
    //     backgroundColor: theme.inputBackgroundColor,
    //     color: theme.textPrimary,
    //     "&:hover": {
    //       backgroundColor: theme.inputBackgroundColor,
    //       color: theme.textPrimary,
    //     },
    //   },
    // },
    // "& .MuiMenu-list": {
    //   backgroundColor: theme.inputBackgroundColor,
    //   color: theme.textPrimary,
    //   "&:hover": {
    //     backgroundColor: theme.inputBackgroundColor,
    //     color: theme.textPrimary,
    //   },
    // },
    // "& .MuiList-root": {
    //   backgroundColor: theme.inputBackgroundColor,
    //   color: theme.textPrimary,
    //   "&:hover": {
    //     backgroundColor: theme.inputBackgroundColor,
    //     color: theme.textPrimary,
    //   },
    // },
    // "& .MuiPaper-root": {
    //   backgroundColor: theme.inputBackgroundColor,	
    //   color: "white !important",
    // },
    // "& .MuiTouchRipple-root": {
    //   backgroundColor: theme.inputBackgroundColor,
    //   color: theme.textPrimary,
    // },
    // "& .MuiButtonBase-root": {
    //   backgroundColor: theme.inputBackgroundColor,
    //   color: theme.textPrimary,
    // },
    // "& .MuiMenuItem-gutters": {
    //   backgroundColor: theme.inputBackgroundColor,
    //   color: theme.textPrimary,
    // },
  },
}));
