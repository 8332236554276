import { CreateAdAction } from "../Store";
import { CreateAdViewState } from "../Types";
import { Session } from "~base/Model/Session";
import { CompositeAd } from "~base/Components/Composite/Ad/Component";
import { Row } from "~base/Components/Elements/Cosmetic/Row";
import { Button } from "@mui/material";
import { HiOutlineArrowNarrowLeft, HiOutlineArrowNarrowRight } from "react-icons/hi";
import { useIntl } from "~base/Internalization/IntlContext";

interface PreviewAdProps {
    state: CreateAdViewState;
    dispatch: React.Dispatch<CreateAdAction>;
    session: Session;
}

export const PreviewAd: React.FC<PreviewAdProps> = ({ state, dispatch, session }) => {

    const intl = useIntl();

    return <>
        <CompositeAd
            ad={state.ad}
            adRequirements={state.adRequirements}
            loading={false}
            applyButtonDisabled={true}
        />
        <Row style={{ marginTop: "2rem" }}>
            <Button
                variant="contained"
                onClick={() => dispatch({ type: "setCurrentStep", value: "Views.CreateAd.Step.Information" })}
            //disabled
            >
                <HiOutlineArrowNarrowLeft style={{ marginRight: "5px" }} />
                {intl.formatText("Views.CreateAd.PreviousStep")}
            </Button>

            <Button
                variant="contained"
                onClick={() => dispatch({ type: "setCurrentStep", value: state.invoice.amount > 0 ? "Views.CreateAd.Step.Payment" : "Views.CreateAd.Step.Create" })}
                style={{ marginLeft: "auto" }}
            //disabled
            >
                {intl.formatText("Views.CreateAd.NextStep")}
                <HiOutlineArrowNarrowRight style={{ marginLeft: "5px" }} />
            </Button>
        </Row>
    </>
}