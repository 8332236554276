// ----------------------------------------------------
// Imports
// ----------------------------------------------------

import { AdResponse } from "~base/Model/ResponseModels/AdResponse";
import { ViewState } from "./Types";
import { MyApplicationsResponse } from "~base/Model/ResponseModels/MyApplicationsResponse";

// ----------------------------------------------------
// Actions
// ----------------------------------------------------

type SetLoading = { type: "setLoading", value: boolean }
type SetLoadingMessage = { type: "setLoadingMessage", value: string }
type SetApplications = { type: "setApplications", value: MyApplicationsResponse[] }

export type ViewAction = SetLoading | SetLoadingMessage | SetApplications;

// ----------------------------------------------------
// Reducer
// ----------------------------------------------------

export const reducer = (state: ViewState, action: ViewAction): ViewState => {
    switch (action.type) {
        case "setLoading":
            return {
                ...state,
                loading: action.value
            }

        case "setLoadingMessage":
            return {
                ...state,
                loadingMessage: action.value
            }

        case "setApplications":
            return {
                ...state,
                applications: action.value
            }
            
        default:
            return {
                ...state
            }
    }
}

// ----------------------------------------------------
// Initial State
// ----------------------------------------------------

export const initialState = (): ViewState => {
    return {
        loading: true,
        loadingMessage: "Views.MyApplications.LoadingMessage",
        applications: []
    }
}